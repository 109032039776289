import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Form,
  InputGroup,
  Modal,
  Table,
  Tab,
  Card,
  FloatingLabel,
  Toast,
} from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import {
  getAllCoinsAction,
  getrateOfpairs,
  getRangesAction,
  getPairsOfpairs,
} from "../../coreFiles/user.action";
import config from "../../coreFiles/config";
import Tilt from "react-parallax-tilt";
import {
  ErrorMessage,
  FixedExchangeMessage,
  MaximumAmountMessage,
  MinimumAmountMessage,
} from "./ToastAlert";
import { CurrencyDropDown } from "./CurrencyDropdown";
import { InputLoader, LockIcon } from "./Icons";
import { useState, useMemo, useEffect } from "react";
import { Enums } from "./Enum";
import FixedPair from "./FixedPair.json";
import FloatingPair from "./FloatingPair.json";
import { useSwiperSlide } from "swiper/react";
// import { IoSwapVerticalOutline } from "react-icons/io5";
import { MdSwapVert } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Shimmer } from "react-shimmer";
import { ClipLoader } from "react-spinners";
import {useParams } from "react-router-dom";

export const SwapForm = (props) => {
  const { ActiveTab, hideExchangeBtn, refreshPrice } = props;

  const Navigate = useNavigate();
  const [isShowPrice, setisShowPrice] = useState(true);
  const [ActiveFromPairs, setActiveFromPairs] = useState([]);
  const [ActiveToPairs, setActiveToPairs] = useState([]);
  const [loadTimer, setLoadTimer] = useState(0);

  const [listCoins, setListCoins] = useState([]);
  const [listAllCoins, setListAllCoins] = useState([]);
  const [upperDropdown, setupperDropdown] = useState({ symbol: "BTC" });
  const [lowerDropdown, setlowerDropdown] = useState({ symbol: "ETH" });
  const [upperModel, setupperModel] = useState(false);
  const [lowerModel, setlowerModel] = useState(false);
  const [exchangebtn, setexchangebtn] = useState(false);

  const [upperValue, setupperValue] = useState(0.1);
  const [lowerValue, setlowerValue] = useState(0);
  const [upperMinMaxValue, setupperMinMaxValue] = useState({ min: 0, max: 0 });

  const [CoinPrice, setCoinPrice] = useState(0);
  const [FixedMessageHide, setFixedMessageHide] = useState(false);
  const [Error, setError] = useState("");
  const [MessageType, setMessageType] = useState("");
  const [isLoader, setIsLoader] = useState({ on: false, type: "" });
  const [PriceLoader, setPriceLoader] = useState(true);
  let { fromCoin, toCoin } = useParams();
  const navigate = useNavigate();

  useMemo(() => {
    setTimeout(() => {
      setexchangebtn(false);
    }, 500);
  }, [exchangebtn]);

  useEffect(() => {
    getCoins();
    setMessageType("");
    setPriceLoader(true);

    if (
      (props.upperValue > 0 || upperValue > 0) &&
      ActiveTab == Enums.FloatingRate
    ) {
      setupperValue(props.upperValue || upperValue);
      getLowerValue(props.upperValue || upperValue);
    }
    if (
      (props.lowerValue > 0 || lowerValue > 0) &&
      ActiveTab == Enums.FixedRate
    ) {
      setlowerValue(props.lowerValue || lowerValue);
      getUpperValue(props.lowerValue || lowerValue);
    }
    closeModel();
  }, [ActiveTab]);

  useEffect(() => {
    if (upperValue > 0 && ActiveTab == Enums.FloatingRate) {
      getLowerValue(upperValue);
    }
    if (lowerValue > 0 && ActiveTab == Enums.FixedRate) {
      getUpperValue(lowerValue);
    }
  }, [upperDropdown, lowerDropdown]);

  useEffect(() => {
    if (refreshPrice) {
      if (ActiveTab == Enums.FixedRate) {
        getRanges(upperDropdown, lowerDropdown);
        getUpperValue(lowerValue);
      } else {
        getRanges(upperDropdown, lowerDropdown);
        getLowerValue(upperValue);
      }
    }
  }, [refreshPrice]);

  useEffect(() => {
    if (listAllCoins.length > 0) {
      getFromPairs(upperDropdown);
      getToPairs(lowerDropdown);
    }
  }, [listAllCoins]);
  useEffect(() => {
    if (PriceLoader) {
      return;
    }
    let value = ActiveTab == Enums.FixedRate ? lowerValue : upperValue;
    if (
      parseFloat(value) == 0 ||
      parseFloat(value) < parseFloat(upperMinMaxValue?.min || 0)
    ) {
      setMessageType("Minimum");
    } else if (parseFloat(value) > parseFloat(upperMinMaxValue?.max || 0)) {
      setMessageType("Maximum");
    } else if (ActiveTab == Enums.FixedRate && !FixedMessageHide) {
      setMessageType("Fixed");
    } else if (ActiveTab == Enums.FloatingRate || FixedMessageHide) {
      setMessageType("Price");
    }
  }, [
    PriceLoader,
    upperMinMaxValue,
    upperValue,
    lowerValue,
    upperDropdown,
    lowerDropdown,
    FixedMessageHide,
  ]);

  const getFromPairs = async (item) => {
    let res = await getPairsOfpairs({
      fromCurrency: item.ticker,
      fromNetwork: item.network,
      flow: ActiveTab,
    });
    let ArrayData = listAllCoins.filter((object) => {
      if (
        res.findIndex(
          (item) =>
            item.toNetwork == object.network &&
            item.toCurrency.toLowerCase() == object.ticker.toLowerCase()
        ) > -1
      ) {
        return object;
      }
    });
    setActiveFromPairs(ArrayData);

    return ArrayData;
  };

  const getToPairs = async (item) => {
    let res = await getPairsOfpairs({
      toCurrency: item.ticker,
      toNetwork: item.network,
      flow: ActiveTab,
    });
    let ArrayData = listAllCoins.filter((object) => {
      if (
        res.findIndex(
          (item) =>
            item.fromNetwork == object.network &&
            item.fromCurrency.toLowerCase() == object.ticker.toLowerCase()
        ) > -1
      ) {
        return object;
      }
    });
    setActiveToPairs(ArrayData);
    return ArrayData;
  };

  const searchCoin = (e) => {
    const { name, value } = e.target;
    if (lowerModel) {
      setListCoins(
        listAllCoins.filter(
          (object) =>
            (object.ticker.toUpperCase().includes(value.toUpperCase()) ||
              object.name.toUpperCase().includes(value.toUpperCase())) &&
            object.legacyTicker != upperDropdown.legacyTicker
        )
      );
    } else {
      setListCoins(
        listAllCoins.filter(
          (object) =>
            (object.ticker.toUpperCase().includes(value.toUpperCase()) ||
              object.name.toUpperCase().includes(value.toUpperCase())) &&
            object.legacyTicker != lowerDropdown.legacyTicker
        )
      );
    }
  };

  const getCoins = async () => {
    let res = await getAllCoinsAction({
      active: true,
      flow: ActiveTab,
    });
    if (res && res.length > 0) {
      let fromTicker = props.fromTicker || upperDropdown.legacyTicker || "btc";
      let toTicker = props.toTicker || lowerDropdown.legacyTicker || "eth";
      let fromArray = res.find((item) => item.legacyTicker == fromTicker);
      let toArray = res.find((item) => item.legacyTicker == toTicker);
      if(fromCoin != undefined){
        fromTicker = fromCoin;
        if(fromCoin == 'usdt'){
          fromArray = res.find((item) => item.legacyTicker == 'usdterc20');
        }
        else{
          fromArray = res.find((item) => item.legacyTicker == fromTicker);
        }
      }
      if(toCoin != undefined){
        toTicker = toCoin;
        if(toCoin == 'usdt'){
          toArray = res.find((item) => item.legacyTicker == 'usdterc20');
        }
        else{
          toArray = res.find((item) => item.legacyTicker == toTicker);
        }
      }
      setupperDropdown(fromArray);
      props?.setFrom(fromArray);

      setlowerDropdown(toArray);
      props?.setTo(toArray);

      setListAllCoins(res);

      getRanges(fromArray, toArray);
    }
  };

  const selectCoin = async (from, to, isUpperChange) => {
    navigate(`${config.baseUrl}${from.ticker}/${to.ticker}`)
    setPriceLoader(true);
    setupperDropdown(from);
    props?.setFrom(from);
   
    setlowerDropdown(to);
    props?.setTo(to);

    if (isUpperChange) {
      const res = await getFromPairs(from);
      if (
        res.findIndex(
          (item) =>
            item.network == to.network &&
            item.ticker.toLowerCase() == to.ticker.toLowerCase()
        ) == -1
      ) {
        to = res[0];
      }
    } else {
      const res = await getToPairs(to);
      if (
        res.findIndex(
          (item) =>
            item.network == from.network &&
            item.ticker.toLowerCase() == from.ticker.toLowerCase()
        ) == -1
      ) {
        from = res[0];
      }
    }
    // setupperDropdown(from);
    // props?.setFrom(from);

    // setlowerDropdown(to);
    // props?.setTo(to);

    getRanges(from, to);
  };

  const getRanges = async (from, to) => {
    try {
      // from = ActiveTab == Enums.FixedRate ? to : from;
      // to = ActiveTab == Enums.FixedRate ? from : to;
      let resupper = await getRangesAction({
        fixed: ActiveTab == Enums.FixedRate,
        fromCurrency: from.ticker ? from.ticker : "btc",
        toCurrency: to.ticker ? to.ticker : "eth",
        fromNetwork: from.network,
        toNetwork: to.network,
        flow: ActiveTab,
      });
      if (resupper) {
        getCoinPrice(resupper, from, to);
        // setupperMinMaxValue({ min: resupper.minAmount, max: !resupper.maxAmount ? 99999999999 : resupper.maxAmount });
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const getCoinPrice = async (resupper, from, to) => {
    try {
      setPriceLoader(true);
      let rate = await getrateOfpairs({
        fromCurrency: from.ticker ? from.ticker : "btc",
        toCurrency: to.ticker ? to.ticker : "eth",
        fromAmount: resupper.minAmount,
        fromNetwork: from.network,
        toNetwork: to.network,
        flow: ActiveTab,
      });
      let price =
        (parseFloat(rate.toAmount) + parseFloat(rate.withdrawalFee)) /
        (parseFloat(rate.fromAmount) - parseFloat(rate.depositFee));
      setCoinPrice(price);
      if (ActiveTab == Enums.FixedRate) {
        setupperMinMaxValue({
          min: (resupper.minAmount * price).toFixed(8),
          max: !resupper.maxAmount
            ? 99999999999
            : (resupper.maxAmount * price).toFixed(8),
        });
      } else {
        setupperMinMaxValue({
          min: resupper.minAmount,
          max: !resupper.maxAmount ? 99999999999 : resupper.maxAmount,
        });
      }

      setPriceLoader(false);
    } catch (err) {
      setPriceLoader(false);
    }
  };

  const changeHandler = async (e) => {
    let { name, value } = e.target;
    if (!value.toString().match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
      return;
    }
    try {
      if (name == "upperValue") {
        setupperValue(value);
        getLowerValue(value);
      } else if (name == "lowerValue" && ActiveTab == Enums.FixedRate) {
        setlowerValue(value);
        getUpperValue(value);
      }
    } catch (error) {}
  };

  const getLowerValue = async (value) => {
    try {
      setIsLoader({
        on: true,
        type: "to",
      });
      let rate = await getrateOfpairs({
        fromCurrency: upperDropdown.ticker ? upperDropdown.ticker : "btc",
        toCurrency: lowerDropdown.ticker ? lowerDropdown.ticker : "eth",
        fromAmount: value,
        fromNetwork: upperDropdown.network,
        toNetwork: lowerDropdown.network,
        flow: ActiveTab,
      });
      if (value > 0) {
        setlowerValue(rate.toAmount);
        props?.setFrom({ ...upperDropdown, amount: value });
        props?.setTo({
          ...lowerDropdown,
          amount: rate.toAmount,
          rateId: rate.rateId,
        });
      } else {
        setlowerValue(0);
      }
      setIsLoader(false);
    } catch (error) {
      // setError(error.response.data?.error || error.response.data?.description);
      setlowerValue(0);
      setIsLoader(false);
    }
  };

  const getUpperValue = async (value) => {
    try {
      setIsLoader({
        on: true,
        type: "from",
      });
      let rate = await getrateOfpairs({
        fromCurrency: upperDropdown.ticker ? upperDropdown.ticker : "btc",
        toCurrency: lowerDropdown.ticker ? lowerDropdown.ticker : "eth",
        toAmount: value,
        fromNetwork: upperDropdown.network,
        toNetwork: lowerDropdown.network,
        flow: ActiveTab,
        type: "reverse",
      });
      if (value > 0) {
        setupperValue(rate.fromAmount);
        props?.setFrom({ ...upperDropdown, amount: rate.fromAmount });
        props?.setTo({ ...lowerDropdown, amount: value, rateId: rate.rateId });
      } else {
        setupperValue(0);
      }
      setIsLoader(false);
    } catch (error) {
      setupperValue("-");
      setIsLoader(false);
    }
  };

  const closeModel = (e) => {
    if (upperModel == true) {
      setupperModel(false);
    }
    if (lowerModel == true) {
      setlowerModel(false);
    }
  };

  const openModel = (e, type) => {
    if (type == "upperModel" && upperModel == false) {
      setupperModel(true);
      setlowerModel(false);
      setListCoins(ActiveToPairs);
    }
    if (type == "lowerModel" && lowerModel == false) {
      setlowerModel(true);
      setupperModel(false);
      setListCoins(ActiveFromPairs);
    }
  };

  const changeDropdown = (e) => {
    e.preventDefault();
    setupperDropdown(lowerDropdown);
    props?.setFrom(lowerDropdown);

    setlowerDropdown(upperDropdown);
    props?.setTo(upperDropdown);

    setlowerValue(upperValue);
    setupperValue(lowerValue);
    getRanges(lowerDropdown, upperDropdown);

    setMessageType("");
    setPriceLoader(true);

    // setupperMinMaxValue({
    //     min: lowerMinMaxValue.min,
    //     max: lowerMinMaxValue.max,
    // });
    // setlowerMinMaxValue({
    //     min: upperMinMaxValue.min,
    //     max: upperMinMaxValue.max,
    // });
    setexchangebtn(!exchangebtn);
    setTimeout(() => {
      setexchangebtn(!exchangebtn);
    }, 5000);
  };

  return (
    <Container>
      {
        // console.log({ PriceLoader, MessageType })
      }
      <Row className="justify-content-center swapAlert">
      
        <Col lg={12} className="px-2">
          <div className="filed_box">
            <Form>
              {/*------------------------------ Upper Model Content Start ----------------------------------------*/}
              <div
                className={
                  upperModel == true ? "filed d-none" : "filed d-block"
                }
              >
                <Row>
                  <Col lg={9} md={9} xs={8} className="px-1 pl-0">
                    <div className="field_block">
                      <FloatingLabel
                        controlId="floatingPassword"
                        label="You Send"
                      >
                        <Form.Control
                          type="text"
                          placeholder="0.1"
                          value={
                            isLoader.on && isLoader.type == "from"
                              ? ""
                              : upperValue
                          }
                          name="upperValue"
                          onChange={(e) => changeHandler(e)}
                          disabled={ActiveTab == Enums.FixedRate}
                        />
                        <InputLoader isLoader={isLoader} type="from" />
                      </FloatingLabel>
                    </div>
                  </Col>
                  <Col lg={3} md={3} xs={4} className="p-0">
                    <Button
                      onClick={(e) => openModel(e, "upperModel")}
                      className="select-btn"
                    >
                      <div className="item-select">
                        <div className="d-flex align-items-center">
                          <img
                            src={upperDropdown?.image || "assets/icons/btc.svg"}
                            alt="btc"
                            className="updown-icon pe-2"
                          />
                          <span>
                            {upperDropdown?.ticker?.toUpperCase() || "BTC"}
                          </span>
                        </div>
                        <img
                          src="assets/icons/dwonarrow.svg"
                          alt="dwonarrow"
                          className="dwonarrow"
                        />
                      </div>
                    </Button>
                  </Col>
                </Row>
              </div>
              <div
                className={
                  upperModel == true
                    ? "select-modal d-block"
                    : "select-modal d-none"
                }
              >
                
                <CurrencyDropDown
                  selectCoin={selectCoin}
                  searchCoin={searchCoin}
                  listCoins={listCoins}
                  closeModel={closeModel}
                  FieldType="upper"
                  upperDropdown={upperDropdown}
                  lowerDropdown={lowerDropdown}
                />
              </div>

              {/*------------------------------ Upper Model Content End----------------------------------------*/}

              <Row
                className={
                  upperModel == true ? "filed d-none" : "filed d-block"
                }
              >
                <Col
                  lg={12}
                  md={12}
                  className="px-0 py-lg-3 py-md-3  rate_padding"
                >
                  <div className="d-flex justify-content-between">
                    <div className="float-rate">
                      {/* {PriceLoader && <Shimmer width={1000} height={30} />} */}
                      {PriceLoader && (
                        <>
                          <div className="floatbox padding_equal">
                            <strong>
                              {Enums.FloatingRate == ActiveTab
                                ? "Estimated Rate"
                                : "Fixed Rate"}{" "}
                              :{" "}
                            </strong>
                            <span>
                              &nbsp; <ClipLoader color="#4e7aee" size={12} />
                            </span>
                          </div>
                        </>
                      )}
                      {!PriceLoader && (
                        <>
                          {MessageType == "Minimum" && (
                            <MinimumAmountMessage
                              onClick={(e) => {
                                e.target.name =
                                  ActiveTab == Enums.FixedRate
                                    ? "lowerValue"
                                    : "upperValue";
                                e.target.value = upperMinMaxValue?.min;
                                changeHandler(e);
                              }}
                              Amount={upperMinMaxValue?.min || 0}
                              Currency={
                                ActiveTab == Enums.FixedRate
                                  ? lowerDropdown.ticker?.toUpperCase()
                                  : upperDropdown.ticker?.toUpperCase()
                              }
                            />
                          )}

                          {MessageType == "Maximum" && (
                            <MaximumAmountMessage
                              onClick={(e) => {
                                e.target.name =
                                  ActiveTab == Enums.FixedRate
                                    ? "lowerValue"
                                    : "upperValue";
                                e.target.value = upperMinMaxValue?.max;
                                changeHandler(e);
                              }}
                              Amount={upperMinMaxValue?.max || 0}
                              Currency={
                                ActiveTab == Enums.FixedRate
                                  ? lowerDropdown.ticker?.toUpperCase()
                                  : upperDropdown.ticker?.toUpperCase()
                              }
                            />
                          )}

                          {MessageType == "Fixed" && (
                            <FixedExchangeMessage
                              onClick={() => setFixedMessageHide(true)}
                            />
                          )}
                          {MessageType == "Price" && (
                            <>
                              <div className="floatbox padding_equal">
                                <strong>
                                  {Enums.FloatingRate == ActiveTab
                                    ? "Estimated Rate"
                                    : "Fixed Rate"}{" "}
                                  :{" "}
                                </strong>
                                <span>
                                  {" "}
                                  1{" "}
                                  {upperDropdown?.ticker?.toUpperCase() ||
                                    "BTC"}{" "}
                                  {Enums.FloatingRate == ActiveTab
                                    ? " ≈ "
                                    : " = "}{" "}
                                  {parseFloat(CoinPrice || 0).toFixed(8)}{" "}
                                  {lowerDropdown?.ticker?.toUpperCase() ||
                                    "ETH"}
                                </span>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {MessageType == "Fixed" && (
                      <FixedExchangeMessage
                        onClick={() => setFixedMessageHide(true)}
                      />
                    )
                      ? ""
                      : MessageType != "Minimum" && (
                          <div className="padding_equal">
                            <button
                              onClick={(e) => changeDropdown(e)}
                              className={
                                exchangebtn
                                  ? "border-0 swapicon"
                                  : "border-0 swapicon"
                              }
                            >
                              {/* <IoSwapVerticalOutline className="swapicon" /> */}
                              <MdSwapVert />
                            </button>
                          </div>
                        )}
                  </div>

                  <div className="exchange_rate">
                    <div className="ratebox">
                      <div className="ratebox-inner">
                        <img
                          src="assets/icons/updown-icon.svg"
                          alt="updown-icon"
                          className="updown-icon"
                        />
                        <span>Floating Exchange Rate</span>
                      </div>
                      <p>
                        This amount you get may change due to market volatility.{" "}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>

              {/*------------------------------ Lower Model Content Start ----------------------------------------*/}

              <div
                className={
                  (upperModel || lowerModel) == true
                    ? "filed d-none"
                    : "filed d-block"
                }
              >
                <Row>
                  <Col lg={9} md={9} xs={8} className="px-1 pl-0">
                    <div className="field_block">
                      <FloatingLabel
                        controlId="floatingPassword"
                        label="You Get"
                      >
                        <Form.Control
                          placeholder="0.00"
                          name="lowerValue"
                          // value={lowerValue}
                          value={
                            isLoader.on && isLoader.type == "to"
                              ? ""
                              : lowerValue
                          }
                          onChange={(e) => changeHandler(e)}
                          disabled={ActiveTab == Enums.FloatingRate}
                        />
                        <InputLoader isLoader={isLoader} type="to" />
                        {/* {ActiveTab == Enums.FixedRate &&
                                                    <LockIcon />
                                                    } */}
                      </FloatingLabel>
                    </div>
                  </Col>
                  <Col lg={3} md={3} xs={4} className="p-0">
                    <Button
                      onClick={(e) => openModel(e, "lowerModel")}
                      className="select-btn"
                    >
                      <div className="item-select">
                        <div className="d-flex align-items-center">
                          <img
                            src={lowerDropdown?.image || "assets/icons/eth.svg"}
                            alt="btc"
                            className="updown-icon pe-2"
                          />
                          <span>
                            {lowerDropdown?.ticker?.toUpperCase() || "ETH"}
                          </span>
                        </div>
                        <img
                          src="assets/icons/dwonarrow.svg"
                          alt="dwonarrow"
                          className="dwonarrow"
                        />
                      </div>
                    </Button>
                  </Col>
                </Row>
              </div>

              <div
                className={
                  lowerModel == true
                    ? "select-modal d-block"
                    : "select-modal d-none"
                }
              >
                <CurrencyDropDown
                  selectCoin={selectCoin}
                  searchCoin={searchCoin}
                  listCoins={listCoins}
                  closeModel={closeModel}
                  FieldType="lower"
                  upperDropdown={upperDropdown}
                  lowerDropdown={lowerDropdown}
                />
              </div>

              {/*------------------------------ Lower Model Content End ----------------------------------------*/}

              {!hideExchangeBtn && (
                <Row
                  className={
                    upperModel || lowerModel == true
                      ? "filed d-none mt-4"
                      : "filed d-block mt-4"
                  }
                >
                  <Col lg={12} md={12} className="mx-auto px-0">
                    <Button
                      variant="primary"
                      className="custombtn w-100"
                      disabled={
                        upperValue === "-" ||
                        upperValue == 0 ||
                        lowerValue == 0 ||
                        isLoader.on
                      }
                      onClick={() =>
                        Navigate(
                          `${config.baseUrl}exchangedetails?from=${
                            upperDropdown.legacyTicker
                          }&to=${lowerDropdown.legacyTicker}&amount=${
                            ActiveTab == Enums.FixedRate
                              ? lowerValue
                              : upperValue
                          }&rate=${ActiveTab}`
                        )
                      }
                      // onClick={() => {
                      //     let a = 23
                      //     setInterval(() => {
                      //         if (a > 0) {
                      //             a -= loadTimer + 1
                      //             setLoadTimer(a)
                      //         }
                      //     }, 1000)
                      // }}
                    >
                      Exchange{" "}
                      {loadTimer > 0 && (
                        <span className="btn-loading bg-white text-black">
                          <small>{loadTimer}</small>
                        </span>
                      )}
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
