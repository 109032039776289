import { getRequest, postRequest, putRequest, deleteRequest, postRequest2, getRequest2, putRequest2 } from "./helper";


export const getAllCoinsAction = (data) => {
  return getRequest('exchange/currencies', data).then(res => { return res.data })
  // return getRequest('get_all_currencies', data).then(res => { return res.data })   
}


export const getrateOfpairs = (data) => {
  return getRequest('exchange/estimated-amount', data).then(res => { return res.data })
  // return getRequest('get_estimated', data).then(res => { return res.data })
}
export const getRangesAction = (data) => {
  return getRequest('exchange/range', data).then(res => { return res.data })
  // return getRequest('get_ranges', data).then(res => { return res.data })
}

export const getPairsOfpairs = (data) => {
  return getRequest('exchange/available-pairs', data).then(res => { return res.data })
}

export const getTransactnioStatusAction = (data) => {
  return getRequest('exchange/by-id', data).then(res => { return res.data })
}


export const createExchangeAction = (data) => {
  return postRequest('exchange', data).then(res => { return res.data })
}

export const CoinActiveDeactiveAction = (data) => {
  return postRequest('userCoinsActiveDeactive', data).then(res => { return res.data })
}


//  AJ ///

export const submitSwapTransactionAction = (data) => {
  return postRequest2("submitSwapTransaction", data).then((res) => {
    return res.data;
  });
};

export const updateSwapTrxStatusAction = (data) => {
  return postRequest2("updateSwapTrxStatus", data).then((res) => {
    return res.data;
  });
};



export const getSwapTransactnioStatusAction = (data) => {
  return postRequest2("getTransactionBySwapId", data).then((res) => {
    return res.data;
  });
};

export const deleteThisSwapTransactionAction = (data) => {
  return postRequest2("deleteThisSwapTransaction", data).then((res) => {
    return res.data;
  });
};

export const getRecentTransactionsAction = (data) => {
  return postRequest2("getRecentTransactions", data).then((res) => {
    return res.data;
  });
};

export const getCmsDataAction = (data) => {
  return getRequest2("getCmsData", data).then((res) => {
    return res.data;
  });
};

export const insertContactDataAction = (data) => {
  return postRequest2("insertContactData", data).then((res) => {
    return res.data;
  });
};


export const getInvestStatisticsAction = (data) => {
  return getRequest2("getStatistics", data).then((res) => {
    return res.data;
  });
};

export const getLiveChatStatusAction = (data) => {
  return getRequest2("getLiveChatStatus", data).then((res) => {
    return res.data;
  });
};


export const createNewAccountAction = (data) => {
  return postRequest2("createNewAccount", data).then((res) => {
    return res.data;
  });
};
export const userLoginAction = (data) => {
  return postRequest2("login", data).then((res) => {
    return res.data;
  });
};
export const getTransactionHistoryAction = (data) => {
  return postRequest2("getTransactionHistory", data).then((res) => {
    return res.data;
  });
};
export const getWalletBalanceAction = (data) => {
  return postRequest2("getWalletBalance", data).then((res) => {
    return res.data;
  });
};
export const UserwithdrawAction = (data) => {
  return postRequest2("withdraw", data).then((res) => {
    return res.data;
  });
}

export const createTransferAction = (data) => {
  return postRequest2("createTransfer", data).then((res) => {
    return res.data;
  });
}

export const createTransferInvestedToAvailableAction = (data) => {
  return postRequest2("createTransferInvestToAvailable", data).then((res) => {
    return res.data;
  });
}

export const updateTransactionStatus = (data) => {
  return postRequest2("updateTransactionStatus", data).then((res) => {
    return res.data;
  });
}

export const getUserStatisticsAction = (data) => {
  return postRequest2("getUserStatistics", data).then((res) => {
    return res.data;
  });
}
export const updateUserPasswordAction = (data) => {
  return postRequest2("updateUserPassword", data).then((res) => {
    return res.data;
  });
}
export const deleteUserAccountAction = (data) => {
  return putRequest2("deleteUserAccount", data).then((res) => {
    return res.data;
  });
}
export const sendEmailConfirmationAction = (data) => {
  return postRequest2("sendEmailConfirmation", data).then((res) => {
    return res.data;
  });
}
export const updateEmailNotificationAction = (data) => {
  return postRequest2("updateEmailNotification", data).then((res) => {
    return res.data;
  });
}

export const getGraphDataAction = (data) => {
  return postRequest2("getGraphData", data).then((res) => {
    return res.data;
  });
}


export const getAdvantageDataAction = (data) => {
  return getRequest2("getAdvantageForVipLevel", data).then((res) => {
    return res.data;
  });
}
export const getUserDetailsByIdAction = (data) => {
  return getRequest2("getUserDetailsById", data).then((res) => {
    return res.data;
  });
}
export const  verifyUserEmailAction = (data) => {
  return postRequest2("verifyUserEmail", data).then((res) => {
    return res.data;
  });
}

export const updateTwofaSettingAction = (data) => {
  return postRequest2("update2FASetting", data).then((res) => {
    return res.data;
  });
}



