import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Tab,
  Button,
  Card,
  FloatingLabel,
  Form,
  Accordion,
  Toast
} from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import '../componentCss/viplevel.css'
import { getAdvantageDataAction, getWalletBalanceAction } from "../../coreFiles/user.action";
import Cookies from "js-cookie";

const VipLevel = ({ userVipLevelInfo }) => {
  const investedWallet = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="mb-0 p-1 text-left">
        The total amount in your Invested Wallet that is currently generating profits.
      </div>
    </Tooltip>
  );
  const vipLevel = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="mb-0 p-1 text-left">
        Your current VIP level, which can be elevated by increasing the amount in your Invested Wallet.
      </div>
    </Tooltip>
  );
  const advantages = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="mb-0 p-1 text-left">
        The benefits that your current VIP level provides.
      </div>
    </Tooltip>
  );
  const vipProgress = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="mb-0 p-1 text-left">
        A progress bar that shows your current position among our various VIP levels.
      </div>
    </Tooltip>
  );



  const viplevel = userVipLevelInfo.levelInfo.id;
  // const viplevel = 6;


  const calculateNextViplevel = () => {
    const differenceAmount = userVipLevelInfo.levelInfo.toAmount - userVipLevelInfo.investedBalance;
    return differenceAmount.toLocaleString();
  }


  // for 0 level
  const levelZeroprogressbarCalucation = () => {
    if (viplevel == 1) {
      if (userVipLevelInfo.investedBalance <= 100) {
        const level0 = (userVipLevelInfo.investedBalance / 100) * 100;
        const result = (level0 / 100 * 100)
        return result;
      }
    } else if (viplevel < 1) {
      return 0;
    }
    else {
      return 100;
    }
  }
  // for 1 level
  const levelOneProgressBarCaluation = () => {
    if (viplevel == 2) {
      if (userVipLevelInfo.investedBalance <= 1000) {
        const level1 = (userVipLevelInfo.investedBalance / 1000) * 100;
        const result = (level1 / 100 * 100)
        return result;
      }
    } else if (viplevel < 2) {
      return 0;
    }
    else {
      return 100;
    }
  };
  // for 2 level
  const levelTwoProgressBarCaluation = () => {
    if (viplevel == 3) {
      if (userVipLevelInfo.investedBalance <= 5000) {
        const level2 = (userVipLevelInfo.investedBalance / 5000) * 100;
        const result = (level2 / 100 * 100)
        return result;
      }
    } else if (viplevel < 3) {
      return 0;
    }
    else {
      return 100;
    }
  };
  // for 3 level
  const levelThreeProgressBarCaluation = () => {
    if (viplevel == 4) {
      if (userVipLevelInfo.investedBalance <= 25000) {
        const level3 = (userVipLevelInfo.investedBalance / 25000) * 100;
        const result = (level3 / 100 * 100)
        return result;
      }
    } else if (viplevel < 4) {
      return 0;
    }
    else {
      return 100;
    }
  };

  // for 4 level
  const levelFourProgressBarCaluation = () => {
    if (viplevel == 5) {
      if (userVipLevelInfo.investedBalance <= 200000) {
        const level5 = (userVipLevelInfo.investedBalance / 200000) * 100;
        const result = (level5 / 100 * 100)
        return result;
      }
    } else if (viplevel < 5) {
      return 0;
    }
    else {
      return 100;
    }
  };

  // for 5 level
  const levelFiveProgressBarCaluation = () => {
    if (viplevel == 6) {
      return 100;
      // if (userVipLevelInfo.investedBalance <= 500000000) {
      //   const level5 = (userVipLevelInfo.investedBalance / 500000000) * 100;
      //   const result = (level5 / 100 * 100)
      //   return result;
      // }
    } else if (viplevel < 6) {
      return 0;
    }
    else {
      return 100;
    }
  };
  return (
    <>
      <Row>
        <Col lg={12} className="">
          {userVipLevelInfo.investedBalance >= 200000 || viplevel == 6 ?
            <><Card className="border-0 mb-3" style={{ background: "#e7f3ed", color: "#147665" }}>
              <div className="alert_block infoBlock">
                <div class="successIconbox me-2">
                  <img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" />
                </div>
                <div className="alert-text">
                  {/* Change calculateNextViplevel to calculateNextViplevel() to invoke the function */}
                  <p className="mb-0">
                    Congratulations! You've reached the highest VIP level. You're now part of the elite!
                  </p>
                </div>
              </div>
            </Card>
            </>
            :
            <><Card className="border-0 mb-3" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
              <div className="alert_block infoBlock">
                <div class="infoIconbox me-2">
                  <img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" />
                </div>
                <div className="alert-text">
                  {/* Change calculateNextViplevel to calculateNextViplevel() to invoke the function */}
                  <p className="mb-0">
                    You are just {calculateNextViplevel()} USDT away from the next VIP level.
                  </p>
                </div>
              </div>
            </Card>
            </>
          }

        </Col>
      </Row>

      <Row>

        <Col lg={8} className="">
          <Row>
            <Col lg={6} className="mb-3 ">
              <Card className="border-0 p-3 shadow ">
                <div>
                  <div>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <small className="">Invested Wallet
                        &nbsp;
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={investedWallet}
                        >
                          <img src="assets/icons/info.svg" width={`16px`} style={{ marginTop: "-2px" }} />
                        </OverlayTrigger>
                      </small>
                      {/* <small className="text-green d-none">↑ 0.00%</small> */}
                    </div>

                    <div className="d-flex align-items-end">
                      <h4 className="mb-0 me-1">{parseFloat(userVipLevelInfo.investedBalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h4>
                      <small className="f fw-lighter">USDT</small>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col lg={6} className="mb-3">
              <Card className="border-0 shadow overflow-hidden">
                <div className="d-flex justify-content-between">
                  <div className="p-3">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <small className="">VIP Level
                        &nbsp;
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={vipLevel}
                        >
                          <img src="assets/icons/info.svg" width={`16px`} style={{ marginTop: "-2px" }} />
                        </OverlayTrigger>
                      </small>
                      {/* <small className="text-green  d-none">↑ 0.00%</small> */}
                    </div>

                    <div className="d-flex align-items-end">
                      <h4 className="mb-0 me-1">{userVipLevelInfo.levelInfo.levelName}</h4>
                      {/* <small className="f fw-lighter">USDT</small> */}
                    </div>
                  </div>
                  <div>
                    <Card className="border-0 p-3 h-100 bg-light-grey vipcard">
                      <div className="h-100 d-flex align-items-center">
                        <div>
                          {viplevel == 6 &&
                            <img src={`assets/images/crown.png`} width={`40px`} />
                          }
                          {viplevel < 6 &&
                            <img src={`assets/icons/steps/step${viplevel - 1}.png`} className="" width="40px" />
                          }
                          {/* <img src={`assets/icons/steps/step${viplevel - 1}.png`} className="" width="40px" /> */}
                          {/* <img src={`assets/icons/step/step2.png`}  className="" width="40px" /> */}

                        </div>
                      </div>
                    </Card>
                  </div>

                </div>



              </Card>
            </Col>
            <Col lg={12} className="mb-3 ">
              <Card className="border-0 p-3 shadow ">
                <div className="singleField">
                  <div className="field_block">

                    <FloatingLabel
                      controlId="depositaddress"
                      label="Referral Address"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter the Referral Address value"
                        name="ReferralAddress"
                        disabled
                        className="text-darkblue"
                        value={`Unlock referral address at level 5 for extra earnings`}
                        autoComplete="off"
                      />

                    </FloatingLabel>


                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col lg={4} className="">
          <Row>
            <Col lg={12}>
              <Card className="border-0 p-3 shadow mb-3">
                <div className="PriceBox">
                  <div>
                    <div className="d-flex mb-1">
                      <small className="">Advantages
                        &nbsp;
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={advantages}
                        >
                          <img src="assets/icons/info.svg" width={`16px`} style={{ marginTop: "-2px" }} />
                        </OverlayTrigger>
                      </small>
                    </div>
                  </div>
                  <hr className="line-gradient my-2" />

                  <div className="PriceBoxlist mnh-auto">

                    <div className="d-flex align-items-center justify-content-between">
                      <div><p className="mb-2">Daily Interest</p></div>
                      <div className="text-right"><p className="mb-2">{parseFloat(userVipLevelInfo.levelInfo.dailyIntrest) == 0 ? "0.00%" : parseFloat(userVipLevelInfo.levelInfo.dailyIntrest).toFixed(2) + "%"}</p></div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div><p className="mb-2">Monthly Interest</p></div>
                      <div className="text-right">
                        <div className="text-right">
                          <p className="mb-2">{parseFloat(userVipLevelInfo.levelInfo.monthlyIntrest) == 0 ? "0.00%" : parseFloat(userVipLevelInfo.levelInfo.monthlyIntrest).toFixed(2) + "%"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div><p className="mb-2">Yearly Interest</p></div>
                      <div className="text-right"><p className="mb-2">{parseFloat(userVipLevelInfo.levelInfo.yearlyIntrest) == 0 ? "0.00%" : parseFloat(userVipLevelInfo.levelInfo.yearlyIntrest).toFixed(2) + "%"}</p></div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div><p className="mb-0">Referral</p></div>
                      <div className="text-right"><p className="mb-0">No</p></div>
                    </div>

                  </div>


                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col lg={12} className="mb-3">
          <Card className="border-0 p-3 shadow ">
            <div>
              <div className="d-flex mb-1">
                <h6 className="mb-0">VIP Progress
                  &nbsp;
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={vipProgress}
                  >
                    <img src="assets/icons/info.svg" width={`16px`} style={{ marginTop: "-2px" }} />
                  </OverlayTrigger>
                </h6>
              </div>
            </div>
            <div>
              <div class="stepper-wrapper">
                <div class="stepper-item completed">
                  <div className="stepprogress">
                    <div class="step-counter" style={{ width: `${levelZeroprogressbarCalucation()}%` }}></div>
                    <div class="step-counter-grey" style={{ width: "100%" }}></div>
                  </div>
                  <div class="step-name">
                    <img src="assets/icons/step/step0.png" className="mb-2" />
                    <div className="">
                      <h6 className="mb-0">Level 0</h6>
                      <p>0 USDT</p>
                    </div>
                    <div>
                      <h6 className="mb-0 fw-lighter" style={{ color: "#1b1340" }}>APY: 0%</h6>
                      <h6 className="mb-0 fw-lighter" style={{ color: "#1b1340" }}>Referral: No</h6>
                    </div>
                  </div>
                </div>
                <div class="stepper-item completed">
                  <div className="stepprogress">
                    <div class="step-counter" style={{ width: `${levelOneProgressBarCaluation()}%` }}></div>
                    <div class="step-counter-grey" style={{ width: "100%" }}></div>
                  </div>
                  <div class="step-name">
                    <img src="assets/icons/step/step1.png" className="mb-2" />
                    <div className="">
                      <h6 className="mb-0">Level 1</h6>
                      <p>100 USDT</p>
                    </div>
                    <div>
                      <h6 className="mb-0 fw-lighter" style={{ color: "#1b1340" }}>APY: 12%</h6>
                      <h6 className="mb-0 fw-lighter" style={{ color: "#1b1340" }}>Referral: No</h6>
                    </div>
                  </div>
                </div>
                <div class="stepper-item completed">
                  <div className="stepprogress">
                    <div class="step-counter" style={{ width: `${levelTwoProgressBarCaluation()}%` }}></div>
                    <div class="step-counter-grey" style={{ width: "100%" }}></div>
                  </div>
                  <div class="step-name">
                    <img src="assets/icons/step/step2.png" className="mb-2" />
                    <div className="">
                      <h6 className="mb-0">Level 2</h6>
                      <p>1,000 USDT</p>
                    </div>
                    <div>
                      <h6 className="mb-0 fw-lighter" style={{ color: "#1b1340" }}>APY: 24%</h6>
                      <h6 className="mb-0 fw-lighter" style={{ color: "#1b1340" }}>Referral: No</h6>
                    </div>
                  </div>
                </div>
                <div class="stepper-item completed">
                  <div className="stepprogress">
                    <div class="step-counter" style={{ width: `${levelThreeProgressBarCaluation()}%` }}></div>
                    <div class="step-counter-grey" style={{ width: "100%" }}></div>
                  </div>
                  <div class="step-name">
                    <img src="assets/icons/step/step3.png" className="mb-2" />
                    <div className="">
                      <h6 className="mb-0">Level 3</h6>
                      <p>5,000 USDT</p>
                    </div>
                    <div>
                      <h6 className="mb-0 fw-lighter" style={{ color: "#1b1340" }}>APY: 36%</h6>
                      <h6 className="mb-0 fw-lighter" style={{ color: "#1b1340" }}>Referral: No</h6>
                    </div>
                  </div>
                </div>
                <div class="stepper-item completed">
                  <div className="stepprogress">
                    <div class="step-counter" style={{ width: `${levelFourProgressBarCaluation()}%` }}></div>
                    <div class="step-counter-grey" style={{ width: "100%" }}></div>
                  </div>
                  <div class="step-name">
                    <img src="assets/icons/step/step4.png" className="mb-2" />
                    <div>
                      <h6 className="mb-0">Level 4</h6>
                      <p>25,000 USDT</p>
                    </div>
                  </div>
                  <div>
                    <h6 className="mb-0 fw-lighter" style={{ color: "#1b1340" }}>APY: 48%</h6>
                    <h6 className="mb-0 fw-lighter" style={{ color: "#1b1340" }}>Referral: No</h6>
                  </div>
                </div>

                <div class="stepper-item completed">
                  <div className="stepprogress">
                    <div class="step-counter" style={{ width: `${levelFiveProgressBarCaluation()}%` }}></div>
                    <div class="step-counter-grey" style={{ width: "100%" }}></div>
                  </div>
                  <div class="step-name">
                    <img src="assets/icons/step/step5.png" className="mb-2" />
                    <div className="">
                      <h6 className="mb-0">Level 5</h6>
                      <p>200,000 USDT</p>
                    </div>
                    <div>
                      <h6 className="mb-0 fw-lighter" style={{ color: "#1b1340" }}>APY: 60%</h6>
                      <h6 className="mb-0 fw-lighter" style={{ color: "#1b1340" }}>Referral: Yes</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default VipLevel;