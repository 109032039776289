import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Tab,
  Button,
  Accordion,
  Card
} from "react-bootstrap";
import "../componentCss/earn.css"
import "../componentCss/financial.css"
import "../componentCss/earnDashboard.css"
import { FaAngleRight, FaRegStar, FaStar } from "react-icons/fa6";
// import greenAlertBox from '../swap/ToastAlert'
import Cookies from "js-cookie";
import Overview from "./overview";
import Wallet from "./wallet";
import Transactions from "./transactions";
import Statistics from "./statistics";
import BlurSection from "../blurSection";
import { MultiColorThreeDotLoader, WalletPageThreeDotLoader } from "../generic";
import config from "../../coreFiles/config";
import { Link, useNavigate } from "react-router-dom";
import AccountSetting from "./accountSetting";
import VipLevel from "./vipLevel";
import { getAdvantageDataAction } from "../../coreFiles/user.action";

const Fincenter = () => {
  const navigate = useNavigate();
  let loginUserData = Cookies.get('purpleUser') ? JSON.parse(Cookies.get('purpleUser')) : '';
  const [walletAddress, setWalletAddress] = useState(loginUserData?.walletAddress)

  const [showSection, setShowSection] = useState('Overview')

  const [activeTab, setActiveTab] = useState("Dashboard");
  const [mainLoading, setMainLoading] = useState(true)
  const [userVipLevelInfo, setUserVipLevelInfo] = useState([])
  const [wallatetitle, setWallatetitle] = useState('deposit')
  const [acordianState, setAcordianState] = useState('0')

  const switchTo = (newTab) => {
    setShowSection(newTab)
  }
  useEffect(() => {
    setMainLoading(true)
    setTimeout(() => {
      setMainLoading(false)
    }, 2000);
    getAdvantageData();
  }, [showSection])

  const logOutUser = () => {
    Cookies.remove('purpleUser');
    window.location.href = config.baseUrl + 'account';
  }
  const walletFaq = [
    {
      eventKey: "0",
      title: `Have a question about the ${wallatetitle} section?`,
      body: '<p>You can visit the support section accessible in the menu to conduct a detailed search among all our FAQs or contact us directly through our contact form, by email, or by chat. Our customer service team is ready to assist you with any inquiries.</p>',
    }
  ];
  const StatisticsFaq = [
    {
      eventKey: "0",
      title: "Have questions about the Statistics section?",
      body: '<p>You can visit the support section accessible in the menu to conduct a detailed search among all our FAQs or contact us directly through our contact form, by email, or by chat. Our customer service team is ready to assist you with any inquiries.</p>',
    }
  ];
  const TransactionFaq = [
    {
      eventKey: "0",
      title: "Have questions about the Transaction section?",
      body: '<p>You can visit the support section accessible in the menu to conduct a detailed search among all our FAQs or contact us directly through our contact form, by email, or by chat. Our customer service team is ready to assist you with any inquiries.</p>',
    }
  ];
  const VIP_LevelFaq = [
    {
      eventKey: "0",
      title: "Have questions about the VIP Level section?",
      body: '<p>You can visit the support section accessible in the menu to conduct a detailed search among all our FAQs or contact us directly through our contact form, by email, or by chat. Our customer service team is ready to assist you with any inquiries.</p>',
    }
  ];
  const AccountFaq = [
    {
      eventKey: "0",
      title: "Have questions about the Account section?",
      body: '<p>You can visit the support section accessible in the menu to conduct a detailed search among all our FAQs or contact us directly through our contact form, by email, or by chat. Our customer service team is ready to assist you with any inquiries.</p>',
    }
  ];
  const overviewFaq = [
    {
      eventKey: "0",
      title: "What are locked profits?",
      body: '<p>Locked Profits are the earnings generated by your invested amount since the start of the month. Interest is calculated and credited to your account daily but is only released at the end of each month.</p>',
    },
    {
      eventKey: "1",
      title: "Why is my investment status 'Inactive'?",
      body: "<p>Your investment status is 'Inactive' because you have not yet transferred funds into your Investment wallet, or you have a transfer transaction in process. As soon as the funds are deposited, your investment will be automatically activated, and your daily profits will begin.</p>",
    },
    {
      eventKey: "2",
      title: "How can I earn more interest?",
      body: '<p>Interest percentages are adjusted according to your VIP Level. For instance, if you have less than $1000 in your invested wallet, you will have 1 star, earning 12% annual interest. If you have between $1000 and $5000, you will move to VIP Level 2 and your interest rate will increase to 24% annually, and so on. For full details about the VIP Level chart, please click on the VIP Level button in the menu on your Financial Center.</p>',
    },
    {
      eventKey: "3",
      title: "I have funds in my 'Available' wallet, but why am I not receiving any profits?",
      body: " <p>Funds in your available wallet do not generate profits. They can be withdrawn at any moment without delay or transferred to your investment wallet to start earning daily profits.</p>",
    },
    {
      eventKey: "4",
      title: "My account is created, what should I do now?",
      body: `<p>Welcome to Purple Swap! Now, what can you do? You need to generate profit! Please follow these detailed instructions to begin generating profits with your account:</p>
      <h6>1. Deposit USDT into Your Wallet: </h6>
      Locate Your ERC-20 Address:
      <ol>
    <li>
     Go to the ' Wallet ' section of the Financial Center and click on ' Deposit '. Here , you'll find your unique ERC-20 deposit address , which is the same as the address you use to log into your account.</li>
    <li>
    Make a Deposit : Send USDT to this address from your external wallet or another exchange . This USDT will appear in your ' Available ' wallet once the transaction is confirmed on the blockchain.</li>
    </ol>
    
    <h6>2. Transfer Funds to Invest:</h6>
    <ol>
    <li>
    Access the Transfer Feature : In the ' Wallet ' section , select ' Transfer ' to initiate the transfer of funds.</li>
    <li>
    Choose the Amount : Enter the amount of USDT you wish to move from your ' Available ' wallet to your ' Invested ' wallet . This is the wallet where your funds will begin accruing interest.</li>
    <li>
    Confirm the Transfer : Follow the prompts to complete the transfer . Remember , there is a 3 - day initiation period before your funds start generating interest.</li>
    </ol>
    
    <h6>3. Start Earning Interest:</h6>
    <ol>
    <li>
    Interest Accrual : After the 3 - day initiation phase , your funds in the ' Invested ' wallet will start earning interest daily.</li>
    <li>
    Check Interest Rates : Visit the VIP section to see the current interest rates . Higher balances earn higher interest rates , enhancing your investment growth.</li>
    </ol>
    <h6>4. Manage Your Funds:</h6>
    <ol>
    <li>
    Full Flexibility: You can withdraw, deposit, or transfer funds anytime without undergoing verification processes.</li>
    <li>
    No Freezing of Funds: Rest assured, your funds will never be frozen for verification or any other reason, ensuring you have unrestricted access to your capital.</li>
    </ol>
    <h6>5. Need More Help?:</h6>
    <ol>
    <li>
    Consult Other FAQs: For additional information about managing your account and investments, check our other FAQs.</li>
    <li>
    Customer Support: If you have any questions or need further assistance, our customer service team is ready to help you. You can reach out to them through the contact options available on our site.</li>
    </ol>
    <p>By following these steps, you'll be well on your way to effectively managing and growing your investment with Purple Swap. Enjoy your investment journey with us!<p/>
    `
    },
    {
      eventKey: "5",
      title: "I can't find the answer to my question, what should I do?",
      body: " <p>If you haven't found the answer among the FAQs above, you can visit the support section accessible in the menu to conduct a detailed search among all our FAQs or contact us directly through our contact form, by email, or by chat.</p>",
    }
  ];
  // const overviewFaq2 = [
  //   {
  //     eventKey: "0",
  //     title: "My account is created, what should I do now?",
  //     body: `<p>Welcome to Purple Swap! Now, what can you do? You need to generate profit! Please follow these detailed instructions to begin generating profits with your account:</p>
  //     <h5>1. Deposit USDT into Your Wallet: </h5>
  //     Locate Your ERC-20 Address:
  //     <ol>
  //   <li>
  //    Go to the ' Wallet ' section of the Financial Center and click on ' Deposit '. Here , you'll find your unique ERC-20 deposit address , which is the same as the address you use to log into your account.</li>
  //   <li>
  //   Make a Deposit : Send USDT to this address from your external wallet or another exchange . This USDT will appear in your ' Available ' wallet once the transaction is confirmed on the blockchain.</li>
  //   </ol>

  //   <h5>2. Transfer Funds to Invest:</h5>
  //   <ol>
  //   <li>
  //   Access the Transfer Feature : In the ' Wallet ' section , select ' Transfer ' to initiate the transfer of funds.</li>
  //   <li>
  //   Choose the Amount : Enter the amount of USDT you wish to move from your ' Available ' wallet to your ' Invested ' wallet . This is the wallet where your funds will begin accruing interest.</li>
  //   <li>
  //   Confirm the Transfer : Follow the prompts to complete the transfer . Remember , there is a 3 - day initiation period before your funds start generating interest.</li>
  //   </ol>

  //   <h5>3. Start Earning Interest:</h5>
  //   <ol>
  //   <li>
  //   Interest Accrual : After the 3 - day initiation phase , your funds in the ' Invested ' wallet will start earning interest daily.</li>
  //   <li>
  //   Check Interest Rates : Visit the VIP section to see the current interest rates . Higher balances earn higher interest rates , enhancing your investment growth.</li>
  //   </ol>
  //   <h5>4. Manage Your Funds:</h5>
  //   <ol>
  //   <li>
  //   Full Flexibility: You can withdraw, deposit, or transfer funds anytime without undergoing verification processes.</li>
  //   <li>
  //   No Freezing of Funds: Rest assured, your funds will never be frozen for verification or any other reason, ensuring you have unrestricted access to your capital.</li>
  //   </ol>
  //   <h5>5. Need More Help?:</h5>
  //   <ol>
  //   <li>
  //   Consult Other FAQs: For additional information about managing your account and investments, check our other FAQs.</li>
  //   <li>
  //   Customer Support: If you have any questions or need further assistance, our customer service team is ready to help you. You can reach out to them through the contact options available on our site.</li>
  //   </ol>
  //   <p>By following these steps, you'll be well on your way to effectively managing and growing your investment with Purple Swap. Enjoy your investment journey with us!<p/>
  //   `
  //   }
  //   // {
  //   //   eventKey: "1",
  //   //   title: "Why is my investment status 'Inactive'?",
  //   //   body: "<p>Your investment status is 'Inactive' because you have not yet transferred funds into your Investment wallet, or you have a transfer transaction in process. As soon as the funds are deposited, your investment will be automatically activated, and your daily profits will begin.</p>",
  //   // },
  //   // {
  //   //   eventKey: "2",
  //   //   title: "How can I earn more interest?",
  //   //   body: '<p>Interest percentages are adjusted according to your VIP Level. For instance, if you have less than $1000 in your invested wallet, you will have 1 star, earning 12% annual interest. If you have between $1000 and $5000, you will move to VIP Level 2 and your interest rate will increase to 24% annually, and so on. For full details about the VIP Level chart, please click on the VIP Level button in the menu on your Financial Center.</p>',
  //   // },
  //   // {
  //   //   eventKey: "3",
  //   //   title: "I have funds in my 'Available' wallet, but why am I not receiving any profits?",
  //   //   body: " <p>Funds in your available wallet do not generate profits. They can be withdrawn at any moment without delay or transferred to your investment wallet to start earning daily profits.</p>",
  //   // },
  //   // {
  //   //   eventKey: "4",
  //   //   title: "I can't find the answer to my question, what should I do?",
  //   //   body: " <p>If you haven't found the answer among the FAQs above, you can visit the support section accessible in the menu to conduct a detailed search among all our FAQs or contact us directly through our contact form, by email, or by chat.</p>",
  //   // }
  // ];

  const [vipLevelInfo, setVipLevelInfo] = useState({})

  const getAdvantageData = async () => {
    try {
      let res = await getAdvantageDataAction();
      if (res.success) {
        setUserVipLevelInfo(res.data);
        setVipLevelInfo(res.data.levelInfo)

      }
    } catch (error) {
      console.log(error)
    }
  };
  const handleStateChange = async (e) => {
    if (e == 0) {
      setAcordianState('1')
    }
    else {
      setAcordianState('0')
    }
  }

  const viplevel = vipLevelInfo.id;

  return (

    <>
      
      <section className="crytpocurrency pt-4 pb-4 bg-banner-img">
        {/* <Container fluid className="px-0">
          <Row>
            <Col lg={8} md={12} className="mx-auto">
              <div className="text-center text-white custom-head mb-0">
                <h2 className="text-white fw-lighter">
                  Financial Center
                </h2>
                <div className="swap_spc">
                  <p className="mb-0 text-white">
                    Create your private account and effortlessly manage your assets.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className=" mt-5">

              <Card className="border-0 p-3  yellowBox rounded-0" style={{ background: "#fff9e6" }}>
              
                <Row className="justify-content-center">
                  <Col lg={8} md={12} className="text-center ctm-col">
                    <div className="me-auto">
                      <p className="mb-0" >The Financial Center is currently in <b>BETA</b> mode. For an enhanced experience, please use a PC or laptop and Google Chrome as your browser until development is finalized. Thank you for your cooperation.</p>
                    </div>
                  </Col>
                </Row>

              </Card>
            </Col>
          </Row>
        </Container> */}
        <Container className="ctm-container">
          <Row className="justify-content-center">
            <Col lg={8} md={12} className="ctm-col">

              <div className="bnr_inner pb-0 position-relative">

                <Tab.Container id="left-tabs-example" defaultActiveKey="Dashboard">

                  <Row>
                    <Col sm={12}>
                      <div className="tab_style1">
                        <Nav variant="pills" className="overflow-hidden justify-content-center forgetPasswordCenter" style={{ borderRadius: "30px 30px 0px 0px" }}>
                          <Nav.Item className=" w-100 text-center">
                            <Nav.Link eventKey="Dashboard" className="center_tab">
                              <div className="position-relative z-1">
                                {showSection}</div>
                            </Nav.Link>
                          </Nav.Item>

                        </Nav>
                      </div>
                    </Col>

                    <Col sm={12}>
                      <Tab.Content>

                        {/* Dashboard */}
                        <Tab.Pane eventKey="Dashboard" active={activeTab == "Dashboard"} className="dashboard">
                          <div className="p-4 pb-0">
                            <Container className="px-0 ctm-container">
                              <Row className="justify-content-center">
                                <Col lg={12}>
                                  <Row className="justify-content-between">
                                    <Col lg={2} md={2} className="mb-3">

                                      <div className="">

                                        {showSection == "Overview" ?
                                          <Button disabled onClick={() => switchTo('Overview')} className="select-btn btn btn-primary rounded-2 text-black justify-content-center">Home</Button>
                                          :
                                          <Button onClick={() => switchTo('Overview')} className="select-btn btn btn-primary rounded-2 text-black justify-content-center">Back</Button>
                                        }

                                      </div>
                                    </Col>
                                    <Col lg={8} md={8}>
                                      <div className="">
                                        <Row>
                                          <Col lg={12}>
                                            {mainLoading &&
                                              <div className="confirmationBox">
                                                <div className="checkSuccess2">
                                                  <WalletPageThreeDotLoader />

                                                </div>
                                              </div>

                                            }

                                          </Col>
                                          <Col lg={6} md={6} xs={12} className="mb-3">
                                            <Button onClick={() => switchTo('VIP Level')} className="select-btn rounded-2 text-black p-3 justify-content-start">
                                              <div className="d-flex align-items-center justify-content-between w-100">
                                                <div className="position-relative">

                                                  <div className="d-flex align-items-center">

                                                    <div className="pe-3">
                                                      {viplevel == 6 &&
                                                        <img src={`assets/images/crown.png`} width={`35px`} />
                                                      }
                                                      {viplevel < 6 &&
                                                        <img src={`assets/icons/steps/step${viplevel - 1}.png`} width={`35px`} />
                                                      }
                                                    </div> VIP - {vipLevelInfo.levelName}
                                                    {/* &nbsp;
                                                    <div style={{ marginTop: "-5px" }}>
                                                      <FaStar fill="#9278ff" />
                                                      <FaRegStar fill="#9278ff" />
                                                      <FaRegStar fill="#9278ff" />
                                                      <FaRegStar fill="#9278ff" />
                                                      <FaRegStar fill="#9278ff" />
                                                    </div> */}
                                                    {/* <sup className="soon" style={{ top: "-2px", right: "-12px" }}>SOON</sup> */}

                                                  </div>

                                                </div>
                                                <div><FaAngleRight fill="#9278ff" /></div>



                                              </div>

                                            </Button>
                                          </Col>
                                          <Col lg={6} md={6} xs={12} className="mb-3">
                                            <Button onClick={() => switchTo('Account')} className="select-btn btn btn-primary rounded-2 text-black p-3">
                                              <div className="d-flex align-items-center justify-content-between w-100">

                                                <div className="d-flex text-break align-items-center AddressCode ">
                                                  <div className="pe-3"><img src="assets/icons/favicon.png" width={`35px`} /></div>
                                                  <div className="position-relative">
                                                    {walletAddress?.substr(0, 6)}...{walletAddress?.slice(-5)}
                                                    <sup className="soon" style={{ top: "-2px", right: "-12px" }}></sup>
                                                  </div>
                                                </div>
                                                <div><FaAngleRight fill="#9278ff" /></div>

                                              </div>
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col lg={2} md={2} className="mb-3">
                                      <div className="">
                                        <Button onClick={logOutUser} className="select-btn btn btn-primary rounded-2 text-black p-3 justify-content-center">Logout</Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              {showSection == "Overview" &&

                                <Overview
                                  loginUserData={loginUserData}
                                  setShowSection={setShowSection}
                                  userVipLevelInfo={userVipLevelInfo}
                                  setAcordianState={setAcordianState}
                                />
                              }

                              {showSection == "Wallet" &&
                                <Wallet
                                  loginUserData={loginUserData}
                                  setWallatetitle={setWallatetitle}
                                />
                              }

                              {showSection == "Transaction" &&
                                <Transactions />
                              }

                              {showSection == "Statistics" &&
                                <Statistics />
                              }

                              {showSection == "Account" &&
                                <AccountSetting />
                              }

                              {showSection == "VIP Level" &&
                                <VipLevel userVipLevelInfo={userVipLevelInfo} />
                              }

                            </Container>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>

                  </Row>
                </Tab.Container>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {userVipLevelInfo.availableBalance == 0 && userVipLevelInfo.investedBalance == 0 ?
        <>
          {showSection == "Overview" &&
            <section className="faq pt-4 pb-4 bg-white">
              <Container>
                <Row className="justify-content-center">
                  <Col lg="8" md="12" xs="12" className="mx-auto">
                    <div className="faq_box faqNewAcc">
                      <Row>
                        <Col lg={12} md={12} className="">
                          <Accordion className="mb-0" defaultActiveKey='0' activeKey={acordianState} >
                            {/* {overviewFaq2.map((item) => ( */}
                            <Accordion.Item eventKey="0" className="mb-2">
                              <Accordion.Header onClick={(e) => handleStateChange(acordianState)}>
                                My account is created, what should I do now?
                              </Accordion.Header>
                              <Accordion.Body>
                                <p>Welcome to Purple Swap! Now, what can you do? You need to generate profit! Please follow these detailed instructions to begin generating profits with your account:</p>
                                <h6>1. Deposit USDT into Your Wallet: </h6>

                                <ul style={{ listStyleType: " disc" }}>
                                  <li>
                                    <b>Locate Your ERC-20 Address:</b> Navigate to the 'Wallet' section of the Financial Center and click on 'Deposit'. You'll find your unique ERC-20 deposit address here, which is the same as the address you use to log into your account.</li>
                                  <li>
                                    <b>Make a Deposit:</b>  Transfer USDT to this address from your external wallet or another exchange. This USDT will show in your 'Available' wallet once the transaction is confirmed on the blockchain.</li>
                                </ul>

                                <h6>2. Transfer Funds to Invest:</h6>
                                <ul style={{ listStyleType: " disc" }}>
                                  <li>
                                    <b>Access the Transfer Feature :</b> In the ' Wallet ' section , select ' Transfer ' to initiate the transfer of funds.</li>
                                  <li>
                                    <b>Choose the Amount :</b> Enter the amount of USDT you wish to move from your ' Available ' wallet to your ' Invested ' wallet . This is the wallet where your funds will begin accruing interest.</li>
                                  <li>
                                    <b>Confirm the Transfer : </b>Follow the prompts to complete the transfer . Remember , there is a 3 - day initiation period before your funds start generating interest.</li>
                                </ul>

                                <h6>3. Start Earning Interest:</h6>
                                <ul style={{ listStyleType: " disc" }}>
                                  <li>
                                    <b>Interest Accrual :</b> After the 3 - day initiation phase , your funds in the ' Invested ' wallet will start earning interest daily.</li>
                                  <li>
                                    <b>Check Interest Rates :</b> Visit the VIP section to see the current interest rates . Higher balances earn higher interest rates , enhancing your investment growth.</li>
                                </ul>
                                <h6>4. Manage Your Funds:</h6>
                                <ul style={{ listStyleType: " disc" }}>
                                  <li>
                                    <b>Full Flexibility:</b> You can withdraw, deposit, or transfer funds anytime without undergoing verification processes.</li>
                                  <li>
                                    <b>No Freezing of Funds: </b>Rest assured, your funds will never be frozen for verification or any other reason, ensuring you have unrestricted access to your capital.</li>
                                </ul>
                                <h6>5. Need More Help?:</h6>
                                <ul style={{ listStyleType: " disc" }}>
                                  <li>
                                    <b>Consult Other FAQs:</b> For additional information about managing your account and investments, check our other FAQs.</li>
                                  <li>
                                    <b>Customer Support:</b> If you have any questions or need further assistance, our customer service team is ready to help you. You can reach out to them through the contact options available on our site.</li>
                                </ul>
                                <p>By following these steps, you'll be well on your way to effectively managing and growing your investment with Purple Swap. Enjoy your investment journey with us!</p>

                              </Accordion.Body>
                            </Accordion.Item>
                            {/* ))} */}
                          </Accordion>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          }
        </>
        :
        <>
          {showSection == "Overview" &&
            <section className="faq pt-4 pb-4 bg-white">
              <Container>
                <Row className="justify-content-center">
                  <Col lg="8" md="12" xs="12" className="mx-auto">
                    <div className="faq_box">
                      <Row>
                        <Col lg={12} md={12} className="">
                          <Accordion className="mb-0" defaultActiveKey="0">
                            {overviewFaq.map((item) => (
                              <Accordion.Item eventKey={item.eventKey} className="mb-2">
                                <Accordion.Header>
                                  {item.title}
                                </Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{
                                    __html: item.body,
                                  }}
                                />
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          }
        </>}


      {showSection == "Wallet" &&
        <section className="faq pt-4 pb-4 bg-white">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8" md="12" xs="12" className="mx-auto">
                <div className="faq_box">
                  <Row>
                    <Col lg={12} md={12} className="">
                      <Accordion className="mb-0" defaultActiveKey="0">
                        {walletFaq.map((item) => (
                          <Accordion.Item eventKey={item.eventKey} className="mb-2">
                            <Accordion.Header>
                              {item.title}
                            </Accordion.Header>
                            <Accordion.Body
                              dangerouslySetInnerHTML={{
                                __html: item.body,
                              }}
                            />
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      }

      {showSection == "Statistics" &&
        <section className="faq pt-4 pb-4 bg-white">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8" md="12" xs="12" className="mx-auto">
                <div className="faq_box">
                  <Row>
                    <Col lg={12} md={12} className="">
                      <Accordion className="mb-0" defaultActiveKey="0">
                        {StatisticsFaq.map((item) => (
                          <Accordion.Item eventKey={item.eventKey} className="mb-2">
                            <Accordion.Header>
                              {item.title}
                            </Accordion.Header>
                            <Accordion.Body
                              dangerouslySetInnerHTML={{
                                __html: item.body,
                              }}
                            />
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      }
      {showSection == "Transaction" &&
        <section className="faq pt-4 pb-4 bg-white">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8" md="12" xs="12" className="mx-auto">
                <div className="faq_box">
                  <Row>
                    <Col lg={12} md={12} className="">
                      <Accordion className="mb-0" defaultActiveKey="0">
                        {TransactionFaq.map((item) => (
                          <Accordion.Item eventKey={item.eventKey} className="mb-2">
                            <Accordion.Header>
                              {item.title}
                            </Accordion.Header>
                            <Accordion.Body
                              dangerouslySetInnerHTML={{
                                __html: item.body,
                              }}
                            />
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      }
      {showSection == "Account" &&
        <section className="faq pt-4 pb-4 bg-white">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8" md="12" xs="12" className="mx-auto">
                <div className="faq_box">
                  <Row>
                    <Col lg={12} md={12} className="">
                      <Accordion className="mb-0" defaultActiveKey="0">
                        {AccountFaq.map((item) => (
                          <Accordion.Item eventKey={item.eventKey} className="mb-2">
                            <Accordion.Header>
                              {item.title}
                            </Accordion.Header>
                            <Accordion.Body
                              dangerouslySetInnerHTML={{
                                __html: item.body,
                              }}
                            />
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      }
      {showSection == "VIP Level" &&
        <section className="faq pt-4 pb-4 bg-white">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8" md="12" xs="12" className="mx-auto">
                <div className="faq_box">
                  <Row>
                    <Col lg={12} md={12} className="">
                      <Accordion className="mb-0" defaultActiveKey="0">
                        {VIP_LevelFaq.map((item) => (
                          <Accordion.Item eventKey={item.eventKey} className="mb-2">
                            <Accordion.Header>
                              {item.title}
                            </Accordion.Header>
                            <Accordion.Body
                              dangerouslySetInnerHTML={{
                                __html: item.body,
                              }}
                            />
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      }

    </>

  );
}
export default Fincenter;