import { Col, Row, Table } from "react-bootstrap";
import config from "../../coreFiles/config";
import { Enums } from "./Enum";
import { useEffect, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { all } from "axios";



export const CurrencyDropDown = (props) => {
    const {
        searchCoin,
        listCoins,
        selectCoin,
        closeModel,
        FieldType,
        upperDropdown,
        lowerDropdown,
    } = props;

    const [PopularCoins, setPopularCoins] = useState(['BTC', 'ETH', 'XMR', 'LTC', 'USDTERC20']);
    const [PopularCoinList, setPopularCoinList] = useState([]);
    const [AllCoinList, setAllCoinList] = useState([]);
    const [acitveCoin, setAcitveCoin] = useState('')
    const [ImageIndex, setImageIndex] = useState(5);
    const [inputKeyWord, setInputKeyWord] = useState('');

    useEffect(() => {
        document.getElementsByName(`upperInput`)[0].focus()
        document.getElementsByName(`upperInput`)[1].focus()
        if (FieldType == "upper") {
            setAcitveCoin(upperDropdown.legacyTicker)
        } else {
            setAcitveCoin(lowerDropdown.legacyTicker)
        }
        coinFilter()
    }, [listCoins, upperDropdown, lowerDropdown])

    const selectHandle = async (item) => {
        if (FieldType == "upper") {
            selectCoin(item, lowerDropdown, true)
        } else {
            selectCoin(upperDropdown, item, false)
        }
        closeModel();
    };
    const onScroll = async (e) => {
        if (parseInt(e.target.scrollTop / 100) < 5) {
            setImageIndex(10)
            return;
        }
        setImageIndex(parseInt(e.target.scrollTop / 100) * 3)
    }

    const coinFilter = async () => {
        const popularCoin = listCoins.filter(item => PopularCoins.includes(item.legacyTicker.toUpperCase()))
        const allCoin = listCoins.filter(item => !PopularCoins.includes(item.legacyTicker.toUpperCase()))
        setPopularCoinList(popularCoin)
        setAllCoinList(allCoin)
        if (popularCoin.length == 0 && allCoin.length == 0) {
            console.log('Cryptocurrency was not found.')
        }
    }


    const closethisModel = async (e) => {
        closeModel(e)
        setInputKeyWord('')
    }

    const inputHandler = async (e) => {
        searchCoin(e)
        setInputKeyWord(e.target.value)
    }

    return (
        <>
        <div class="fade modal-backdrop show"></div>
            <div className="type_selectbox">
                <Row>
                    <Col lg={12} className="px-0">
                        <div>
                            <div className="search_ticket">
                                {/* <img
                                    src="assets/icons/search.svg"
                                    alt="search"
                                    className="tiketicon"
                                /> */}
                                <IoIosSearch className="typesearch" />
                                <input
                                    name='upperInput'
                                    type="text"
                                    onChange={(e) =>
                                        inputHandler(e)
                                    }
                                    autoComplete="off"
                                    className="border-0"
                                    placeholder="Type a cryptocurrency or ticker"
                                    value={inputKeyWord}
                                />
                                <img
                                    src="assets/icons/close.png"
                                    alt="close"
                                    className="selectclose"
                                    onClick={(e) =>
                                        closethisModel(e)
                                    }
                                />
                            </div>
                            <div className="currencyDropdownlist">
                                <div className="table_popular" onScroll={onScroll}>
                                    
                                    {PopularCoinList.length > 0 || AllCoinList.length > 0 ?
                                        <>
                                            {PopularCoinList.length > 0 &&
                                                <>
                                                    <h6>
                                                        Popular cryptocurrencies
                                                    </h6>
                                                    <ul className="pl-0 Currency-list curListBtm">

                                                        {PopularCoinList &&
                                                            PopularCoinList.map(
                                                                (item, index) => {
                                                                    return (
                                                                        <li style={{ cursor: 'pointer' }} className={acitveCoin == item.legacyTicker ? 'active' : ''} onClick={(e) => selectHandle(item)}>
                                                                            <div className="d-flex align-items-center ">
                                                                                <div>
                                                                                    <div
                                                                                        className="item-select"
                                                                                    >
                                                                                        <div className="d-flex align-items-center">
                                                                                            <img
                                                                                                src={ImageIndex >= index ? item.image : 'assets/icons/coindefault.svg'}
                                                                                                alt="btc"
                                                                                                className=" pe-2"
                                                                                            />
                                                                                            <span>
                                                                                                {item.ticker?.toUpperCase()}
                                                                                            </span>
                                                                                            {/* <span
                                                                                className={
                                                                                    item.network
                                                                                        ? "token-network"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.network.toUpperCase()
                                                                                }
                                                                            </span> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div
                                                                                        className="item-name"
                                                                                    >
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </li>
                                                                    );
                                                                }
                                                            )}

                                                    </ul>
                                                </>
                                            }


                                            {AllCoinList.length > 0 &&
                                                <>
                                                    <h6>
                                                        All cryptocurrencies
                                                    </h6>
                                                    <ul className="pl-0 Currency-list">

                                                        {AllCoinList &&
                                                            AllCoinList.map(
                                                                (item, index) => {
                                                                    return (
                                                                        <li style={{ cursor: 'pointer' }} className={acitveCoin == item.legacyTicker ? 'active' : ''} onClick={(e) => selectHandle(item)}>
                                                                            <div className="d-flex align-items-center">
                                                                                <div>
                                                                                    <div
                                                                                        className="item-select"
                                                                                    >
                                                                                        <div className="d-flex align-items-center">
                                                                                            <img
                                                                                                src={ImageIndex >= index ? item.image : 'assets/icons/coindefault.svg'}
                                                                                                alt="btc"
                                                                                                className=" pe-2"
                                                                                            />
                                                                                            <span>
                                                                                                {item.ticker?.toUpperCase()}
                                                                                            </span>
                                                                                            {/* <span
                                                                                className={
                                                                                    item.network
                                                                                        ? "token-network"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.network.toUpperCase()
                                                                                }
                                                                            </span> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div
                                                                                        className="item-name"
                                                                                    >
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                }
                                                            )}


                                                    </ul>
                                                </>
                                            }
                                        </>
                                        :
                                        <h6>
                                            Cryptocurrency was not found.
                                        </h6>
                                    }

                                </div>
                            </div>

                        </div>
                    </Col>
                </Row >

            </div>
        </>
    )
}