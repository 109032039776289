import { ThreeDots } from 'react-loader-spinner';

export const LockIcon = () => {
    return <button type="button" class="btn btn-default border-0 lockbtn"><svg width="18" height="21" viewBox="0 0 18 21" xmlns="http://www.w3.org/2000/svg" fill="#987bff"><path d="M8 15V12H10V15C10 15.5523 9.55229 16 9 16C8.44771 16 8 15.5523 8 15Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M4 5C4 2.23858 6.23858 0 9 0C11.7614 0 14 2.23842 14 4.99988V7H16C17.1046 7 18 7.89543 18 9V17C18 19.2091 16.2091 21 14 21H4C1.79086 21 0 19.2091 0 17V9C0 7.89543 0.895431 7 2 7H4V5ZM12 7H6V5C6 3.34315 7.34315 2 9 2C10.6569 2 12 3.34307 12 4.99988V7ZM2 9V17C2 18.1046 2.89543 19 4 19H14C15.1046 19 16 18.1046 16 17V9H2Z"></path></svg></button>
}

export const InputLoader = ({ isLoader, type }) => {
    if (isLoader.type == type) {
        return <div className="dotsloader" style={{ display: !isLoader.on ? 'none' : '' }} >
            <div className="snippet" data-title="dot-flashing">
                <div className="stage">
                    <div className="dot-flashing" />
                </div>
            </div>
        </div>
    }
}
export const ThreeDotLoaderProcess = ({ isLoader}) => {
    if (isLoader.type) {
        return <div className="dotsloader" style={{ display: !isLoader.on ? 'none' : '' }} >
            <div className="snippet" data-title="dot-flashing">
                <div className="stage">
                    <div className="dot-flashing" />
                </div>
            </div>
        </div>
    }
}

export const ThreeDotLoader = () => {
        return <ThreeDots
        visible={true}
        height="10"
        width="1000"
        color="#fff"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
    />
}


