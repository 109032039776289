import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Header from '../directives/Header';
import Footer from '../directives/Footer';
import { Container, Row, Col, Nav, Navbar, NavDropdown, Button, Form, InputGroup, Modal, Table, Accordion } from 'react-bootstrap';
import Exchange from './exchange';


const Exchanging = () => {
    return (
        <>

            <Header />



            <section className='exchanging pt-5'>
                <Container>
                    <Row>
                        <Col lg={12} md={12} className='mb-4'>
                            <div className='need_help'>
                                <Row>
                                    <Col lg={6} md={6}>
                                        <div className='exchangeid'>
                                            <span>Exchange ID: </span>
                                            <Form.Control
                                                placeholder="543mn1202455v52v5vt"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                            />
                                            <a href='#'><span><img src="assets/icons/Plus.svg" alt="Plus" className='question' /></span></a>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <div className='help_right'>
                                            <img src="assets/icons/question.svg" alt="question" className='question me-2' />
                                            <span>Need Help?</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className='add_exchangetext mb-3'>
                                <div className='deposit_content pb-3'>
                                    <h2 className='mb-3'>Confirm the transection</h2>
                                    <p className='deposit_para'>Let’s give some time to blockchain confirmations </p>
                                </div>

                                <div className='deposit_bottom'>

                                    <div className='deposit_bottom_block checkblock'>
                                        <img src="assets/icons/depositcheck.svg" alt='' className='mb-3 depositeimg active' />
                                        <p>Pending Deposit</p>
                                    </div>

                                    <div className='deposit_bottom_block checkblock'>
                                        <img src="assets/icons/depositcheck.svg" alt='' className='mb-3 depositeimg' />
                                        <p>Confirming</p>
                                    </div>

                                    <div className='deposit_bottom_block checkblock'>
                                        <img src="assets/icons/depositcheck.svg" alt='' className='mb-3 depositeimg' />
                                        <p>Exchanging</p>
                                    </div>

                                    <div className='deposit_bottom_block active'>
                                        <img src="assets/icons/sending.svg" alt='' className='mb-3 depositeimg' />
                                        <p>Sending</p>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} md={12} className='mt-4 mb-5'>
                            <div className='brnexchange_sec'>
                                <div>
                                    <img src="assets/images/bnr_exchange.png" alt='' className='bnr_exchange' />
                                </div>
                                <div className='brntext_block'>
                                    <h3>Get an account to receive cashback for every exchange</h3>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>

                            <div className='add_exchangetext '>
                                <div className='deposit_content'>
                                    <div className='operating_head'>
                                        <h4>Operating details</h4>
                                    </div>
                                    <Row>
                                        <Col lg={12} className='mt-4'>
                                            <div className='send_deposit mb-4'>
                                                <p>You get:</p>
                                                <div className='depositfield btcoin'>
                                                    <div>
                                                        <span><img src="assets/icons/eth.svg" alt='' className='pe-3' /></span>
                                                        ETH = 54.87550210105
                                                    </div>

                                                    <div className='btcoin ms-lg-4'>
                                                        <p>ETH=20 coin</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>


                                        <Col lg={12} className='mb-5'>
                                            <div className='depositaddress'>
                                                <p>Recipient address:</p>
                                                <div className='recieptbox'>
                                                    <div className=''>
                                                        <p>XYZ1233545669778kdfdhdgffgsasass555d5sdjhssgdjsdsds8ds465f</p>
                                                    </div>

                                                    <div className='copybox'>
                                                        <a href='#'><span className='icobox me-3'><img src="assets/icons/copyicon.svg" alt='' className='' /></span></a>
                                                        <a href='#'><span className='icobox'><img src="assets/icons/sendicons.svg" alt='' className='' /></span></a>
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12} className='mt-4'>
                                            <div className='send_deposit mb-4'>
                                                <p>You sent:</p>
                                                <div className='depositfield btcoin'>
                                                    <div>
                                                        <span><img src="assets/icons/btc.svg" alt='' className='pe-3' /></span>
                                                        BTC = 54.87550210105
                                                    </div>

                                                    <div className='btcoin ms-lg-4'>
                                                        <p>BTC=20 coin</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>


                                        <Col lg={12} className='mb-4'>
                                            <div className='depositaddress'>
                                                <p>Deposit address:</p>
                                                <div className='recieptbox'>
                                                    <div className=''>
                                                        <p>XYZ1233545669778kdfdhdgffgsasass555d5sdjhssgdjsdsds8ds465f</p>
                                                    </div>

                                                    <div className='copybox'>
                                                        <a href='#'><span className='icobox me-3'><img src="assets/icons/copyicon.svg" alt='' className='' /></span></a>
                                                        <a href='#'><span className='icobox'><img src="assets/icons/sendicons.svg" alt='' className='' /></span></a>
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>
                                        <Col lg={12} className=''>
                                            <div className='depositaddress'>
                                                <p>Hash in:</p>
                                                <div className='recieptbox'>
                                                    <div className=''>
                                                        <p>XYZ1233545669778kdfdhdgffgsasass555d5sdjhssgdjsdsds8d<br />s46569778kdfdhdgffgsasf</p>
                                                    </div>

                                                    <div className='copybox'>
                                                        <a href='#'><span className='icobox me-3'><img src="assets/icons/copyicon.svg" alt='' className='' /></span></a>
                                                        <a href='#'><span className='icobox'><img src="assets/icons/sendicons.svg" alt='' className='' /></span></a>
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} md={12} className='my-5'>
                            <div className='start_exchange'>
                                <a href='#'>
                                    <span><img src="assets/icons/addcircle.svg" alt='' className='pe-3' /></span>
                                    Start a new exchange
                                </a>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>





            <Footer />


        </>
    )
}

export default Exchanging;
