export const PressData = [
    {
      image: "assets/images/FortaCapitallogoNew.png", //assets/images/FortaCapital_logo.png
      title: "Purple Swap Review",
      span: "2023",
      description: "Purple Swap is recognized as a user-friendly and secure cryptocurrency exchange platform. Designed for fast and effortless crypto transactions…",
      link: "Read More",
    }
    
  ];
