import { Toast } from "react-bootstrap"
import "../componentCss/toaster.css"

export const FixedExchangeMessage = ({ onClick }) => {
    return (
        <Toast style={{ width: "100%" }} bg="primary" onClose={onClick}>
            <Toast.Header>
                <div className="me-auto">
                    <small class=""><span class="fixedfont">Fixed Rate –</span> <span>Purple Swap guarantees you will receive the agreed amount.</span></small>
                </div>
            </Toast.Header>
        </Toast>
    )
}


export const MinimumAmountMessage = ({ Amount, Currency, onClick }) => {
    return (
        <Toast style={{ width: "100%" }} bg="danger">
            {/*  onClick={onClick} */}
            <Toast.Header>
                <div className="me-auto">
                    <small onClick={onClick}>
                        <span>
                            Minimum amount is :&nbsp;

                            <span>
                                <strong className="me-auto">
                                    {Amount} {Currency}
                                </strong>
                            </span>
                        </span>
                    </small>
                </div>
            </Toast.Header>
        </Toast>
    )
}


export const MaximumAmountMessage = ({ Amount, Currency, onClick }) => {
    return (
        <Toast style={{ width: "100%" }} bg="danger">
            {/*  onClick={onClick} */}
            <Toast.Header>
                <div className="me-auto">
                    <small onClick={onClick}>
                        <span>
                            Maximum amount is :&nbsp;
                        </span>
                        <span>
                            <strong className="me-auto">
                                {Amount} {Currency}
                            </strong>
                        </span>
                    </small>
                </div>
            </Toast.Header>
        </Toast>
    )
}


// export const ErrorMessage = ({ error }) => {
//     return (
//         <Toast style={{ width: "100%" }} bg="danger" >
//             <Toast.Header>
//                 <div className="me-auto">
//                     <small>
//                         {error}
//                     </small>
//                 </div>
//             </Toast.Header>
//         </Toast>
//     )
// }

export const SuccessMessage = ({ msg }) => {
    return (
        <Toast style={{ width: "100%" }} bg="success" >
            <Toast.Header className="p-0">
                <div className="me-auto">
            
                    <small class=""><div className="alert_block"><div class="successIconbox me-2"><img src="assets/icons/checkedIcon.png" alt="successIcon" className="alertIcon" /></div> <span className="alert-text">  <b>Confirmation : </b> {msg}</span></div></small>
                        
             
                </div>
            </Toast.Header>
        </Toast>
    )
}

export const ErrorMessage = ({ error, type = 'Error' }) => {
    return (
        <Toast style={{ width: "100%" }} bg="danger">
            <Toast.Header className="p-0">
                <div className="me-auto">
                    <small class=""><div className="alert_block"><div class="crossIconbox me-2"><img src="assets/icons/crossIcon.png" alt="crossIcon" className="alertIcon" /></div> <span className="alert-text">  {error}</span></div></small>

                </div>
            </Toast.Header>
        </Toast>
    )
}


export const NoteMessage = () => {
    return (
        <Toast style={{ width: "100%" }} bg="primary" className="alertBox infodiv" >
            <Toast.Header className="text-left p-0" >
                <div className="me-auto">
                    <small class=""><div className="alert_block"><div class="infoIconbox me-2"><img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" /></div> <span className="alert-text">Please provide detailed information in all fields to help us assist you more effectively.</span></div></small>
                </div>
            </Toast.Header>
        </Toast>
    )
}
export const FixedRate = () => {
    return (
        <Toast style={{ width: "100%" }} bg="primary" className="alertBox yellowBox" >
            <Toast.Header className="text-left p-0">
                <div className="me-auto">
                    {/* <small class="">
                        <span class="fixedfont">Info :</span> 
                        <span>Make sure to provide the ERC-20 address associated with your Purple Swap account.</span>
                    </small> */}
                    <small class=""><div className="alert_block"><div class="warningIconbox me-2"><img src="assets/icons/warningIcon.png" alt="warningIcon" className="alertIcon" /></div> <span className="alert-text">  Make sure to provide the ERC-20 address of your Purple Swap account.</span></div></small>

                </div>
            </Toast.Header>
        </Toast>
    )
}

export const WarningForget = () => {
    return (
        <Toast style={{ width: "100%" }} bg="primary" className="alertBox yellowBox" >
            <Toast.Header className="text-left p-0" style={{ background: " #fff9e6", color: "#ac8515" }}>
                {/* <div className="me-auto">
                    <small class="">
                        <span class="fixedfont">Info :</span>
                        <span>Please provide the ERC-20 address associated with your Purple Swap account and a contact email address. A specialist will swiftly reach out to assist you in regaining access to your account.</span></small>
                </div> */}

                <div className="me-auto">
                    <small class="">
                        <div className="alert_block warningBlock">
                            <div class="warningIconbox me-2">
                                <img src="assets/icons/warningIcon.png" alt="warningIcon" className="alertIcon" />
                            </div>
                            <div className="alert-text">
                                <p className="fs-6 mb-0" style={{lineHeight:"1.5"}}>
                                    Please note that Purple Swap cannot recover your chosen password. Losing your password means losing access to all funds in your account. It is crucial to store your password securely.
                                </p>
                            </div>
                        </div>
                    </small>
                </div>
            </Toast.Header>
        </Toast>
    )
}
export const VerificationEmail = () => {
    return (
        <Toast style={{ width: "100%" }} bg="primary" className="alertBox yellowBox" >
            <Toast.Header className="text-left" style={{ background: " #fff9e6", color: "#ac8515" }}>
                <div className="me-auto">
                    <small class="">
                        {/* <span class="fixedfont">Info :</span>  */}
                        <span>A verification email will be sent to the provided address.</span></small>
                </div>
            </Toast.Header>
        </Toast>
    )
}

export const NoteMessage2 = () => {
    return (
        <Toast style={{ width: "100%" }} bg="primary" className="alertBox" >
            <Toast.Header className="text-left">
                <div className="me-auto">
                    <small class=""><span class="fixedfont"><img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" /></span> <span className="alert-text">Please provide detailed information in all fields to help us assist you more effectively.</span></small>
                </div>
            </Toast.Header>
        </Toast>
    )
}

export const SuccessMessageSupport = () => {
    return (
        <Toast style={{ width: "100%" }} bg="success" >
            <Toast.Header className="text-center d-block">
                <div className="p-4 px-0">

                    <h5 className=" text-black mb-4"><b>Your message has been sent!</b></h5>

                    <p className="mb-0 fs-6">
                        We're glad to hear from you. Our team is on the case and will get back to you as soon as we can. Hang tight!
                    </p>

                </div>


            </Toast.Header>
        </Toast>
    )
}

// --------greenAlertBox-------------

export const GreenAlertBox = () => {
    return (
        
        <Card className="border-0 mt-4" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
        <div className="alert_block infoBlock">
            <div class="infoIconbox me-2">
                <img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" />
            </div>
            <div className="alert-text">
                <p className="mb-0">
                I acknowledge that I am creating an invest account with Purple Swap. I understand the importance of the access details I will receive for managing my account and funds, recognizing their confidentiality. I commit to implementing all necessary steps to protect this information.
                </p>
            </div>
        </div>

    </Card>


        // <Card className="border-0 mt-4 mb-4" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
        //     <div className="alert_block infoBlock">
        //         <div class="infoIconbox me-2">
        //             <img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" />
        //         </div>
        //         <div>
        //             <p className="mb-0">
        //                 You are now required to choose a password to secure your account. To ensure a strong password, you must meet the following five criteria, which are designed to prevent unauthorized access and maintain your account's security.
        //             </p>
        //         </div>
        //     </div>
        // </Card>




    )
}
