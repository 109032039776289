import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Nav,
  Tab,
  Button,
  Card,
  FloatingLabel,
  Form,
  Toast
} from "react-bootstrap";
import "../componentCss/earn.css"
import "../componentCss/financial.css"
import "../componentCss/earnDashboard.css"
import "../componentCss/wallet.css"
import { FaRegEye, FaChevronDown, FaRegEyeSlash } from "react-icons/fa6";
import { CiLock } from "react-icons/ci";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import copy from "copy-to-clipboard";
import QRCode from "react-qr-code";
import { MultiColorThreeDotLoader, QrCodeLoader, WalletPageThreeDotLoader } from "../generic";
import { UserwithdrawAction, createTransferAction, createTransferInvestedToAvailableAction, getWalletBalanceAction } from "../../coreFiles/user.action";
import { isAddress } from "web3-validator";
import Cookies from "js-cookie";
import { MdSwapVert } from "react-icons/md";
const percentage = 0;
const percentage2 = 0;

const Wallet = ({ setWallatetitle }) => {
  let loginUserData = Cookies.get('purpleUser') ? JSON.parse(Cookies.get('purpleUser')) : '';

  const [handleCopyState, setCopyState] = useState(false)
  const [walletAddress, setWalletAddress] = useState(loginUserData.walletAddress)
  const [confirmPasswordLoading, setConfirmPasswordLoading] = useState(false);
  const [handleMassageSection, setMassageSection] = useState(false)
  const [handleConfirmMessage, sethandleConfirmMessage] = useState(false)
  const [handleBlurSection, setBlurSection] = useState(false)
  const [showPassword, setShowHidePassword] = useState(false)
  const [showPassword_transfer, setShowHidePassword_transfer] = useState(false)

  // wirhdraw section feild-----
  const [withdraw_WalletAddressfield, setwithdraw_WalletAddressfield] = useState('')
  const [withdraw_Passwordfield, setwithdraw_Passwordfield] = useState('')
  const [withdraw_amount, setwithdraw_amount] = useState()
  const [handleButtonDesable, setButtonDesable] = useState(false)
  const [userWalletBalance, setuserWalletBalance] = useState([])
  // wirhdraw section feild ends-----
  // transfer section feild
  const [transfer_Amountfield, setTransfer_Amountfield] = useState('')
  const [transfer_Passwordsfield, setTransfer_Passwordsfield] = useState('')
  // transfer section feild ends
  const [QrCodeLoder, setQrCodeLoder] = useState(true)
  const [activeTab, setActiveTab] = useState('deposit');
  const [fundManagementError, setFundManagementError] = useState('')
  const [trxId, setTrxId] = useState('');
  const [mainLoading, setMainLoading] = useState(false)
  // for showing wallete Balance percentage on wallate page 
  const [hotWalletBalancePercentage, sethotWalletBalancePercentage] = useState(0)
  const [coldWalletBalancePercentage, setcoldWalletBalancePercentage] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setQrCodeLoder(false)
    }, 2000);
    if (loginUserData) {
      getWalletBalance();

      // setInterval(() => {
      //   getWalletBalance();
      // }, 10000);
    }
  }, []);

  const copyToClipboard = (order) => {
    copy(order);
    navigator.clipboard.writeText(order)
  };
  const handleCopyAction = async () => {
    setCopyState(true)
    setTimeout(() => {
      setCopyState(false)
    }, 1500);
  }
  const showHidePassword_Withdraw = (s) => {
    setShowHidePassword(s)
  }
  const showHidePassword = (s) => {
    setShowHidePassword_transfer(s)

  }

  const submitWithdrwRequest = async () => {

    if (!isAddress(withdraw_WalletAddressfield)) {
      setFundManagementError('The provided ERC-20 address does not meet the required format.')
      return false;
    }
    if (withdraw_WalletAddressfield == '') {
      setFundManagementError('The provided ERC-20 address does not meet the required format.')
      return false;
    }
    if (withdraw_WalletAddressfield == walletAddress) {
      setFundManagementError('Withdrawing to your Purple Swap account address is not possible.')
      return false;
    }
    if (withdraw_Passwordfield == '') {
      setFundManagementError('The entered password does not match our records.')
      return false;
    } if (withdraw_amount < 100) {
      setFundManagementError('The withdrawal amount is below the minimum requirement of 100 USDT. ')
      return false;
    } if (withdraw_amount > userWalletBalance.hotWalletBalance || withdraw_amount > 5000000) {
      setFundManagementError('The withdrawal amount exceeds your available balance.')
      return false
    }
    setConfirmPasswordLoading(true);
    setMassageSection(true)
    setButtonDesable(true)

    let data = {
      fromAddress: loginUserData.walletAddress,
      withdrawalAddress: withdraw_WalletAddressfield,
      usdtAmount: parseInt(withdraw_amount),
      password: withdraw_Passwordfield
    }

    let res = await UserwithdrawAction(data)
    if (res.success) {
      sethandleConfirmMessage(true)
      setTrxId(res.data.trxId);
      getWalletBalance();
      setFundManagementError('')
      setBlurSection(true)
      setMassageSection(false)
      setTimeout(() => {
        setConfirmPasswordLoading(false);
      }, 3000);
    }
    else {
      setConfirmPasswordLoading(false);
      setFundManagementError(res.msg);
    }


  }

  const [fromWalletId, setFromWalletId] = useState(1);
  const [toWalletId, setToWalletId] = useState(2);

  const submitTransferRequest = async () => {
    if (transfer_Amountfield == '') {
      setFundManagementError('Please enter amount.')
      return false;
    } if (transfer_Passwordsfield == '') {
      setFundManagementError('The entered password does not match our records.')
      return false;
    }
    if (transfer_Amountfield < 115) {
      setFundManagementError('The transfer amount is below the minimum requirement of 115 USDT.')
      return false;
    } if (transfer_Amountfield > 5000000) {
      setFundManagementError('The transfer amount exceeds your available balance.')
      return false
    }
    let walletBalance = userWalletBalance.hotWalletBalance;
    if (fromWalletId == 2) {
      walletBalance = userWalletBalance.coldWalletBalance
    }
    if (transfer_Amountfield > walletBalance) {
      setFundManagementError("Amount should be less than your available wallet balance.")
      return false
    }
    setConfirmPasswordLoading(true)
    setMassageSection(true)
    setButtonDesable(true)
    let data = {
      usdtAmount: parseInt(transfer_Amountfield),
      password: transfer_Passwordsfield,
      fromWalletId,
      toWalletId,
    }
    let res;
    if (fromWalletId == 1) {
      res = await createTransferAction(data)
    }
    else {
      res = await createTransferInvestedToAvailableAction(data)
    }

    if (res.success) {
      sethandleConfirmMessage(true)
      setTrxId(res.data.trxId);
      getWalletBalance();
      setMassageSection(false)
      setButtonDesable(false)
      setFundManagementError('')
      setBlurSection(true)
      setConfirmPasswordLoading(false);
      // setTimeout(() => {
      // }, 3000);
    }
    else {
      setConfirmPasswordLoading(false)
      setFundManagementError(res.msg)
    }

  }
  const handleTabChange = async (data) => {
    // setMainLoading(true)
    getWalletBalance();
    // walletAvailableBalancePercentage()
    sethandleConfirmMessage(false)
    setWallatetitle(data)
    setBlurSection(false)
    setConfirmPasswordLoading(false)
    setMassageSection(false)
    setFundManagementError('')
    setwithdraw_Passwordfield('')
    setwithdraw_WalletAddressfield('')
    setwithdraw_amount('')
    setTransfer_Passwordsfield('')
    setTransfer_Amountfield('')
  }

  const getWalletBalance = async () => {
    try {
      let data = {
        walletAddress: loginUserData.walletAddress
      }
      let res = await getWalletBalanceAction(data);
      if (res.success) {
        setuserWalletBalance(res.data);
        const sum = res.data.coldWalletBalance + res.data.hotWalletBalance;
        let hotWalletBalancePercentage = parseFloat((res.data.hotWalletBalance * 100) / sum).toFixed(2);
        let coldWalletBalancePercentage = parseFloat((res.data.coldWalletBalance * 100) / sum).toFixed(2);
        if (hotWalletBalancePercentage == 'NaN') hotWalletBalancePercentage = 0;
        if (coldWalletBalancePercentage == 'NaN') coldWalletBalancePercentage = 0;
        sethotWalletBalancePercentage(hotWalletBalancePercentage);
        setcoldWalletBalancePercentage(coldWalletBalancePercentage);
      }
    } catch (error) {
      console.log(error)
    }
  };
  useEffect(() => {
    setTransfer_Amountfield('')
  }, [fromWalletId, toWalletId]);

  const onClickFromField = () => {
    const temp = toWalletId;
    setToWalletId(fromWalletId);
    setFromWalletId(temp);
  };

  const handleMaxTransfer = () => {
    if (fromWalletId == 1) {
      setTransfer_Amountfield(parseFloat(userWalletBalance.hotWalletBalance).toFixed(0));
    }
    else {
      setTransfer_Amountfield(parseFloat(userWalletBalance.coldWalletBalance).toFixed(0));
    }
  }

  return (
    <>
      <Row>
        <Col lg={12} className="">
          {/* {handleMassageSection == true ? <>
            <div className="float-rate d-table px-0 mb-3 ToasterCloseHide">
              <Toast style={{ width: "100%" }} bg="danger" className="alertBox yellowBox" >
                <Toast.Header className="text-left p-0">
                  <div className="me-auto">
                    <small class="">
                      <div className="alert_block">
                        <div class="warningIconbox me-2">
                          <img src="assets/icons/warningIcon.png" alt="warningIcon" className="alertIcon" />
                        </div>
                        <span className="alert-text">You currently have a transaction in progress. Please refer to the Transactions section for more details.</span>
                      </div>
                    </small>
                  </div>
                </Toast.Header>
              </Toast>
            </div>
          </> : ""} */}
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mb-3 ">
          <Card className="border-0 p-3 shadow ">

            <div>

              <div className="d-flex align-items-center justify-content-between">
                <div className="mb-1">
                  <div className="mb-1">
                    <h5 className="mb-1">Available</h5>
                    <small className=" fw-lighter w-75">Hot Wallet</small>
                  </div>
                  <div className="d-flex align-items-end">
                    <h4 className="mb-0 me-1">{userWalletBalance.hotWalletBalance == null ? '0.00' : parseFloat(userWalletBalance.hotWalletBalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h4>
                    <small className="f fw-lighter ">USDT</small>
                  </div>
                </div>
                <div style={{ width: 80, height: 80 }}>
                  <CircularProgressbar
                    styles={buildStyles({
                      rotation: 10,
                      textSize: '20px',
                      pathTransitionDuration: 0.5,
                      pathColor: `rgba(27, 20, 64)`,
                      textColor: '#000',
                      trailColor: '#e9e3ff',
                      backgroundColor: '#3e98c7',
                    })}
                    value={hotWalletBalancePercentage}
                    text={`${hotWalletBalancePercentage}%`} />
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={6} className="mb-3">
          <Card className="border-0 p-3 shadow ">
            <div>
              <div className="d-flex align-items-center justify-content-between mb-1">
                <div>
                  <div className="mb-1">
                    <h5 className="mb-1">Invested</h5>
                    <small className=" fw-lighter">Cold Wallet</small>
                  </div>
                  <div className="d-flex align-items-end">
                    <h4 className="mb-0 me-1">{parseFloat(userWalletBalance.coldWalletBalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h4>
                    <small className="f fw-lighter">USDT</small>
                  </div>
                </div>
                <div style={{ width: 80, height: 80 }}>
                  <CircularProgressbar
                    styles={buildStyles({
                      rotation: 10,
                      textSize: '20px',
                      pathTransitionDuration: 0.5,
                      pathColor: `rgba(27, 20, 64)`,
                      textColor: '#000',
                      trailColor: '#e9e3ff',
                      backgroundColor: '#3e98c7',
                    })}
                    value={coldWalletBalancePercentage}
                    text={`${coldWalletBalancePercentage}%`} />
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="mb-4">
          {mainLoading &&
            <div className="confirmationBox">
              <div className="checkSuccess2">
                <WalletPageThreeDotLoader />

              </div>
            </div>

          }
          <div className="tab_style3">
            <Tab.Container id="left-tabs-example" defaultActiveKey="deposit">
              <Row>
                <Col sm={12}>
                  <Nav variant="pills" className="row justify-content-between">
                    <Nav.Item className="col-lg-4 col-md-4 col-sm-4 col-4">
                      <Nav.Link eventKey="deposit" onClick={() => { handleTabChange('deposit'); setActiveTab('deposit'); }} className="tab-content-box text-center">Deposit</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="col-lg-4 col-md-4 col-sm-4 col-4">
                      <Nav.Link eventKey="withdraw" onClick={() => { handleTabChange('withdraw'); setActiveTab('withdraw'); }} className="tab-content-box text-center">Withdraw</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="col-lg-4 col-md-4 col-sm-4 col-4">
                      <Nav.Link eventKey="transfer" onClick={() => { handleTabChange('transfer'); setActiveTab('transfer'); }} className="tab-content-box text-center">
                        <div className="position-relative">
                          Transfer
                          {/* <sup className="soon">SOON</sup> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12} className="">
                  <hr class="my-3 opacity-100 border-0" style={{ background: "linear-gradient(to right, rgb(151, 123, 255), rgb(78, 122, 238), rgb(38, 182, 216))", height: "5px" }} />
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="deposit">
                      <Row>
                        <Col lg={12} className="">
                          <Card className="border-0 mb-3" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
                            <div className="alert_block infoBlock">
                              <div class="infoIconbox me-2">
                                <img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" />
                              </div>
                              <div className="alert-text">
                                <p className="mb-0">
                                  Make sure to deposit only USDT on the ERC-20 network into your account.
                                </p>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>

                      <div className="filed_box">
                        <Row>
                          <Col lg={10} md={10} xs={9} className="singleField mb-3">
                            <div className="field_block">
                              <FloatingLabel
                                controlId="depositaddress"
                                label="Deposit Address"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Enter the Deposit Address value"
                                  name="DepositAddress"
                                  disabled
                                  className="text-darkblue"
                                  value={walletAddress}
                                  autoComplete="off"
                                />
                              </FloatingLabel>
                            </div>
                          </Col>
                          <Col lg={2} md={2} xs={3} className="singleBtn  ml-auto mb-3">
                            <Button variant="default"
                              onClick={() => { copyToClipboard(walletAddress); handleCopyAction() }}
                              className="captchaCode select-btn fs-6 w-100 lh-0 letter-spacing-inherit"
                            >
                              {handleCopyState == true ? "Copied" : "Copy"}
                            </Button>
                          </Col>
                          <Col lg={12}>
                            <Row>
                              <Col xl={8} lg={7} className="singleField">
                                <div className="field_block mb-3">

                                  <FloatingLabel
                                    controlId="selectedcoin"
                                    label="Selected Coin"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the Selected coin value"
                                      name="selectedcoin"
                                      disabled
                                      className="text-darkblue"
                                      value={`USDT Tether`}
                                      autoComplete="off"
                                    />

                                    <span className="postion-absolute" style={{ top: "12px", right: "12px" }}>
                                      <CiLock fill="#6c757d" style={{ fontSize: "30px" }} />
                                    </span>
                                  </FloatingLabel>

                                </div>
                                <div className="field_block mb-3">

                                  <FloatingLabel
                                    controlId="selectnetwork"
                                    label="Selected Network"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the Selected Network value"
                                      name="selectnetwork"
                                      className="text-darkblue"
                                      disabled
                                      value={`ETH Ethereum (ERC-20)`}
                                      autoComplete="off"
                                    />
                                    <span className="postion-absolute" style={{ top: "12px", right: "12px" }}>
                                      <CiLock fill="#6c757d" style={{ fontSize: "30px" }} />
                                    </span>

                                  </FloatingLabel>

                                </div>
                                <div className="detailBox " style={{ backgroundColor: "#e9ecef" }}>

                                  <Row>

                                    <Col lg={3}>
                                      <small className="text-grey">Deposit Limit</small>
                                    </Col>
                                    <Col lg={9} className="text-right">
                                      <small className="text-darkblue">  Max. 500,000 </small>
                                    </Col>
                                    <Col lg={6}>
                                      <small className="text-grey">Selected Coin</small>
                                    </Col>
                                    <Col lg={6} className="text-right">
                                      <small className="text-darkblue">USDT Tether</small>
                                    </Col>
                                    <Col lg={6} >
                                      <small className="text-grey">Selected Network</small>
                                    </Col>
                                    <Col lg={6} className="text-right">
                                      <small className="text-darkblue">ETH Ethereum (ERC-20)</small>
                                    </Col>
                                    <Col lg={6}>
                                      <small className="text-grey">Expected Arrival</small>
                                    </Col>
                                    <Col lg={6} className="text-right">
                                      <small className="text-darkblue">~ 3 Minutes</small>
                                    </Col>
                                    <Col lg={6}>
                                      <small className="text-grey">Network Fee</small>
                                    </Col>
                                    <Col lg={6} className="text-right">
                                      <small className="text-darkblue">Free</small>
                                    </Col>

                                  </Row>
                                </div>


                              </Col>
                              <Col xl={4} lg={5}>
                                <Card className="qrCode border-0 shadow pt-0">
                                  <small className="text-grey qrcodlable">QR Code</small>
                                  <div className="p-4">
                                    {!QrCodeLoder ?
                                      <QRCode
                                        title=""
                                        value={walletAddress}
                                        // bgColor={back}
                                        // fgColor={fore}
                                        size={185}
                                      />
                                      :
                                      <div className="qrImage">
                                        <QrCodeLoader />
                                      </div>
                                    }
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                          </Col>

                        </Row>

                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="withdraw">
                      {handleConfirmMessage == true ?
                        <>
                          <Card className="border-0 greenBox mb-3 p-3">
                            <div className="">
                              <p className="mb-0">
                                <b>Transaction ID: #{trxId}</b> - Your withdrawal request has been received and should appear in your wallet within a few minutes. You can check the progress of your withdrawal on the transactions page.
                              </p>

                            </div>
                          </Card>
                        </>
                        : ""}


                      <div className="filed_box position-relative">
                        <Row>
                          {!fundManagementError == '' ?
                            <Col lg={12} md={12} className=" pb-1">

                              <div className="justify-content-between FixedRate">

                                <div className="float-rate d-table px-0 mb-0 alertBox ToasterCloseHide">
                                  <Toast style={{ width: "100%" }} bg="danger" className="alertBox" >
                                    <Toast.Header className="text-left p-0">
                                      <div className="me-auto">
                                        <small class="">
                                          <div className="alert_block">
                                            <div class="crossIconbox me-2">
                                              <img src="assets/icons/crossIcon.png" alt="crossIcon" className="alertIcon" />
                                            </div>
                                            <span className="alert-text">{fundManagementError}</span>
                                          </div>
                                        </small>
                                      </div>
                                    </Toast.Header>
                                  </Toast>
                                </div>
                              </div>

                            </Col>
                            :
                            <></>
                          }
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <Row>
                              <Col xl={8} lg={7} className="singleField">
                                <div className="field_block mb-3">

                                  <FloatingLabel
                                    controlId="selectedwallet"
                                    label="Selected Wallet"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the Available - Hot Wallet value"
                                      name="hotwallet"
                                      disabled
                                      className="text-darkblue"
                                      value={`Available - Hot Wallet`}
                                      autoComplete="off"
                                    />

                                    <span className="postion-absolute" style={{ top: "12px", right: "12px" }}>
                                      <CiLock fill="#6c757d" style={{ fontSize: "30px" }} />
                                    </span>
                                  </FloatingLabel>

                                </div>
                                <div className="field_block mb-3">

                                  <FloatingLabel
                                    controlId="selectedcoin"
                                    label="Selected Coin"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the USDT Tether value"
                                      name="USDT Tether"
                                      className="text-darkblue"
                                      disabled
                                      value={`USDT Tether`}
                                      autoComplete="off"
                                    />
                                    <span className="postion-absolute" style={{ top: "12px", right: "12px" }}>
                                      <CiLock fill="#6c757d" style={{ fontSize: "30px" }} />
                                    </span>

                                  </FloatingLabel>

                                </div>
                                <div className="field_block mb-3">

                                  <FloatingLabel
                                    controlId="selectnetwork"
                                    label="Selected Network"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the Selected Network value"
                                      name="selectnetwork"
                                      className="text-darkblue"
                                      disabled
                                      value={`ETH Ethereum (ERC-20)`}
                                      autoComplete="off"
                                    />
                                    <span className="postion-absolute" style={{ top: "12px", right: "12px" }}>
                                      <CiLock fill="#6c757d" style={{ fontSize: "30px" }} />
                                    </span>

                                  </FloatingLabel>

                                </div>
                                <div className="field_block mb-3">
                                  <FloatingLabel
                                    controlId="withdrawaladdress"
                                    label="Withdrawal Address"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the Withdrawal Address value"
                                      name="withdrawaladdress"
                                      className="text-darkblue"
                                      autoComplete="off"
                                      value={withdraw_WalletAddressfield}
                                      onChange={(e) => { setwithdraw_WalletAddressfield(e.target.value); }}
                                    />
                                  </FloatingLabel>

                                </div>
                                <div className="field_block mb-3">

                                  <FloatingLabel
                                    className="d-flex"
                                    controlId="Amount (USDT)"
                                    label="Amount (USDT)"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the Amount (USDT) value"
                                      name="amount"
                                      className="text-darkblue me-2"
                                      autoComplete="off"
                                      value={withdraw_amount}
                                      onChange={(e) => {
                                        let inputValue = e.target.value.replace(/[^\d]/g, ''); {/* Replace non-numeric characters */ }
                                        inputValue = inputValue.slice(0, 6); {/* Limit input length */ }
                                        setwithdraw_amount(inputValue);
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === '.' || e.key === ',') {
                                          e.preventDefault();
                                        }
                                      }}
                                    />



                                    <Button className="captchaCode w-25 select-btn fs-6 justify-content-center text-darkblue rounded-8" onClick={() => setwithdraw_amount(userWalletBalance.hotWalletBalance)}>MAX</Button>

                                  </FloatingLabel>

                                </div>

                                <div className="field_block mb-3">

                                  <FloatingLabel
                                    controlId="password"
                                    label="Password"
                                  >
                                    <Form.Control
                                      type={showPassword ? "text" : "password"}
                                      placeholder="Enter the Password value"
                                      name="password"
                                      className="text-darkblue"
                                      autoComplete="off"
                                      value={withdraw_Passwordfield}
                                      onChange={(e) => { setwithdraw_Passwordfield(e.target.value); }}

                                    />
                                    <span className="eyeIcon">
                                      {!showPassword ?
                                        <FaRegEye className="eye" fill="#6d4aff" onClick={(e) => showHidePassword_Withdraw(true)} style={{ cursor: "pointer" }} /> :
                                        <FaRegEyeSlash className="eyecut" fill="#6d4aff" onClick={(e) => showHidePassword_Withdraw(false)} style={{ cursor: "pointer" }} />}
                                    </span>

                                  </FloatingLabel>
                                </div>
                              </Col>
                              <Col xl={4} lg={5}>
                                <div className="detailBlock mb-3 withdrawlimit">

                                  <Row>

                                    <Col lg={12} className="">
                                      <div className="mb-1">
                                        <div className="small text-grey">Withdrawal Limit</div>
                                        <div className="small text-darkblue"> Min. 100 - Max. 500,000</div>
                                      </div>
                                      <div className="mb-1">
                                        <div className="small text-grey">Selected Coin</div>
                                        <div className="small text-darkblue">USDT Tether</div>
                                      </div>
                                      <div className="mb-1">
                                        <div className="small text-grey">Selected Network</div>
                                        <div className="small text-darkblue">ETH Ethereum (ERC-20)</div>
                                      </div>
                                      <div className="mb-1">
                                        <div className="small text-grey">Expected Arrival</div>
                                        <div className="small text-darkblue">~ 5 Minutes</div>
                                      </div>
                                      <div className="mb-1">
                                        <div className="small text-grey">Network Fee</div>
                                        <div className="small text-darkblue">15 USDT</div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                                <Card className="p-3 border-0 shadow mb-3 receivebox">
                                  <h5 className="mb-1">Receive Amount</h5>
                                  <div class="mb-0 text-break recieveamount">
                                    <span class="h2 me-1"> {withdraw_amount > 0 || withdraw_amount > 15 ? (parseFloat(withdraw_amount) - 15).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0.00'}
                                    </span>
                                    <small class="fw-lighter">USDT</small>
                                  </div>
                                </Card>

                              </Col>
                              <Col lg={12}>
                                {!handleBlurSection &&
                                  <>
                                    {withdraw_WalletAddressfield == '' || withdraw_Passwordfield == '' ?
                                      <>
                                        <Button variant="primary" disabled className="custombtn w-100 btn btn-primary">
                                          Confirm
                                        </Button>
                                      </>
                                      :
                                      <>
                                        {!confirmPasswordLoading ?
                                          <Button variant="primary" onClick={submitWithdrwRequest} className="custombtn w-100 btn btn-primary">
                                            Confirm
                                          </Button>
                                          :
                                          <Button disabled className="custombtn w-100 threeDotMulticolor">
                                            <MultiColorThreeDotLoader />
                                          </Button>
                                        }
                                      </>
                                    }
                                  </>
                                }

                              </Col>
                              <Col lg={12}>
                                {handleBlurSection == true ?
                                  <>
                                    <div className="confirmationBox">
                                      <div className="checkSuccess">
                                        <img src="assets/icons/check_1.png" width={`100px`} />
                                      </div>
                                    </div>
                                  </>
                                  : ''}


                              </Col>
                            </Row>
                          </Col>


                        </Row>

                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="transfer">
                      {handleConfirmMessage == true ?
                        <>
                          <Card className="border-0 greenBox mb-3 p-3">
                            <div className="">
                              <p className="mb-0">
                                <b>Transaction ID: #{trxId}</b> - Your transfer request has been received and will be processed in exactly 72 hours. You can monitor the progress of your transfer on the transactions page.
                              </p>

                            </div>
                          </Card>
                        </>
                        : ""}


                      <div className="filed_box position-relative">
                        <Row>

                          <Col lg={12}>
                            <Row>
                              {!fundManagementError == '' ?
                                <Col lg={12} md={12} className="pb-1">
                                  <div className="justify-content-between FixedRate">
                                    <div className="float-rate d-table px-0 mb-0 alertBox ToasterCloseHide">
                                      <Toast style={{ width: "100%" }} bg="danger" className="alertBox" >
                                        <Toast.Header className="text-left p-0">
                                          <div className="me-auto">
                                            <small class="">
                                              <div className="alert_block">
                                                <div class="crossIconbox me-2">
                                                  <img src="assets/icons/crossIcon.png" alt="crossIcon" className="alertIcon" />
                                                </div>
                                                <span className="alert-text">{fundManagementError}</span>
                                              </div>
                                            </small>
                                          </div>
                                        </Toast.Header>
                                      </Toast>
                                    </div>
                                  </div>

                                </Col>
                                :
                                <></>
                              }
                              <Col xl={8} lg={7} className="singleField">
                                <div className="field_block mb-3" onClick={onClickFromField}>
                                  <div className="d-flex">
                                    <FloatingLabel controlId="fromInput" label="From" className="w-100 me-2">
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter From"
                                        className="rounded-8 me-2"
                                        value={fromWalletId == 1 ? "Available - Hot Wallet" : "Invested - Cold Wallet"}
                                        onChange={(e) => setFromWalletId(e.target.value)}
                                        disabled
                                      />
                                    </FloatingLabel>
                                    <Button className="captchaCode w-25 select-btn fs-6 justify-content-center text-darkblue rounded-8">Toggle</Button>
                                  </div>

                                </div>

                                <div className="field_block mb-3">
                                  <FloatingLabel controlId="toInput" label="To">
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter To"
                                      className="rounded-8 form-control"
                                      style={{ border: 'none' }}
                                      value={toWalletId == 1 ? "Available - Hot Wallet" : "Invested - Cold Wallet"}
                                      onChange={(e) => setToWalletId(e.target.value)}
                                      disabled
                                    />
                                    <span className="postion-absolute" style={{ top: "12px", right: "12px" }}>
                                      <CiLock fill="#6c757d" style={{ fontSize: "30px" }} />
                                    </span>
                                  </FloatingLabel>
                                </div>

                                <div className="field_block mb-3">

                                  <FloatingLabel
                                    controlId="selectnetwork"
                                    label="Selected Network"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the Selected Network value"
                                      name="selectnetwork"
                                      className="text-darkblue"
                                      disabled
                                      value={`ETH Ethereum (ERC-20)`}
                                      autoComplete="off"
                                    />
                                    <span className="postion-absolute" style={{ top: "12px", right: "12px" }}>
                                      <CiLock fill="#6c757d" style={{ fontSize: "30px" }} />
                                    </span>

                                  </FloatingLabel>

                                </div>
                                <div className="field_block mb-3">

                                  <FloatingLabel
                                    controlId="selectedcoin"
                                    label="Selected Coin"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the USDT Tether value"
                                      name="USDT Tether"
                                      className="text-darkblue"
                                      disabled
                                      value={`USDT Tether`}
                                      autoComplete="off"
                                    />
                                    <span className="postion-absolute" style={{ top: "12px", right: "12px" }}>
                                      <CiLock fill="#6c757d" style={{ fontSize: "30px" }} />
                                    </span>

                                  </FloatingLabel>

                                </div>

                                <div className="field_block mb-3">

                                  <FloatingLabel
                                    className="d-flex"
                                    controlId="Amount (USDT)"
                                    label="Amount (USDT)"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the Amount (USDT) value"
                                      name="amount"
                                      className="text-darkblue me-2"
                                      autoComplete="off"
                                      value={transfer_Amountfield}
                                      onChange={(e) => {
                                        let inputValue = e.target.value.replace(/[^0-9]/g, ''); {/* Replace non-numeric characters */ }
                                        inputValue = inputValue.slice(0, 6);
                                        setTransfer_Amountfield(inputValue);
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === '.' || e.key === ',') {
                                          e.preventDefault();
                                        }
                                      }}
                                    />


                                    <Button className="captchaCode w-25 select-btn fs-6 justify-content-center text-darkblue rounded-8" onClick={handleMaxTransfer}>MAX</Button>


                                  </FloatingLabel>

                                </div>

                                <div className="field_block mb-3">

                                  <FloatingLabel
                                    controlId="password"
                                    label="Password"
                                  >
                                    <Form.Control
                                      type={showPassword_transfer ? "text" : "password"}
                                      placeholder="Enter the Password value"
                                      name="password"
                                      className="text-darkblue"
                                      autoComplete="off"
                                      onChange={(e) => { setTransfer_Passwordsfield(e.target.value); }}
                                      value={transfer_Passwordsfield}
                                    />
                                    <span className="eyeIcon">
                                      {!showPassword_transfer ?
                                        <FaRegEye className="eye" fill="#6d4aff" onClick={(e) => showHidePassword(true)} style={{ cursor: "pointer" }} /> :
                                        <FaRegEyeSlash className="eyecut" fill="#6d4aff" onClick={(e) => showHidePassword(false)} style={{ cursor: "pointer" }} />}
                                    </span>

                                  </FloatingLabel>

                                </div>


                              </Col>
                              <Col xl={4} lg={5}>

                                <div className="detailBlock  mb-3">
                                  <Row>

                                    <Col lg={12} className="">
                                      <div className="mb-1">
                                        <div className="small text-grey">Transfer Limit</div>
                                        <div className="small text-darkblue"> Min. 115 - Max. 500,000</div>
                                      </div>
                                      <div className="mb-1">
                                        <div className="small text-grey">Selected Coin</div>
                                        <div className="small text-darkblue">USDT Tether</div>
                                      </div>
                                      <div className="mb-1">
                                        <div className="small text-grey">Selected Network</div>
                                        <div className="small text-darkblue">ETH Ethereum (ERC-20)</div>
                                      </div>
                                      <div className="mb-1">
                                        <div className="small text-grey">Expected Unlock</div>
                                        <div className="small text-darkblue">72 Hours</div>
                                      </div>
                                      <div className="mb-1">
                                        <div className="small text-grey">Network Fee</div>
                                        <div className="small text-darkblue">15 USDT</div>
                                      </div>

                                    </Col>

                                  </Row>
                                </div>
                                <Card className="p-3 border-0 shadow mb-3 receivebox">
                                  <h5 className="mb-1">Receive Amount</h5>
                                  <div class="mb-0 text-break recieveamount">
                                    <span class="h2 me-1">  {transfer_Amountfield > 0 ? (parseFloat(transfer_Amountfield) - 15).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0.00'}
                                    </span>
                                    <small class="fw-lighter">USDT</small>
                                  </div>

                                </Card>

                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                {!handleBlurSection &&
                                  <>
                                    {transfer_Amountfield == '' || transfer_Passwordsfield == '' ?
                                      <>
                                        <Button variant="primary" disabled className="custombtn w-100 btn btn-primary">
                                          Confirm
                                        </Button>
                                      </>
                                      :
                                      <>
                                        {!confirmPasswordLoading ?
                                          <Button variant="primary" onClick={submitTransferRequest} className="custombtn w-100 btn btn-primary">
                                            Confirm
                                          </Button>
                                          :
                                          <Button disabled className="custombtn w-100 h-auto threeDotMulticolor">
                                            <MultiColorThreeDotLoader />
                                          </Button>
                                        }
                                      </>
                                    }
                                  </>
                                }

                              </Col>
                            </Row>
                          </Col>
                          <Col lg={12}>
                            {handleBlurSection == true ?
                              <>
                                <div className="confirmationBox">
                                  <div className="checkSuccess">
                                    <img src="assets/icons/check_1.png" width={`100px`} />
                                  </div>
                                </div>
                              </>
                              : ''}


                          </Col>

                        </Row>

                      </div>

                      {/* ------------------ */}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Col >
      </Row >
    </>
  );
}
export default Wallet;