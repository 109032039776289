import React, { useState, useEffect, useRef } from "react";
import {
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { Button, Col, Container, FloatingLabel, Form, Row, Tab, Toast } from "react-bootstrap";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { isAddress } from 'web3-validator';
import { userLoginAction } from "../../coreFiles/user.action.js";
import { MultiColorThreeDotLoader } from "../generic.jsx";
import Cookies from "js-cookie";
import config from "../../coreFiles/config.js";
import { FixedRate } from "../swap/ToastAlert.jsx";

const Login = ({ activeTab, setIsLoggedIn }) => {
  const [showPassword, setShowHidePassword] = useState(false);
  const [formFundManagement, setFormFundManagement] = useState({
    'erc20Address': "",
    'password': "",
    "captchaCode": "",
    "twofaCode":""
  });
  const [formFundManagementValidate, setFormFundManagementValidate] = useState(false);
  const [fundManagementError, setFundManagementError] = useState('');
  const [creatingAccountLoginLoading, setCreatingAccountLoginLoading] = useState(false);
  const captchaKeyRef = useRef(0);

  useEffect(() => {
    setFundManagementError("");
    setFormFundManagement({
      erc20Address: "",
      password: "",
      captchaCode: "",
      twofaCode: ""
    });
  }, [activeTab]);

  const handleCaptchaError = () => {
    if (formFundManagement.erc20Address == '' || formFundManagement.password == '' || fundManagementError !== '') {
      captchaKeyRef.current += 1;
    }
  };

  const inputHandlerFundManagement = async (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setShowHidePassword(false);
    }
    setFormFundManagement((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (formFundManagement.erc20Address !== "" && formFundManagement.password !== "" && formFundManagement.captchaCode !== "") {
      setFormFundManagementValidate(true);
    } else {
      setFormFundManagementValidate(false);
    }
  }, [formFundManagement]);

  const resetCaptcha = () => {
    validateCaptcha(123698);
    setFormFundManagement((prevData) => ({
      ...prevData,
      captchaCode: "",
    }));
  }

  const connectForFundManagement = async () => {
    try {
      if (!isAddress(formFundManagement.erc20Address)) {
        setFundManagementError('The provided ERC-20 address does not meet the required format.');
        resetCaptcha();
        return false;
      }
      if (validateCaptcha(formFundManagement.captchaCode) === false) {
        setFundManagementError('The captcha is incorrect, please try again.');
        resetCaptcha();
        return false;
      }
      setCreatingAccountLoginLoading(true);
      setFundManagementError('');
      const data = {
        walletAddress: formFundManagement.erc20Address,
        password: formFundManagement.password,
        twofaCode : formFundManagement.twofaCode
      };  
      let res = await userLoginAction(data);
      if (res.success === true) {
        setTimeout(() => {
          Cookies.set('purpleUser', JSON.stringify(res.data));
          window.location.href = `${config.baseUrl}account`;
        }, 1500);
      } else {
        resetCaptcha();
        setCreatingAccountLoginLoading(false);
        setFundManagementError(res.msg);
      }
    } catch (error) {
      setCreatingAccountLoginLoading(false);
      console.log("Something went wrong. Please try again.");
    }
  };

  return (
    <>
      <Tab.Pane eventKey="fundManagement" active={activeTab === "fundManagement"}>
        <div className="p-4">
          <Container>
            <Row className="justify-content-center">
              <Col lg={12} className="">
                <Row>
                  {fundManagementError !== "" &&
                    <Col lg={12} md={12} className="px-0 pb-1">
                      <div className="justify-content-between FixedRate">
                        <div className="float-rate d-table px-0 mb-0 alertBox ToasterCloseHide">
                          <Toast style={{ width: "100%" }} bg="danger" className="alertBox" >
                            <Toast.Header className="text-left p-0">
                              <div className="me-auto">
                                <small className="">
                                  <div className="alert_block">
                                    <div className="crossIconbox me-2">
                                      <img src="assets/icons/crossIcon.png" alt="crossIcon" className="alertIcon" />
                                    </div>
                                    <span className="alert-text">{fundManagementError}</span>
                                  </div>
                                </small>
                              </div>
                            </Toast.Header>
                          </Toast>
                          {/* <FixedRate /> */}
                        </div>
                      </div>
                    </Col>
                  }
                </Row>
                <div className="filed_box mb-4">
                  <Row>
                    <Col lg={12} className="singleField px-0">
                      <div className="field_block">
                        <FloatingLabel
                          controlId="ERC-20"
                          label="ERC-20"
                        >
                          <Form.Control
                            type="text"
                            placeholder="ERC-20"
                            name="erc20Address"
                            value={formFundManagement.erc20Address}
                            onChange={inputHandlerFundManagement}
                            autoComplete="off"
                          />
                        </FloatingLabel>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="filed_box mb-4">
                  <Row>
                    <Col lg={6} className="singleField px-0">
                      <div className="field_block">
                        <FloatingLabel
                          controlId="password"
                          label="Password"
                        >
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            name="password"
                            value={formFundManagement.password}
                            onChange={inputHandlerFundManagement}
                            autoComplete="off"
                          />
                          <span className="eyeIcon">
                            {!showPassword ?
                              <FaRegEye className="eye" fill="#6d4aff" onClick={(e) => setShowHidePassword(true)} style={{ cursor: "pointer" }} /> :
                              <FaRegEyeSlash className="eyecut" fill="#6d4aff" onClick={(e) => setShowHidePassword(false)} style={{ cursor: "pointer" }} />}
                          </span>
                        </FloatingLabel>
                      </div>
                    </Col>
                    <Col lg={5} className="singleField px-1 pr-0 ml-auto" style={{marginLeft: '12px', width: '47.7%'}}>
                      <div className="field_block">
                        <FloatingLabel
                          controlId="twofaCode"
                          label="2FA Code"
                        >
                          <Form.Control
                            type="text"
                            placeholder="2FA Code"
                            name="twofaCode"
                            value={formFundManagement.twofaCode}
                            onChange={inputHandlerFundManagement}
                            autoComplete="off"
                          />
                        </FloatingLabel>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="filed_box">
                  <Row>
                    <Col lg={9} md={9} xs={12} className="singleField px-1 pl-0 mb-2">
                      <div className="field_block">
                        <FloatingLabel
                          controlId="captchaCode"
                          label="Enter the Captcha value"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter the Captcha value"
                            name="captchaCode"
                            value={formFundManagement.captchaCode}
                            onChange={inputHandlerFundManagement}
                            autoComplete="off"
                          />
                        </FloatingLabel>
                      </div>
                    </Col>
                    <Col lg={3} md={3} xs={7} className="singleBtn pr-0 ml-auto mb-4">
                      <Button variant="default"
                        className="captchaCode border bg-white fs-6 w-100 lh-0"
                      >
                        {activeTab === "fundManagement" &&
                          <LoadCanvasTemplateNoReload
                            key={captchaKeyRef.current}
                            onError={handleCaptchaError}
                          />}
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="filed_box">
                  <Row>
                    <Col lg={12} className="px-0">
                      {formFundManagementValidate ?
                        <>
                          {!creatingAccountLoginLoading ?
                            <Button onClick={() => connectForFundManagement()} className="custombtn h-auto w-100 btn btn-primary">
                              Connect
                            </Button>
                            :
                            <Button disabled className="custombtn w-100 h-auto threeDotMulticolor">
                              <MultiColorThreeDotLoader />
                              {/* Connect */}
                            </Button>
                          }
                        </>
                        :
                        <Button disabled className="custombtn h-auto w-100 btn btn-primary">
                          Connect
                        </Button>
                      }
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Tab.Pane>
    </>
  );
};

export default Login;
