import React from 'react';
import Header from '../directives/Header';
import Footer from '../directives/Footer';
import { Container, Row, Col } from 'react-bootstrap';

const NotFoundPage = () => {

  return (
    <>
      <Header />

      <Container className='pt-3 pb-3'>
        <Row>
          <Col md={12}>
            <center>
              <img src='./images/404.webp' width={'350px'} height={'300px'}></img>
            </center>
          </Col>
        </Row>
      </Container>

      <Footer />


    </>
  )
}

export default NotFoundPage;
