const config = {
    baseUrl: '/',
    // baseUrl: '/purpleswap/',
    serverPath: "https://api.changenow.io/v2",
    APIkey: "ff2e750f-1bfb-4d86-9292-9e647cc7f917",

    // apiUrl: "http://localhost:8181/api",  // Local
    // apiUrl: "https://espsofttech.org:8181/api", // Esp Live
    apiUrl: "https://api1.servomain.com/api", // Client Live

    // blockchainUrl : 'https://sepolia.etherscan.io/tx/', // testnet
    blockchainUrl : 'https://etherscan.io/tx/', // mainnet

    privacyPolicyUrl:'https://purpleswap.io/legal/privacypolicy',
    termConditionUrl:'https://purpleswap.io/legal/termscondition'
};

// Package.json - https://purpleswap.io/

export default config;