import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import config from "./coreFiles/config";
import Home from "./component/home";
import Exchange from "./component/exchange";
import Addexchange from "./component/addexchange";
import Deposit from "./component/deposit";
import Exchangedetails from "./component/exchangedetails";
import Pendingdeposit from "./component/pendingdeposit";
import Confirming from "./component/confirming";
import Exchanging from "./component/exchanging";
import Sending from "./component/sending";
import Successful from "./component/successful";
import Mobileslider from "./component/mobileslider";
import Smoothscroll from "./component/smoothscroll";
import Iconslider from "./component/iconslider";
import Swapmanagement from "./component/swapmanagement";
import Support from "./component/support";
import Purpletoken from "./component/purpletoken";
import Earn from "./component/earn";
import Fincenter from "./component/earnAndDashboard/fincenter";
import NotFoundPage from "./component/NotFoundPage";
import Legal from "./component/Legal";
import VerifyEmail from "./component/verifyEmail";
import ProtectedRoute from "./component/protectedRoute";
import Account from "./component/earnAndDashboard/account";

// import WebSimpleStep from './component/webSimpleStep';
// import MobileSimpleStep from './component/mobileslider';

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Home />} />
          <Route
            path={`${config.baseUrl}:fromCoin/:toCoin`}
            element={<Home />}
          />
          <Route path={`${config.baseUrl}exchange`} element={<Exchange />} />

          {/* <Route path={`${config.baseUrl}addexchange/:from/:to/:upperamount/:loweramount/:rate`} element={<Addexchange />} /> */}
          <Route
            path={`${config.baseUrl}addexchange`}
            element={<Addexchange />}
          />
          {/* <Route path={`${config.baseUrl}deposit`} element={<Deposit />} /> */}
          <Route
            path={`${config.baseUrl}exchangedetails`}
            element={<Exchangedetails />}
          />
          <Route
            path={`${config.baseUrl}pendingdeposit`}
            element={<Pendingdeposit />}
          />
          <Route
            path={`${config.baseUrl}confirming`}
            element={<Confirming />}
          />
          <Route
            path={`${config.baseUrl}exchanging`}
            element={<Exchanging />}
          />
          <Route path={`${config.baseUrl}sending`} element={<Sending />} />
          {/* <Route path={`${config.baseUrl}successful`} element={<Successful />} /> */}
          <Route
            path={`${config.baseUrl}smoothscroll`}
            element={<Smoothscroll />}
          />
          <Route
            path={`${config.baseUrl}iconslider`}
            element={<Iconslider />}
          />

          <Route
            path={`${config.baseUrl}exchange/txs/:swapId`}
            element={<Deposit />}
          />
          <Route
            path={`${config.baseUrl}exchange/404`}
            element={<NotFoundPage />}
          />
          <Route
            path={`${config.baseUrl}swapmanagement`}
            element={<Swapmanagement />}
          />
          <Route
            path={`${config.baseUrl}swapmanagement/:tab`}
            element={<Swapmanagement />}
          />
          <Route path={`${config.baseUrl}support`} element={<Support />} />
          <Route
            path={`${config.baseUrl}support/:parameter`}
            element={<Support />}
          />
          <Route
            path={`${config.baseUrl}pur`}
            element={<Purpletoken />}
          />
          <Route
            path={`${config.baseUrl}pur/:tab`}
            element={<Purpletoken />}
          />
          <Route
            path={`${config.baseUrl}earning`}
            element={<Earn showDashboard={false} />}
          />
          {/* <Route path={`${config.baseUrl}earnDashboard`} element={<Earn showDashboard={true} />} /> */}
          <Route
            path={`${config.baseUrl}earning/:tab`}
            element={<Earn showDashboard={false} />}
          />
          <Route
            path={`${config.baseUrl}legal`}
            element={<Legal showDashboard={false} />}
          />
          <Route
            path={`${config.baseUrl}account`}
            element={<Account showDashboard={false}/>}
          />
          <Route
            path={`${config.baseUrl}account/:page`}
            element={<Account showDashboard={false}/>}
          />

          <Route path={`${config.baseUrl}fincenter`} 
          element={ <ProtectedRoute><Fincenter/></ProtectedRoute>}/> 
          <Route
            path={`${config.baseUrl}legal/:tab`}
            element={<Legal showDashboard={false} />}
          />
          <Route path={`${config.baseUrl}verifyemail/:id/:email`}
            element={<VerifyEmail />}
          />
          {/* <Route path={`${config.baseUrl}mobileslider`} element={<Mobileslider />} /> */}
          {/* <Route path={`${config.baseUrl}webSimpleStep`} element={<WebSimpleStep />} />
          <Route path={`${config.baseUrl}mobileSimpleStep`} element={<MobileSimpleStep />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
