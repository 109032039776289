export const cardData = [
  {
    image: "images/shield.png",
    title: "Anonymity",
    span: "",
    description: "Embrace complete anonymity with Purple Swap – no personal information, not even an email, is required. Our system automatically generates a unique ERC-20 address for each client, secured by a strong and encrypted password, ensuring total privacy and security for your account.",
    link: "Learn More",
  },
  {
    image: "images/padlock.png",
    title: "Secure",
    span: "Funds",
    description: "At Purple Swap, your investment is our top priority. We securely store your funds in cold wallets, ensuring they're used solely within our swap ecosystem for enhanced safety and dependability, all while maintaining seamless access for you.",
    link: "Learn More",
  },
  {
    image: "images/coin.png",
    title: "Profitability",
    span: "",
    description: "Experience unparalleled profitability with Purple Swap, offering up to 5% monthly and 60% annually, among the highest returns in the market. We provide the flexibility for you to withdraw both your earned interest and capital at any time, ensuring full control over your financial assets.",
    link: "Learn More",
  },
  {
    image: "images/no_kyc.png",
    title: "No",
    span: "KYC",
    description: "Purple Swap eliminates the need for KYC, with all transactions, including deposits and withdrawals, conducted through your own ERC-20 address. This ensures a secure and transparent process, free from the risk of illicit transfers or third-party interference.",
    link: "Learn More",
  },
  {
    image: "images/fast.png",
    title: "Instant",
    span:"Access",
    description: "With Purple Swap, your funds are readily available, ensuring autonomy through our Financial Center that's accessible 24/7. Enjoy the freedom to deposit or withdraw at any time, giving you full control over your assets and making your financial planning adaptable to your immediate needs.",
    link: "Learn More",
  },
  {
    image: "images/transparency.png",
    title: "Transparency",
    span:"",
    description: "At Purple Swap, your investment information is always at your fingertips. Just log in with your wallet address and password to see up-to-date details on your assets' performance and accrued interest, ensuring you're always in the loop.",
    link: "Learn More",
  },
];

export const tabsData = [
  {
    id: 'tab1',
    img: 'https://i.postimg.cc/Vvss5Mqc/1.png"',
    span: 'h_span1',
    backgroundColor: '#383848',
    color: 'white',
    heading: 'Choose a currency pair',
    description:
      'Select your preferred exchange pair from over 50,000 options available on Purple Swap. With such a vast selection, you\'re sure to find the perfect match for your needs.',
  },
  {
    id: 'tab2',
    img: 'https://i.postimg.cc/jjdrHKWz/2.png',
    span: 'h_span2',
    backgroundColor: '#383848',
    color: 'white',
    heading: 'Enter the recipient’s address',
    description:
      'Review all the details of your exchange, including an estimated transaction duration. Then, carefully transfer your funds to our secure, one-time-use deposit address.',
  },
  {
    id: 'tab3',
    img: 'https://i.postimg.cc/zDMXqLy4/3.png',
    span: 'h_span3',
    backgroundColor: '#383848',
    color: 'white',
    heading: 'Send and receive coins',
    description:
      'Experience the Purple Swap efficiency. We\'ll secure the most favorable exchange rate, efficiently swap your assets, and promptly return them to your account within minutes.',
  },
  {
    id: 'tab4',
    img: 'https://i.postimg.cc/CLhTTSN9/4.png',
    span: 'h_span4',
    backgroundColor: '#383848',
    color: 'white',
    heading: 'That\'s all!',
    description:
      'Once you see the exchange status marked as \'Completed,\' your swap is successfully finalized. Access all your transaction details on the completion page for full transparency.',
  },
];
