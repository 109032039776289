import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import config from "../coreFiles/config";

function ProtectedRoute({ children }) {

    let loginUserData = Cookies.get('purpleUser') ? JSON.parse(Cookies.get('purpleUser')) : '';
    if (!loginUserData) return <Navigate to={`${config.baseUrl}earning`} replace />;
    return children;
}
export default ProtectedRoute;