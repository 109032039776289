import { cardData } from "../data/Data";
import { GoChevronRight } from "react-icons/go";
// import "./card.css";
import "../component/componentCss/card.css";
import "../component/componentCss/brand.css";
import { getInvestStatisticsAction } from "../coreFiles/user.action";
import { useEffect, useState } from "react";


const Earnbrand = () => {

  const [statisticsData,setStatisticsData] = useState([])
  useEffect(() => {
    getInvestStatistics();
  }, []);

  const getInvestStatistics = async () => {
    try {
      let res = await getInvestStatisticsAction({ });
      if (res.success) {
        setStatisticsData(res.data);
      }
    } catch (err) {}
  };


  return (
    <>
      <div className="brandingbox">
        <div className="grid-container">
          <div className="inner">
          <div className="grid-item">
              <div className="content-container">
                <div className="item-content">
                  <span className="number" id="five">
                  {statisticsData.satisfaction}
                  </span>
                  <span className="symbol" id="min">
                    %
                  </span>
                </div>
                <div className="text" id="time">
                  Satisfaction
                </div>
              </div>
            </div>
            
            <div className="grid-item">
              <div className="content-container">
                <div className="item-content">
                  <span className="number">{statisticsData.annualRoi}</span>
                  <span className="symbol">%</span>
                </div>
                <div className="text" id="client">
                APY Up to
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="content-container">
                <div className="item-content" id="eighth">
                  <span className="number">{statisticsData.intrestPaid}</span>
                  <span className="symbol" id="plus">
                    K
                  </span>
                </div>
                <div className="text" id="currency">
                  Interest Paid
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="content-container">
                <div className="item-content">
                  <span className="number">{statisticsData.managedFund}</span>
                  <span className="symbol">M</span>
                </div>
                <div className="text" id="rate">
                  USDT invested
                </div>
              </div>
            </div>
        
         
          </div>
        </div>
      </div>
    </>
  );
};

export default Earnbrand;
