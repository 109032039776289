import { cardData } from "../data/EarnData";
import { GoChevronRight } from "react-icons/go";
// import "./card.css";
import "../component/componentCss/earncard.css";
import config from '../coreFiles/config';
import { useParams } from 'react-router-dom';


const Earncard = () => {
    let { f } = useParams();
    return (
        <>
            <div className=" sixcard_sec mt-5">
                <div className="container">
                    <div className="innercon">
                        {cardData.map((card, index) => {
                            return (
                                <a
                                    // href={`${config.baseUrl}support/${index == 0 ? 1 :
                                    //     index == 1 ? 0 : index}`}
                                        href="javascript:void(0)"
                                    
                                    className={`card-link ${index === 1 ? '' : ''}`}
                                    // className={`card-link card-link${index + 1}`}
                                    key={index}
                                    style={{cursor: "auto"}}
                                >
                                    <div className="title-parent">
                                        <div className="logo">
                                            <img src={card.image} alt="" />
                                        </div>
                                        <div className="title">{card.title} <span>{card.span}</span></div>
                                    </div>
                                    <p className="desc">{card.description}</p>
                                    {/* <span href="" className="link-flex">
                                        {card.link}
                                        <GoChevronRight className="chevron_right" />
                                    </span> */}
                                </a>
                            );
                        })}
                    </div>
                </div>
            </div>

        </>
    );
};

export default Earncard;
