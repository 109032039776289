import React, { useState, useEffect, useRef } from "react";
import {
    Container,
    Row,
    Col,
    Nav,
    Tab,
    Button,
    Accordion,
    Card
} from "react-bootstrap";

import { verifyUserEmailAction } from "../coreFiles/user.action";
import { useParams } from "react-router-dom";
import config from "../coreFiles/config";

const VerifyEmail = () => {
    const { id, email } = useParams();
    const [msg, setMsg] = useState('Confirming...')

    useEffect(() => {
        verifyEmail()
            ;
    }, [])

    const verifyEmail = async () => {
        try {
            let data = {
                id: id,
                email: email
            }
            console.log(data);
            let res = await verifyUserEmailAction(data);
            if (res.success) {
                setMsg('Email confirmed, thank you!')
            }
            else {
                setMsg('Invalid request!')
                // window.location.href = config.baseUrl;
            }
        } catch (error) {
            console.error("Error :", error);
        }
    };

    return (

        <>
            {/* <section className="crytpocurrency pt-4 pb-4 "> */}

            <Row className="justify-content-center">
                <Col className=" col-lg-8 col-md-12 mt-5">
                    <div className="bnr_inner pb-0 position-relative">

                        <Tab.Container id="left-tabs-example" defaultActiveKey="Dashboard">
                            <Row>
                                <Col sm={12}>

                                    <center>
                                        {/* <img style={{ height: '75px', width: '455px' }}
                                            src="assets/images/logo/logo.png"
                                            alt="logo"
                                            className="logo"
                                        />
    */}

                                        <h2 className="mt-5">
                                            {msg}
                                        </h2>
                                        <a href={`${config.baseUrl}`} className="mt-5 h-auto btn " style={{ backgroundColor: '#e9e3ff' }}>
                                            Home
                                        </a>
                                    </center>
                                </Col>

                            </Row>
                        </Tab.Container>

                    </div>
                </Col>
            </Row>


            {/* </section> */}

        </>

    );
}
export default VerifyEmail;