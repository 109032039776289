import { useEffect, useState } from 'react';
import "../component/componentCss/loader.css";

const Loader = ({setIsMainLoader}) => {
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [progressMessage, setProgressMessage] = useState('Loading.');

  const loadingPoints = [
    { time: 1, percentage: 33, color: '#987bff', message: 'Loading..' },
    { time: 2, percentage: 66, color: '#987bff', message: 'Loading...' },
    { time: 3, percentage: 100, color: '#4e7aee', message: 'Loading....' },
    // { time: 0.25, percentage: 99, color: '#4e7aee', message: 'Creating trade order…' },
    { time: 0.5, percentage: 100, color: '#26b6d8', message: 'Completed!' },
  ];

  useEffect(() => {
    let totalTime = 0;

    loadingPoints.forEach((point) => {
      totalTime += point.time * 1000;

      setTimeout(() => {
        setLoadingPercentage(point.percentage);
        setProgressMessage(point.message);
      }, totalTime);
    });
    setTimeout(() => {
      setIsMainLoader(false)
    },totalTime + 1000)
  }, []);
  
  return (
    <>
      <div className="line">
        <div className="circle"></div>
      </div>

      <div className="loading-bar-container">
        <div
          className="loading-bar"
          style={{
            width: `${loadingPercentage}%`,
            // backgroundColor: loadingPercentage === 100 ? '#26b6d8' : '#ccc',
            backgroundColor: loadingPercentage === 100  ? '#ccc' : '',
          }}
        ></div>
      </div>

      <div className="progress-message">{progressMessage}</div>
    </>
  );
};

export default Loader;