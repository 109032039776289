import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Table
} from "react-bootstrap";
import "../componentCss/earn.css"
import "../componentCss/financial.css"
import "../componentCss/earnDashboard.css"
import { FaLink } from "react-icons/fa6";
import { getTransactionHistoryAction, updateTransactionStatus } from "../../coreFiles/user.action";
import moment from "moment";
import copy from "copy-to-clipboard";
import Cookies from "js-cookie";
import { MultiColorThreeDotLoader, QrCodeLoader } from "../generic";
import config from "../../coreFiles/config";

export const TransactionApprovalTimer = (props) => {
  var dateEntered = new Date(props.item.approvedDate);
  var now = new Date(props.item.currentDate);
  var difference = dateEntered.getTime() - now.getTime();

  if (difference < 1) {
    if (props.item.approvedByAdmin == 2) {
      return (<span> Error </span>)
    }
    else if (props.item.status == 2) {
      return (<span> Completed </span>)
    }
    else {
      return (<span> Completed </span>)
    }
  } else {

    var seconds = Math.floor(difference / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours %= 24;
    minutes %= 60;
    // seconds %= 60;
    if (days == 0 && hours == 0 && minutes <= 0) {
      if (props.item.approvedByAdmin == 2) {
        return (<span> Error </span>)
      }
      else if (props.item.status == 2) {
        return (<span> Completed </span>)
      }
      else {
        return (<span> Processing </span>)
      }
    }
    else {
      if(days == 0 && hours == 0 && minutes > 0 && props.item.approvedByAdmin == 1 && props.item.status == 1 && props.item.walletType == "Hot Wallet"){
        return (<span> Completed </span>)
      }
      else{
        return (<span>{days + 'D ' + hours + 'h' + minutes + 'm'}</span>)
      }
    }
  }
}
const Transactions = ({ }) => {

  const [transactionHistory, settransactionHistory] = useState([])
  let loginUserData = Cookies.get('purpleUser') ? JSON.parse(Cookies.get('purpleUser')) : '';
  const [trxLoading, setTrxLoading] = useState(true);
  const [timerCall, setTimerCall] = useState(0);

  useEffect(() => {
    getTransactionHistory();
    setTimeout(() => {
      setTrxLoading(false)
    }, 2000);
    setInterval(() => {
      getTransactionHistory();
    }, 20000);
    setInterval(() => {
      setTimerCall(new Date());
    }, 1000);

  }, []);

  const getTransactionHistory = async () => {
    try {
      let data = {
        walletAddress: loginUserData.walletAddress,
        limit: trxLimit.current
      }
      let res = await getTransactionHistoryAction(data);
      if (res.success) {
        settransactionHistory(res.data);
      }
    } catch (error) {
      console.log(error)
    }
  };
  const copyToClipboard = (order) => {
    copy(order);
    navigator.clipboard.writeText(order)
  };
  const handleRefreshButtonAciton = async () => {
    getTransactionHistory()
  }
  const trxLimit = useRef(0);
  const updateTrxLimit = (action) => {
    if (action == "prev") {
      trxLimit.current = trxLimit.current - 7
    }
    else {
      trxLimit.current = trxLimit.current + 7
    }
    getTransactionHistory();
  }

  const onTimerClosed = async (item) => {
    try {
      let data = {
        trxId: item.customTrxId,
      }
      let res = await updateTransactionStatus(data);
      if (res.success) {
        getTransactionHistory();
      }
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
      <div className="earn" >
        {/* <Row>
        <Col lg={12} className="">
        <Card className="border-0 mb-3" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
            <div className="alert_block infoBlock">
              <div class="infoIconbox me-2">
                <img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" />
              </div>
              <div className="alert-text">
                <p className="mb-0">
                Why is there a countdown next to my transfer? <a href="javascript:void(0);">Click here</a> for more info.
                </p>
              </div>
            </div>
          </Card>
         
        </Col>
      </Row> */}
        <Row>
          <Col lg={12} className="mb-4">
            <Card className="border-0 p-0 rounded-8 overflow-hidden shadow ">

              <div className="table-responsive table_striped_style transa_table">

                <Table striped className="mb-0">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>ID</th>
                      <th>Amount</th>
                      <th > <div >Type</div></th>
                      <th>Destination</th>
                      <th className="text-right">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                  <td colSpan={6}>
                    <center>No Data Found</center>
                  </td>
                </tr> */}
                    {trxLoading ?
                      <>

                        <tr rowSpan={3}>

                          <td colSpan={6} >
                            <br />
                            <br />
                            <br />
                            <center><QrCodeLoader /></center>
                            <br />
                            <br />
                            <br />
                          </td>
                        </tr>

                      </>
                      :
                      <>
                        {transactionHistory?.length > 0 ? (
                          transactionHistory?.map((item, index) => (
                            <tr key={index}>
                              <td>{moment(item.datetime).format("YYYY-MM-DD HH:mm")}</td>
                              {item.customTrxId == null ? (
                                <td><span>&nbsp;</span></td>
                              ) : (
                                <td>
                                  <span>
                                    {/* &nbsp;{item?.customTrxId} */}
                                    <FaLink fill="#9278ff" style={{ cursor: 'pointer' }} onClick={() => { copyToClipboard(item?.customTrxId); }} />
                                  </span>
                                </td>
                              )}
                              <td>{item.amount < 0 ? item.amount * -1 : item.amount} USDT</td>
                              <td><div>{item.transactionType}</div></td>

                              <td>
                                {(item.transactionTypeId == 1 && item.walletTypeId == 1) &&
                                  <div>Available Wallet</div>
                                }
                                {(item.transactionTypeId == 2 && item.walletTypeId == 1) && //&& item.hash != null
                                  <span>&nbsp;{item?.toAddress?.substring(0, 6) + '...' + item?.toAddress?.substring(item?.toAddress?.length - 5)}
                                    <a href="javascript:void(0)">
                                      <FaLink fill="#9278ff" onClick={() => { copyToClipboard(item?.toAddress); }} />
                                    </a>
                                  </span>
                                  // <span>&nbsp;{item?.hash?.substring(0, 6) + '...' + item?.hash?.substring(item?.hash?.length - 5)}
                                  //   <a href={`${config.blockchainUrl}${item.hash}`} target="__blank">
                                  //     <FaLink fill="#9278ff" onClick={() => { copyToClipboard(item?.hash); }} />
                                  //   </a>
                                  // </span>
                                }
                                {(item.transactionTypeId == 3 && item.walletTypeId == 1 && item.fromWalletTypeId == 2) &&
                                  <div>Invested Wallet</div>
                                }
                                {(item.transactionTypeId == 3 && item.walletTypeId == 2 && item.fromWalletTypeId == 1) &&
                                  <div>Available Wallet</div>
                                }
                                {(item.transactionTypeId == 6 && item.walletTypeId == 2) &&
                                  <div>Invested Wallet</div>
                                }
                              </td>

                              {item.transactionType == "Transfer" && item.status == 1 ?
                                <td className="text-right">
                                  <TransactionApprovalTimer timerCall={timerCall} item={item} onTimerClosed={() => onTimerClosed(item)} /></td>
                                :
                                <td className="text-right">
                                  {item.status == 0 && 'Pending'}
                                  {item.status == 1 && 'Processing'}
                                  {item.transactionTypeId == 3 &&
                                    <>
                                      {item.approvedByAdmin == 1 && 'Completed'}
                                      {item.approvedByAdmin == 2 && 'Error'}
                                    </>
                                  }
                                  {item.transactionTypeId != 3 && item.status == 2 && 'Completed'}
                                </td>
                              }
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" style={{ textAlign: 'center' }}>No Transaction found</td>
                          </tr>
                        )}
                      </>
                    }

                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
          {!trxLoading &&

            <Col lg={12} className="mb-4">
              <div className="d-flex justify-content-between paginationBtn">
                <div>
                  {trxLimit.current > 1 ?
                    <Button className="select-btn btn btn-primary rounded-2 text-black p-3 justify-content-center px-3" onClick={() => updateTrxLimit('prev')}>Previous</Button>
                    :
                    <Button className="select-btn btn btn-primary rounded-2 text-black p-3 justify-content-center px-3" disabled>Previous </Button>
                  }
                </div>
                {/* <div>
                  <Button className="select-btn btn btn-primary rounded-2 text-black p-3 justify-content-center  px-3" onClick={handleRefreshButtonAciton}>Refresh</Button>
                </div> */}
                {transactionHistory.length > 6
                  ?
                  <>
                    <div>
                      <Button className="select-btn btn btn-primary rounded-2 text-black p-3 justify-content-center  px-3" onClick={() => updateTrxLimit('next')} >Next</Button>
                    </div>
                  </>
                  :
                  <div>
                    <Button className="select-btn btn btn-primary rounded-2 text-black p-3 justify-content-center  px-3" disabled >Next </Button>
                  </div>}
                {/* <div>
              <Button className="select-btn btn btn-primary rounded-2 text-black p-3 justify-content-center  px-3" disabled >Next</Button>
            </div> */}
              </div>

            </Col>
          }

        </Row>
      </div>
    </>
  );
}
export default Transactions;