import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Header from "../directives/Header";
import Footer from "../directives/Footer";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Form,
  InputGroup,
  Modal,
  Table,
  Accordion,
  Tab,
  Card,
  Toast,
} from "react-bootstrap";
import config from "../coreFiles/config";
import { useNavigate } from "react-router-dom";
import "./componentCss/swapmanagement.css";
import toast, { Toaster } from "react-hot-toast";
import {
  deleteThisSwapTransactionAction,
  getSwapTransactnioStatusAction,
} from "../coreFiles/user.action";
import { ErrorMessage, SuccessMessage } from "./swap/ToastAlert";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

import { ThreeDotLoader } from "./swap/Icons";

const Swapstatus = () => {
  const [swapIdForStatus, setSwapIdForStatus] = useState("");
  const [swapIdForDelete, setSwapIdForDelete] = useState("");
  const [captchaCode, setCaptchaCode] = useState("");
  const [swapInfo, setSwapInfo] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [delErrorMsg, setDelErrorMsg] = useState("");
  const [delSuccessMsg, setDelSuccessMsg] = useState("");
  const [checkingStatus, setCheckingStatus] = useState(false);

  const [errMsgType, setErrMsgType] = useState("Warning");

  let { tab } = useParams();
  tab = tab === undefined ? "status" : tab;
  const navigate = useNavigate();
  const redirectTo = (tab) => {
    clearState();
    navigate(`${config.baseUrl}swapmanagement/${tab}`);
  };

  useEffect(() => {
    loadCaptchaEnginge(6, "#fff", "#000", "numbers");
  }, []);

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    if (name == "swapIdForStatus") {
      setSwapIdForStatus(value);
      if (value == "") {
        setErrorMsg("");
        setDelErrorMsg("");
      }
    } else if (name == "swapIdForDelete") {
      setSwapIdForDelete(value);
      if (value == "") {
        setDelErrorMsg("");
        setErrorMsg("");
      }
    } else if (name == "captchaCode") {
      setCaptchaCode(value);
      if (value == "") {
        setDelErrorMsg("");
        setErrorMsg("");
      }
    }
  };
  const checkTransactionStatus = async () => {
    try {
      if (swapIdForStatus == "") {
        setErrMsgType("Error");
        setErrorMsg("Swap id required!");
        return;
      }
      setCheckingStatus(true);
      let res = await getSwapTransactnioStatusAction({
        modifiedSwapId: swapIdForStatus,
      });
      if (res.success) {
        // setCheckingStatus(false);
        if (res.data.status == 0) {
          setErrorMsg("");
          window.location.href =
            config.baseUrl + "exchange/txs/" + swapIdForStatus;
        } else {
          setSwapIdForStatus("");
          setCheckingStatus(false);
          setErrorMsg(res.msg);
        }
      } else {
        setSwapIdForStatus("");
        setCheckingStatus(false);
        setErrorMsg(res.msg);
      }
    } catch (err) {
      toast.error(err.msg);
    }
  };
  const deleteThisSwapTransaction = async () => {
    try {
      if (captchaCode == "") {
        setDelSuccessMsg("");
        setErrMsgType("Error");
        setDelErrorMsg("Captcha code required!");
        return;
      } else if (validateCaptcha(captchaCode) === false) {
        setCaptchaCode("");
        setDelSuccessMsg("");
        setErrMsgType("Error");
        setDelErrorMsg("The captcha is incorrect, please try again.");
        return;
      } else if (swapIdForDelete == "") {
        setDelSuccessMsg("");
        setErrMsgType("Error");
        setDelErrorMsg("Swap id required!");
        return;
      }
      setCheckingStatus(true);
      let res = await deleteThisSwapTransactionAction({
        modifiedSwapId: swapIdForDelete,
      });
      if (res.success) {
        setDelErrorMsg("");
        setDelSuccessMsg(res.msg);
        setCaptchaCode("");
        setSwapIdForDelete("");
        loadCaptchaEnginge(6, "#fff", "#000", "numbers");
      } else {
        setDelSuccessMsg("");
        setCaptchaCode("");
        setSwapIdForDelete("");
        setErrMsgType("Error");
        setDelErrorMsg(res.msg);
        loadCaptchaEnginge(6, "#fff", "#000", "numbers");
      }
      setCheckingStatus(false);
      // setTimeout(() => {
      //   setDelErrorMsg('')
      // }, 2500);
    } catch (err) {
      toast.error(err.msg);
    }
  };

  const clearState = async () => {
    setDelSuccessMsg("");
    setCaptchaCode("");
    setSwapIdForDelete("");
    setErrMsgType("Warning");
    setDelErrorMsg("");
    setSwapIdForStatus("");
    setErrorMsg("");
  };

  return (
    <>
      <Header />
      <Toaster />
      <section className="swapmanagement  pt-4 pb-4">
        <Container>
          <Row>
            <Col xl={8} lg={12} md={12} className="mx-auto pb-lg-5 pb-2">
              <div className="swap_banner">
                <h2 className="text-white fw-lighter">Swap Management</h2>
                <h6 className="text-primary-gradient fw-lighter">
                  Manage your current or past swap statuses with these unique
                  Purple Swap functionalities.
                </h6>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8} md={12} className="mx-auto">
              <div className="tab_style">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey={tab == undefined ? "swapstatus" : ""}
                >
                  <Nav variant="pills" className="mb-5 justify-content-between">
                    <Nav.Item className=" text-center">
                      <Nav.Link
                        className={"right_tab"}
                        active={tab == "status"}
                        eventKey="swapstatus"
                        onClick={() => redirectTo("status")}
                      >
                        <div className="position-relative">Swap Status</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className=" text-center">
                      <Nav.Link
                        className={"right_tab"}
                        eventKey="shareswap"
                        active={tab == "share"}
                        disabled
                        onClick={() => redirectTo("share")}
                      >
                        <div className="position-relative">
                          Share Swap <sup className="soon">SOON</sup>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className=" text-center">
                      <Nav.Link
                        className={"right_tab"}
                        eventKey="deleterecords"
                        active={tab == "delete"}
                        onClick={() => redirectTo("delete")}
                      >
                        <div className="position-relative">Delete Records</div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Row>
                    <Col sm={12} className="text-center">
                      <Tab.Content className="">
                        <Tab.Pane
                          active={tab == "status"}
                          eventKey="swapstatus"
                        >
                          <div className="swap_spc">
                            <p className="mb-0">
                              Purple Swap keeps a record of all swaps, allowing
                              for straightforward tracking and verification,
                              giving you peace of mind in confirming your
                              transactions.
                            </p>
                          </div>
                          <Col
                            sm={12}
                            className="text-center mt-5 statusSection"
                          >
                            <Card className="p-4 border-0 ">
                              {errorMsg != "" && (
                                <>
                                  <div className="float-rate mb-4 alertBox 1">
                                    <ErrorMessage
                                      error={errorMsg}
                                      type={"Error"}
                                    />
                                  </div>
                                </>
                              )}
                              {successMsg != "" && (
                                <>
                                  <div className="float-rate mb-4 alertBox">
                                    <SuccessMessage error={successMsg} />
                                  </div>
                                </>
                              )}

                              <Row>
                                <Col lg={9} md={9} sm={9} xs={7}>
                                  <Form.Control
                                    type="text"
                                    placeholder="Swap ID"
                                    name="swapIdForStatus"
                                    onChange={inputHandler}
                                    value={swapIdForStatus}
                                  />
                                </Col>
                                <Col lg={3} md={3} sm={3} xs={5}>
                                  {checkingStatus ? (
                                    <button
                                      type="button"
                                      class="custombtn btn text-white h-auto fs-6 w-100"
                                      disabled={true}
                                    >
                                      <ThreeDotLoader />
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      class="custombtn btn text-white h-auto fs-6 w-100"
                                      onClick={checkTransactionStatus}
                                      disabled={swapIdForStatus === "" && true}
                                    >
                                      Check{" "}
                                    </button>
                                  )}
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Tab.Pane>
                        <Tab.Pane active={tab == "share"} eventKey="shareswap">
                          {/* <div>
                            <p className="mb-0">
                              With 'Delete Records', you can permanently erase
                              specific swaps from our database, ensuring
                              complete discretion. This <a href="#">unique feature</a> is
                              designed for your <a href="#">total privacy.</a>
                            </p>
                          </div> */}
                        </Tab.Pane>
                        <Tab.Pane
                          active={tab == "delete"}
                          eventKey="deleterecords"
                        >
                          <div className="swap_spc">
                            <p className="mb-0 ">
                              With 'Delete Records', you can permanently erase
                              specific swaps from our database, ensuring
                              complete discretion. This{" "}
                              <span className="text-blue">unique feature</span>{" "}
                              is designed for your{" "}
                              <span className="text-blue">total privacy</span>.
                            </p>
                          </div>

                          <Col
                            sm={12}
                            className="text-center mt-5 deleteSection"
                          >
                            <Card className="p-4 border-0">
                              {delErrorMsg != "" ? (
                                <>
                                  <div className="float-rate h-auto mb-3 alertBox">
                                    <ErrorMessage error={delErrorMsg} type={errMsgType} />
                                  </div>
                                </>
                              ) :
                                <>
                                  {delSuccessMsg != "" ? (
                                    <>
                                      <div className="float-rate h-auto mb-3 alertBox">
                                        <SuccessMessage msg={delSuccessMsg} />
                                      </div>
                                    </>
                                  ) :
                                    <div className="float-rate d-table px-0 mb-3 ToasterCloseHide">
                                      <Toast
                                        style={{ width: "100%" }}
                                        bg="danger"
                                        className="alertBox yellowBox"
                                      >
                                        <Toast.Header className="text-left p-0">
                                          <div className="me-auto">
                                            <small class="">
                                              <div className="alert_block">
                                                <div class="warningIconbox me-2">
                                                  <img
                                                    src="assets/icons/warningIcon.png"
                                                    alt="warningIcon"
                                                    className="alertIcon"
                                                  />
                                                </div>{" "}
                                                <span className="alert-text">
                                                  {" "}
                                                  Deleting a swap record is a permanent action and cannot be reversed.
                                                </span>
                                              </div>
                                            </small>
                                          </div>
                                        </Toast.Header>
                                      </Toast>
                                    </div>
                                  }

                                </>
                              }

                              <Row className="justify-content-end">
                                <Col
                                  lg={9}
                                  md={9}
                                  sm={9}
                                  xs={12}
                                  className="mb-4"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter the Captcha value"
                                    name="captchaCode"
                                    value={captchaCode}
                                    onChange={inputHandler}
                                  />
                                </Col>
                                <Col
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  xs={7}
                                  className="mb-4"
                                >
                                  <button
                                    type="button"
                                    class="btn-default border btn h-auto fs-5 w-100 lh-0"
                                  >
                                    <LoadCanvasTemplateNoReload />
                                  </button>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={9} md={9} sm={9} xs={7}>
                                  <Form.Control
                                    type="text"
                                    placeholder="Swap ID"
                                    name="swapIdForDelete"
                                    value={swapIdForDelete}
                                    onChange={inputHandler}
                                  />
                                </Col>
                                <Col lg={3} md={3} sm={3} xs={5}>
                                  {checkingStatus ? (
                                    <button
                                      type="button"
                                      class="btn-danger btn text-white h-auto fs-6 w-100"
                                      disabled={true}
                                    >
                                      <ThreeDotLoader />
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      class="btn-danger btn text-white h-auto fs-6 w-100"
                                      onClick={deleteThisSwapTransaction}
                                      disabled={
                                        swapIdForDelete === "" ||
                                        (captchaCode === "" && true)
                                      }
                                    >
                                      Delete{" "}
                                    </button>
                                  )}
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="faq pt-4 pb-4 bg-white">
        <Container>
          <Row className="justify-content-center">
            <Col lg="8" md="12" xs="12" className="mx-auto">
              <div className="faq_box">
                <Row>
                  <Col lg={12} md={12} className="">
                    <Accordion className="mb-0">
                      <Accordion.Item eventKey="0" className="mb-2">
                        <Accordion.Header>
                          How can I retrieve the details of a past swap?
                        </Accordion.Header>
                        <Accordion.Body>
                          To access information about a past swap, simply enter
                          the unique swap ID that was displayed during your
                          transaction in the "Swap Status" section. Once you
                          input the swap ID, the details of your swap will be
                          displayed.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1" className="mb-2">
                        <Accordion.Header>
                          What does it mean if my Swap ID is not found in the
                          system?
                        </Accordion.Header>
                        <Accordion.Body>
                          If your Swap ID is not found, it could mean either
                          there was a typo in the swap ID you entered, or the
                          swap has been deleted from our database at your
                          request. Please check the accuracy of your swap ID and
                          try again.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2" className="mb-2">
                        <Accordion.Header>
                          How can I permanently erase all traces of a completed
                          swap?
                        </Accordion.Header>
                        <Accordion.Body>
                          To completely erase a swap, go to the "Delete Record"
                          submenu. Enter the unique swap ID of the swap you wish
                          to erase, followed by the six-digit captcha code to
                          confirm you are human. Click on "Delete." A
                          confirmation message will appear once the request is
                          processed, indicating that the swap history has been
                          permanently deleted.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3" className="mb-2">
                        <Accordion.Header>
                          Why should I consider deleting my swap information?
                        </Accordion.Header>
                        <Accordion.Body>
                          There are several reasons, with privacy being the main
                          one. Each person has their own personal reasons,
                          related to personal security, discretion, or other
                          concerns. Deleting a swap's history allows you to
                          control your personal data.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4" className="mb-2">
                        <Accordion.Header>
                          Is it possible to recover a swap record after it has
                          been deleted?
                        </Accordion.Header>
                        <Accordion.Body>
                          No, once a swap record is deleted, it cannot be
                          recovered, either by the client or by our team. The
                          deletion of a swap record is final and irreversible,
                          ensuring complete data removal.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5" className="">
                        <Accordion.Header>
                          How do I get help with Swap Management issues?
                        </Accordion.Header>
                        <Accordion.Body>
                          If you encounter any issues with the Swap Management
                          functionalities, you can reach out to our customer
                          service team through the Support section of our
                          website. Alternatively, you can connect with a
                          Specialist via our live chat, which is available 24/7.
                          Our Specialists are always ready to assist you with
                          any problems or queries you may have.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default Swapstatus;
