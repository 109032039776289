import React, { useState, useMemo, useEffect } from "react";
import Header from "../../directives/Header.js";
import Footer from "../../directives/Footer.js";
import {
    Container,
    Row,
    Col,
    Nav,
    Tab,
} from "react-bootstrap";
import "../componentCss/earn.css"
import { Toaster } from "react-hot-toast";
import {
    useParams,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "../../coreFiles/config.js";
import Privacypolicy from "./privacypolicy.jsx";
import TermsCondition from "./termscondition.jsx"
import Disclaimer from "./disclaimer.jsx";

const Legal = () => {

    let { tab } = useParams();
    tab = tab === undefined ? 'privacypolicy' : tab;
    const navigate = useNavigate();
    const redirectTo = (tab) => {
        navigate(`${config.baseUrl}legal/${tab}`)
    }
    return (
        <>
            <Header />
            <Toaster />
            <div className="earn">
                <section className="pt-4 pb-4">
                    <Container>
                        <Row>
                            <Col xl={8} lg={12} md={12} className="mx-auto pb-lg-5 pb-2">
                                <div className="swap_banner">
                                    <h2 className="text-white fw-lighter">Legal</h2>
                                    <h6 className="text-primary-gradient fw-lighter">
                                        Explore our legal documents to understand your rights and responsibilities when using Purple Swap.
                                        {/* Boost your financial growth with USDT investments, stake your Purple (PUR) tokens for rewards, and explore our Affiliate program for more opportunities. */}

                                    </h6>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={8} md={12} className="mx-auto">
                                <div className="tab_style2 earntabs">
                                    <Tab.Container
                                        id="left-tabs-example"
                                        defaultActiveKey={tab == undefined ? 'privacypolicy' : ''}
                                    >
                                        <Nav variant="pills" className="justify-content-between">
                                            <Nav.Item className="text-center">
                                                <Nav.Link className={'right_tab'}
                                                    active={tab == 'privacypolicy'}
                                                    eventKey="privacypolicy" onClick={() => redirectTo('privacypolicy')}>
                                                    <div className="position-relative tab-content-box">Privacy Policy</div>
                                                    {/* <div className="tab-content-bottom"><small className="mb-0">60% APR</small></div> */}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className=" text-center">
                                                <Nav.Link
                                                    className={'right_tab'}
                                                    eventKey="termscondition"
                                                    onClick={() => redirectTo('termscondition')}
                                                    active={tab == 'termscondition'}
                                                // change terms 
                                                >
                                                    <div className="position-relative  tab-content-box">
                                                        Terms & Conditions
                                                    </div>
                                                    {/* <div className="tab-content-bottom"><small className="mb-0">34% APR</small></div> */}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="text-center">
                                                <Nav.Link
                                                    className={'right_tab'}
                                                    eventKey="disclaimer"
                                                    onClick={() => redirectTo('disclaimer')}
                                                    active={tab == 'disclaimer'}
                                                >
                                                    <div className="position-relative tab-content-box">Disclaimer</div>
                                                    {/* <div className="tab-content-bottom"><small className="mb-0">5% APR</small></div> */}
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Row>
                                            <Col sm={12} className="text-center">
                                                <Tab.Content className="">
                                                    <Tab.Pane active={tab == 'privacypolicy'} eventKey="privacypolicy">

                                                        <div className="swap_spc">
                                                            {/* <p className="mb-0">
                                                                Boost Purple Swap's expansion by investing USDT into our management fund. Experience the flexibility to withdraw at any time while earning daily interest on your investment.
                                                            </p> */}
                                                        </div>

                                                    </Tab.Pane>
                                                    <Tab.Pane active={tab == 'termscondition'} eventKey="termscondition">
                                                        <div className="swap_spc">
                                                            {/* <p className="mb-0">
                                                               
                                                                Stake your Purple (PUR) tokens to support the project, earn interest, and increase its value, with a rewarding 34% APR as an added benefit.
                                                            </p> */}
                                                        </div>


                                                    </Tab.Pane>
                                                    <Tab.Pane active={tab == 'disclaimer'} eventKey="disclaimer">
                                                        <div className="swap_spc">
                                                            {/* <p className="mb-0">
                                                                Share unique affiliate links generated just for you on your blog, web page, or social media account
                                                                and get paid for every exchange made through your links.
                                                            </p> */}
                                                        </div>

                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {tab == 'privacypolicy' &&
                    <>
                        <section className="faq pt-4 pb-4 bg-white">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col lg="8" md="12" xs="12" className="mx-auto">
                                        <div className="">
                                            <Row>
                                                <Col lg={12}>
                                                    <Privacypolicy />
                                                </Col>

                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>

                    </>
                }


                {tab == 'termscondition' &&
                    <>
                        <section className="faq pt-4 pb-4 bg-white">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col lg="8" md="12" xs="12" className="mx-auto">
                                        <div className="">
                                            <Row>
                                                <Col lg={12}>
                                                    <TermsCondition />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </>
                }
                {tab == 'disclaimer' &&
                    <>
                        <section className="faq pt-4 pb-4 bg-white">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col lg="8" md="12" xs="12" className="mx-auto">
                                        <div className="">
                                            <Row>
                                                <Col lg={12}>
                                                    <Disclaimer />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </>
                }

            </div >




            <Footer />
        </>
    );
};

export default Legal;
