import React, { useEffect, useMemo, useState, useCallback } from "react";
import Header from "../directives/Header";
import Footer from "../directives/Footer";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Form,
  InputGroup,
  Modal,
  Table,
  Accordion,
} from "react-bootstrap";
import Exchange from "./exchange";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import config from "../coreFiles/config";
import {
  getTransactnioStatusAction,
  getSwapTransactnioStatusAction,
  submitSwapTransactionAction,
  updateSwapTrxStatusAction,
} from "../coreFiles/user.action";
import QRCode from "react-qr-code";
import copy from "copy-to-clipboard";
import {
  ConfirmingBox,
  ExchangingBox,
  PendingBox,
  SendingBox,
} from "./ExchangeProcess";
import BarLoader from "react-bar-loader";
import LoaderDirect from "./LoaderDirect";
import Loader from "./Loader";
import { generateString } from "../coreFiles/helper";
import Successful from "./successful";
import { RiVerifiedBadgeLine } from "react-icons/ri";

const Deposit = () => {
  let { swapId } = useParams();
  const navigate = useNavigate();
  const [TrxData, setTrxData] = useState({});
  const modifiedSwapId = swapId;
  let trxIdIs = modifiedSwapId.substring(2);
  trxIdIs = trxIdIs.slice(0, -1);
  const [TrxId, setTrxId] = useState(trxIdIs || "");
  const [ErrorMsg, setErrorMsg] = useState("");
  const [isMainLoader, setIsMainLoader] = useState(true);
  const [trxStatus, setTrxStatus] = useState("PENDING");
  let action = localStorage.getItem("action")
    ? localStorage.getItem("action")
    : "direct";

  if (!TrxId) {
    navigate(`${config.baseUrl}`);
  }

  useEffect(() => {
    getTransactnioStatus();
    setInterval(() => {
      getTransactnioStatus();
    }, 15000);

    setTimeout(() => {
      localStorage.removeItem("action");
    }, 7000);
  }, []);
  const getTransactnioStatus = async () => {
    try {
      const getTrx = await getSwapTransactnioStatusAction({
        modifiedSwapId: modifiedSwapId,
      });
      if (getTrx.success) {
        // console.log('getTrx', getTrx)
        if (getTrx.data.status == 1) {
          window.location.href = `${config.baseUrl}exchange/404`;
        } else {
          if (getTrx.data.trxStatus == "waiting") {
            checkAndUpdateTrx(getTrx.data.datetime);
          } else if (getTrx.data.trxStatus == "finished") {
            setTrxStatus("COMPLETED");
          }
        }
      } else {
        checkAndUpdateTrx(new Date());
      }
    } catch (err) {
      // navigate(`${config.baseUrl}`)
    }
  };
  const getTrxProcessTime = async (startDateTime) => {
    let startDate = new Date(startDateTime);
    let endDate = new Date();

    let seconds = Math.floor((endDate - startDate) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    let processTime = minutes + " min " + seconds + " sec";
    return processTime;
  };

  const [destinationTag, setDestinationTag] = useState('');

  const checkAndUpdateTrx = async (startDateTime) => {
    try {
      const res = await getTransactnioStatusAction({ id: TrxId });
      setDestinationTag(res.payinExtraId)
      let processTime = await getTrxProcessTime(startDateTime);
      let postData = {
        modifiedSwapId: modifiedSwapId,
        trxStatus: res.status,
        processTime: processTime,
      };
      res.fromImage = localStorage.getItem("from");
      res.toImage = localStorage.getItem("to");
      setTrxData(res);
      if (res.status == "finished") {
        await updateSwapTrxStatusAction(postData);
        setTrxStatus("COMPLETED");
      } else if (res.status == "refunded") {
        await updateSwapTrxStatusAction(postData);
        setErrorMsg("Transaction refunded, Please contact to support.");
      } else if (res.status == "failed") {
        await updateSwapTrxStatusAction(postData);
        setErrorMsg("Transaction failed, Please contact to support.");
      }
    } catch (error) {
      // console.log('checkAndUpdateTrx', error);
    }
  };
  const [handleCopyState, setCopyState] = useState(false);
  const handleCopyAction = () => {
    setCopyState(true);
    setTimeout(() => {
      setCopyState(false);
    }, 1500);
  };

  const copyToClipboard = (order) => {
    copy(order);
    navigator.clipboard.writeText(order);
  };
  return (
    <>
      {isMainLoader && (
        <div className="loaderbox">
          {action == "create" && <Loader setIsMainLoader={setIsMainLoader} />}

          {action == "direct" && (
            <LoaderDirect setIsMainLoader={setIsMainLoader} />
          )}
        </div>
      )}

      <Header />
      {trxStatus == "PENDING" && (
        <>
          <section className="deposit_sec pt-lg-5 pt-2">
            <Container>
              {/* <Row>
                        <Col lg={7} md={7} className='mx-auto pb-5'>
                            <div className='swap_block'>
                                <h4>How to swap:</h4>
                                <div className='swap-head'>
                                    <div className='swapbox'>
                                        <img src="assets/icons/checkicon.svg" alt="checkicon" className='' />
                                        <span>Choose currencies</span>
                                    </div>

                                    <div className='swapbox'>
                                        <img src="assets/icons/nubtwo.svg" alt="nubtwo" className='' />
                                        <span>Enter the address</span>
                                    </div>

                                    <div className='swapbox'>
                                        <img src="assets/icons/nubthree.svg" alt="nubthree" className='' />
                                        <span>Create an exchange</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}

              <Row>
                <Col lg={12} md={12} className="mb-4">
                  <div className="need_help">
                    <Row>
                      <Col lg={6} md={6}>
                        <div className="exchangeid">
                          <span>Exchange ID: {modifiedSwapId}</span>
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="help_right">
                          <img
                            src="assets/icons/question.svg"
                            alt="question"
                            className="question me-2"
                          />
                          <span>Need Help?</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="add_exchangetext mb-3">
                    <div className="deposit_content">
                      <h2>Awaiting your deposit</h2>
                      <Row>
                        <Col lg={12} className="">
                          <div className="send_deposit mb-4">
                            <p>Send deposit:</p>
                            <div className="depositfield">
                              <span>
                                <img
                                  src={TrxData?.fromImage}
                                  alt=""
                                  className="pe-3"
                                />
                              </span>
                              {TrxData?.expectedAmountFrom}{" "}
                              {TrxData?.fromCurrency?.toUpperCase()}
                            </div>
                          </div>
                        </Col>
                        {destinationTag && destinationTag != "" &&
                          <Col lg={12} className="">
                            <div className="send_deposit mb-3">
                              <p className="mb-1">Destination tag:</p>
                              <span className="copytag" title="Click to Copy"
                                onClick={() => { copyToClipboard(destinationTag); handleCopyAction() }}
                              >{destinationTag}
                                &nbsp;
                                {handleCopyState ?
                                  <RiVerifiedBadgeLine fill="green" height={'20px'} />
                                  :
                                  <img src="assets/icons/copyicon.svg" alt="copy" height={'15px'} />
                                }

                              </span>
                            </div>
                          </Col>
                        }

                        <Col lg={12} className="">
                          <div className="depositaddress">
                            <p>Deposit address:</p>
                            <div className="deposit_inr">
                              <div className="qrcode_inner">
                                <div className="codetext">
                                  <div className="pe-3">
                                    <QRCode
                                      value={TrxData?.payinAddress || ""}
                                      style={{
                                        height: "auto",
                                        maxWidth: "150px",
                                        width: "150px",
                                      }}
                                    />
                                  </div>
                                  <div className="qrcodetext" style={{width:"90%"}}>
                                    <p>{TrxData?.payinAddress}</p>
                                  </div>
                                </div>
                                <div className="copybox">
                                  {/* <a href='#'><span className='icobox me-3'><img src="assets/icons/sendicons.svg" alt='' className='' /></span></a> */}
                                  {/* <a href='#'><img src="assets/icons/copyico.svg" alt='' className='' /></a> */}
                                  <a
                                    onClick={(e) =>
                                      copy(TrxData?.payinAddress, {
                                        debug: true,
                                        message: "Press #{key} to copy",
                                      })
                                    }
                                  >
                                    <span className="icobox">
                                      <img
                                        src="assets/icons/copyicon.svg"
                                        alt=""
                                        className=""
                                      />
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="text-center">
                      {ErrorMsg && <p className="text-danger">{ErrorMsg}</p>}
                    </div>
                    <BarLoader height={2} backgroundColor={"#9b80ff2e"} />

                    <div className="deposit_bottom">
                      <PendingBox type={TrxData?.status} />
                      <ConfirmingBox type={TrxData?.status} />
                      <ExchangingBox type={TrxData?.status} />
                      <SendingBox type={TrxData?.status} />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="add_exchangetext ">
                    <div className="deposit_content">
                      <div className="operating_head">
                        <h4>Operating details</h4>
                      </div>
                      <Row>
                        <Col lg={12} className="mt-4">
                          <div className="send_deposit mb-4">
                            <p>You get:</p>
                            <div className="depositfield btcoin">
                              <div>
                                <span>
                                  <img
                                    src={TrxData?.toImage}
                                    alt=""
                                    className="pe-3"
                                  />
                                </span>
                                {TrxData?.toCurrency?.toUpperCase()}{" "}
                                {TrxData?.expectedAmountTo}
                              </div>

                              {/* <div className='btcoin ms-lg-4'>
                                                        <p>BTC=20 coin</p>
                                                    </div> */}
                            </div>
                          </div>
                        </Col>

                        <Col lg={12} className="">
                          <div className="depositaddress">
                            <p>Recipient address:</p>
                            <div className="recieptbox">
                              <div className="">
                                <p>{TrxData?.payoutAddress}</p>
                              </div>

                              <div className="copybox">
                                {/* <a href='#'><span className='icobox'><img src="assets/icons/sendicons.svg" alt='' className='' /></span></a> */}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* <section className='wallet_sec pt-2'>
						<Container>
							<Row>
								<Col lg={12} md={12} className='mx-auto'>
									<h2>Have any Questions</h2>
								</Col>
							</Row>
							<Row>
								<Col lg="12" md="12" xs="12" className='mx-auto'>
									<div className="amoutpaid_box">

										<Row>
											<Col lg={12} md={12} className='p-4'>
												<Accordion className=''>
													<Accordion.Item eventKey="0" className='mb-3'>
														<Accordion.Header>
															What is the recipient's address and where do I get it?</Accordion.Header>
														<Accordion.Body>
															Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
															eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
															minim veniam, quis nostrud exercitation ullamco laboris nisi ut
															aliquip ex ea commodo consequat. Duis   aute irure dolor in
															reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
															pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
															culpa qui officia deserunt mollit anim id est laborum.
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="1" className='mb-3'>
														<Accordion.Header>
															Why is my recipient address shown as invalid?</Accordion.Header>
														<Accordion.Body>
															Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
															eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
															minim veniam, quis nostrud exercitation ullamco laboris nisi ut
															aliquip ex ea commodo consequat. Duis aute irure dolor in
															reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
															pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
															culpa qui officia deserunt mollit anim id est laborum.
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="3" className='mb-3'>
														<Accordion.Header>
															How do I get cashback for the exchange on SimpleSwap?</Accordion.Header>
														<Accordion.Body>
															Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
															eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
															minim veniam, quis nostrud exercitation ullamco laboris nisi ut
															aliquip ex ea commodo consequat. Duis aute irure dolor in
															reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
															pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
															culpa qui officia deserunt mollit anim id est laborum.
														</Accordion.Body>
													</Accordion.Item>

												</Accordion>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Container>
					</section> */}
          <br />
        </>
      )}
      {trxStatus == "COMPLETED" && <Successful swapId={swapId}></Successful>}

      <Footer />
    </>
  );
};

export default Deposit;
