import { ThreeDots } from 'react-loader-spinner';
import { Toast } from "react-bootstrap"
import "./componentCss/toaster.css"

export const MultiColorThreeDotLoader = () => {
  return <ThreeDots
    visible={true}
    height="10"
    width="100%"
    color="#fff"
    radius="9"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
}
export const WalletPageThreeDotLoader = () => {
  return <ThreeDots
    visible={true}
    height="20"
    width="100%"
    color="#fff"
    radius="9"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
}

export const QrCodeLoader = () => {
  return <ThreeDots
    visible={true}
    height="15"
    width="100%"
    color="#9278ff"
    radius="9"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
    marginTop="80px"
    // style={{marginTop: '80px'}}
  />
}

export const SuccessMessage = ({ msg }) => {
  return (
      <Toast style={{ width: "100%" }} bg="success" >
          <Toast.Header className="p-0">
              <div className="me-auto">
                  <small class=""><div className="alert_block"><div class="successIconbox me-2"><img src="assets/icons/checkedIcon.png" alt="successIcon" className="alertIcon" /></div> <span className="alert-text">{msg}</span></div></small>
              </div>
          </Toast.Header>
      </Toast>
  )
}

export const ErrorMessage = ({ error, type = 'Error' }) => {
  return (
      <Toast style={{ width: "100%" }} bg="danger">
          <Toast.Header className="p-0">
              <div className="me-auto">
                  <small class=""><div className="alert_block"><div class="crossIconbox me-2"><img src="assets/icons/crossIcon.png" alt="crossIcon" className="alertIcon" /></div> <span className="alert-text">  {error}</span></div></small>
              </div>
          </Toast.Header>
      </Toast>
  )
}