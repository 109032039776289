import React, { useEffect, useState } from "react";
import Slider from "react-slick";
// import "./cell.css"; 
// import "../component/componentCss/card.css";
import "../component/componentCss/cell.css";

import { tabsData } from "../data/Data";

const Cell = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const shouldShowSlider = windowWidth <= 950;

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
  };

  return (
    <>
    <div className="cell_sec">
    {shouldShowSlider ? (
      <>
       <Slider className="slick-slider" {...sliderSettings}>
          <div className="element element-1">
            <div className="grid">
              <div className="slider_img">
                <img src="https://i.postimg.cc/Vvss5Mqc/1.png" alt="" />
              </div>
              <div className="slider_content">
              <div className="slider_c1">
                <p className="slider_h" style={{padding: "6px 15px"}}>
                  1
                </p>
                <p className="slider_head">Choose a currency pair</p>
                <p className="slider_p">
                  Select your preferred exchange pair from over 50,000 options
                  available on Purple Swap. With such a vast selection, you're
                  sure to find the perfect match for your needs.
                </p>
              </div>
              </div>
            </div>
          </div>
          <div className="element element-2">
            <div className="grid">
              <div className="slider_img">
                <img src="https://i.postimg.cc/jjdrHKWz/2.png" alt="" />
              </div>
              <div className="slider_content">
              <div className="slider_c1">
                <p className="slider_h slider_h2" sstyle={{padding: "6px 12px"}}>
                  2
                </p>
                <p className="slider_head">Enter the recipient’s address</p>
                <p className="slider_p">
                Review all the details of your exchange, including an estimated transaction duration. Then, carefully transfer your funds to our secure, one-time-use deposit address.
                </p>
              </div>
            </div>
          </div>
          </div>
          <div className="element element-2">
            <div className="grid">
              <div className="slider_img">
                <img src="https://i.postimg.cc/zDMXqLy4/3.png" alt="" />
              </div>
              <div className="slider_content">
              <div className="slider_c1">
                <p className="slider_h slider_h3" style={{padding: "6px 15px"}}>
                  3
                </p>
                <p className="slider_head">Send and receive coins</p>
                <p className="slider_p">
                  Experience the Purple Swap efficiency. We'll secure the most
                  favorable exchange rate, efficiently swap your assets, and
                  promptly return them to your account within minutes.
                </p>
              </div>
            </div>
          </div>
          </div>
          <div className="element element-2">
            <div className="grid">
              <div className="slider_img">
                <img src="https://i.postimg.cc/CLhTTSN9/4.png" alt="" />
              </div>
              <div className="slider_content">
                <div className="slider_c1">
                <p className="slider_h slider_h4" style={{padding: "6px 15px"}}>
                  4
                </p>
                <p className="slider_head">That's all!</p>
                <p className="slider_p">
                  Once you see the exchange status marked as 'Completed,' your
                  swap is successfully finalized. Access all your transaction
                  details on the completion page for full transparency.
                </p>
              </div>
              </div>
            </div>
          </div>
        </Slider>
      
      </>
    ) : (
      <>
      <div className="protonworking">
      <div className="grid_items">
        {tabsData.map((tab) => (
          <img className="cellimg"
            key={tab.id}
            src={tab.img}
            alt=""
            style={{ display: activeTab === tab.id ? "block" : "none" }}
          />
        ))}
      </div>
      <div className="grid_items">
        <div className="tabs">
          {tabsData.map((tab) => (
            <div
              key={tab.id}
              className={`tab ${activeTab === tab.id ? "active" : ""}`}
              style={{
                borderLeft: tab.id === "tab4" ? "0" : "2px solid ",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick(tab.id)}
            >
              <p
                className="heading"
                style={{ marginLeft: "42px", cursor: "pointer" }}
              >
                <span
                  className="h_span"
                  style={{
                    background:
                      activeTab === tab.id ? "#49496c" : tab.backgroundColor,
                    color: tab.color,
                    marginRight: "20px",
                  }}
                >
                  {tab.id.replace("tab", "")}
                </span>
                {tab.heading}
              </p>
              <p
                className={`tab_p ${activeTab === tab.id ? "active" : ""}`}
                style={{
                  marginLeft: "45px",
                //   paddingTop: "10px",
                  transition: "all 0.5s ease",
                  // height:
                  //         activeTab === "tab2" && tab.id === "tab2"
                  //           ? "62px"
                  //           : activeTab === tab.id
                  //           ? "88px"
                  //           : "0px",
                  height: activeTab === tab.id ? "100px" : "0",
                }}
              >
                {tab.description}
                {/* <span className="extra">{tab.extra}</span> */}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
      </>
    )}
    </div>
    </>
  );
};

export default Cell;
