import React, { useState, useMemo, useEffect } from "react";
import Header from "../directives/Header.js";
import Footer from "../directives/Footer.js";
import Earnbrand from "../component/earnbrand.js";
import Earncard from "./earncard.js";
import {
	Container,
	Row,
	Col,
	Nav,
	Accordion,
	Tab,
	Card,
} from "react-bootstrap";
// import { FaRegCircle } from "react-icons/fa";
// import "./componentCss/swapmanagement.css";
// import "./componentCss/support.css";
import "./componentCss/earn.css"
import { Toaster } from "react-hot-toast";
import {
	useParams,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "../coreFiles/config";
// import EarnFinacialCenter from "./earnFinancialCenter.jsx";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const Earn = ({ showDashboard }) => {
	let loginUserData = Cookies.get('purpleUser') ? JSON.parse(Cookies.get('purpleUser')) : '';
	let { tab } = useParams();
	tab = tab === undefined ? 'invest' : tab;
	const navigate = useNavigate();
	const redirectTo = (tab) => {
		navigate(`${config.baseUrl}earning/${tab}`)
	}

	const goToPage = (page) => {
		navigate(`${config.baseUrl}${page}`)
	}
		
	return (
		<>
			<Header />
			<Toaster />
			<div className="earn">
				<section className="pt-4 mb-5">
					<Container>
						<Row>
							<Col xl={8} lg={12} md={12} className="mx-auto pb-lg-5 pb-2">
								<div className="swap_banner">
									<h2 className="text-white fw-lighter">Earning</h2>
									<h6 className="text-primary-gradient fw-lighter">
										Maximize your earnings on Purple Swap with options to invest in USDT, stake $PUR, and participate in our Affiliate program.
									</h6>
								</div>
							</Col>
						</Row>

						<Row>
							<Col lg={8} md={12} className="mx-auto">
								<div className="tab_style2 earntabs">
									<Tab.Container
										id="left-tabs-example"
										defaultActiveKey={tab == undefined ? 'invest' : ''}
									>
										<Nav variant="pills" className="mb-5 justify-content-between">
											<Nav.Item className="text-center">
												<Nav.Link className={'right_tab'}
													active={tab == 'invest'}
													eventKey="invest" onClick={() => redirectTo('invest')}>
													<div className="position-relative tab-content-box">Investment</div>
													{/* <div className="tab-content-bottom"><small className="mb-0">60% APR</small></div> */}
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className=" text-center">
												<Nav.Link
													className={'right_tab'}
													eventKey="stake"
													onClick={() => redirectTo('stake')}
													active={tab == 'stake'}
												// change terms 
												>
													<div className="position-relative  tab-content-box">
														Stake
													</div>
													{/* <div className="tab-content-bottom"><small className="mb-0">34% APR</small></div> */}
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className="text-center">
												<Nav.Link
													className={'right_tab'}
													eventKey="affiliate"
													onClick={() => redirectTo('affiliate')}
													active={tab == 'affiliate'}
												>
													<div className="position-relative tab-content-box">Affiliate</div>
													{/* <div className="tab-content-bottom"><small className="mb-0">5% APR</small></div> */}
												</Nav.Link>
											</Nav.Item>
										</Nav>
										<Row>
											<Col sm={12} className="text-center">
												<Tab.Content className="">
													<Tab.Pane active={tab == 'invest'} eventKey="invest1">

														<div className="swap_spc">
															<p className="mb-0">
																Boost Purple Swap's expansion by investing USDT into our management fund. Experience the flexibility to withdraw at any time while earning daily interest on your investment.
															</p>
														</div>

													</Tab.Pane>
													<Tab.Pane active={tab == 'stake'} eventKey="stake">
														<div className="swap_spc">
															<p className="mb-0">
																{/* Stake your Purple $PUR coins to not only support the project and earn interest, but also contribute to increasing its value. */}
																Stake your Purple (PUR) tokens to support the project, earn interest, and increase its value, with a rewarding 34% APR as an added benefit.
															</p>
														</div>


													</Tab.Pane>
													<Tab.Pane active={tab == 'affiliate'} eventKey="affiliate">
														<div className="swap_spc">
															<p className="mb-0">
																Share unique affiliate links generated just for you on your blog, web page, or social media account
																and get paid for every exchange made through your links.
															</p>
														</div>

													</Tab.Pane>
												</Tab.Content>
											</Col>
										</Row>
									</Tab.Container>
								</div>
							</Col>
						</Row>
					</Container>
				</section>


				{tab == 'invest' &&
					<>
						{/* Financial Center */}
						{/* <section className="crytpocurrency pt-4 pb-4 ">
							<EarnFinacialCenter showDashboard={showDashboard}></EarnFinacialCenter>
						</section> */}
						{/*-------------------------- Counter Section Start --------------------*/}
						<section className="counter_sec">
							<Container>
								<Row>
									<Col lg={8} md={12} className="mx-auto">
										<Earnbrand />
									</Col>
								</Row>

							</Container>
						</section>
						{/*------------------------ Counter Section Exit --------------------*/}

						<section className="cards_sec pt-4 pb-4">
							<Container>
								<Row className="justify-content-center">
									<Col lg={8} md={12} className="">
										<div className="custom-head mb-2">
											<h2 className="fw-lighter">The basis and key advantages</h2>
											<div className="swap_spc">
												<p className="mb-0">
													Unlock the full potential of your USDT with Purple Swap, offering up to a standout 60% APR. Our platform is designed for accessibility, security, and high returns, catering to a diverse range of investors. Explore the details below to discover the fundamental reasons and enticing benefits of investing with us.
												</p>
											</div>
										</div>
									</Col>
								</Row>
							</Container>

							<div className="EarnCard">
								<Container>
									<Row>
										<Col lg={8} md={12} className="mx-auto">
											<Earncard />
										</Col>
									</Row>
								</Container>
							</div>
						</section>

						<section className="crytpocurrency pt-4 pb-4 ">

							<Container>
								<Row>
									<Col lg={8} md={12} className="mx-auto">
										<div className="text-center text-white custom-head mb-2">
											<h2 className="text-white fw-lighter">
												Essential readings before you proceed
											</h2>
											<div className="swap_spc">
												<p className="mb-0 text-white">
													Arm yourself with knowledge, dive in prepared.
												</p>
											</div>
										</div>
									</Col>
								</Row>
							</Container>
							<Container className="accordionBlue position-relative mt-5">
								<Row className="justify-content-center">
									<Col lg={8} md={12} className="mx-auto">
										<div className="faq_box">
											<Row>
												<Col lg={12} md={12} className="">
													<Accordion className="mb-0" defaultActiveKey="0">
														<Accordion.Item eventKey="0" className="mb-2">
															<Accordion.Header>
																Use of Investment Funds
															</Accordion.Header>
															<Accordion.Body>
																<p>Your investments are the driving force behind Purple Swap's growth and functionality. We utilize the invested funds to expand our swap capacity and support larger transaction volumes.</p>

																<p>By maintaining a substantial reserve across all supported cryptocurrencies, we ensure efficient fulfillment of swap requests, thereby elevating our service quality. The profits generated from swap fees directly contribute to the competitive interest rates available to our investors.</p>
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="1" className="mb-2">
															<Accordion.Header>
																Investment Process
															</Accordion.Header>
															<Accordion.Body>

																Understanding the investment process at Purple Swap is essential for maximizing your returns. Here's a streamlined approach:
																<div className="mt-2">
																	<ol type="1">
																		<li className="mb-2"><b>Create Your Unique Account : </b>  Begin by setting up your unique account in the Financial Center. This will be your command center for all investment activities.</li>
																		<li className="mb-2"><b>Add USDT to Your Account : </b> Deposit USDT into your account, establishing your funds on our platform.</li>
																		<li className="mb-2"><b>Allocate Funds for Investment : </b> From your available balance, allocate the desired amount to the investment fund. This crucial step activates the interest-generating process.</li>


																	</ol>
																</div>
																<div className="mb-2"><b>Interest Accrual : </b> Following a 3-day initiation phase after allocation, your funds start accruing interest at Purple Swap's attractive rates, ensuring your capital is actively working towards your financial growth.</div>
																<p>This procedure is crafted to offer you both flexibility and control, enabling decisions on investment amounts and timing with ease, and providing a clear path to accruing interest on your assets.</p>

															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="2" className="mb-2">
															<Accordion.Header>
																Withdrawal Flexibility
															</Accordion.Header>
															<Accordion.Body>
																At Purple Swap, we ensure you have the flexibility to optimize your investment potential:
																<div className="mt-2">
																	<ol type="1">

																		<li className="mb-2"><b>Interest Management : </b>
																			<div className="mt-3">
																				<ul style={{ listStyleType: "circle" }}>
																					<li className="mb-2"><b>Withdraw or Reinvest Interest : </b> You have the freedom to either withdraw your earned interest immediately or reinvest it into your investment fund. This allows for interest compounding, significantly increasing the overall value of your investment and boosting future earnings.</li>

																				</ul>
																			</div>
																		</li>
																		<li className="mb-2"><b>Capital Management : </b>
																			<div className="mt-3">
																				<ul style={{ listStyleType: "circle" }}>
																					<li className="mb-2"><b>Initiate Transfer : </b> To access your invested capital, first choose the amount you wish to transfer from your 'Invested' account to your 'Available' account.</li>
																					<li className="mb-2"><b>3-Day Waiting Period : </b> After initiating the transfer, a 3-day waiting period ensures, preparing your funds for withdrawal.</li>
																					<li className="mb-2"><b>Withdraw Funds : </b> Following this period, your funds in the 'Available' account can be withdrawn to an external wallet of your choice, offering the flexibility to access your funds when needed.</li>
																				</ul>
																			</div>
																		</li>
																	</ol>
																</div>
																<p>This approach provides the option to quickly benefit from your interest earnings or reinvest them for increased growth, along with a seamless process for managing and withdrawing your capital.</p>
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="3" className="mb-2">
															<Accordion.Header>
																Availability
															</Accordion.Header>
															<Accordion.Body>
																<p>The opportunity to invest with Purple Swap is subject to availability, ensuring we manage our resources effectively and maintain service quality. Should we reach our maximum investment capacity or find that our company funds sufficiently cover operational needs, we may temporarily suspend new investments.</p>

																<p>This approach allows us to potentially revisit and adjust our investment strategies in the future, always aiming to align with our platform's sustainability and efficiency goals.</p>
															</Accordion.Body>
														</Accordion.Item>

													</Accordion>
												</Col>
											</Row>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
						<section className="how_it_work pt-4 pb-4">
							<Container>
								<Row className="justify-content-center">
									<Col lg={8} md={12} className="text-center">
										<div className="custom-head mb-2">
											<h2 className="fw-lighter">How it works</h2>
											<div className="swap_spc">
												<p className="mb-0">
													A simple walkthrough to boost your investment.
												</p>
											</div>
										</div>
									</Col>
								</Row>
							</Container>
							<Container>
								<Row>
									<Col lg={8} md={12} className="mx-auto">

										<div className="EarnCard">
											<div className=" sixcard_sec mt-5">
												<Container>
													<Row>
														<Col lg={6} className="mb-4">
															<Card className="bg-white border-0 numberCard">
																<div className="d-flex">
																	<div className="me-4">
																		<h1 className="text-blue">1</h1>
																	</div>
																	<div>
																		<div className=""><h5 className="">Set up your account</h5>
																			<p className="mb-0">Access the Financial Center and select the "Create an Account" tab. Carefully follow the four detailed steps provided to efficiently create your account.</p>
																		</div>

																	</div>
																</div>
															</Card>

														</Col>
														<Col lg={6} className="mb-4">
															<Card className="bg-white border-0 numberCard">
																<div className="d-flex">
																	<div className="me-4">
																		<h1 className="text-blue">2</h1>
																	</div>
																	<div>
																		<div className=""><h5 className="">Access your account</h5>
																			<p className="mb-0">Once your account is created, you will be redirected to the login page. Enter your unique ERC-20 address and password to access your account seamlessly.</p>
																		</div>

																	</div>
																</div>
															</Card>

														</Col>
														<Col lg={6} className="mb-4">
															<Card className="bg-white border-0 numberCard">
																<div className="d-flex">
																	<div className="me-4">
																		<h1 className="text-blue">3</h1>
																	</div>
																	<div>
																		<div className=""><h5 className="">Invest your funds</h5>
																			<p className="mb-0">Deposit USDT into your new account. From there, you're able to transfer the desired amount from 'Available' wallet to 'Invested' wallet to initiate the earning process.</p>
																		</div>

																	</div>
																</div>
															</Card>

														</Col>
														<Col lg={6} className="">
															<Card className="bg-white border-0 numberCard">
																<div className="d-flex">
																	<div className="me-4">
																		<h1 className="text-blue">4</h1>
																	</div>
																	<div>
																		<div className=""><h5 className="">Earn daily interest</h5>
																			<p className="mb-0">Begin earning daily interest after a short 3-day initiation phase. Use the Financial Center to monitor your progress, reinvest profits, or withdraw your funds as needed.</p>
																		</div>

																	</div>
																</div>
															</Card>

														</Col>
													</Row>
												</Container>
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
						
						<section className="crytpocurrency pt-4 pb-4 mn-auto">
							<Container>
								<Row>
									<Col lg={8} md={12} className="mx-auto mb-5">
										<div className="text-center text-white custom-head mb-2">
											<h2 className="text-white fw-lighter">
												Ready to start earning?
											</h2>
											<div className="swap_spc">
												<p className="mb-0 text-white">
													Create your account for free and begin navigating through your finacial center with no commitment.
												</p>
											</div>
										</div>
									</Col>
								</Row>
								<Container>

									<Row>
										<Col lg={8} md={12} className="mx-auto text-center">
											
											{loginUserData == '' ?
												<button className="custombtn btn w-45 btn-primary px-5 text-inherit" onClick={()=>goToPage('account/create')}>
												<Link to={`${config.baseUrl}account/create`} className="account_icons text-decoration-none text-white">
													Create my Account
												</Link>
												</button>
												:
												<button className="custombtn btn w-45 btn-primary px-5" onClick={()=>goToPage('account')}>
												<Link to={`${config.baseUrl}account`} className="account_icons text-decoration-none text-white">
													My Account
												</Link>
												</button>
											}
										</Col>
									</Row>
								</Container>
							</Container>
						</section>
						{/* <div className="d-none"> */}
						<section className="faq pt-4 pb-4 bg-white">
							<Container>
								<Row className="justify-content-center">
									<Col lg="8" md="12" xs="12" className="mx-auto">
										<div className="faq_box">
											<Row>
												<Col lg={12} md={12} className="">
													<Accordion className="mb-0">

														<Accordion.Item eventKey="0" className="mb-2">
															<Accordion.Header>
																What do I need to create a new account?
															</Accordion.Header>
															<Accordion.Body>
																To set up your account, you just need to navigate to the Financial Center, select the 'New Account' tab, and follow the simple steps, including choosing a secure password.
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="1" className="mb-2">
															<Accordion.Header>
																How do I choose a strong password for my account?
															</Accordion.Header>
															<Accordion.Body>
																When creating your password, you must meet several mandatory criteria designed to ensure your password is strong. This includes a mix of uppercase and lowercase letters, numbers, and special characters. We've implemented these requirements to help secure your account effectively.
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="2" className="mb-2">
															<Accordion.Header>
																Can I have multiple accounts with Purple Swap?
															</Accordion.Header>
															<Accordion.Body>
																Our platform currently supports one account per user to maintain security and manageability. This ensures each user has a focused and secure investment experience.
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="3" className="mb-2">
															<Accordion.Header>
																What happens if I forget my account password?
															</Accordion.Header>
															<Accordion.Body>
																On the Account Management page, click on "Forgot your password..." and follow the instructions to reset your password securely. This feature is designed to help you regain access to your account with minimal hassle.
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="4" className="mb-2">
															<Accordion.Header>
																Is providing an email address mandatory for account creation?
															</Accordion.Header>
															<Accordion.Body>
																No, providing an email address is optional. However, adding an email can enhance your account security and facilitate communication for account-related notifications.
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="5" className="mb-2">
															<Accordion.Header>
																How can I ensure the security of my account?
															</Accordion.Header>
															<Accordion.Body>
																Keep your password and ERC-20 address confidential, use a strong, unique password, and avoid sharing your login details with anyone to maintain account security.
															</Accordion.Body>
														</Accordion.Item>
														{/* ----------- */}
														<Accordion.Item eventKey="6" className="mb-2">
															<Accordion.Header>
																What is the ERC-20 address associated with my account?
															</Accordion.Header>
															<Accordion.Body>
																Your unique ERC-20 address is generated during account creation and is used for all your transactions, deposits, and withdrawals. It's crucial for accessing your account.
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="7" className="mb-2">
															<Accordion.Header>
																Can I add or change my email address once my account is created?
															</Accordion.Header>
															<Accordion.Body>
																Yes, you can add or change the email address associated with your account at any time. Keeping your email updated ensures you receive all important notifications related to your account directly.
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="8" className="mb-2">
															<Accordion.Header>
																What should I do if I suspect unauthorized access to my account?
															</Accordion.Header>
															<Accordion.Body>
																Immediately change your password and contact Purple Swap support. We'll assist you in securing your account and investigating any unauthorized activity.
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="9" >
															<Accordion.Header>
																If I have further questions, what are my options?
															</Accordion.Header>
															<Accordion.Body>
																Should you have any additional questions or require assistance, you have two main options: our 24/7 live chat support and the customer support section under "Contact." Both services are dedicated to providing you with timely and helpful
															</Accordion.Body>
														</Accordion.Item>


													</Accordion>
												</Col>
											</Row>
										</div>
									</Col>
								</Row>
							</Container>
						</section>

					</>
				}

				{tab == 'invest11' &&
					<>
						<section className="faq pt-4 pb-4 bg-white">
							<Container>
								<Row className="justify-content-center">
									<Col lg="8" md="12" xs="12" className="mx-auto">
										<div className="faq_box">
											<Row>
												<Col lg={12}>
													<center>
														<h5 className="fw-normal" style={{ color: "#1b1340" }}>
															Coming Soon
														</h5>
													</center>
												</Col>

											</Row>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
					</>
				}
				{tab == 'stake' &&
					<>
						<section className="faq pt-4 pb-4 bg-white">
							<Container>
								<Row className="justify-content-center">
									<Col lg="8" md="12" xs="12" className="mx-auto">
										<div className="faq_box">
											<Row>
												<Col lg={12}>
													<center>
														<h5 className="fw-normal" style={{ color: "#1b1340" }}>
															Coming Soon
														</h5>
													</center>
												</Col>

											</Row>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
					</>
				}
				{tab == 'affiliate' &&
					<>
						<section className="faq pt-4 pb-4 bg-white">
							<Container>
								<Row className="justify-content-center">
									<Col lg="8" md="12" xs="12" className="mx-auto">
										<div className="faq_box">
											<Row>
												<Col lg={12}>
													<center>
														<h5 className="fw-normal" style={{ color: "#1b1340" }}>
															Coming Soon
														</h5>
													</center>
												</Col>

											</Row>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
					</>
				}

			</div >




			<Footer />
		</>
	);
};

export default Earn;
