import React, { useState, useEffect } from "react";
import ReactApexChart from 'react-apexcharts';
import "../component/componentCss/linechart.css"
import { getGraphDataAction } from "../coreFiles/user.action";

const LineChart = ({userWalletBalance}) => {

  const [amountArr, setAmountArr] = useState([]);
  const [durationArr, setDurationArr] = useState([]);
  const [activeDuration, setActiveDuration] = useState('1D'); 
  const totalBalance = parseFloat(userWalletBalance.hotWalletBalance + userWalletBalance.coldWalletBalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  
  useEffect(()=>{
    getGraphData('1D');
  },[])

  const series = [
    {
      name: 'USDT',
      data: amountArr,
      // data: [1000, 4100, 3500, 5100, 4900, 6200, 6900, 9100, 10000.00],
    }
  ];
  const options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      marker: {
        fillColors: ['#6d49ff'], // Change the color of the tooltip marker
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      colors:'#6d49ff'
    },
    title: {
      text: 'Total Balance : '+totalBalance+' USDT',
      align: 'left',
      
      style: {
        fontSize: '14px', // X-axis label font size
        fontFamily: 'EduArizonaSans', // X-axis label font family
        filter: 'contrast(15.47)'
        
      },
    },
    markers: {
      colors: '#6d49ff', // Color of the markers
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
        
      },
    },
    xaxis: {
      categories: durationArr,
      // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  };

  const getGraphData = async(duration) => {
    try {
      let res = await getGraphDataAction({duration});
      if (res.success) {
        setAmountArr(res.data.cumulativeAmount.split(','));
        setDurationArr(res.data.duration.split(','));
        setActiveDuration(duration); // Set active duration

      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
       <div className='calenderBtn d-inline-block pull-right'>
        <button onClick={()=>getGraphData('1D')} className={`select-btn rounded-2 d-inline-block w-auto h-auto fw-lighter py-0 me-2 ${activeDuration === '1D' ? 'active' : ''}`}>1D</button>
        <button onClick={()=>getGraphData('1W')} className={`select-btn rounded-2 d-inline-block w-auto h-auto fw-lighter py-0 me-2 ${activeDuration === '1W' ? 'active' : ''}`}>1W</button>
        <button onClick={()=>getGraphData('1M')} className={`select-btn rounded-2 d-inline-block w-auto h-auto fw-lighter py-0 me-2 ${activeDuration === '1M' ? 'active' : ''}`}>1M</button>
        <button onClick={()=>getGraphData('1Y')} className={`select-btn rounded-2 d-inline-block w-auto h-auto fw-lighter py-0 me-2 ${activeDuration === '1Y' ? 'active' : ''}`}>1Y</button>
        {/* <button onClick={()=>getGraphData('5Y')} className={`select-btn rounded-2 d-inline-block w-auto h-auto fw-lighter py-0 ${activeDuration === '5Y' ? 'active' : ''}`}>5Y</button> */}
      </div>
      <div id="chart" className='postion-relative'>
        <ReactApexChart options={options} series={series} type="line" height={350} >
          
        </ReactApexChart>
      </div>
      
      <div id="html-dist"></div>
    </div>
  );
};

export default LineChart;