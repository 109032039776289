import React, { useState, useMemo, useEffect, useRef } from "react";
import {
	FixedRate,
	WarningForget
} from "../swap/ToastAlert.jsx";
import {
	Container,
	Row,
	Col,
	Nav,
	Button,
	Form,
	Modal,
	Tab,
	Card,
	FloatingLabel,
	Toast,
	InputGroup,
	Accordion
} from "react-bootstrap";
import "../componentCss/earn.css"
import "../componentCss/financial.css"
import {
	loadCaptchaEnginge,
	LoadCanvasTemplateNoReload,
	validateCaptcha,
} from "react-simple-captcha";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from "react-icons/md";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { isAddress } from 'web3-validator';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import copy from "copy-to-clipboard";
import ReactConfetti from "react-confetti";
import { createNewAccountAction, userLoginAction } from "../../coreFiles/user.action.js";
import useWindowSize from "react-use/lib/useWindowSize";
import EarnFinacialDashboard from "./fincenter.jsx";
import { MultiColorThreeDotLoader } from "../generic.jsx";
import config from "../../coreFiles/config.js";
import { useParams } from 'react-router-dom';
import Cookies from "js-cookie";
import Login from "./login.jsx";
import Header from "../../directives/Header.js";
import Footer from "../../directives/Footer.js";


const QontoConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: 'calc(-50% + 16px)',
		right: 'calc(50% + 16px)',
		border: "1px solid #000",
		padding: "20px",
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#6d49ff',

		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#6d49ff',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderTopWidth: 3,
		borderRadius: 1,
	},
}));

const steps = ['', '', '', ''];

const Account = ({ showDashboard }) => {
	const { width, height } = useWindowSize();
	const { page } = useParams();
	const [showPassword2, setShowHidePassword2] = useState(false)
	const [showPassword3, setShowHidePassword3] = useState(false)
	const [formForgetPassword, setFormForgetPassword] = useState({
		'erc20Address': "",
		'email': "",
		"captchaCode": ""
	})
	const [forgetPassError, setForgetPassError] = useState('')

	const [formNewAccount, setFormNewAccount] = useState({
		'email': "",
		'password': "",
		'confirmPassword': ""
	})
	const [validationNewAccount, setValidationNewAccount] = useState({
		'emailError': "",
		'password': "",
		'confirmPassword': "",
		'lowerCaseFound': false,
		'upperCaseFound': false,
		'minCharFound': false,
		'numberFound': false,
		'spacialCharFound': false,
		'passwordMatch': false,
	})

	const [formForgetPasswordValidate, setFormForgetPasswordValidate] = useState(false)
	const [dynamicCssClass, setdynamicCssClass] = useState('passSecure_0')
	const [emailFeildDisply, setemailFeildDisply] = useState(0)
	const [activeTab, setActiveTab] = useState(page == "create" ? "termsAndCondition" : "fundManagement");
	const [isLoggedIn, setIsLoggedIn] = useState(showDashboard); // default false
	const [termsContinueLoading, setTermsContinueLoading] = useState(false);
	const [confirmPasswordLoading, setConfirmPasswordLoading] = useState(false);
	const [timerInterval, setTimerInterval] = useState('');
	let loginUserData = Cookies.get('purpleUser') ? JSON.parse(Cookies.get('purpleUser')) : '';

	useEffect(() => {
		if (loginUserData != "") {
			setIsLoggedIn(true)
		}
	}, [])

	useEffect(() => {
		if ((activeTab == "fundManagement" || activeTab == "forgetPass") && isLoggedIn == false) {
			loadCaptchaEnginge(6, '#fff', '#000', 'numbers');
		}
		if (activeTab == "Confirmation") {
			let time = 10;
			let timerIntervalIs = setInterval(() => {
				if (time > 0) {
					time = time - 1
					setBackToLoginTimer(time)
				}
				if (time == 0) {
					setBackToLoginStatus(true);
				}
			}, 1000)
			setTimerInterval(timerIntervalIs);
		}

	}, [activeTab, isLoggedIn])

	const [skipped, setSkipped] = React.useState(new Set());

	const isStepOptional = (step) => {
		return step === 1;
	};
	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	// Forget Password
	const inputHandlerForgetPassword = async (e) => {
		const { name, value } = e.target;
		setFormForgetPassword((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	useEffect(() => {
		if (formForgetPassword.erc20Address != "" && formForgetPassword.email != "" && formForgetPassword.captchaCode != "") {
			setFormForgetPasswordValidate(true)
		}
		else {
			setFormForgetPasswordValidate(false)
		}
	}, [formForgetPassword])

	const confirmForgetPassword = () => {
		if (!isAddress(formForgetPassword.erc20Address)) {
			setForgetPassError('The provided ERC-20 address does not meet the required format.')
			return false;
		}
		if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formForgetPassword.email)) {
			setForgetPassError('The provided email address does not meet the required format.')
			return false;
		}
		if (validateCaptcha(formForgetPassword.captchaCode) === false) {
			setForgetPassError('The captcha is incorrect, please try again.')
			setFormForgetPassword((prevData) => ({
				...prevData,
				captchaCode: "",
			}));
			return false;
		}
	}

	// Start New Fund //
	const checkedTermsCount = useRef(0);
	const [checkedTermsCountTotal, setCheckedTermsCountTotal] = useState(0);
	const [animationStatus, setanimationStatus] = useState(false)
	const [newAccountDetails, setnewAccountDetails] = useState([])
	const [handleCopyState, setCopyState] = useState(false)
	const passSercureNum = useRef(0);
	const [securePercent, setSecurePercent] = useState(0);
	const [activeYes, setActiveYes] = useState('');
	const [activeNo, setActiveNo] = useState('');
	const [creatingAccountLoading, setCreatingAccountLoading] = useState(false);
	const [backToLoginStatus, setBackToLoginStatus] = useState(false);
	const [backToLoginTimer, setBackToLoginTimer] = useState(10);

	const checkTerms = (e) => {
		if (e.target.checked) {
			checkedTermsCount.current = parseInt(checkedTermsCount.current) + parseInt(1);
		}
		else {
			checkedTermsCount.current = parseInt(checkedTermsCount.current) - parseInt(1);
		}
		setCheckedTermsCountTotal(checkedTermsCount.current)
	}
	const showHidePassword2 = (s) => {
		setShowHidePassword2(s)
	}

	const showHidePassword3 = (s) => {
		setShowHidePassword3(s)
	}

	function passwordValidate(password) {
		passSercureNum.current = 0;
		setValidationNewAccount((prevData) => ({
			...prevData,
			lowerCaseFound: false,
			upperCaseFound: false,
			minCharFound: false,
			numberFound: false,
			spacialCharFound: false,
			passwordMatch: false
		}))
		if (password.length > 0) {

			if (/[a-z]/.test(password)) { // Lowercase found
				setValidationNewAccount((prevData) => ({
					...prevData,
					lowerCaseFound: true
				}))
				passSercureNum.current = parseInt(passSercureNum.current) + parseInt(1)
			}
			if (/[A-Z]/.test(password)) { // Upperrcase found
				setValidationNewAccount((prevData) => ({
					...prevData,
					upperCaseFound: true
				}))
				passSercureNum.current = parseInt(passSercureNum.current) + parseInt(1)
			}
			if (password.length >= 12) { // 8CharFound found
				setValidationNewAccount((prevData) => ({
					...prevData,
					minCharFound: true
				}))
				passSercureNum.current = parseInt(passSercureNum.current) + parseInt(1)
			}
			if (/\d/.test(password)) {
				setValidationNewAccount((prevData) => ({
					...prevData,
					numberFound: true
				}))
				passSercureNum.current = parseInt(passSercureNum.current) + parseInt(1)
			}
			if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
				setValidationNewAccount((prevData) => ({
					...prevData,
					spacialCharFound: true
				}))
				passSercureNum.current = parseInt(passSercureNum.current) + parseInt(1)
			}
			if (formNewAccount.confirmPassword == password) {
				setValidationNewAccount((prevData) => ({
					...prevData,
					passwordMatch: true
				}))
			}
			let securePer = 0;
			if (passSercureNum.current == 0) {
				securePer = 0
				setdynamicCssClass('passSecure_0')
			}
			if (passSercureNum.current == 1) {
				securePer = 20
				setdynamicCssClass('passSecure_20')
			}
			if (passSercureNum.current == 2) {
				securePer = 40;
				setdynamicCssClass('passSecure_40')
			}
			if (passSercureNum.current == 3) {
				securePer = 60;
				setdynamicCssClass('passSecure_60')
			}
			if (passSercureNum.current == 4) {
				securePer = 80;
				setdynamicCssClass('passSecure_80')
			}
			if (passSercureNum.current == 5) {
				securePer = 100;
				setdynamicCssClass('passSecure_100')
			}

			setSecurePercent(securePer);
		}
		if (passSercureNum.current == 0) {
			setdynamicCssClass('passSecure_0')
			setSecurePercent(0);
		}
	}

	const inputHandlerNewAccount = async (e) => {
		const { name, value } = e.target;
		if (name === "password") {
			setShowHidePassword2(false)
			passwordValidate(value);
		}
		if (name === "confirmPassword") {
			setShowHidePassword3(false)
			setValidationNewAccount((prevData) => ({
				...prevData,
				passwordMatch: false
			}))
			if (formNewAccount.password == value && formNewAccount.password != "") {
				setValidationNewAccount((prevData) => ({
					...prevData,
					passwordMatch: true
				}))
			}
		}
		setFormNewAccount((prevData) => ({
			...prevData,
			[name]: value,
		}));
		setValidationNewAccount((prevData) => ({
			...prevData,
			emailError: "",
		}));
	};

	const showEmailInput = async () => {
		setemailFeildDisply(1)
		if (checkedTermsCountTotal >= 7) {
			setCheckedTermsCountTotal(6)
			checkedTermsCount.current = 6;
		}
		setActiveYes('activeTabClass')
		setActiveNo('');
	}
	const hideEmailInput = async () => {
		setemailFeildDisply(2)
		if (checkedTermsCountTotal >= 7) {
			setCheckedTermsCountTotal(6)
			checkedTermsCount.current = 6;
		}
		setActiveYes('')
		setActiveNo('activeTabClass');
		setFormNewAccount((prevData) => ({
			...prevData,
			email: "",
		}));
		setValidationNewAccount((prevData) => ({
			...prevData,
			emailError: "",
		}));
	}
	const handleCopyAction = async () => {
		setCopyState(true)
		setTimeout(() => {
			setCopyState(false)
		}, 1500);
	}
	const copyToClipboard = (order) => {
		copy(order);
		navigator.clipboard.writeText(order)
	};

	const switchTo = (newTab) => {
		setTermsContinueLoading(true)
		setConfirmPasswordLoading(true)
		setActiveTab(newTab)
		if (newTab == "fundManagement") {  // Reset alll state on click cancel
			setSecurePercent(0)
			setCopyState(false)
			setFormNewAccount((prevData) => ({
				...prevData,
				email: "",
				password: "",
				confirmPassword: "",
			}));

			var checkTerms = document.getElementsByName('checkTerms');
			for (var i = 0; i < checkTerms.length; i++) {
				checkTerms[i].checked = false;
			}
			checkedTermsCount.current = 0;
			setCheckedTermsCountTotal(0);
			setValidationNewAccount((prevData) => ({
				...prevData,
				lowerCaseFound: false,
				upperCaseFound: false,
				minCharFound: false,
				numberFound: false,
				spacialCharFound: false,
				passwordMatch: false
			}))
			setForgetPassError("")
			setFormForgetPassword((prevData) => ({
				...prevData,
				erc20Address: "",
				email: "",
				captchaCode: ""
			}));
			setdynamicCssClass('passSecure_0')
			setSecurePercent(0);
			setemailFeildDisply(0)
			setActiveYes('')
			setActiveNo('');
			setFormNewAccount((prevData) => ({
				...prevData,
				email: "",
			}));
			clearInterval(timerInterval);
			setBackToLoginStatus(false);
			setBackToLoginTimer(10);
		}

		window.scroll({
			top: 0,
			behavior: 'smooth'
		});
		setTimeout(() => {
			setTermsContinueLoading(false);
			setConfirmPasswordLoading(false);
		}, 1500);
	}

	const createMyAccount = async () => {
		try {
			if (emailFeildDisply == 1) {
				if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formNewAccount.email)) {
					setValidationNewAccount((prevData) => ({
						...prevData,
						emailError: "The provided email address does not meet the required format.",
					}));
					return false;
				}
			}
			setCreatingAccountLoading(true)
			const data = {
				email: formNewAccount.email,
				password: formNewAccount.password,
				confirmPassword: formNewAccount.confirmPassword,
				emailNotification: emailFeildDisply == 2 ? '0' : '1'
			};
			let res = await createNewAccountAction(data);
			if (res.success) {
				setnewAccountDetails(res.data)
				setTimeout(() => {
					setanimationStatus(true);
					switchTo('Confirmation')
					setCreatingAccountLoading(false)
					setTimeout(() => {
						setanimationStatus(false)
					}, 2500);
				}, 3000);
			} else {
				setCreatingAccountLoading(false)
			}
		} catch (error) {
			setCreatingAccountLoading(false)
		}
	};

	return (
		<>
			<Header />

			<div className="earn">
				{!isLoggedIn &&

					<section className="crytpocurrency pt-0 pb-4 bg-banner-img">
						<Container fluid className="px-0">
							<Row>
								<Col lg={12} className=" ">

									<Card className="border-0 p-3  yellowBox rounded-0" style={{ background: "#fff9e6" }}>

										<Row className="justify-content-center">
											<Col lg={8} md={12} className="text-center ctm-col">
												<div className="me-auto">
													<p className="mb-0" >The Financial Center is currently in <b>BETA</b> mode. For an enhanced experience, please use a PC or laptop and Google Chrome as your browser until development is finalized. Thank you for your cooperation.</p>
												</div>
											</Col>
										</Row>

									</Card>
								</Col>
							</Row>
						</Container>
						<Container>

							<Row>
								<Col lg={8} md={12} className="mx-auto mt-5">
									<div className="text-center text-white custom-head mb-0">
										<h2 className="text-white fw-lighter">
											Financial Center
										</h2>
										<div className="swap_spc">
											<p className="mb-0 text-white">
												Create your private account and effortlessly manage your assets.
											</p>
										</div>
									</div>
								</Col>
							</Row>

							<Row className="justify-content-center">
								<Col className="col-lg-7 col-md-12 mt-5">
									<div className="bnr_inner pb-0">
										<div className="tab_style1">
											<Tab.Container id="left-tabs-example" defaultActiveKey="fundManagement">
												<Row>
													{(activeTab === "fundManagement" || activeTab === "startNewFund") &&
														<Col sm={12}>
															<Nav variant="pills" className="overflow-hidden" style={{ borderRadius: "30px 30px 0px 0px" }}>
																<Nav.Item className=" w-50 text-center">
																	<Nav.Link eventKey="fundManagement" active={activeTab === "fundManagement"} className="left_tab" onClick={(e) => switchTo("fundManagement")}>
																		<div className="position-relative">
																			Log in </div>
																	</Nav.Link>
																</Nav.Item>
																<Nav.Item className=" w-50 text-center">
																	<Nav.Link eventKey="startNewFund" active={activeTab === "startNewFund"}
																		onClick={() => switchTo("termsAndCondition")}
																		className="right_tab">
																		<div className="position-relative">
																			Create an Account</div>
																	</Nav.Link>
																</Nav.Item>
															</Nav>
														</Col>
													}
													{activeTab === "forgetPass" &&
														<>
															<Col sm={12}>
																<Nav variant="pills" className="overflow-hidden justify-content-center forgetPasswordCenter" style={{ borderRadius: "30px 30px 0px 0px" }}>
																	<Nav.Item className=" w-100 text-center">
																		<Nav.Link eventKey="forgetPass" className="center_tab">
																			<div className="position-relative z-1">
																				Forgot Password</div>
																		</Nav.Link>
																	</Nav.Item>

																</Nav>
															</Col>
														</>
													}

													{activeTab === "termsAndCondition" &&
														<>
															<Col sm={12}>
																<Nav variant="pills" className="overflow-hidden justify-content-center forgetPasswordCenter" style={{ borderRadius: "30px 30px 0px 0px" }}>
																	<Nav.Item className=" w-100 text-center">
																		<Nav.Link eventKey="termsAndCondition" className="center_tab">
																			<div className="position-relative z-1">
																				Terms and Condition</div>
																		</Nav.Link>
																	</Nav.Item>
																</Nav>
															</Col>
														</>
													}
													{activeTab === "encryptedPassword" &&
														<>
															<Col sm={12}>
																<Nav variant="pills" className="overflow-hidden justify-content-center forgetPasswordCenter" style={{ borderRadius: "30px 30px 0px 0px" }}>
																	<Nav.Item className=" w-100 text-center">
																		<Nav.Link eventKey="encryptedPassword" className="center_tab">
																			<div className="position-relative z-1">
																				Encrypted Password</div>
																		</Nav.Link>
																	</Nav.Item>
																</Nav>
															</Col>
														</>
													}
													{activeTab === "communicationTab" &&
														<>
															<Col sm={12}>
																<Nav variant="pills" className="overflow-hidden justify-content-center forgetPasswordCenter" style={{ borderRadius: "30px 30px 0px 0px" }}>
																	<Nav.Item className=" w-100 text-center">
																		<Nav.Link eventKey="communicationTab" className="center_tab">
																			<div className="position-relative z-1">
																				Communication
																			</div>
																		</Nav.Link>
																	</Nav.Item>
																</Nav>
															</Col>
														</>
													}
													{activeTab === "Confirmation" &&
														<>
															<Col sm={12}>
																<Nav variant="pills" className="overflow-hidden justify-content-center forgetPasswordCenter" style={{ borderRadius: "30px 30px 0px 0px" }}>
																	<Nav.Item className=" w-100 text-center">
																		<Nav.Link eventKey="Confirmation" className="center_tab">
																			<div className="position-relative z-1">
																				Confirmation
																			</div>
																		</Nav.Link>
																	</Nav.Item>
																</Nav>
															</Col>
														</>
													}

													<Col sm={12}>
														<Tab.Content>

															{/* Fund Management */}
															<Login
																activeTab={activeTab}
																setActiveTab={setActiveTab}
																setIsLoggedIn={setIsLoggedIn}
															/>

															{/* Start New Fund */}
															<Tab.Pane eventKey="startNewFund" active={activeTab == "startNewFund"}>
															</Tab.Pane>

															{/* Forget Password */}
															<Tab.Pane eventKey="forgetPass" active={activeTab == "forgetPass"}>
																<div className="p-4">
																	<Container>
																		<Row className="justify-content-center">

																			<Col lg={12} className="">
																				<Row>
																					<Col lg={12} md={12} className="px-0 pb-1">
																						{/* <div className="justify-content-between FixedRate">
																					<div className="float-rate d-table px-0 mb-0 ToasterCloseHide">
																						{forgetPassError != "" ?
																							<Toast style={{ width: "100%" }} bg="danger" className="alertBox" >
																								<Toast.Header className="text-left">
																									<div className="me-auto">
																										<b>Error : </b><small class="">
																											<span>{forgetPassError}</span></small>
																									</div>
																								</Toast.Header>
																							</Toast>
																							:
																							<WarningForget />
																						}
																					</div>
																				</div> */}

																						<Toast style={{ width: "100%" }} bg="primary" className="alertBox yellowBox" >
																							<Toast.Header className="text-left" style={{ background: " #fff9e6", color: "#ac8515" }}>
																								<div className="me-auto">
																									<small class="">
																										{/* <span class="fixedfont">Info :</span>  */}
																										<span>Please provide the ERC-20 address associated with your Purple Swap account and a contact email address. A specialist will swiftly reach out to assist you in regaining access to your account.</span></small>
																								</div>
																							</Toast.Header>
																						</Toast>
																					</Col>
																				</Row>
																				<div className="filed_box mb-4">
																					<Row>
																						<Col lg={12} className="singleField px-0">
																							<div className="field_block">
																								<FloatingLabel
																									controlId="ERC-20"
																									label="ERC-20"
																								>
																									<Form.Control
																										type="text"
																										placeholder="ERC-20"
																										name="erc20Address"
																										value={formForgetPassword.erc20Address}
																										onChange={inputHandlerForgetPassword}
																										autoComplete="off"
																									/>
																								</FloatingLabel>
																							</div>
																						</Col>

																					</Row>
																				</div>
																				<div className="filed_box mb-4">
																					<Row>
																						<Col lg={12} className="singleField px-0">
																							<div className="field_block">
																								<FloatingLabel
																									controlId="Email"
																									label="Email"
																								>
																									<Form.Control
																										type="text"
																										placeholder="Email"
																										name="email"
																										value={formForgetPassword.email}
																										onChange={inputHandlerForgetPassword}
																										autoComplete="off"
																									/>
																								</FloatingLabel>
																							</div>
																						</Col>
																					</Row>
																				</div>
																				<div className="filed_box">
																					<Row>
																						<Col lg={9} md={9} xs={12} className="singleField px-1 pl-0 mb-2">
																							<div className="field_block">
																								<FloatingLabel
																									controlId="floatingPassword"
																									label="Enter the Captcha value"
																								>
																									<Form.Control
																										type="text"
																										placeholder="Enter the Captcha value"
																										name="captchaCode"
																										value={formForgetPassword.captchaCode}
																										onChange={inputHandlerForgetPassword}
																										autoComplete="off"
																									/>
																								</FloatingLabel>
																							</div>
																						</Col>
																						<Col lg={3} md={3} xs={7} className="singleBtn pr-0 ml-auto mb-4">
																							<Button variant="default"
																								className="captchaCode border bg-white fs-6 w-100 lh-0"
																							>
																								{activeTab == "forgetPass" &&
																									<LoadCanvasTemplateNoReload />}
																							</Button>
																						</Col>
																					</Row>
																				</div>

																				<div className="filed_box mb-3">
																					<Row>
																						<Col lg={12} className="px-0">
																							{formForgetPasswordValidate ?
																								<button type="button" onClick={confirmForgetPassword} class="custombtn h-auto w-100 btn btn-primary text-inherit">Reset my Password </button>
																								:
																								<button type="button" disabled class="custombtn h-auto w-100 btn btn-primary text-inherit">Reset my Password </button>
																							}
																						</Col>
																					</Row>
																				</div>
																				<div className="text-center">
																					<a href="javascript:void(0)" onClick={(e) => switchTo("fundManagement")} className="text-blue fs-6 fw-lighter">Cancel</a>
																				</div>
																			</Col>
																		</Row>
																	</Container>

																</div>
															</Tab.Pane>

															{/* Terms and conditions */}
															<Tab.Pane eventKey="termsAndCondition" active={activeTab == "termsAndCondition"}>
																<div className="p-4">
																	<Container>
																		<Row className="justify-content-center">
																			<Col lg={12} className="px-0 stepform">
																				<Container className="">
																					<Row className="justify-content-center">
																						<Col lg={10}>
																							<Box sx={{ width: '100%' }}>
																								<Stepper activeStep={0} connector={<QontoConnector />}>
																									{steps.map((label, index) => {
																										const stepProps = {};
																										const labelProps = {};
																										if (isStepOptional(index)) {
																											labelProps.optional = (
																												<Typography variant="caption"></Typography>
																											);
																										}
																										if (isStepSkipped(index)) {
																											stepProps.completed = false;
																										}
																										return (
																											<Step key={label} {...stepProps}>
																												<StepLabel {...labelProps}>{label}</StepLabel>
																											</Step>
																										);
																									})}
																								</Stepper>
																							</Box>
																						</Col>
																						<React.Fragment>
																							<Container className="">
																								<Row>
																									<Col lg={12} className="px-0 mb-4">

																										{/* <Card className="border-0 p-3 mt-4" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
																									<p className="mb-0">
																										I acknowledge that I am creating an investment account with Purple Swap. I understand the importance of the access details I will receive for managing my account and funds, recognizing their confidentiality. I commit to implementing all necessary steps to protect this information.
																									</p>
																								</Card> */}

																										<Card className="border-0 mt-4" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
																											<div className="alert_block infoBlock">
																												<div class="infoIconbox me-2">
																													<img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" />
																												</div>
																												<div className="alert-text">
																													<p className="mb-0">
																														I acknowledge that I am creating an investment account with Purple Swap. I understand the importance of the access details I will receive for managing my account and funds, recognizing their confidentiality. I commit to implementing all necessary steps to protect this information.
																													</p>
																												</div>
																											</div>

																										</Card>
																										{/* ------------- */}


																									</Col>
																								</Row>

																								<Row>
																									<Col lg={12} className="px-0 mb-4">
																										<div>
																											<ul className="p-3 py-0 mb-0">
																												<li>

																													<div className="me-1">
																														<label class="container-check">
																															<h6 className="mb-0">I confirm :</h6>
																															<p>I will be the sole user of this account.</p>
																															<input type="checkbox" onChange={checkTerms} name="checkTerms" />
																															<span class="checkmark"></span>
																														</label>
																													</div>

																												</li>
																												<li>

																													<div className="me-1">
																														<label class="container-check">
																															<h6 className="mb-0">I confirm :</h6>
																															<p>All funds deposited into this account are from legitimate sources.</p>
																															<input type="checkbox" onChange={checkTerms} name="checkTerms" />
																															<span class="checkmark"></span>
																														</label>
																													</div>

																												</li>
																												<li>

																													<div className="me-1">
																														<label class="container-check">
																															<h6 className="mb-0">I confirm :</h6>
																															<p>My login details and access information will remain confidential.</p>
																															<input type="checkbox" onChange={checkTerms} name="checkTerms" />
																															<span class="checkmark"></span>
																														</label>
																													</div>

																												</li>
																												<li>

																													<div className="me-1">
																														<label class="container-check">
																															<h6 className="mb-0">I confirm :</h6>
																															<p>I possess the required knowledge for conducting cryptocurrency transactions.</p>
																															<input type="checkbox" onChange={checkTerms} name="checkTerms" />
																															<span class="checkmark"></span>
																														</label>
																													</div>

																												</li>
																												<li>

																													<div className="me-1">
																														<label class="container-check">
																															<h6 className="mb-0">I confirm :</h6>
																															<p className="mb-0">I do not hold any other active investment account with Purple Swap.</p>
																															<input type="checkbox" onChange={checkTerms} name="checkTerms" />
																															<span class="checkmark"></span>
																														</label>
																													</div>

																												</li>
																											</ul>
																										</div>
																									</Col>
																								</Row>

																								<Row>
																									<Col lg={12} className="px-0">
																										{checkedTermsCountTotal >= 5 ?
																											<>
																												{!termsContinueLoading ?
																													< Button onClick={() => switchTo('encryptedPassword')} className="custombtn mb-3 h-auto w-100 btn btn-primary">
																														Continue
																													</Button>
																													:
																													<Button className="custombtn mb-3 w-100 h-auto threeDotMulticolor">
																														<MultiColorThreeDotLoader />
																													</Button>
																												}
																											</>
																											:
																											<Button disabled className="custombtn mb-3 h-auto w-100 btn btn-primary">
																												Continue
																											</Button>
																										}

																									</Col>
																								</Row>
																							</Container>
																						</React.Fragment>



																					</Row>
																					<div className="text-center">
																						<span>Already have an account?</span> <a href="javascript:void(0)" onClick={(e) => switchTo("fundManagement")} className="text-blue fs-6 fw-lighter">Log in</a>
																					</div>
																				</Container>
																			</Col>
																		</Row>
																	</Container>
																</div>
															</Tab.Pane>

															{/* Encrypted Password */}
															<Tab.Pane eventKey="encryptedPassword" active={activeTab == "encryptedPassword"}>
																<div className="p-4">
																	<Container>
																		<Row className="justify-content-center">

																			<Col lg={12} className="px-0 stepform">

																				<Container className="">
																					<Row className="justify-content-center">
																						<Col lg={10} className="">
																							<Box sx={{ width: '100%' }}>
																								<Stepper activeStep={1} connector={<QontoConnector />}>
																									{steps.map((label, index) => {
																										const stepProps = {};
																										const labelProps = {};
																										if (isStepOptional(index)) {
																											labelProps.optional = (
																												<Typography variant="caption"></Typography>
																											);
																										}
																										if (isStepSkipped(index)) {
																											stepProps.completed = false;
																										}
																										return (
																											<Step key={label} {...stepProps}>
																												<StepLabel {...labelProps} >{label}</StepLabel>
																											</Step>
																										);
																									})}
																								</Stepper>
																							</Box>
																						</Col>
																					</Row>
																					<Row>
																						<Col lg={12} className="px-0">
																							{/* <Card className="border-0 p-3 mt-4 mb-4" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
																						<div className="">
																							<p className="mb-0">
																								You are now required to choose a password to secure your account. To ensure a strong password, you must meet the following five criteria, which are designed to prevent unauthorized access and maintain your account's security.
																							</p>
																						</div>
																					</Card> */}

																							<Card className="border-0 mt-4 mb-4" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
																								<div className="alert_block infoBlock">
																									<div class="infoIconbox me-2">
																										<img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" />
																									</div>
																									<div className="alert-text">
																										<p className="mb-0">
																											You are now required to choose a password to secure your account. To ensure a strong password, you must meet the following five criteria, which are designed to prevent unauthorized access and maintain your account's security.
																										</p>
																									</div>
																								</div>
																							</Card>


																						</Col>
																					</Row>
																					<Row>
																						<Col lg={12} className="px-0">
																							<Row className="justify-content-end">
																								<Col xl={9} lg={8} md={9} className="">
																									<div className="filed_box px-0 mb-4">
																										<Row>
																											<Col lg={12} className="singleField">
																												<div className="field_block">
																													<FloatingLabel
																														controlId="password"
																														label="Password"
																													>
																														<Form.Control
																															type={showPassword2 ? "text" : "password"}
																															placeholder="Password"
																															name="password"
																															value={formNewAccount.password}
																															onChange={inputHandlerNewAccount}
																															autoComplete="off"
																														/>
																														<span className="eyeIcon">
																															{!showPassword2 ?
																																<FaRegEye className="eye" fill="#6d4aff" onClick={(e) => showHidePassword2(true)} style={{ cursor: "pointer" }} /> :
																																<FaRegEyeSlash className="eyecut" fill="#6d4aff" onClick={(e) => showHidePassword2(false)} style={{ cursor: "pointer" }} />}
																														</span>
																													</FloatingLabel>
																												</div>
																											</Col>

																										</Row>

																									</div>
																									<div className="filed_box px-0 mb-4">
																										<Row>
																											<Col lg={12} className="singleField">
																												<div className="field_block">
																													<FloatingLabel
																														controlId="confirmPassword"
																														label="Confirm Password"
																													>
																														<Form.Control
																															type={showPassword3 ? "text" : "password"}
																															placeholder="Password"
																															name="confirmPassword"
																															value={formNewAccount.confirmPassword}
																															onChange={inputHandlerNewAccount}
																															autoComplete="off"
																														/>
																														<span className="eyeIcon">
																															{!showPassword3 ?
																																<FaRegEye className="eye" fill="#6d4aff" onClick={(e) => showHidePassword3(true)} style={{ cursor: "pointer" }} /> :
																																<FaRegEyeSlash className="eyecut" fill="#6d4aff" onClick={(e) => showHidePassword3(false)} style={{ cursor: "pointer" }} />}</span>
																													</FloatingLabel>
																												</div>
																											</Col>

																										</Row>

																									</div>

																								</Col>
																								<Col xl={3} lg={4} md={3} xs={5} className="pl-0">
																									<Card className="border-0 passwordScore" style={{ padding: "0.5rem 0.5rem 0.5rem 0.75rem" }}>
																										<div className="percentageBox position-relative">
																											<label className="mb-0" >
																												Password Score
																											</label>

																											<div className="percentageText text-nowrap passSecure_0">
																												{securePercent == 100
																													?
																													<>
																														<div className="text-right position-absolute threeStar">
																															<img src="assets/images/star.png" width={'30px'} />
																														</div>
																													</>
																													:
																													""
																												}
																												<h1 className={`text-blue mb-0 fw-lighter ${dynamicCssClass}`}>{securePercent}<span className="">%</span></h1>
																											</div>
																										</div>
																									</Card>
																								</Col>
																							</Row>
																							<div className="filed_box px-0">
																								<Row>
																									<Col lg={12} className=" px-0">
																										<ul class="requirements mb-4 ">
																											<li className={`${validationNewAccount.lowerCaseFound ? 'text-blue' : 'text-muted'} mb-1`}>
																												<small>
																													{validationNewAccount.lowerCaseFound ?
																														<MdOutlineRadioButtonChecked /> :
																														<MdOutlineRadioButtonUnchecked />
																													}
																													<span style={{ paddingLeft: "35px", fontWeight: "200", fontWeight: "200" }}>One lowercase character</span>
																												</small>
																											</li>
																											<li className={`${validationNewAccount.upperCaseFound ? 'text-blue' : 'text-muted'} mb-1`}>
																												<small >
																													{validationNewAccount.upperCaseFound ?
																														<MdOutlineRadioButtonChecked /> :
																														<MdOutlineRadioButtonUnchecked />
																													}
																													<span style={{ paddingLeft: "35px", fontWeight: "200" }}>One uppercase character</span>
																												</small>
																											</li>
																											<li className={`${validationNewAccount.minCharFound ? 'text-blue' : 'text-muted'} mb-1`}>
																												<small>
																													{validationNewAccount.minCharFound ?
																														<MdOutlineRadioButtonChecked /> :
																														<MdOutlineRadioButtonUnchecked />
																													}
																													<span style={{ paddingLeft: "35px", fontWeight: "200" }}>12 characters minimum</span>
																												</small>
																											</li>
																											<li className={`${validationNewAccount.numberFound ? 'text-blue' : 'text-muted'} mb-1`}>
																												<small >
																													{validationNewAccount.numberFound ?
																														<MdOutlineRadioButtonChecked /> :
																														<MdOutlineRadioButtonUnchecked />
																													}
																													<span style={{ paddingLeft: "35px", fontWeight: "200" }}>One number</span></small>
																											</li>
																											<li className={`${validationNewAccount.spacialCharFound ? 'text-blue' : 'text-muted'} mb-1`}>
																												<small>
																													{validationNewAccount.spacialCharFound ?
																														<MdOutlineRadioButtonChecked /> :
																														<MdOutlineRadioButtonUnchecked />
																													}

																													<span style={{ paddingLeft: "35px", fontWeight: "200" }}>One special character</span>
																												</small>
																											</li>
																											<li className={`${validationNewAccount.passwordMatch ? 'text-blue' : 'text-muted'} mb-1`}>
																												<small>
																													{validationNewAccount.passwordMatch ?
																														<MdOutlineRadioButtonChecked /> :
																														<MdOutlineRadioButtonUnchecked />
																													}
																													<span style={{ paddingLeft: "35px", fontWeight: "200" }}>Passwords match</span></small>
																											</li>

																										</ul>
																									</Col>
																								</Row>
																							</div>
																							<div>
																								<Row>
																									<Col lg={12} md={12} className="mb-4">
																										<div className="justify-content-between FixedRate">
																											<div className="float-rate d-table px-0 mb-0 ToasterCloseHide">
																												{forgetPassError != "" ?
																													<Toast style={{ width: "100%" }} bg="danger" className="alertBox" >
																														<Toast.Header className="text-left">
																															<div className="me-auto">
																																<small class="">
																																	<div className="alert_block warningBlock" >
																																		<div class="warningIconbox me-2" >
																																			<img src="assets/icons/warningIcon.png" alt="warningIcon" className="alertIcon" />
																																		</div>
																																		<div className="alert-text">
																																			<div className="fs-6"> <b></b> <span>Please note that Purple Swap cannot recover your chosen password. Losing your password means losing access to all funds in your account. It is crucial to store your password securely.</span></div>
																																		</div>
																																	</div>
																																</small>

																															</div>
																														</Toast.Header>
																													</Toast>
																													:
																													<WarningForget />
																												}
																											</div>
																										</div>

																										{/* <Card className="border-0 mt-4 mb-4" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
																									<div className="alert_block infoBlock">
																										<div class="infoIconbox me-2">
																											<img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" />
																										</div>
																										<div>
																											<p className="mb-0">
																												You have the option to add an email address to your account for receiving
																												notifications, such as deposit or withdrawal confirmations. This step is optional,
																												allowing you to stay completely anonymous if you prefer.
																											</p>
																										</div>
																									</div>
																								</Card> */}
																									</Col>
																								</Row>
																							</div>
																							<Col lg={12} className="px-0">
																								<div>
																									<ul className="p-3 py-0 mb-4">

																										<li>

																											<div className="me-1 mb-4">
																												<label class="container-check">
																													<h6 className="mb-0">I confirm :</h6>
																													<p className="mb-0">I understand that losing my password means losing access to my funds, and Purple Swap cannot recover it for me.
																													</p>
																													<input type="checkbox" onChange={checkTerms} name="checkTerms" />
																													<span class="checkmark"></span>
																												</label>
																											</div>
																										</li>
																									</ul>
																								</div>
																							</Col>
																							<div className="filed_box">
																								<Row>
																									<Col lg={12} className="">
																										{securePercent == 100 && validationNewAccount.passwordMatch && checkedTermsCountTotal == 6 ?
																											<>
																												{!confirmPasswordLoading ?
																													<Button onClick={() => switchTo('communicationTab')} className="custombtn mb-3 h-auto w-100 btn btn-primary">
																														Confirm my Password
																													</Button>
																													:
																													<Button className="custombtn mb-3 w-100 h-auto threeDotMulticolor">
																														<MultiColorThreeDotLoader />
																													</Button>
																												}
																											</>
																											:
																											<Button disabled className="custombtn mb-3 h-auto w-100 btn btn-primary">
																												Confirm my Password
																											</Button>
																										}
																										{/* <Button onClick={() => switchTo('communicationTab')} className="custombtn mb-3 h-auto w-100 btn btn-primary">
																							
																						</Button> */}
																									</Col>
																								</Row>

																							</div>
																							<div className="text-center">
																								<a href="javascript:void(0)" onClick={(e) => switchTo("fundManagement")} className="text-blue fs-6 fw-lighter">Cancel</a>
																							</div>
																						</Col>
																					</Row>

																				</Container>

																			</Col>
																		</Row>
																	</Container>
																</div>

															</Tab.Pane>

															{/* Communication */}
															<Tab.Pane eventKey="communicationTab" active={activeTab == "communicationTab"}>
																<div className="p-4">
																	<Container>
																		<Row className="justify-content-center">

																			<Col lg={12} className="px-0 stepform">

																				<Container className="">
																					<Row className="justify-content-center">
																						<Col lg={10} className="">
																							<Box sx={{ width: '100%' }}>
																								<Stepper activeStep={2} connector={<QontoConnector />}>
																									{steps.map((label, index) => {
																										const stepProps = {};
																										const labelProps = {};
																										if (isStepOptional(index)) {
																											labelProps.optional = (
																												<Typography variant="caption"></Typography>
																											);
																										}
																										if (isStepSkipped(index)) {
																											stepProps.completed = false;
																										}
																										return (
																											<Step key={label} {...stepProps}>
																												<StepLabel {...labelProps} >{label}</StepLabel>
																											</Step>
																										);
																									})}
																								</Stepper>
																							</Box>
																						</Col>
																					</Row>
																					<Row>
																						<Col lg={12} className="px-0">
																							{/* <Card className="border-0 p-3 mt-4 mb-4" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
																						<div className="">
																							<p className="mb-0">
																								You have the option to add an email address to your account for receiving
																								notifications, such as deposit or withdrawal confirmations. This step is optional,
																								allowing you to stay completely anonymous if you prefer.
																							</p>

																						</div>
																					</Card> */}
																							<Card className="border-0 mt-4 mb-4" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
																								<div className="alert_block infoBlock">
																									<div class="infoIconbox me-2">
																										<img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" />
																									</div>
																									<div className="alert-text">
																										<p className="mb-0">
																											You have the option to add an email address to your account for receiving
																											notifications, such as deposit or withdrawal confirmations. This step is optional,
																											allowing you to stay completely anonymous if you prefer.
																										</p>
																									</div>
																								</div>
																							</Card>
																						</Col>
																						<Col lg={12} className=" yesNo px-0">
																							<p className="mb-4 p-3 py-0">Would you like to add an email for account notifications?</p>
																							<Row>
																								<Col lg={6} md={6} xs={6}>
																									<Button variant="default" onClick={showEmailInput} className={`border bg-white w-100 ${activeYes}`}>Yes</Button>
																								</Col>
																								<Col lg={6} md={6} xs={6}>
																									<Button variant="default" onClick={hideEmailInput} className={`border bg-white w-100 ${activeNo}`}>No</Button>
																								</Col>
																							</Row>
																						</Col>
																					</Row>
																					{emailFeildDisply > 0 &&
																						<>
																							<Row>
																								<Col lg={12} className="px-0">
																									<hr className="my-4 opacity-100 border-0" style={{ background: "linear-gradient(to right, #977bff, #4e7aee, #26b6d8)", height: "5px" }} />
																								</Col>


																								{emailFeildDisply == 1 ?
																									<>
																										{validationNewAccount.emailError != "" ?
																											<>
																												<div className="float-rate d-table px-0 mb-3 alertBox ToasterCloseHide">
																													<Toast style={{ width: "100%" }} bg="danger" className="alertBox" >
																														<Toast.Header className="text-left p-0">
																															{/* <div className="me-auto" style={{ color: '#b2302f' }}>
																														<b>Error : </b><small class="">
																															<span style={{ color: '#b2302f' }}>{validationNewAccount.emailError}</span></small>
																													</div> */}
																															<div className="me-auto" style={{ color: '#b2302f' }}>
																																<small class=""><div className="alert_block"><div class="crossIconbox me-2"><img src="assets/icons/crossIcon.png" alt="crossIcon" className="alertIcon" /></div> <span className="alert-text"> {validationNewAccount.emailError}</span></div></small>
																															</div>
																														</Toast.Header>
																													</Toast>
																												</div>
																												<br />
																												<br />
																											</>
																											:
																											<>
																												{/* <div className="float-rate d-table px-0 mb-3 ToasterCloseHide">
																													<Toast style={{ width: "100%" }} bg="danger" className="alertBox yellowBox" >
																														<Toast.Header className="text-left p-0">
																															<div className="me-auto">
																																<small class="">
																																	<div className="alert_block">
																																		<div class="warningIconbox me-2">
																																			<img src="assets/icons/warningIcon.png" alt="warningIcon" className="alertIcon" />
																																		</div>
																																		<span className="alert-text">  A verification email will be sent to the provided address.</span>
																																	</div>
																																</small>
																															</div>
																														</Toast.Header>
																													</Toast>
																												</div>
																												<br />
																												<br /> */}
																											</>
																										}

																										<div className="filed_box mb-4">
																											<Row>
																												<Col lg={12} className="singleField px-0">
																													<div className="field_block">
																														<FloatingLabel
																															controlId="Email"
																															label="Enter your email address"
																														>
																															<Form.Control
																																type="email"
																																placeholder="Email"
																																name="email"
																																value={formNewAccount.email}
																																onChange={inputHandlerNewAccount}
																																autoComplete="off"
																															/>
																														</FloatingLabel>
																													</div>
																												</Col>
																											</Row>
																										</div>

																										<Col lg={12} className="px-0">
																											<div>
																												<ul className="p-3 py-0 mb-4">

																													<li>

																														<div className="me-1 mb-4">
																															<label class="container-check">
																																<h6 className="mb-0">I confirm :</h6>
																																<p className="mb-0">That I agree to receive emails from Purple Swap related to my account. These emails will solely be notifications of account activities, keeping me informed of the actions occurring within my account.
																																</p>
																																<input type="checkbox" onChange={checkTerms} name="checkTerms" />
																																<span class="checkmark"></span>
																															</label>
																														</div>
																														<div className="me-1">
																															<label class="container-check">
																																<h6 className="mb-0">I confirm :</h6>
																																<p className="mb-0">By proceeding, I agree to the <a href={`${config.baseUrl}legal/termscondition`} target="__blank" className="text-blue">Terms & Conditions</a> and <a href={`${config.baseUrl}legal/privacypolicy`} target="__blank" className="text-blue">Privacy Policy</a>.
																																</p>
																																<input type="checkbox" onChange={checkTerms} name="checkTerms" />
																																<span class="checkmark"></span>
																															</label>
																														</div>


																													</li>
																												</ul>
																											</div>
																										</Col>
																									</> : ""}


																								{emailFeildDisply == 2 ?
																									<Col lg={12} className="px-0">
																										<div>
																											<ul className="p-3 py-0 mb-4">

																												<li>

																													<div className="me-1 mb-4">
																														<label class="container-check">
																															<h6 className="mb-0">I confirm :</h6>
																															<p className="mb-0">Selecting this option means I will <span className="text-blue fw-bold">NOT</span> receive emails from Purple Swap, and therefore, I will not be promptly notified of any activities within my account.
																															</p>
																															<input type="checkbox" onChange={checkTerms} name="checkTerms" />
																															<span class="checkmark"></span>
																														</label>
																													</div>
																													<div className="me-1">
																														<label class="container-check">
																															<h6 className="mb-0">I confirm :</h6>
																															<p className="mb-0">By proceeding, I agree to the <a href={`${config.baseUrl}legal/termscondition`} target="__blank" className="text-blue">Terms & Conditions</a> and <a href={`${config.baseUrl}legal/privacypolicy`} target="__blank" className="text-blue">Privacy Policy</a>.
																															</p>
																															<input type="checkbox" onChange={checkTerms} name="checkTerms" />
																															<span class="checkmark"></span>
																														</label>
																													</div>

																												</li>
																											</ul>
																										</div>
																									</Col>
																									: ""}
																							</Row>
																							<div className="filed_box">
																								<Row>
																									<Col lg={12} className="px-0">

																										{checkedTermsCountTotal == 8 && emailFeildDisply == 2 ?
																											<>
																												{!creatingAccountLoading ?
																													<Button onClick={() => createMyAccount()} className="custombtn mb-3 h-auto w-100 btn btn-primary">
																														Create my Account
																													</Button>
																													:
																													<Button disabled className="custombtn mb-3 w-100 h-auto threeDotMulticolor">
																														<MultiColorThreeDotLoader />
																													</Button>
																												}
																											</>
																											:
																											<>
																												{checkedTermsCountTotal == 8 && emailFeildDisply == 1 && formNewAccount.email != "" ?
																													<>
																														{!creatingAccountLoading ?
																															<Button onClick={() => createMyAccount()} className="custombtn mb-3 h-auto w-100 btn btn-primary">
																																Create my Account
																															</Button>
																															:
																															<Button disabled className="custombtn mb-3 w-100 h-auto threeDotMulticolor">
																																<MultiColorThreeDotLoader />

																															</Button>
																														}
																													</>
																													:
																													<Button disabled className="custombtn mb-3 h-auto w-100 btn btn-primary">
																														Create my Account
																													</Button>
																												}
																											</>
																										}
																									</Col>
																								</Row>
																							</div>
																							<div className="text-center">
																								<a href="javascript:void(0)" onClick={(e) => switchTo("fundManagement")} className="text-blue fs-6 fw-lighter">Cancel</a>
																							</div>
																						</>
																					}
																				</Container>

																			</Col>
																		</Row>
																	</Container>
																</div>
															</Tab.Pane>

															{/* Last step */}
															<Tab.Pane eventKey="Confirmation" active={activeTab == "Confirmation"}>
																<ReactConfetti width={width} height={height} recycle={animationStatus} />
																<div className="p-4 position-relative">
																	<Container>
																		<Row className="justify-content-center">
																			<Col lg={12} className="px-0 stepform">
																				<Container>
																					<Row className="justify-content-center">
																						<Col lg={10} className="">
																							<Box sx={{ width: '100%' }}>
																								<Stepper activeStep={3} connector={<QontoConnector />}>
																									{steps.map((label, index) => {
																										const stepProps = {};
																										const labelProps = {};
																										if (isStepOptional(index)) {
																											labelProps.optional = (
																												<Typography variant="caption"></Typography>
																											);
																										}
																										if (isStepSkipped(index)) {
																											stepProps.completed = false;
																										}
																										return (
																											<Step key={label} {...stepProps}>
																												<StepLabel {...labelProps} >{label}</StepLabel>
																											</Step>
																										);
																									})}
																								</Stepper>
																							</Box>
																						</Col>
																					</Row>
																					<Row className="justify-content-center">

																						<Col lg={12} className="px-0">
																							<Container>
																								<Row>
																									<Col lg={12} className="px-0">
																										<Card className="border-0 mt-4 mb-4" style={{ background: "#e7f3ed", color: "#147665" }}>
																											<div className="alert_block infoBlock">
																												<div class="successIconbox me-2">
																													<img src="assets/icons/checkedIcon.png" alt="successIcon" className="alertIcon" />
																												</div>
																												<div className="alert-text">
																													<p className="mb-0">
																														Congratulations, your account has been created!
																													</p>
																												</div>

																											</div>
																										</Card>

																									</Col>
																									<Col lg={12} className="px-0">
																										<p className="mb-4 p-3 py-0">Below you will find your ERC-20 address. This address will be used both to log
																											into your account and to transfer funds into your wallet.</p>
																									</Col>
																									<Col lg={12} className="mb-4 px-0">
																										<InputGroup className="">
																											<Form.Control
																												placeholder="0x40302DeC6aA2570651Ac65FD99e0eFf18F213f82"
																												aria-label="Search"
																												aria-describedby="basic-addon2"
																												disabled
																												value={newAccountDetails.address}
																												className="searchinput rounded-2 me-3"
																											/>
																											<Button
																												onClick={() => { copyToClipboard(newAccountDetails.address); handleCopyAction() }}
																												variant="default"
																												d="button-addon2"
																												className="select-btn px-4 w-auto rounded-2 col-md-2 col-sm-3 col-4"
																											>
																												{handleCopyState == true ? "Copied" : "Copy"}
																											</Button>
																										</InputGroup>
																									</Col>

																									<Col lg={12} className="px-0">
																										<p className="p-3 py-0 mb-4">Please make sure to copy your ERC-20 address before closing this window and
																											store it securely. Your address is just as important as your password.</p>
																									</Col>


																								</Row>

																								<div className="filed_box">
																									<Row>
																										<Col lg={12} className="px-0">
																											{/* <Button onClick={() => switchTo('fundManagement')} className="custombtn h-auto w-100 btn btn-primary">
																										Back to Login
																									</Button> */}
																											{backToLoginStatus ?
																												<Button onClick={() => switchTo('fundManagement')} className="custombtn h-auto w-100 btn btn-primary">
																													Back to Login
																												</Button>
																												:
																												<Button disabled className="custombtn h-auto w-100 btn btn-primary">
																													{backToLoginTimer}
																													{/* Back to Login */}
																													{/* {backToLoginTimer > 0 && (
																												<span className="btn-loading bg-white text-black">
																													<small>{backToLoginTimer} : 00</small>
																												</span>
																											)} */}
																												</Button>
																											}
																										</Col>
																									</Row>
																								</div>
																							</Container>

																						</Col>
																					</Row>
																				</Container>
																			</Col>
																		</Row>
																	</Container>
																</div>
															</Tab.Pane>

														</Tab.Content>
													</Col>
												</Row>
											</Tab.Container>
										</div>
									</div>
								</Col>
							</Row>
						</Container >
					</section>
				}

				{isLoggedIn &&
					<EarnFinacialDashboard
						setIsLoggedIn={setIsLoggedIn}
						setActiveTabPrev={setActiveTab}
						loginUserData={loginUserData}
					/>
				}
				{!isLoggedIn &&
					<section className="faq pt-4 pb-4 bg-white">
						<Container>
							<Row className="justify-content-center">
								<Col lg="8" md="12" xs="12" className="mx-auto">
									<div className="faq_box">
										<Row>
											<Col lg={12} md={12} className="">
												<Accordion className="mb-0">

													<Accordion.Item eventKey="0" className="mb-2">
														<Accordion.Header>
															What do I need to create a new account?
														</Accordion.Header>
														<Accordion.Body>
															To set up your account, you just need to navigate to the Financial Center, select the 'New Account' tab, and follow the simple steps, including choosing a secure password.
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="1" className="mb-2">
														<Accordion.Header>
															How do I choose a strong password for my account?
														</Accordion.Header>
														<Accordion.Body>
															When creating your password, you must meet several mandatory criteria designed to ensure your password is strong. This includes a mix of uppercase and lowercase letters, numbers, and special characters. We've implemented these requirements to help secure your account effectively.
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="2" className="mb-2">
														<Accordion.Header>
															Can I have multiple accounts with Purple Swap?
														</Accordion.Header>
														<Accordion.Body>
															Our platform currently supports one account per user to maintain security and manageability. This ensures each user has a focused and secure investment experience.
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="3" className="mb-2">
														<Accordion.Header>
															What happens if I forget my account password?
														</Accordion.Header>
														<Accordion.Body>
															On the Account Management page, click on "Forgot your password..." and follow the instructions to reset your password securely. This feature is designed to help you regain access to your account with minimal hassle.
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="4" className="mb-2">
														<Accordion.Header>
															Is providing an email address mandatory for account creation?
														</Accordion.Header>
														<Accordion.Body>
															No, providing an email address is optional. However, adding an email can enhance your account security and facilitate communication for account-related notifications.
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="5" className="mb-2">
														<Accordion.Header>
															How can I ensure the security of my account?
														</Accordion.Header>
														<Accordion.Body>
															Keep your password and ERC-20 address confidential, use a strong, unique password, and avoid sharing your login details with anyone to maintain account security.
														</Accordion.Body>
													</Accordion.Item>
													{/* ----------- */}
													<Accordion.Item eventKey="6" className="mb-2">
														<Accordion.Header>
															What is the ERC-20 address associated with my account?
														</Accordion.Header>
														<Accordion.Body>
															Your unique ERC-20 address is generated during account creation and is used for all your transactions, deposits, and withdrawals. It's crucial for accessing your account.
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="7" className="mb-2">
														<Accordion.Header>
															Can I add or change my email address once my account is created?
														</Accordion.Header>
														<Accordion.Body>
															Yes, you can add or change the email address associated with your account at any time. Keeping your email updated ensures you receive all important notifications related to your account directly.
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="8" className="mb-2">
														<Accordion.Header>
															What should I do if I suspect unauthorized access to my account?
														</Accordion.Header>
														<Accordion.Body>
															Immediately change your password and contact Purple Swap support. We'll assist you in securing your account and investigating any unauthorized activity.
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="9" >
														<Accordion.Header>
															If I have further questions, what are my options?
														</Accordion.Header>
														<Accordion.Body>
															Should you have any additional questions or require assistance, you have two main options: our 24/7 live chat support and the customer support section under "Contact." Both services are dedicated to providing you with timely and helpful
														</Accordion.Body>
													</Accordion.Item>


												</Accordion>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				}
			</div>
			<Footer />
		</>
	);
}
export default Account;