import React, { useEffect, useMemo, useState, useCallback } from 'react';
// import Header from '../directives/Header';
import Footer from '../directives/Footer';
import { Container, Row, Col, Nav, Navbar, NavDropdown, Button, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import { FiChevronDown } from 'react-icons/fi';
import { getAllCoinsAction, getrateOfpairs, getRangesAction } from '../coreFiles/user.action'
import config from '../coreFiles/config';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
const Exchange = (data) => {

    const [listCoins, setListCoins] = useState([])
    const [listAllCoins, setListAllCoins] = useState([])
    const [upperDropdown, setupperDropdown] = useState({})
    const [lowerDropdown, setlowerDropdown] = useState({})
    const [upperModel, setupperModel] = useState(false)
    const [lowerModel, setlowerModel] = useState(false)
    const [upperValue, setupperValue] = useState(0.1)
    const [lowerValue, setlowerValue] = useState(0)
    const [upperMinMaxValue, setupperMinMaxValue] = useState({ min: 0, max: 0 })
    const [lowerMinMaxValue, setlowerMinMaxValue] = useState({ min: 0, max: 0 })

   
      

    useEffect(() => {
        getCoins()
        setupperValue(data.upperamount)
        setlowerValue(data.loweramount)
    }, [data])


    const getCoins = async () => {
        let res = await getAllCoinsAction({ api_key: config.APIkey })
        if (res && res.length > 0) {
            setListCoins(res)
            setListAllCoins(res)
            setupperDropdown(res.find(item => item.symbol == data.from))
            setlowerDropdown(res.find(item => item.symbol == data.to))
            getRanges(data.from, data.to)
        }
    }

    const selectHandle = async (e, type, symbol) => {
        if (type == "upper") {
            const checkrange = await getRanges(listAllCoins.find(item => item.symbol == symbol).symbol, lowerDropdown.symbol)
            if (checkrange == true) {
                setupperDropdown(listAllCoins.find(item => item.symbol == symbol))
        
                closeModel()
            }

        } else {
            const checkrange = await getRanges(upperDropdown.symbol, listAllCoins.find(item => item.symbol == symbol).symbol)
            if (checkrange == true) {
                setlowerDropdown(listAllCoins.find(item => item.symbol == symbol))
       
                closeModel()
            }
        }

        setTimeout(() => {
            data.handleChange(e,{upperDropdown:upperDropdown,lowerDropdown:lowerDropdown,upperValue:upperValue,lowerValue:lowerValue})   
        }, 200);

    }


    const getRanges = async (upperDropdownsymbol, lowerDropdownsymbol) => {
        try {
            let resupper = await getRangesAction({ api_key: config.APIkey, fixed: true, currency_from: upperDropdownsymbol ? upperDropdownsymbol : "btc", currency_to: lowerDropdownsymbol ? lowerDropdownsymbol : "eth" })
            let reslower = await getRangesAction({ api_key: config.APIkey, fixed: true, currency_from: lowerDropdownsymbol ? lowerDropdownsymbol : "eth", currency_to: upperDropdownsymbol ? upperDropdownsymbol : "btc" })
            if (resupper && resupper.min && resupper.max) {
                setupperMinMaxValue({ min: resupper.min, max: resupper.max })

            }

            if (reslower && reslower.min && reslower.max) {
                setlowerMinMaxValue({ min: reslower.min, max: reslower.max })

            }
            return true

        } catch (error) {
            return false
        }

    }



    const changeHandler = async (e) => {
        const { name, value } = e.target
        name == "upperValue" ? setupperValue(value) : setlowerValue(value)
       
     
        try {
            if (name == "upperValue" && parseFloat(value) > upperMinMaxValue.min && parseFloat(value) < upperMinMaxValue.max) {

                let rate = await getrateOfpairs({ api_key: config.APIkey, fixed: true, currency_from: upperDropdown.symbol ? upperDropdown.symbol : "btc", currency_to: lowerDropdown.symbol ? lowerDropdown.symbol : "eth", amount: value })
               
           
                setlowerValue(rate)

            } else if (parseFloat(value) > lowerMinMaxValue.min && parseFloat(value) < lowerMinMaxValue.max) {

                let rate = await getrateOfpairs({ api_key: config.APIkey, fixed: true, currency_from: lowerDropdown.symbol ? lowerDropdown.symbol : "eth", currency_to: upperDropdown.symbol ? upperDropdown.symbol : "btc", amount: value })
              
   
                setupperValue(rate)
            } else {
                name == "upperValue" ? setlowerValue(0) : setupperValue(0)

        
            }


            data.handleChange(e,{upperDropdown:upperDropdown,lowerDropdown:lowerDropdown,upperValue:upperValue,lowerValue:lowerValue})   
          


        } catch (error) {
            console.log('eerr', error)
        }


    }


    const closeModel = (e) => {
        if (upperModel == true) {
            setupperModel(false)
        }
        if (lowerModel == true) {
            setlowerModel(false)
        }

    }

    const openModel = (e, type) => {
        if (type == "upperModel" && upperModel == false) {
            setupperModel(true)
            setlowerModel(false)
            setListCoins(listAllCoins)
        }
        if (type == "lowerModel" && lowerModel == false) {
            setlowerModel(true)
            setupperModel(false)
            setListCoins(listAllCoins)
        }

    }


    const searchCoin = (e) => {
        const { name, value } = e.target
        setListCoins(listAllCoins.filter(object => object.symbol.toUpperCase().includes(value.toUpperCase()) || object.name.toUpperCase().includes(value.toUpperCase())))
    }



    return (
        <>

            {/* <Header /> */}
            {/* <ToastContainer /> */}


            <section className='exchange'>
                <div className='exchange_inner py-0'>
                    <Row>
                        <Col lg={12}>
                            <div  >
                                <div className='filed_box'>
                                    <Form>
                                        {/*------------------------------ Upper Model Content Start ----------------------------------------*/}
                                        <div className={upperModel == true ? 'filed d-none' : "filed d-block"}>
                                            <Row>
                                                <Col lg={9} md={9} className='px-1'>
                                                    <div className='field_block'>
                                                        <p>You Send</p>
                                                        <Form.Control
                                                            placeholder="0.1"
                                                            value={upperValue}
                                                            name="upperValue"
                                                            onChange={e => changeHandler(e)}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                        />
                                                    </div>
                                                    {parseFloat(upperMinMaxValue.min) > parseFloat(upperValue) ? <div className='red-alert'>Min Amount Required:{upperMinMaxValue.min}</div> : ""}
                                                    {parseFloat(upperMinMaxValue.max) < parseFloat(upperValue) ? <div className='red-alert'>Max Amount Required:{upperMinMaxValue.max}</div> : ""}
                                                </Col>
                                                <Col lg={3} md={3} className='p-0'>

                                                    <Button onClick={e => openModel(e, "upperModel")} className='select-btn'>
                                                        <div className='item-select'>
                                                            <div>
                                                                <img src={upperDropdown.image} alt="btc" className='updown-icon pe-2' />
                                                                <span>{upperDropdown?.symbol?.toUpperCase()}</span>
                                                            </div>
                                                            <img src="assets/icons/dwonarrow.svg" alt="dwonarrow" className='dwonarrow' />
                                                        </div>
                                                    </Button>

                                                </Col>

                                            </Row>
                                        </div>


                                        <div className={upperModel == true ? 'select-modal d-block' : "select-modal d-none"}>
                                            <Row>
                                                <Col lg={12} >
                                                    <div >

                                                        <div className='search_ticket'>
                                                            <img src="assets/icons/search.svg" alt="search" className='tiketicon' />
                                                            <input type='search' onChange={e => searchCoin(e)} placeholder='Type a cryptocurrency or ticket' />
                                                            <img src="assets/icons/close.svg" alt="close" className='tiketicon' onClick={e => closeModel(e)} />
                                                        </div>

                                                        <div className='table_popular'>
                                                            <h6>Popular cryptocurrency</h6>
                                                            <div className='table-responsive'>
                                                                <Table >
                                                                    <tbody style={{ overflow: "scroll", height: "200px" }}>
                                                                        {listCoins && listCoins.map(item => {
                                                                            return <tr className='active'>
                                                                                <td>
                                                                                    <div className='item-select' onClick={e => selectHandle(e, "upper", item.symbol)}>
                                                                                        <div onClick={e => selectHandle(e, "upper", item.symbol)}>
                                                                                            <img src={item.image} alt="btc" className=' pe-2' />11
                                                                                            <span>{item.symbol.toUpperCase()}</span>
                                                                                            <span className={item.network ? 'token-network' : ""}>{item.network}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div onClick={e => selectHandle(e, "upper", item.symbol)} className='text-end'>
                                                                                        {item.name}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        })}


                                                                        {/* <tr>
                                                                                <td>
                                                                                    <div className='item-select'>
                                                                                        <div>
                                                                                            <img src="assets/icons/eth2.svg" alt="eth2" className=' pe-2' />
                                                                                            <span>ETH</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='text-end'>
                                                                                        Ethereum
                                                                                    </div>
                                                                                </td>
                                                                            </tr> */}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                        {/*------------------------------ Upper Model Content End----------------------------------------*/}


                                        <Row className={(upperModel) == true ? 'filed d-none' : "filed d-block"}>
                                            <Col lg={12} md={12} className='px-1'>
                                                <div className='float-rate'>
                                                    <div className='floatbox'>
                                                        <img src="assets/icons/lock.svg" alt="lock" className='lock' />
                                                        <span>Floating Rate</span>
                                                    </div>
                                                    <div>
                                                        <img src="assets/icons/updown-icon.svg" alt="updown-icon" className='updown-icon' />
                                                    </div>
                                                </div>

                                                <div className='exchange_rate'>
                                                    <div className='ratebox'>
                                                        <div className='ratebox-inner'>
                                                            <img src="assets/icons/updown-icon.svg" alt="updown-icon" className='updown-icon' />
                                                            <span>Floating Exchange Rate</span>
                                                        </div>
                                                        <p>This amount you get may change due to market volatility. </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>


                                        {/*------------------------------ Lower Model Content Start ----------------------------------------*/}
                                        <div className={(upperModel || lowerModel) == true ? 'filed d-none' : "filed d-block"}>
                                            <Row>
                                                <Col lg={9} md={9} className='px-1'>
                                                    <div className='field_block'>
                                                        <p>You  Get</p>
                                                        <Form.Control
                                                            placeholder="= 1.82752907"
                                                            aria-label="Username"
                                                            name="lowerValue"
                                                            value={lowerValue}
                                                            onChange={e => changeHandler(e)}
                                                            aria-describedby="basic-addon1"
                                                        />
                                                    </div>
                                                    {parseFloat(lowerMinMaxValue.min) > parseFloat(lowerValue) ? <div className='red-alert'>Min Amount Required:{lowerMinMaxValue.min}</div> : ""}
                                                    {parseFloat(lowerMinMaxValue.max) < parseFloat(lowerValue) ? <div className='red-alert'>Max Amount Required:{lowerMinMaxValue.max}</div> : ""}
                                                </Col>
                                                <Col lg={3} md={3} className='p-0'>

                                                    <Button onClick={e => openModel(e, "lowerModel")} className='select-btn'>
                                                        <div className='item-select'>
                                                            <div>
                                                                <img src={lowerDropdown.image} alt="btc" className='updown-icon pe-2' />
                                                                <span>{lowerDropdown?.symbol?.toUpperCase()}</span>
                                                            </div>
                                                            <img src="assets/icons/dwonarrow.svg" alt="dwonarrow" className='dwonarrow' />
                                                        </div>
                                                    </Button>
                                                </Col>

                                            </Row>
                                        </div>


                                        <div className={lowerModel == true ? 'select-modal d-block' : "select-modal d-none"}>
                                            <Row >
                                                <Col lg={12}>
                                                    <div >

                                                        <div className='search_ticket'>
                                                            <img src="assets/icons/search.svg" alt="search" className='tiketicon' />
                                                            <input type='search' onChange={e => searchCoin(e)} placeholder='Type a cryptocurrency or ticket' />
                                                            <img src="assets/icons/close.svg" alt="close" className='tiketicon' onClick={e => closeModel(e)} />
                                                        </div>

                                                        <div className='table_popular'>
                                                            <h6>Popular cryptocurrency</h6>
                                                            <div className='table-responsive'>
                                                                <Table >

                                                                    <tbody>
                                                                        {listCoins && listCoins.map(item => {
                                                                            return <tr className='active'>
                                                                                <td>
                                                                                    <div onClick={e => selectHandle(e, "lower", item.symbol)} className='item-select'>
                                                                                        <div onClick={e => selectHandle(e, "lower", item.symbol)}>
                                                                                            <img src={item.image} alt="btc" className=' pe-2' />
                                                                                            <span>{item.symbol.toUpperCase()}</span>
                                                                                            <span className={item.network ? 'token-network' : ""}>{item.network}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div onClick={e => selectHandle(e, "lower", item.symbol)} className='text-end'>
                                                                                        {item.name}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        })}


                                                                        {/* <tr>
                                                                                <td>
                                                                                    <div className='item-select'>
                                                                                        <div>
                                                                                            <img src="assets/icons/eth2.svg" alt="eth2" className=' pe-2' />
                                                                                            <span>ETH</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='text-end'>
                                                                                        Ethereum
                                                                                    </div>
                                                                                </td>
                                                                            </tr> */}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </Col>

                                            </Row>
                                        </div>

                                        {/*------------------------------ Lower Model Content End ----------------------------------------*/}


                                        {/* <Row className={upperModel || lowerModel == true ? 'filed d-none' : "filed d-block"}>
                                            <Col lg={12} md={12} className='mx-auto px-1 pt-5'>
                                                <Button variant="primary" className='custombtn w-100'>exchange</Button>{' '}
                                            </Col>
                                        </Row> */}

                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </div>

            </section>





        </>
    )
}

export default Exchange;
