
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./slider.css"; 
import "../component/componentCss/slider.css";

const Iconslider = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    dots: true,
    arrows: true,
  };

  const shouldShowSlider = windowWidth <= 992;

  return (
    <div className="main_iconslider">
      <div className="parent">
        {shouldShowSlider ? (
          <Slider className="slick-slider1 slider1" {...sliderSettings}>
            {/* Slide 1 */}
            <div className="pt-3 col-lg-4 col-md-6 slick-slide slick-active">
              <div className="quickbox purple">
                <div className="iconblock">
                  <img
                    src="images/star.png"
                    alt="staricon"
                    className="iconblockimg"
                  />
                </div>
                <div className="quicktext">
                  <h5>No hidden Fees </h5>
                </div>
              </div>
            </div>
            {/* Slide 2 */}
            <div className="pt-3 col-lg-4 col-md-6">
              <div className="quickbox blue">
                <div className="iconblock">
                  <img
                    src="images/infinity.png"
                    alt="infinity"
                    className="iconblockimg"
                  />
                </div>
                <div className="quicktext">
                  <h5>Limitless exchange </h5>
                </div>
              </div>
            </div>
            {/* Slide 3 */}
            <div className="pt-3 col-lg-4 col-md-6">
              <div className="quickbox lightblue">
                <div className="iconblock">
                  <img
                    src="images/chat.png"
                    alt="chaticon"
                    className="iconblockimg"
                  />
                </div>
                <div className="quicktext">
                  <h5>24/7 support </h5>
                </div>
              </div>
            </div>
          </Slider>
        ) : (
          <>
            {/* Block 1 */}
            <div className="slider-flex">
              <div className="pt-3 col-lg-4 col-md-6">
                <div className="quickbox purple">
                  <div className="iconblock">
                    <img
                      src="images/star.png"
                      alt="staricon"
                      className="iconblockimg"
                    />
                  </div>
                  <div className="quicktext">
                    <h5>No hidden Fees </h5>
                  </div>
                </div>
              </div>
              {/* Block 2 */}
              <div className="pt-3 col-lg-4 col-md-6">
                <div className="quickbox blue">
                  <div className="iconblock">
                    <img
                      src="images/infinity.png"
                      alt="infinity"
                      className="iconblockimg"
                    />
                  </div>
                  <div className="quicktext">
                    <h5>Limitless Exchange </h5>
                  </div>
                </div>
              </div>
              {/* Block 3 */}
              <div className="pt-3 col-lg-4 col-md-6">
                <div className="quickbox lightblue">
                  <div className="iconblock">
                    <img
                      src="images/chat.png"
                      alt="chaticon"
                      className="iconblockimg"
                    />
                  </div>
                  <div className="quicktext">
                    <h5>24/7 Support </h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Iconslider;
