export const PendingBox = ({ type }) => {
    switch (type) {
        case "new":
            return <div className='deposit_bottom_block'>
                <img src="assets/icons/pending.svg" alt='' className='mb-3 depositeimg' />
                <p>Pending Deposit</p>
            </div>;
        case "waiting":
            return <div className='deposit_bottom_block active'>
                <img src="assets/icons/pending.svg" alt='' className='mb-3 depositeimg' />
                <p>Pending Deposit</p>
            </div>;
        case "confirming":
            return <div className='deposit_bottom_block checkblock'>
                <img src="assets/icons/depositcheck.svg" alt='' className='mb-3 depositeimg' />
                <p>Deposited</p>
            </div>;
        default:
            return <div className='deposit_bottom_block'>
                <img src="assets/icons/pending.svg" alt='' className='mb-3 depositeimg' />
                <p>Pending Deposit</p>
            </div>;
    }
}

export const ConfirmingBox = ({ type }) => {
    switch (type) {
        case "confirming":
            return <div className='deposit_bottom_block active'>
                <img src="assets/icons/confirming.svg" alt='' className='mb-3 depositeimg' />
                <p>Confirming</p>
            </div>;
        case "exchanging":
            return <div className='deposit_bottom_block checkblock'>
                <img src="assets/icons/depositcheck.svg" alt='' className='mb-3 depositeimg' />
                <p>Confirming</p>
            </div>;
        default:
            return <div className='deposit_bottom_block'>
                <img src="assets/icons/confirming.svg" alt='' className='mb-3 depositeimg' />
                <p>Confirming</p>
            </div>;
    }
}

export const ExchangingBox = ({ type }) => {
    switch (type) {
        case "exchanging":
            return <div className='deposit_bottom_block active'>
                <img src="assets/icons/exchanging.svg" alt='' className='mb-3 depositeimg' />
                <p>Exchanging</p>
            </div>;
        case "sending":
            return <div className='deposit_bottom_block checkblock'>
                <img src="assets/icons/depositcheck.svg" alt='' className='mb-3 depositeimg' />
                <p>Exchanging</p>
            </div>;
        default:
            return <div className='deposit_bottom_block'>
                <img src="assets/icons/exchanging.svg" alt='' className='mb-3 depositeimg' />
                <p>Exchanging</p>
            </div>;
    }
}

export const SendingBox = ({ type }) => {
    switch (type) {
        case "sending":
            return <div className='deposit_bottom_block active'>
                <img src="assets/icons/sending.svg" alt='' className='mb-3 depositeimg' />
                <p>Sending</p>
            </div>;
        case "finished":
            return <div className='deposit_bottom_block checkblock'>
                <img src="assets/icons/depositcheck.svg" alt='' className='mb-3 depositeimg' />
                <p>Sending</p>
            </div>;
        default:
            return <div className='deposit_bottom_block'>
                <img src="assets/icons/sending.svg" alt='' className='mb-3 depositeimg' />
                <p>Sending</p>
            </div>;
    }
}


