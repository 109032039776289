import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import Header from "../directives/Header";
import { useParams } from "react-router-dom";
import Footer from "../directives/Footer";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Form,
  InputGroup,
  Modal,
  Table,
  Accordion,
  Tab,
  Card,
  Toast,
} from "react-bootstrap";
import "./componentCss/swapmanagement.css";
import "./componentCss/support.css";
import {
  NoteMessage,
  ErrorMessage,
  SuccessMessageSupport,
} from "../component/swap/ToastAlert";
import toast, { Toaster } from "react-hot-toast";
import smoothscroll from "smoothscroll-polyfill";
import config from "../coreFiles/config";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter, FaWhatsapp } from "react-icons/fa6";
import { BiLogoTelegram } from "react-icons/bi";
import { getLiveChatStatusAction, insertContactDataAction } from "../coreFiles/user.action";
import { ThreeDotLoader } from "./swap/Icons";
import { useNavigate } from "react-router-dom";

smoothscroll.polyfill();

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const Support = () => {
  // Purple Swap Highlights
  const accordionData = [
    {
      eventKey: "0",
      title: "Swiss Privacy",
      body: " Based in Switzerland, Purple Swap benefits from some of the strictest privacy laws globally. This location ensures that your crypto transactions are not only secure but also benefit from neutrality and stability. Trust in a platform where privacy is paramount and your crypto assets are exchanged in a safe and legally protected environment.",
    },
    {
      eventKey: "1",
      title: "No KYC",
      body: "Enjoy total anonymity with Purple Swap. No KYC required means you can swap crypto freely without divulging personal information. We prioritize your privacy, allowing for anonymous transactions fully protected under Swiss laws, making every swap secure and confidential.",
    },

    {
      eventKey: "2",
      title: "No Sign-Up",
      body: "Dive straight into swapping without the hassle of sign-up processes. Purple Swap offers immediate access to cryptocurrency exchange, streamlining your experience to be as efficient and straightforward as possible. Enjoy the simplicity of instant exchanges without any registration delays.",
    },
    {
      eventKey: "3",
      title: "Best Rate",
      body: "Experience competitive and dynamic pricing with every swap. Purple Swap uses advanced algorithms to ensure you always get the best market rates for your crypto exchanges. Whether you opt for fixed or floating rates, we strive to offer the most favorable exchange rates available.",
    },
    {
      eventKey: "4",
      title: " Express Swap",
      body: "Time is of the essence in cryptocurrency trading, and Purple Swap’s high-speed engine is designed to execute your swaps swiftly. Enjoy quick and efficient exchanges among over 900+ different cryptocurrencies, ensuring that your trades are completed in a timely manner",
    },
    {
      eventKey: "5",
      title: " 24/7 Support",
      body: "We understand the importance of support in your trading journey. Our dedicated team is available 24/7 to assist you with any questions or issues. Whether it’s a query about a transaction or guidance on using our platform, our support team is always ready to ensure a smooth and hassle-free swapping experience",
    },
  ];

  // About Purple Swap-----------------------------
  const accordionData1 = [
    {
      eventKey: "0",
      title: " What is Purple Swap?",
      body: "<p>Purple Swap is an advanced and user-friendly platform for instant cryptocurrency exchanges. Crafted for simplicity and effectiveness, Purple Swap allows users to exchange more than 800+ different cryptocurrencies effortlessly, with no need for registration and free from any transaction limits. Our commitment to transparency ensures that all exchanges are free from hidden fees.</p>   <p> Accessible both as a streamlined website and as a mobile app for iOS and Android, Purple Swap provides versatile trading solutions wherever you are.With options for both floating and fixed rate exchanges, you can select the approach that best aligns with your trading needs.</p> <p>Security is paramount at Purple Swap. We adhere to strict Swiss privacy and security laws, providing you with a secure and trustworthy environment for your cryptocurrency exchanges.</p>     <p>Our 24/7 support team is always on hand to offer prompt and professional assistance, ensuring a seamless and secure trading experience with Purple Swap.</p>",
    },

    {
      eventKey: "1",
      title: " Why should I trust Purple Swap?",
      body: " <p>Purple Swap, founded by experienced professionals in fintech and blockchain, offers a secure and user-focused experience. Our platform has been a reliable player in the market for years and is built on two core principles: absolute privacy and maximum convenience. We do not require KYC (Know Your Customer) procedures, ensuring your transactions remain anonymous. Additionally, users are not required to create accounts or store deposits, greatly reducing the risk of financial theft and maintaining privacy.</p> <p>We always provide the best exchange rates by comparing a wide range of reputable trading platforms. Our commitment to adhering to stringent Swiss legal standards for data protection and transaction security makes us a trustworthy choice for cryptocurrency exchanges. Plus, our 24/7 customer support team is always available to assist with any issues or concerns, especially for ongoing swap transactions, ensuring a seamless and supportive trading experience at all times.</p>",
    },
    {
      eventKey: "2",
      title: "What makes Purple Swap different from other?",
      body: "  <p>Purple Swap stands out for its exceptional information security, high-performing APIs that ensure the best exchange rates, and a unique Swap Management section. This feature allows customers to delete all traces of their swaps, offering unparalleled privacy and control.</p>",
    },
    {
      eventKey: "3",
      title:
        "What security measures does Purple Swap implement to protect user transactions?",
      body: "<p>Our servers are located in secure Swiss bunkers, with redundant servers and multiple internet connections to ensure continuous operation. Every transaction is monitored in real time, with immediate alerts to our team in case of any issues. In the event of a swap amount error or other issues during a transaction, our system will automatically refund to the provided return wallet address or await your instructions for preferred reimbursement methods.</p>",
    },
    {
      eventKey: "4",
      title: "Can I use Purple Swap in my country?",
      body: "<p>Purple Swap is globally accessible and available in countries across the world, making it easy for everyone to use our services.</p>",
    },
    {
      eventKey: "5",
      title: " How does Purple Swap ensure the best exchange rates?",
      body: " <p>We have developed a robust API that queries major exchanges within fractions of a second to determine the best possible rate for your swap. This ensures that you always get the most favorable exchange rate available.</p>",
    },
    {
      eventKey: "6",
      title: "What currencies can I trade on Purple Swap?",
      body: "<p>Purple Swap currently offers over 800 types of cryptocurrencies, with new ones being added almost daily by our programmers. We strive to offer our clients all potentially valuable cryptocurrencies as quickly as possible after their release.</p>",
    },
    {
      eventKey: "7",
      title: "Are there any fees for using Purple Swap?",
      body: " Purple Swap charges a nominal fee of 0.1% per transaction. Additionally, users should consider blockchain transfer fees. There are no hidden fees with us; the amount displayed before creating your swap is exactly what you'll receive. We believe in complete transparency with no hidden costs.",
    },
    {
      eventKey: "8",
      title:
        "How can I stay updated with Purple Swap's latest features and updates?",
      body: "   <p>Stay connected with us on Twitter, where we post major updates, market analyses, and announcements of new cryptocurrencies listed. We also have a private Telegram group where you can reach our team at any time.</p>",
    },
    {
      eventKey: "9",
      title: "What is Purple Swap's vision for the future?",
      body: "<p>We have grand ambitions for Purple Swap in the coming months and years, with several innovative developments that will delight our clients! We invite you to follow us on Twitter to stay updated on our future developments and join us on this exciting journey.</p>",
    },
  ];
  // Cryptocurrency Transactions-----------------------
  const accordionData2 = [
    {
      eventKey: "0",
      title: "What is a cryptocurrency wallet address?",
      body: "<p>A cryptocurrency wallet address is a unique identifier, typically a string of numbers and letters, ranging from 26 to 35 characters. It functions similarly to a bank account number, allowing you to receive and send cryptocurrencies. An example address might look like this: '17bkZPLB4Wn6F347PLZBR34ijhzQDUFZ4ZC.'</p> <p>Your wallet also includes a private key, which is like a password and should be kept secure at all times. It's essential never to share your private key with anyone, as it gives access to your wallet. Remember, legitimate services will never ask for your private key.</p>   <p>In essence, the wallet address (or public key) is what you share with others to receive crypto, while the private key is what you use to access and manage your funds. Keeping your private key secure is crucial for the safety of your assets. </p>",
    },
    {
      eventKey: "1",
      title: "How do I get a cryptocurrency wallet address?",
      body: "<p>To obtain a cryptocurrency wallet address, first decide which cryptocurrency you want to use. Then, visit the official website of that cryptocurrency to find recommended wallet options. Most cryptocurrencies have an official wallet, or they will list trusted wallets you can use.</p> <p>When you set up a wallet, you will automatically receive a wallet address and a private key. Remember, your private key is akin to a password and should be kept confidential at all times. It's crucial for your security never to share your private key with anyone.</p> <p>For added safety, always choose a reputable wallet service to prevent the loss of your coins. At Purple Swap, we prioritize your security and will never ask for your private keys, and you should be cautious of anyone who does.</p>",
    },
    {
      eventKey: "2",
      title: "What is the recipient’s address?",
      body: " <p>The recipient's address refers to the wallet address where the cryptocurrency will be sent following an exchange. For example, if you're exchanging Bitcoin for Ethereum, the recipient's address is the Ethereum wallet address where you want to receive your ETH. Each type of cryptocurrency has its own specific address format. It's crucial to ensure that you enter the correct recipient address for the specific cryptocurrency you're receiving to avoid any errors in the transaction.</p> <p>Always double-check that the address you're using matches the type of coin you expect to receive. Using an incorrect or incompatible wallet address can result in the loss of your cryptocurrency.</p>",
    },
    {
      eventKey: "3",
      title: "What is a transaction hash?",
      body: "<p>A transaction hash, also known as a 'TX' or 'tx ID,' is a unique identifier for every transaction made on the blockchain. When a transaction is initiated, this unique ID is generated and recorded in the blockchain. You can use this hash to track and verify the details and status of your transaction on the network.</p>  <p>The transaction hash serves as a proof of the transaction's existence and its current state (whether it's pending, completed, or failed). It's a crucial tool for ensuring transparency and security in all blockchain transactions. You can usually find the transaction hash or tx ID displayed in your wallet or on the transaction details page.</p>",
    },

    {
      eventKey: "4",
      title: "What are network fees?",
      body: " <p>Network fees, also known as transaction fees, are charges paid to miners or validators on a blockchain network. These fees compensate for the computational energy required to process and validate transactions on the blockchain. When you make a cryptocurrency transaction, a network fee is necessary to ensure that your transaction is included in a block and confirmed by the network.</p>  <p>The amount of the network fee can vary based on several factors, including the congestion of the network and the size of your transaction. Setting a sufficient network fee is important for the timely and successful completion of your transaction. If the fee is too low, your transaction may take longer to process or could even be rejected by the network. It's always advisable to check the current recommended fees or use the fee estimations provided by your wallet or exchange service.</p > ",
    },
  ];
  // Swap information----------------------------------
  const accordionData3 = [
    {
      eventKey: "0",
      title: "How does Purple Swap work?",
      body: "<p>Purple Swap operates by integrating with top-tier cryptocurrency trading platforms, including renowned exchanges like Binance, Coinbase, and Kraken. Our system is designed for instant coin exchanges without the need for deposit storage.At the moment of each trade, Purple Swap's advanced algorithms scan multiple platforms to secure the best possible exchange rate for our customers, ensuring a quick, profitable, and seamless trading experience. This approach not only maximizes efficiency but also upholds our commitment to providing secure and transparent transactions, backed by the robust privacy standards of Swiss law.</p>",
    },
    {
      eventKey: "1",
      title: "What is Swap ID?",
      body: "<p>An Swap ID is a unique identifier assigned to your specific exchange transaction, consisting of alphanumeric characters. This ID is essential as it allows you to track all the details and progress of your swap. It contains vital information such as the amount exchanged, the date of the transaction, and the status of the swap. When you need assistance from our support team, providing your Swap ID will greatly expedite the process of locating and addressing your transaction in our system. It's a key tool for managing and reviewing your exchanges on Purple Swap.</p>",
    },
    {
      eventKey: "2",
      title: "Where to get my Swap ID?",
      body: "   <p>Once you initiate an exchange by clicking the “Create an Exchange” button, a Swap ID will be generated for your transaction. This ID will be displayed on the exchange confirmation page. </p><p>Additionally, you can find your Swap ID in the browser’s address bar. For instance, in a URL like https://purpleswap.io/exchange/txs/n9d9c481149ac0d6r, the Swap ID is n9d9c481149ac0d6r. </p><p>It's important to save your Swap ID or the link to your exchange. This enables you to easily return to and review your exchange details at any time. You can also use this Swap ID to track the progress of your swap, often found in the footer of our website or in the Swap Management section. Remember, the Swap ID is your key to accessing all information related to a specific transaction on Purple Swap.</p>",
    },
    {
      eventKey: "3",
      title: "What is a floating rate exchange?",
      body: " <p>A floating rate exchange is a type of cryptocurrency exchange where the final amount you receive is subject to market fluctuations. When you initiate a swap, the estimated amount you'll receive is provided, but this can vary by the time the transaction is completed. This variation is due to the inherently volatile nature of cryptocurrencies.</p><p>Floating rate exchanges offer flexibility, as there are no strict limits on the amount of cryptocurrency you can swap. Additionally, these exchanges don't impose stringent time constraints for sending your deposit. This makes floating rate exchanges ideal for those who prefer trading with the potential for market-driven gains, while also being aware of the possibility of receiving a slightly different amount than initially estimated.</p>",
    },
    {
      eventKey: "4",
      title: "What is a fixed rate exchange?",
      body: " <p>A fixed rate exchange in cryptocurrency is an exchange where you receive the exact amount estimated at the beginning of the transaction, irrespective of any market fluctuations that occur afterward. This type of exchange offers stability and predictability, as the final amount is not affected by changes in coin rates during the transaction process.</p><p>When you opt for a fixed rate exchange on Purple Swap, you are given a 20-minute window to send your deposit. This time limit is in place because the coin rates can change rapidly, and maintaining a fixed rate involves risk management on our part. However, within these 20 minutes, we guarantee the stability of the amount you will receive, safeguarding you against market volatility during that period.</p><p>Fixed rate exchanges can sometimes offer lower rates compared to floating rate exchanges, but the opposite can also be true. The key advantage of a fixed rate exchange is the assurance that the amount you expect to receive will not change during the course of the transaction.</p>",
    },
    {
      eventKey: "5",
      title: "When should I choose a fixed rate exchange?",
      body: " <p>You should opt for a fixed rate exchange when you prefer certainty and stability in your transactions. If you want to avoid the risks associated with rapid changes in cryptocurrency rates, a fixed rate exchange is ideal. It ensures that the amount of crypto you will receive remains unchanged, regardless of market fluctuations.</p><p>Fixed rate exchanges are particularly suitable if you are concerned about sudden rate drops or prefer to know the exact amount you'll receive in advance. Additionally, Purple Swap has optimized the speed of fixed rate exchanges, meaning that from the moment we receive your deposit to when the coins are sent to you, the process is typically quite fast. This efficiency, combined with the rate stability, makes fixed rate exchanges an attractive choice for those who value both speed and predictability in their cryptocurrency transactions.</p>",
    },
    {
      eventKey: "6",
      title:
        "What are the differences between a fixed rate exchange and a floating rate exchange?",
      body: " <p>The primary difference between a fixed rate exchange and a floating rate exchange lies in the stability and predictability of the exchange rate.</p><p><strong>Floating Rate Exchange:</strong> In a floating rate exchange, the rate is subject to change during the transaction process due to market fluctuations. This means the amount of cryptocurrency you receive can vary; you might receive more or less than initially expected. Floating rate exchanges offer more flexibility as there are no restrictions or upper limits, but they also come with the unpredictability of market movements.</p><p><strong>Fixed Rate Exchange:</strong> Conversely, a fixed rate exchange guarantees the amount you will receive, which is displayed at the start of the transaction. These exchanges are insulated from rate fluctuations and market changes. However, due to the risks taken by the exchange to maintain this fixed rate, especially against cryptocurrency volatility, fixed rate exchanges often have upper limits on the amount that can be transacted. This risk management ensures your safety from exchange rate fluctuations. The rate you see at the beginning of the exchange is what you get, with no additional fees.</p><p>In summary, if you prefer certainty and want to know exactly how much you will receive, a fixed rate exchange is the better option. If you are willing to take a risk for potentially better rates and have no constraints on the amount, a floating rate exchange might suit you better.</p>",
    },
    {
      eventKey: "7",
      title:
        "Is a network fee included in the final amount at a fixed rate exchange?",
      body: " <p>At Purple Swap, while we strive to provide a fixed rate exchange that is as accurate as possible, predicting the exact network fee for a specific transaction in advance can be challenging due to the variable nature of blockchain fees. Consequently, the network fee is not typically included in the displayed final amount at the start of a fixed rate exchange.</p><p>However, it's important to note that network fees for most coin transactions are generally minimal, and therefore the difference between the displayed amount and the final amount received, after the deduction of the network fee, is usually negligible. This means that the amount you receive will be very close to, if not exactly, what was initially shown at the beginning of the exchange process. We always aim to be transparent with our users about such nuances in our transactions.</p>",
    },
    {
      eventKey: "8",
      title: "What is a classic rate cryptocurrency exchange on Purple Swap?",
      body: " <p>A classic rate cryptocurrency exchange on Purple Swap refers to executing an exchange at the most favorable current rate available in the market. When you initiate a classic rate exchange, the rate you see is the best available rate at that moment. However, due to the inherent volatility of the cryptocurrency market, this rate can fluctuate rapidly.</p><p>In a classic rate exchange, each transaction is subject to its own unique exchange rate. This rate is influenced by several factors, including market conditions and network fees. As the cryptocurrency market is dynamic and prices can shift quickly, the actual rate at the time of transaction completion may be different from the initial rate displayed. Consequently, the final amount of cryptocurrency you receive could be more or less than initially estimated.</p><p>It's important for users to understand this aspect of a classic rate exchange and to be prepared for the potential variance in the final amount received due to these market-driven rate changes.</p></p>",
    },
  ];

  // Transaction detail----------------------------------
  const accordionData4 = [
    {
      eventKey: "0",
      title: "How long does it take to exchange coins?",
      body: "<p>The time it takes to complete a coin exchange on Purple Swap typically ranges from 2 to 15 minutes. However, there are instances where it may take longer due to various factors:</p><p><strong>Network Congestion:</strong> If the blockchain is heavily loaded with transactions, yours might need to wait its turn to be included in a block. During times of high traffic, delays are more common.</p><p><strong>DDoS Attacks:</strong> While rare, DDoS attacks can occur, potentially slowing down transaction times. Our team is always on standby to address and resolve such issues promptly.</p><p><strong>Cryptocurrency Updates:</strong> Sometimes, we may need to temporarily disable certain coins for updates. Rest assured, as soon as the updates are complete and the coins are reactivated, your transaction will proceed.</p><p>Most transactions on Purple Swap are processed quickly, often within just a few minutes. We make every effort to ensure a swift and efficient exchange experience, but please be aware that external factors like network congestion can affect transaction times. Our support team is available to assist you if your transaction is taking longer than expected.</p>",
    },
    {
      eventKey: "1",
      title: "Why does my transaction take so long?",
      body: "<p>While most cryptocurrency exchanges on Purple Swap are completed within 5 to 60 minutes, there are occasions when a transaction may take longer. Several factors can contribute to this delay:</p><p><strong>Blockchain Overload:</strong> High traffic on the blockchain can lead to slower transaction times. When the network is overloaded with transactions, each one, including yours, must wait for its turn to be processed.</p><p><strong>Coin Updates:</strong> We periodically update different coins on our platform. During these updates, transactions involving the updated coins may be temporarily delayed until the updates are completed.</p><p><strong>Confirmation Requirements:</strong> Certain transactions require a specific number of confirmations on the blockchain before they can be completed. The number of required confirmations varies depending on the coin and the size of the transaction. Larger transactions typically require more confirmations, which can increase processing time.</p><p>We understand that delays can be frustrating and appreciate your patience during these times. If your transaction is taking significantly longer than expected, please don't hesitate to contact our support team for assistance. They are always ready to help and can provide more detailed information about the status of your transaction.</p>",
    },
    {
      eventKey: "2",
      title:
        "What are the different statuses for a crypto-to-crypto exchange on Purple Swap?",
      body: "   <p>There are five key statuses you'll encounter during a crypto-to-crypto exchange:</p><p><strong>Waiting:</strong> This status means that you should send your coins to the indicated address. It indicates that the exchange is ready to start once we receive your coins.</p><p><strong>Confirming:</strong> This status appears while your transaction is gaining the required number of confirmations in the coin’s network. It's a crucial step for validating your transaction.</p><p><strong>Exchanging:</strong> Once your coins are confirmed, the exchange process begins, and this status is displayed. Your initial cryptocurrency is now being converted to the target cryptocurrency.</p><p><strong>Sending:</strong> This status shows that the exchanged cryptocurrency is being sent to your wallet. It signifies that the process of transferring the new coins to your specified receiving address is underway.</p><p><strong>Completed:</strong> The final status, indicating that the exchange has been successfully completed. Your exchanged cryptocurrency has been transferred and should now be in your wallet.</p><p>These statuses provide a comprehensive overview of each stage in your exchange, ensuring a transparent and informed trading experience on Purple Swap.</p>",
    },
    {
      eventKey: "3",
      title:
        "Why is the final amount of coins different from the estimated one?",
      body: " <p>The final amount of coins in a transaction can differ from the estimated amount primarily due to the volatile nature of cryptocurrencies. This is particularly true for floating rate exchanges.</p><p>In a floating rate exchange, the rate at the moment of transaction confirmation may vary slightly from the initially estimated rate. This variation is because cryptocurrency rates can fluctuate significantly within short periods. As a result, the rate at the exact time of your exchange could be higher or lower than the estimated rate, affecting the final amount of coins you receive.</p><p>If you prefer stability and certainty in the amount of cryptocurrency you will receive, you can opt for a fixed rate exchange. With a fixed rate, the amount estimated at the beginning of the transaction is the exact amount you will receive, regardless of rate fluctuations during the transaction process.</p><p>At Purple Swap, we always aim to secure the best available rate at the moment of each exchange operation, whether you choose a floating or fixed rate.</p>",
    },
    {
      eventKey: "4",
      title: "How can I cancel my exchange?",
      body: " <p>If you've initiated an exchange but decide not to proceed before sending your coins to the specified address (when the status is “Waiting”), there's no further action required from your side. In this case, you can simply leave the transaction as it is; it won’t proceed, and there’s no need to formally cancel it.</p><p>However, it’s important to note that once you’ve sent your coins to the indicated address, the exchange process becomes irreversible. At this point, it is not possible to cancel the exchange. Due to the nature of blockchain transactions, once they are initiated and coins are sent, they cannot be reversed. This highlights the importance of being certain about your decision before sending your coins for an exchange.</p>",
    },
    {
      eventKey: "5",
      title: "What is the minimum amount for an exchange on Purple Swap?",
      body: " <p>At Purple Swap, while we do not impose strict upper limits on exchanges, there are minimum amounts that vary for each cryptocurrency. These minimums are necessary to ensure that your transaction can cover all the network fees charged by the blockchain. The exact minimum amount depends on the specific cryptocurrency and can range from a small sum equivalent to approximately $1.7 to about $20.</p><p>We continually strive to lower these minimum amounts for all cryptocurrencies available on our service, making exchanges accessible for smaller amounts. However, it's important to note that if a deposit is less than the minimum required amount for an exchange, we may not be able to complete the exchange. In such cases, the ability to guarantee a refund can also be limited.</p><p>We recommend users to always check the minimum required amount for their chosen cryptocurrency before initiating an exchange to ensure a smooth and successful transaction.</p>",
    },
    {
      eventKey: "6",
      title: "What is the maximum amount for an exchange on Purple Swap?",
      body: " <p>At Purple Swap, we offer great flexibility with our floating rate exchange option, which does not have any maximum limits. This means you can exchange as much cryptocurrency as you desire. We understand the varied needs of our users, from small to very large transactions, and our platform is designed to accommodate a wide range of exchange volumes. Whether you're looking to swap a modest amount or conduct a large-scale exchange, Purple Swap is equipped to handle your needs with ease and efficiency.</p>",
    },
    {
      eventKey: "7",
      title: "Is there a KYC procedure on Purple Swap?",
      body: " <p>At Purple Swap, we prioritize user privacy and anonymity in all transactions. Consistent with our commitment to these principles and Swiss law, we do not require a Know Your Customer (KYC) procedure. This approach ensures that our users can conduct their transactions without the need to reveal their personal information.</p><p>Our platform's design upholds the highest standards of privacy, allowing for completely anonymous and secure exchanges. This means that you won't be asked to provide personal details, legal documents, or any form of identification to use our services. Our stance aligns with the stringent privacy laws of Switzerland, offering an additional layer of security and confidence to our users.</p><p>Should you have any concerns or questions about our privacy policies or transaction procedures, our support team is available to assist you. We're committed to providing a secure and user-friendly platform for all your cryptocurrency exchange needs.</p>",
    },
    {
      eventKey: "8",
      title: "Do I need to register to use Purple Swap?",
      body: " <p>At Purple Swap, we have designed our service for maximum ease of use and privacy. Our users can start exchanging cryptocurrencies immediately without the need for registration or account creation, ensuring a quick and private trading experience.</p><p>While we are committed to user anonymity, in line with Swiss privacy laws, we also prioritize the security and integrity of our platform. In the rare event that a transaction is flagged as suspicious by our automated systems, we take a cautious approach. Such transactions may be cancelled, and in these cases, funds are immediately returned to the originating address of the client.</p><p>This policy is in place to maintain a secure trading environment for all users and to comply with regulatory requirements. However, these instances are very rare and do not affect the majority of our transactions. Our aim is always to provide a straightforward, secure, and private trading experience for our valued users.</p>",
    },
    {
      eventKey: "9",
      title:
        "What happens if I deposit an unsupported coin or token on Purple Swap?",
      body: " <p>At Purple Swap, we support a wide range of cryptocurrencies, but it’s crucial to ensure that the coin or token you are sending is supported on our platform. If you accidentally deposit a coin or token that is not supported by Purple Swap (you can check the full list of our supported assets on our website), we may not be able to process the exchange or guarantee a refund for such transactions.</p><p>To safeguard your funds, we strongly recommend verifying the compatibility of your coin or token with our platform before initiating any exchange. This precaution helps prevent any potential loss of your assets due to unsupported transactions. Remember, always double-check to ensure a smooth and successful exchange experience.</p>",
    },
    {
      eventKey: "10",
      title: "How to cancel a cryptocurrency transaction?",
      body: " <p>Cryptocurrency transactions on the blockchain are irreversible. Once a transaction is confirmed and included in the blockchain, it cannot be canceled by anyone. This irreversible nature emphasizes the importance of double-checking all details before initiating a transaction.</p><p>Before confirming any exchange or transfer, it is crucial to carefully review the recipient's address, the amount being sent, and the currency type. Accuracy in these details is vital as there is no way to reverse a transaction once it has been executed on the blockchain. Remember, careful verification is your best safeguard against unintended transactions.</p>",
    },
  ];
  // Swap Management----------------------------------

  const accordionData5 = [
    {
      eventKey: "0",
      title: "How can I retrieve the details of a past swap?",
      body: '<p>To access information about a past swap, simply enter the unique swap ID that was displayed during your transaction in the "Swap Status" section. Once you input the swap ID, the details of your swap will be displayed.</p>',
    },
    {
      eventKey: "1",
      title: "What does it mean if my Swap ID is not found in the system?",
      body: "<p>If your Swap ID is not found, it could mean either there was a typo in the swap ID you entered, or the swap has been deleted from our database at your request. Please check the accuracy of your swap ID and try again.</p>",
    },
    {
      eventKey: "2",
      title: "How can I permanently erase all traces of a completed swap?",
      body: '<p>To completely erase a swap, go to the "Delete Record" submenu. Enter the unique swap ID of the swap you wish to erase, followed by the six-digit captcha code to confirm you are human. Click on "Delete." A confirmation message will appear once the request is processed, indicating that the swap history has been permanently deleted.</p>',
    },
    {
      eventKey: "3",
      title: "Why should I consider deleting my swap information?",
      body: " <p>There are several reasons, with privacy being the main one. Each person has their own personal reasons, related to personal security, discretion, or other concerns. Deleting a swap's history allows you to control your personal data.</p>",
    },
    {
      eventKey: "4",
      title:
        "Is it possible to recover a swap record after it has been deleted?",
      body: " <p>No, once a swap record is deleted, it cannot be recovered, either by the client or by our team. The deletion of a swap record is final and irreversible, ensuring complete data removal.</p>",
    },
    {
      eventKey: "5",
      title: "How do I get help with Swap Management issues?",
      body: " <p>If you encounter any issues with the Swap Management functionalities, you can reach out to our customer service team through the Support section of our website. Alternatively, you can connect with a Specialist via our live chat, which is available 24/7. Our Specialists are always ready to assist you with any problems or queries you may have.</p>",
    },
  ];

  const accordionData6 = [
    {
      eventKey: "0",
      title: "What do I need to create a new account?",
      body: "<p>To set up your account, you just need to navigate to the Financial Center, select the 'New Account' tab, and follow the simple steps, including choosing a secure password.</p>",
    },
    {
      eventKey: "1",
      title: "How do I choose a strong password for my account?",
      body: "<p>When creating your password, you must meet several mandatory criteria designed to ensure your password is strong. This includes a mix of uppercase and lowercase letters, numbers, and special characters. We've implemented these requirements to help secure your account effectively.</p>",
    },
    {
      eventKey: "2",
      title: "Can I have multiple accounts with Purple Swap?",
      body: "<p>Our platform currently supports one account per user to maintain security and manageability. This ensures each user has a focused and secure investment experience.</p>",
    },
    {
      eventKey: "3",
      title: "What happens if I forget my account password?",
      body: "<p>On the Account Management page, click on 'Forgot your password...' and follow the instructions to reset your password securely. This feature is designed to help you regain access to your account with minimal hassle.</p>",
    },
    {
      eventKey: "4",
      title: "Is providing an email address mandatory for account creation?",
      body: "<p>No, providing an email address is optional. However, adding an email can enhance your account security and facilitate communication for account-related notifications.</p>",
    },
    {
      eventKey: "5",
      title: "How can I ensure the security of my account?",
      body: "<p>Keep your password and ERC-20 address confidential, use a strong, unique password, and avoid sharing your login details with anyone to maintain account security.</p>",
    },
    {
      eventKey: "6",
      title: "What is the ERC-20 address associated with my account?",
      body: "<p>Your unique ERC-20 address is generated during account creation and is used for all your transactions, deposits, and withdrawals. It's crucial for accessing your account.</p>",
    },
    {
      eventKey: "7",
      title: "Can I add or change my email address once my account is created?",
      body: "<p>Yes, you can add or change the email address associated with your account at any time. Keeping your email updated ensures you receive all important notifications related to your account directly.</p>",
    },
    {
      eventKey: "8",
      title: "What should I do if I suspect unauthorized access to my account?",
      body: "<p>Immediately change your password and contact Purple Swap support. We'll assist you in securing your account and investigating any unauthorized activity.</p>",
    },
    {
      eventKey: "9",
      title: "If I have further questions, what are my options?",
      body: "<p>Should you have any additional questions or require assistance, you have two main options: our 24/7 live chat support and the customer support section under 'Contact.' Both services are dedicated to providing you with timely and helpful</p>",
    },
    // ---------------
    {
      eventKey: "10",
      title: "Use of Investment Funds",
      body: "<p>Your investments are the driving force behind Purple Swap's growth and functionality. We utilize the invested funds to expand our swap capacity and support larger transaction volumes.</p><p>By maintaining a substantial reserve across all supported cryptocurrencies, we ensure efficient fulfillment of swap requests, thereby elevating our service quality. The profits generated from swap fees directly contribute to the competitive interest rates available to our investors.</p>",
    },
    {
      eventKey: "11",
      title: "Investment Process",
      body: "Understanding the investment process at Purple Swap is essential for maximizing your returns. Here's a streamlined approach:<div class='mt-2'><ol type='1'><li class='mb-2'><b>Create Your Unique Account : </b> Begin by setting up your unique account in the Financial Center provided below. This will be your command center for all investment activities.</li><li class='mb-2'><b>Add USDT to Your Account : </b> Deposit USDT into your account, establishing your funds on our platform.</li><li class='mb-2'><b>Allocate Funds for Investment : </b> From your available balance, allocate the desired amount to the investment fund. This crucial step activates the interest-generating process.</li></ol></div><div class='mb-2'><b>Interest Accrual : </b> Following a 3-day initiation phase after allocation, your funds start accruing interest at Purple Swap's attractive rates, ensuring your capital is actively working towards your financial growth.</div><p>This procedure is crafted to offer you both flexibility and control, enabling decisions on investment amounts and timing with ease, and providing a clear path to accruing interest on your assets.</p>",
    },
    {
      eventKey: "12",
      title: " Withdrawal Flexibility",
      body: "At Purple Swap, we ensure you have the flexibility to optimize your investment potential:<div class='mt-2'><ol type='1'><li class='mb-2'><b>Interest Management : </b><div class='mt-3'><ul style='list-style-type: circle;'><li class='mb-2'><b>Withdraw or Reinvest Interest : </b> You have the freedom to either withdraw your earned interest immediately or reinvest it into your investment fund. This allows for interest compounding, significantly increasing the overall value of your investment and boosting future earnings.</li></ul></div></li><li class='mb-2'><b>Capital Management : </b><div class='mt-3'><ul style='list-style-type: circle;'><li class='mb-2'><b>Initiate Transfer : </b> To access your invested capital, first choose the amount you wish to transfer from your 'Invested' account to your 'Available' account.</li><li class='mb-2'><b>3-Day Waiting Period : </b> After initiating the transfer, a 3-day waiting period ensures, preparing your funds for withdrawal.</li><li class='mb-2'><b>Withdraw Funds : </b> Following this period, your funds in the 'Available' account can be withdrawn to an external wallet of your choice, offering the flexibility to access your funds when needed.</li></ul></div></li></ol></div><p>This approach provides the option to quickly benefit from your interest earnings or reinvest them for increased growth, along with a seamless process for managing and withdrawing your capital.</p>",
    },
    {
      eventKey: "13",
      title: "Availability",
      body: "<p>The opportunity to invest with Purple Swap is subject to availability, ensuring we manage our resources effectively and maintain service quality. Should we reach our maximum investment capacity or find that our company funds sufficiently cover operational needs, we may temporarily suspend new investments.</p><p>This approach allows us to potentially revisit and adjust our investment strategies in the future, always aiming to align with our platform's sustainability and efficiency goals.</p>",
    }
    // -------------

  ];
  const overviewFaq = [
    {
      eventKey: "0",
      title: "What are locked profits?",
      body: '<p>Locked Profits are the earnings generated by your invested amount since the start of the month. Interest is calculated and credited to your account daily but is only released at the end of each month.</p>',
    },
    {
      eventKey: "1",
      title: "Why is my investment status 'Inactive'?",
      body: "<p>Your investment status is 'Inactive' because you have not yet transferred funds into your Investment wallet, or you have a transfer transaction in process. As soon as the funds are deposited, your investment will be automatically activated, and your daily profits will begin.</p>",
    },
    {
      eventKey: "2",
      title: "How can I earn more interest?",
      body: '<p>Interest percentages are adjusted according to your VIP Level. For instance, if you have less than $1000 in your invested wallet, you will have 1 star, earning 12% annual interest. If you have between $1000 and $5000, you will move to VIP Level 2 and your interest rate will increase to 24% annually, and so on. For full details about the VIP Level chart, please click on the VIP Level button in the menu on your Financial Center.</p>',
    },
    {
      eventKey: "3",
      title: "I have funds in my 'Available' wallet, but why am I not receiving any profits?",
      body: " <p>Funds in your available wallet do not generate profits. They can be withdrawn at any moment without delay or transferred to your investment wallet to start earning daily profits.</p>",
    },
    {
      eventKey: "4",
      title: "I can't find the answer to my question, what should I do?",
      body: " <p>If you haven't found the answer among the FAQs above, you can visit the support section accessible in the menu to conduct a detailed search among all our FAQs or contact us directly through our contact form, by email, or by chat.</p>",
    }
  ];

  const highlightKeywords = (text, keywords) => {
    if (!text.trim()) {
      return <>{text}</>;
    }
    const regex = new RegExp(`(${keywords.join("|")})`, "gi");
    let data = text.split(regex).map((part, index) => {
      return regex.test(part)
        ? `<mark style="background-color: #6d4aff; color: white;">${part}</mark>`
        : part;
    });
    return data;
  };
  let { parameter } = useParams();

  const [lastSegment, setlastSegment] = useState(parameter);
  // lastSegment = window.location.pathname.split("/").pop()

  var urlLength = window.location.pathname.split("/");

  // const combinedAccordionData = [...accordionData, ...accordionData1, ...accordionData2, ...accordionData3];
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [searchResult1, setSearchResult1] = useState([]);
  const [searchResult2, setSearchResult2] = useState([]);
  const [searchResult3, setSearchResult3] = useState([]);
  const [searchResult4, setSearchResult4] = useState([]);
  const [searchResult5, setSearchResult5] = useState([]);
  const [searchResult6, setSearchResult6] = useState([]);
  const [searchResult7, setSearchResult7] = useState([]);

  const [keywordShow, setkeywordShow] = useState(false);
  // const [activeKey, setActiveKey] = useState(null);
  const [IsScroll, setIsScroll] = useState(true);
  const [IsFound, setIsFound] = useState(true);
  const [IsFound1, setIsFound1] = useState(true);
  const [IsFound2, setIsFound2] = useState(true);
  const [IsFound3, setIsFound3] = useState(true);
  const [IsFound4, setIsFound4] = useState(true);
  const [IsFound5, setIsFound5] = useState(true);
  const [IsFound6, setIsFound6] = useState(true);
  const [IsFound7, setIsFound7] = useState(true);
  const [wayToConnectData, setWayToConnectData] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgSearch, setErrorMsgSearch] = useState("Sorry, no results found. Please try different keywords.");

  const [submitSupportBtn, setSubmitSupportBtn] = useState(false);
  const [submitSupportBtnContact, setSubmitSupportBtnContact] = useState(false);

  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  let tab = queryParameters.get("t")
  tab = tab == null ? 'faq' : tab;
  const redirectTo = (tab) => {
    navigate(`${config.baseUrl}support?t=${tab}`)
  }


  // const [activeKey, setActiveKey] = useState(urlLength.length > 2 ? lastSegment : "");
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        setlastSegment();
        // window.location.href = `${config.baseUrl}support`;
      } else {
      }
    }
    if (urlLength.length > 3) {
    }
    scrollToDiv(lastSegment);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // setlastSegment(window.location.pathname.split("/").pop())
  }, []);

  // return windowDimensions;

  const inputHandler = async (e) => {
    setSearchKeyword(e.target.value);
    setkeywordShow(false);

    if (e.target.value == "") {
      setIsFound(true);
      setIsFound1(true);
      setIsFound2(true);
      setIsFound3(true);
      setIsFound4(true);
      setIsFound5(true);
      setIsFound6(true);
      setIsFound7(true);
      setSearchResult([]);
      setSearchResult1([]);
      setSearchResult2([]);
      setSearchResult3([]);
      setSearchResult4([]);
      setSearchResult5([]);
      setSearchResult6([]);
      setSearchResult7([])
    }
  };
  const handleClose = () => {
    setTimeout(() => {
      setlastSegment("");
    }, 200);
  };

  const handleSearch = () => {
    if (searchKeyword.length == 0) {
      return;
    }
    setkeywordShow(true);
    const result = accordionData.filter((item) =>
      // item.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.body.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setSearchResult(result);
    setIsFound(result.length > 0 ? true : false);

    const result1 = accordionData1.filter((item) =>
      // item.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.body.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setSearchResult1(result1);
    setIsFound1(result1.length > 0 ? true : false);

    const result2 = accordionData2.filter((item) =>
      // item.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.body.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setSearchResult2(result2);
    setIsFound2(result2.length > 0 ? true : false);

    const result3 = accordionData3.filter((item) =>
      // item.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.body.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setSearchResult3(result3);
    setIsFound3(result3.length > 0 ? true : false);

    const result4 = accordionData4.filter((item) =>
      // item.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.body.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setSearchResult4(result4);
    setIsFound4(result4.length > 0 ? true : false);

    const result5 = accordionData5.filter((item) =>
      // item.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.body.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setSearchResult5(result5);
    setIsFound5(result5.length > 0 ? true : false);

    const result6 = accordionData6.filter((item) =>
      item.body.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setSearchResult6(result6);
    setIsFound6(result6.length > 0 ? true : false);

    const result7 = overviewFaq.filter((item) =>
      item.body.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setSearchResult7(result7);
    setIsFound7(result7.length > 0 ? true : false);
  };

  function findPosition(obj, add) {
    var currenttop = add;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));

      return [currenttop];
    }
  }

  function scrollToDiv(lastSegmentIs) {
    if (lastSegmentIs >= 0) {
      const targetElement = document.getElementById(
        `lastSegmen${lastSegmentIs}`
      );

      if (targetElement) {
        let calc;
        let padd;
        if (windowDimensions.width == 1440 && windowDimensions.height == 502) {
          calc = 0;
          padd = 20;
        } else if (windowDimensions.width < 500) {
          calc = -20;
          padd = 10;
        } else {
          calc = 15;
          padd = 18;
        }

        const targetPosition =
          parseInt(findPosition(targetElement, calc).join("")) - padd;

        setTimeout(() => {
          // Use smoothscroll-polyfill for smooth scrolling on all browsers
          window.scroll({
            top: targetPosition,
            // left: 0,
            behavior: "smooth",
          });
        }, 1000);
      }
    }
  }

  const switchTab = (tab) => {
    if (tab == "FAQ") {
      setSearchKeyword("");
      setIsFound(true);
      setIsFound1(true);
      setIsFound2(true);
      setIsFound3(true);
      setIsFound4(true);
      setIsFound5(true);
      setIsFound6(true);
      setIsFound7(true);
      setSearchResult([]);
      setSearchResult1([]);
      setSearchResult2([]);
      setSearchResult3([]);
      setSearchResult4([]);
      setSearchResult5([]);
      setSearchResult6([]);
      setSearchResult7([]);
      setWayToConnectData(1);
      setFormData({
        firstName: "",
        email: "",
        subject: "",
        message: "",
      });
      setErrorMsg("");
      setSubmitSupportBtn(false);
      setSubmitSupportBtnContact(false)
    } else if (tab == "SEARCH") {
      handleSearch();
      setWayToConnectData(2);
      setFormData({
        firstName: "",
        email: "",
        subject: "",
        message: "",
      });
      setErrorMsg("");
      setSubmitSupportBtn(false);
      setSubmitSupportBtnContact(false)

    } else if (tab == "CONTACT") {
      setWayToConnectData(3);
      setSearchKeyword("");
      setIsFound(true);
      setIsFound1(true);
      setIsFound2(true);
      setIsFound3(true);
      setIsFound4(true);
      setIsFound5(true);
      setIsFound6(true);
      setIsFound7(true);
      setSearchResult([]);
      setSearchResult1([]);
      setSearchResult2([]);
      setSearchResult3([]);
      setSearchResult4([]);
      setSearchResult5([]);
      setSearchResult6([]);
      setSearchResult7([])
    }
  };

  const onKeyPressHandler = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    subject: "Choose a subject",
    message: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setSubmitSupportBtn(false);
    setErrorMsg(false);
  };

  const validateEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};

    // Validate first name
    if (formData.firstName.trim() === "") {
      errors.firstName = "Name is required";
    }

    // Validate last name
    if (formData.email.trim() === "") {
      errors.email = "Email is required";
    } else if (!validateEmail(formData.email.trim())) {
      errors.email = "";
      // setSubmitSupportBtn(false)
      setErrorMsg(
        "The provided email address does not meet the required format."
      );
    }

    // Validate subject
    if (formData.subject === "Choose a subject") {
      errors.subject = "Please choose a subject";
    }

    // Validate message
    if (formData.message.trim() === "") {
      errors.message = "Message is required";
    }

    setValidationErrors(errors);


    // Return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitSupportBtnContact(true)
    setTimeout(async () => {
      const isValid = validateForm();
      if (isValid) {
        try {
          let res = await insertContactDataAction(formData);
          if (res.success) {
            setSubmitSupportBtn(true);
            // setSubmitSupportBtnContact(false)
            setFormData({
              firstName: "",
              email: "",
              subject: "",
              message: "",
            });
          }
          else {
            setSubmitSupportBtnContact(false)
          }
        } catch (err) {
          setSubmitSupportBtnContact(false)
        }
      }
      else {
        setSubmitSupportBtnContact(false)
      }
    }, 500);

  };

  const emailAddress = "support@purpleswap.io";
  const subject = "Your Subject";
  const body = "Your Message";

  // Encode special characters in subject and body
  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);

  // Construct the mailto link
  const mailtoLink = `mailto:${emailAddress}`;

  const chatButtonRef = useRef(null);

  useEffect(() => {
    const onTidioChatApiReady = () => {
      window.tidioChatApi.hide();
    };

    if (window.tidioChatApi) {
      window.tidioChatApi.on("ready", onTidioChatApiReady);
    }

    return () => {
      // Clean up event listener when the component unmounts
      if (chatButtonRef.current) {
        chatButtonRef.current.onclick = null;
      }
    };
  }, []); // Empty dependency array means this effect runs once on mount

  const handleChatButtonClick = () => {
    if (window.tidioChatApi) {
      window.tidioChatApi.show();
      window.tidioChatApi.open();
    }
  };

  const [liveChatStatus, setLiveChatStatus] = useState('')
  useEffect(() => {
    getLiveChatStatus();
  }, []);

  const getLiveChatStatus = async () => {
    try {
      let res = await getLiveChatStatusAction({ });
      if (res.success) {
        setLiveChatStatus(res.data.liveChatEnable);
      }
    } catch (err) {}
  };

  return (
    <>
      <Header />
      <Toaster />
      <section className="swapmanagement  pt-4 mb-5">
        <Container>
          <Row>
            <Col xl={8} lg={12} md={12} className="mx-auto pb-lg-5 pb-2">
              <div className="swap_banner">
                <h2 className="text-white fw-lighter">Support</h2>
                <h6 className="text-primary-gradient fw-lighter">
                  Access our FAQ for quick answers or reach out to our customer
                  support for personalized assistance.
                </h6>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8} md={12} className="mx-auto">
              <div className="tab_style">
                <Tab.Container id="left-tabs-example" defaultActiveKey={tab == null ? 'faq' : ''}>
                  <Nav variant="pills" className="mb-5 justify-content-between">
                    <Nav.Item
                      className=" text-center"
                      onClick={() => switchTab("FAQ")}
                    >
                      <Nav.Link className={'right_tab'}
                        active={tab == 'faq'}

                        eventKey="faq"
                        onClick={() => redirectTo('faq')}
                      >
                        <div className="position-relative">FAQ</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className=" text-center"
                      onClick={() => switchTab("SEARCH")}
                    >
                      <Nav.Link
                        active={tab == 'search'}

                        className={'right_tab'}
                        eventKey="search"
                        onClick={() => redirectTo('search')}
                      >
                        <div className="position-relative">Search</div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                      className=" text-center"
                      onClick={() => switchTab("CONTACT")}
                    >
                      <Nav.Link className={'right_tab'}

                        active={tab == 'contact'}
                        eventKey="contactus" onClick={() => redirectTo('contact')}>
                        <div className="position-relative">
                          Contact Us
                          <sup className="soon"></sup>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Row>
                    <Col sm={12} className="text-center">
                      <Tab.Content className="">
                        <Tab.Pane active={tab == 'faq'} eventKey="faq">
                          <div className="swap_spc">
                            <p className="mb-0">
                            Explore our FAQ section for useful answers and information. Should you not find the answers you need, feel free to reach out to us directly for more help.
                            </p>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane active={tab == 'search'} eventKey="search">
                          <div>
                            <Card className="p-4 border-0">
                              {!IsFound &&
                                !IsFound1 &&
                                !IsFound2 &&
                                !IsFound3 &&
                                !IsFound4 &&
                                !IsFound5 &&
                                !IsFound6 &&
                                !IsFound7 &&
                                (
                                  <>
                                    <Col lg={12} md={12} className="pb-1">
                                      <div className="d-block justify-content-between">
                                        <div className="float-rate d-table">
                                          <>
                                            <div className="float-rate d-table text-left alertBox">
                                              <ErrorMessage
                                                error={errorMsgSearch}
                                                type={"Error"}
                                              />
                                            </div>
                                          </>
                                        </div>
                                      </div>
                                    </Col>
                                  </>
                                )}
                              <InputGroup className="">
                                <Form.Control
                                  placeholder="Search"
                                  aria-label="Search"
                                  aria-describedby="basic-addon2"
                                  className="searchinput rounded-2 me-3"
                                  value={searchKeyword}
                                  onChange={(e) => inputHandler(e)}
                                  onKeyPress={onKeyPressHandler}
                                />
                                <Button
                                  variant="default"
                                  d="button-addon2"
                                  disabled={searchKeyword == "" && true}
                                  onClick={handleSearch}
                                  className="custombtn searchbtn text-white rounded-2 col-md-2 col-sm-3 col-4"
                                >
                                  Search
                                </Button>
                              </InputGroup>
                            </Card>
                          </div>

                          {/* <div className="mt-3">
                                                        <p className="mb-0">Sorry, we found nothing</p>
                                                    </div> */}
                        </Tab.Pane>
                        <Tab.Pane active={tab == 'contact'} className="contactus" eventKey="contactus">
                          <div className="mb-5 swap_spc">
                            {/* <p className="mb-0">
                              Need help or have questions? Our Purple Swap team
                              is ready to assist. Use the form below or email us
                              at{" "}
                              <a href={mailtoLink} className="text-blue">
                                support@purpleswap.io
                              </a>{" "}
                              for support inquiries.
                            </p> */}
                            <p className="mb-0">
                              Need help or have questions? Our Purple Swap team is ready to assist. Use the form below or check "More Ways to Connect" for further assistance.

                            </p>
                          </div>
                          <div>
                            <Card className="p-4 border-0">
                              <Form onSubmit={handleSubmit}>
                                <Row>
                                  {submitSupportBtn === false ? (
                                    errorMsg != "" ? (
                                      <Col lg={12} md={12} className="pb-1">
                                        <div className="d-block justify-content-between">
                                          <div className="float-rate d-table">
                                            <>
                                              <div className="float-rate d-table text-left alertBox">

                                                <ErrorMessage
                                                  error={errorMsg}
                                                  type={"Error"}
                                                />
                                              </div>
                                            </>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      <Col lg={12} md={12} className="pb-1">
                                        <div className="d-block justify-content-between">
                                          <div className="float-rate d-table mb-0 alertBox">
                                            <NoteMessage />
                                          </div>
                                        </div>
                                      </Col>
                                    )
                                  ) : (
                                    <Col lg={12} md={12} className="pb-0">
                                      <div className="d-block justify-content-between">
                                        <div className="float-rate d-table alertBox">
                                          <SuccessMessageSupport />
                                        </div>
                                      </div>
                                    </Col>
                                  )}

                                  {submitSupportBtn === false ? (
                                    <>
                                      <Col lg={6} className="">
                                        <Form.Group
                                          className="mb-4"
                                          controlId="firstName"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Name"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleInputChange}
                                          />
                                          <span className="error-message">
                                            {validationErrors.firstName}
                                          </span>
                                        </Form.Group>
                                      </Col>
                                      <Col lg={6} className="">
                                        <Form.Group
                                          className="mb-4"
                                          controlId="email"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                          />
                                          <span className="error-message">
                                            {validationErrors.email}
                                          </span>
                                        </Form.Group>
                                      </Col>
                                      <Col lg={12} className="">
                                        <Form.Group
                                          className="mb-4"
                                          controlId="subject"
                                        >
                                          <Form.Select
                                            // style={{ color: "#82919f" }}
                                            id={
                                              formData.subject == "" ||
                                                formData.subject ==
                                                "Choose a subject"
                                                ? "subject"
                                                : "subjectClassBold"
                                            }
                                            value={formData.subject}
                                            onChange={handleInputChange}
                                            name="subject"
                                          >
                                            <option>Choose a subject</option>
                                            <option>General Inquiries</option>
                                            <option>Technical Support</option>
                                            <option>
                                              Feedback and Suggestions
                                            </option>
                                          </Form.Select>
                                          <span className="error-message">
                                            {validationErrors.subject}
                                          </span>
                                        </Form.Group>
                                      </Col>
                                      <Col lg={12} className="">
                                        <Form.Group
                                          className="mb-4"
                                          controlId="message"
                                        >
                                          <Form.Control
                                            as="textarea"
                                            rows={4}
                                            placeholder="Message"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                          />
                                          <span className="error-message">
                                            {validationErrors.message}
                                          </span>
                                        </Form.Group>
                                      </Col>
                                      <Col lg={12}>
                                        {/* <ThreeDotLoader/> */}
                                        {submitSupportBtnContact === false ?
                                          <div>
                                            <Button
                                              variant="primary"
                                              disabled={
                                                !formData.firstName ||
                                                !formData.email ||
                                                !formData.subject ||
                                                !formData.message
                                              }
                                              className="custombtn h-auto fs-6 col-lg-3 col-md-3 col-sm-3 col-12"
                                              type="submit"
                                            >
                                              Send
                                            </Button>
                                          </div> :
                                          <div>
                                            <Button
                                              variant="primary"
                                              className="custombtn h-auto fs-6 col-lg-3 col-md-3 col-sm-3 col-12"
                                              type="submit"
                                            >
                                              <ThreeDotLoader />
                                            </Button>
                                          </div>


                                        }
                                      </Col>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                              </Form>
                            </Card>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {tab == "contact" &&
        <section className="way_to_connect swapmanagement pt-4 pb-4 bg-white">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8" md="12" xs="12" className="mx-auto">
                <Row>
                  <Col lg={12}>
                    <div className="mb-2 text-center">
                      <h2 className="fw-lighter" style={{ color: "#1b1340" }}>
                        More Ways to Connect
                      </h2>
                      <p className="mb-0">
                        If you would prefer to contact us without using the
                        contact form, you can use the options below.
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} className=" mt-5">
                    <Row>
                      <Col xl={4} lg={6} md={6} className="">
                        <Card className="p-4 purple text-left rounded-4 text-center">
                          <div className="cardBox">
                            <h5 className="text-black">Direct Email</h5>
                            <p>
                              Click the button below to open your default email
                              app, with our contact already set.
                            </p>
                          </div>
                          <button
                            variant="primary"
                            className="custombtn h-auto fs-6 col-lg-12 col-12"
                          >
                            <a
                              href={mailtoLink}
                              style={{ textDecoration: "none", color: "#fff" }}
                              className="text-blue"
                            >
                              Write to Us
                            </a>
                          </button>
                        </Card>
                      </Col>
                      <Col xl={4} lg={6} md={6} className="">
                        <Card className="p-4 purple text-left rounded-4 text-center">
                          <div className="cardBox">
                            <h5 className="text-black">Live Chat</h5>
                            <p>
                              For immediate assistance, use the button below to
                              start a live chat with our support team.
                            </p>
                          </div>
                        {liveChatStatus == 1 ?
                          <Button
                            variant="primary"
                            className="custombtn h-auto fs-6 col-lg-12 col-12"
                            ref={chatButtonRef}
                            onClick={handleChatButtonClick}
                          >
                            Talk to Us
                          </Button>
                          :
                          <Button
                            variant="primary"
                            className="custombtn h-auto fs-6 col-lg-12 col-12"
                            ref={chatButtonRef}
                            disabled
                          >
                            Talk to Us
                          </Button>
                        }

                          <script>
                            {`
          (function() {
            function onTidioChatApiReady() {
              window.tidioChatApi.hide();
            }

            if (window.tidioChatApi) {
              window.tidioChatApi.on("ready", onTidioChatApiReady);
            }
          })();
        `}
                          </script>
                        </Card>
                      </Col>
                      <Col xl={4} lg={6} md={6} className="">
                        <Card className="p-4 purple text-left rounded-4 text-center">
                          <div className="cardBox">
                            <h5 className="text-black">Social Media</h5>
                            <p>
                              Follow us on social media for the latest updates
                              and join our engaging community.
                            </p>
                          </div>
                          <div className="social-list">
                            <ul className="">
                              <li className="me-3">
                                <a
                                  href="https://twitter.com/PurpleSwap_io"
                                  target="_blank"
                                >
                                  <div className="f-icon purple-gradient">
                                    <FaXTwitter fill="#fff" />
                                  </div>
                                </a>
                              </li>
                              <li className="me-2">
                                <a
                                  href="https://t.me/purpleswap"
                                  target="_blank"
                                >
                                  <div className="f-icon lightblue-gradient">
                                    <BiLogoTelegram fill="#fff" />
                                  </div>
                                </a>
                              </li>

                              {/* <li>
                                <a
                                  href="https://www.whatsapp.com/channel/0029VaMeb8i2P59g1S7ijw46"
                                  target="_blank"
                                >
                                  <div className="f-icon blue-gradient">
                                    <FaWhatsapp fill="#fff" />
                                  </div>
                                </a>
                              </li> */}
                            </ul>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      }

      {(tab == "faq" || tab == "search") &&
        <section className="faq pt-4 pb-4 bg-white">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8" md="12" xs="12" className="mx-auto">
                <div className="faq_box">
                  <Row>
                    {/* {IsFound1 && ( */}
                    <Col lg={12} md={12}>
                      <div className="mb-2">
                        <h4>About Purple Swap</h4>
                      </div>

                      {searchResult1.length > 0 ? (
                        <Accordion
                          activeKey={searchResult1.map((item) => item.eventKey)}
                        >
                          {searchResult1.map((item) => (
                            <Accordion.Item
                              key={item.eventKey}
                              eventKey={item.eventKey}
                              className="mb-2"
                            >
                              <Accordion.Header>{item.title}</Accordion.Header>

                              <Accordion.Body
                                dangerouslySetInnerHTML={{
                                  __html:
                                    keywordShow == true
                                      ? highlightKeywords(item.body, [
                                        searchKeyword,
                                      ]).join("")
                                      : item.body,
                                }}
                              />
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      ) : (
                        <Accordion>
                          {accordionData1.map((item) => (
                            <Accordion.Item
                              key={item.eventKey}
                              eventKey={item.eventKey}
                              className="mb-2"
                            >
                              <Accordion.Header>{item.title}</Accordion.Header>
                              <Accordion.Body
                                dangerouslySetInnerHTML={{
                                  __html: item.body,
                                }}
                              />
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      )}
                    </Col>
                    {/* )} */}
                    {lastSegment ? (
                      // IsFound &&
                      <Col lg={12} md={12}>
                        <div className="mb-2">
                          <h4>Highlights</h4>
                        </div>
                        {searchResult.length > 0 ? (
                          <Accordion
                            activeKey={searchResult.map(
                              (item) => item.eventKey
                            )}
                          >
                            {searchResult.map((item) => (
                              <Accordion.Item
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                className="mb-2"
                              >
                                <Accordion.Header>
                                  {item.title}
                                </Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      keywordShow == true
                                        ? highlightKeywords(item.body, [
                                          searchKeyword,
                                        ]).join("")
                                        : item.body,
                                  }}
                                />
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        ) : lastSegment != "" ? (
                          <Accordion
                            activeKey={urlLength.length > 2 && lastSegment}
                          >
                            {accordionData.map((item, index) => (
                              <Accordion.Item
                                onClick={handleClose}
                                id={`lastSegmen${index}`}
                                eventKey={item.eventKey}
                                className="mb-2"
                              >
                                <Accordion.Header>
                                  {item.title}
                                </Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{
                                    __html: item.body,
                                  }}
                                />
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        ) : (
                          <Accordion>
                            {accordionData.map((item) => (
                              <Accordion.Item
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                className="mb-2"
                              >
                                <Accordion.Header>
                                  {item.title}
                                </Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{
                                    __html: item.body,
                                  }}
                                />
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        )}
                      </Col>
                    ) : (
                      // IsFound &&
                      <Col lg={12} md={12} className="">
                        <div className="">
                          <h4>Highlights</h4>
                        </div>
                        {searchResult.length > 0 ? (
                          <Accordion
                            activeKey={searchResult.map(
                              (item) => item.eventKey
                            )}
                          >
                            {searchResult.map((item) => (
                              <Accordion.Item
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                className="mb-2"
                              >
                                <Accordion.Header>
                                  {item.title}
                                </Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      keywordShow == true
                                        ? highlightKeywords(item.body, [
                                          searchKeyword,
                                        ]).join("")
                                        : item.body,
                                  }}
                                />
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        ) : (
                          <Accordion>
                            {accordionData.map((item) => (
                              <Accordion.Item
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                className="mb-2"
                              >
                                <Accordion.Header>
                                  {item.title}
                                </Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{
                                    __html: item.body,
                                  }}
                                />
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        )}
                      </Col>
                    )}

                    {
                      // IsFound3 &&
                      <Col lg={12} md={12} className="">
                        <div className="">
                          <h4>Swap Information</h4>
                        </div>
                        {searchResult3.length > 0 ? (
                          <Accordion
                            activeKey={searchResult3.map(
                              (item) => item.eventKey
                            )}
                          >
                            {searchResult3.map((item) => (
                              <Accordion.Item
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                className="mb-2"
                              >
                                <Accordion.Header>
                                  {item.title}
                                </Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      keywordShow == true
                                        ? highlightKeywords(item.body, [
                                          searchKeyword,
                                        ]).join("")
                                        : item.body,
                                  }}
                                />
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        ) : (
                          <Accordion>
                            {accordionData3.map((item) => (
                              <Accordion.Item
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                className="mb-2"
                              >
                                <Accordion.Header>
                                  {item.title}
                                </Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{
                                    __html: item.body,
                                  }}
                                />
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        )}
                      </Col>
                    }

                    {/* --------------- */}
                    {
                      // IsFound4 &&
                      <Col lg={12} md={12} className="">
                        <div className="">
                          <h4>Transaction Details</h4>
                        </div>
                        {searchResult4.length > 0 ? (
                          <Accordion
                            activeKey={searchResult4.map(
                              (item) => item.eventKey
                            )}
                          >
                            {searchResult4.map((item) => (
                              <Accordion.Item
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                className="mb-2"
                              >
                                <Accordion.Header>
                                  {item.title}
                                </Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      keywordShow == true
                                        ? highlightKeywords(item.body, [
                                          searchKeyword,
                                        ]).join("")
                                        : item.body,
                                  }}
                                />
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        ) : (
                          <Accordion>
                            {accordionData4.map((item) => (
                              <Accordion.Item
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                className="mb-2"
                              >
                                <Accordion.Header>
                                  {item.title}
                                </Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{
                                    __html: item.body,
                                  }}
                                />
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        )}
                      </Col>
                    }
                    {/* --------------- */}
                    {
                      // IsFound5 &&
                      <Col lg={12} md={12} className="">
                        <div className="">
                          <h4>Swap Management</h4>
                        </div>
                        {searchResult5.length > 0 ? (
                          <Accordion
                            activeKey={searchResult5.map(
                              (item) => item.eventKey
                            )}
                          >
                            {searchResult5.map((item) => (
                              <Accordion.Item
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                className="mb-2"
                              >
                                <Accordion.Header>
                                  {item.title}
                                </Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      keywordShow == true
                                        ? highlightKeywords(item.body, [
                                          searchKeyword,
                                        ]).join("")
                                        : item.body,
                                  }}
                                />
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        ) : (
                          <Accordion>
                            {accordionData5.map((item) => (
                              <Accordion.Item
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                className="mb-2"
                              >
                                <Accordion.Header>
                                  {item.title}
                                </Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{
                                    __html: item.body,
                                  }}
                                />
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        )}
                      </Col>
                    }

                    <Col lg={12} md={12} className="">
                      <div className="">
                        <h4>Earn</h4>
                      </div>

                      {searchResult6.length > 0 ? (
                        <Accordion
                          activeKey={searchResult6.map(
                            (item) => item.eventKey
                          )}
                        >
                          {searchResult6.map((item) => (
                            <Accordion.Item
                              key={item.eventKey}
                              eventKey={item.eventKey}
                              className="mb-2"
                            >
                              <Accordion.Header>
                                {item.title}
                              </Accordion.Header>
                              <Accordion.Body
                                dangerouslySetInnerHTML={{
                                  __html:
                                    keywordShow == true
                                      ? highlightKeywords(item.body, [
                                        searchKeyword,
                                      ]).join("")
                                      : item.body,
                                }}
                              />
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      ) : (
                        <Accordion>
                          {accordionData6.map((item) => (
                            <Accordion.Item
                              key={item.eventKey}
                              eventKey={item.eventKey}
                              className="mb-2"
                            >
                              <Accordion.Header>
                                {item.title}
                              </Accordion.Header>
                              <Accordion.Body
                                dangerouslySetInnerHTML={{
                                  __html: item.body,
                                }}
                              />
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      )}
                    </Col>
                    <Col lg={12} md={12} className="">
                      <div className="">
                        <h4>Financial Center</h4>
                      </div>

                      {searchResult7.length > 0 ? (
                        <Accordion
                          activeKey={searchResult7.map(
                            (item) => item.eventKey
                          )}
                        >
                          {searchResult7.map((item) => (
                            <Accordion.Item
                              key={item.eventKey}
                              eventKey={item.eventKey}
                              className="mb-2"
                            >
                              <Accordion.Header>
                                {item.title}
                              </Accordion.Header>
                              <Accordion.Body
                                dangerouslySetInnerHTML={{
                                  __html:
                                    keywordShow == true
                                      ? highlightKeywords(item.body, [
                                        searchKeyword,
                                      ]).join("")
                                      : item.body,
                                }}
                              />
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      ) : (
                        <Accordion>
                          {overviewFaq.map((item) => (
                            <Accordion.Item
                              key={item.eventKey}
                              eventKey={item.eventKey}
                              className="mb-2"
                            >
                              <Accordion.Header>
                                {item.title}
                              </Accordion.Header>
                              <Accordion.Body
                                dangerouslySetInnerHTML={{
                                  __html: item.body,
                                }}
                              />
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      )}
                    </Col>
                    <Col lg={12} md={12} className="">
                    </Col>

                    {/* {!IsFound &&
                      !IsFound1 &&
                      !IsFound2 &&
                      !IsFound3 &&
                      !IsFound4 &&
                      !IsFound5 && <center>Sorry, we found nothing</center>} */}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      }

      <Footer />
    </>
  );
};

export default Support;
