import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Tab,
  Button,
  Card,
  FloatingLabel,
  Form,
  Accordion,
  Toast
} from "react-bootstrap";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from "react-icons/md";
import '../../component/componentCss/account.css'
import { deleteUserAccountAction, getUserDetailsByIdAction, sendEmailConfirmationAction, updateEmailNotificationAction, updateTwofaSettingAction, updateUserEmailAction, updateUserPasswordAction } from "../../coreFiles/user.action";
import { ErrorMessage, QrCodeLoader, SuccessMessage } from "../generic";
import Cookies from "js-cookie";
import copy from "copy-to-clipboard";
import { CiLock } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

import config from "../../coreFiles/config";
// import QRCode from "react-qr-code";
import { SlLock } from "react-icons/sl";

const Account = ({ }) => {
  const navigate = useNavigate();

  const [showPassword2, setShowHidePassword2] = useState(false)
  const [showPassword3, setShowHidePassword3] = useState(false)
  const [showPassword4, setShowHidePassword4] = useState(false)
  const [dynamicCssClass, setdynamicCssClass] = useState('passSecure_0')
  const [securePercent, setSecurePercent] = useState(0);
  const passSercureNum = useRef(0);
  const [handlePasswordButtonShow, setPasswordButtonShow] = useState(false)
  let loginUserData = Cookies.get('purpleUser') ? JSON.parse(Cookies.get('purpleUser')) : '';
  const [walletAddress, setWalletAddress] = useState(loginUserData.walletAddress)
  const [errorMsgChangePass, setErrorMsgChangePass] = useState("");
  const [successMsgChangePass, setSuccessMsgChangePass] = useState("");
  const [greenMsgChangePass, setGreenMsgChangePass] = useState("");
  const [QrCodeLoder, setQrCodeLoder] = useState(true)

  useEffect(() => {
    getUserDetails()
  }, [])
  // section for updating the User password 
  const [UserPasswordData, setUserPasswordData] = useState({
    'oldPassword': "",
    'password': "",
    'confirmPassword': ""
  })

  const showHidePassword2 = (s) => {
    setShowHidePassword2(s)
  }

  const showHidePassword3 = (s) => {
    setShowHidePassword3(s)
  }
  const showHidePassword4 = (s) => {
    setShowHidePassword4(s)
  }
  const [validationNewAccount, setValidationNewAccount] = useState({
    'emailError': "",
    'password': "",
    'confirmPassword': "",
    'lowerCaseFound': false,
    'upperCaseFound': false,
    'minCharFound': false,
    'numberFound': false,
    'spacialCharFound': false,
    'passwordMatch': false,
  })


  const inputHandlerPassword = async (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setShowHidePassword2(false)
      passwordValidate(value);
    }
    if (name === "confirmPassword") {
      setShowHidePassword3(false)
      setValidationNewAccount((prevData) => ({
        ...prevData,
        passwordMatch: false
      }))
      if (UserPasswordData.password == value && UserPasswordData.password != "") {
        setValidationNewAccount((prevData) => ({
          ...prevData,
          passwordMatch: true
        }))
      }
    }
    setUserPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValidationNewAccount((prevData) => ({
      ...prevData,
      emailError: "",
    }));
  };

  function passwordValidate(password) {
    passSercureNum.current = 0;
    setValidationNewAccount((prevData) => ({
      ...prevData,
      lowerCaseFound: false,
      upperCaseFound: false,
      minCharFound: false,
      numberFound: false,
      spacialCharFound: false,
      passwordMatch: false
    }))
    if (password.length > 0) {

      if (/[a-z]/.test(password)) { // Lowercase found
        setValidationNewAccount((prevData) => ({
          ...prevData,
          lowerCaseFound: true
        }))
        passSercureNum.current = parseInt(passSercureNum.current) + parseInt(1)
      }
      if (/[A-Z]/.test(password)) { // Upperrcase found
        setValidationNewAccount((prevData) => ({
          ...prevData,
          upperCaseFound: true
        }))
        passSercureNum.current = parseInt(passSercureNum.current) + parseInt(1)
      }
      if (password.length >= 12) { // 8CharFound found
        setValidationNewAccount((prevData) => ({
          ...prevData,
          minCharFound: true
        }))
        passSercureNum.current = parseInt(passSercureNum.current) + parseInt(1)
      }
      if (/\d/.test(password)) {
        setValidationNewAccount((prevData) => ({
          ...prevData,
          numberFound: true
        }))
        passSercureNum.current = parseInt(passSercureNum.current) + parseInt(1)
      }
      if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
        setValidationNewAccount((prevData) => ({
          ...prevData,
          spacialCharFound: true
        }))
        passSercureNum.current = parseInt(passSercureNum.current) + parseInt(1)
      }
      if (UserPasswordData.confirmPassword == password) {
        setValidationNewAccount((prevData) => ({
          ...prevData,
          passwordMatch: true
        }))
      }
      let securePer = 0;
      if (passSercureNum.current == 0) {
        securePer = 0
        setdynamicCssClass('passSecure_0')
      }
      if (passSercureNum.current == 1) {
        securePer = 20
        setdynamicCssClass('passSecure_20')
      }
      if (passSercureNum.current == 2) {
        securePer = 40;
        setdynamicCssClass('passSecure_40')
      }
      if (passSercureNum.current == 3) {
        securePer = 60;
        setdynamicCssClass('passSecure_60')
      }
      if (passSercureNum.current == 4) {
        securePer = 80;
        setdynamicCssClass('passSecure_80')
      }
      if (passSercureNum.current == 5) {
        securePer = 100;
        setdynamicCssClass('passSecure_100')
      }

      setSecurePercent(securePer);
      setPasswordButtonShow()
    }
    if (passSercureNum.current == 0) {
      setdynamicCssClass('passSecure_0')
      setSecurePercent(0);
    }
  }
  const updateUserPssword = async (e) => {
    try {
      let data = {
        oldPassword: UserPasswordData.oldPassword,
        newPassword: UserPasswordData.password
      }
      let res = await updateUserPasswordAction(data)
      if (res.success) {
        setErrorMsgChangePass('')
        setSuccessMsgChangePass(res.msg)
        setUserPasswordData(
          {
            'oldPassword': "",
            'password': "",
            'confirmPassword': ""
          }
        )
        setdynamicCssClass('passSecure_0')
        setSecurePercent(0);
        setValidationNewAccount((prevData) => ({
          ...prevData,
          lowerCaseFound: false,
          upperCaseFound: false,
          minCharFound: false,
          numberFound: false,
          spacialCharFound: false,
          passwordMatch: false
        }))
      } else {
        setErrorMsgChangePass(res.msg)
      }
    } catch (error) {
      console.log(error)
    }

  }
  // section for updating the User password ENDs......
  // section for Delete  the User Account ENDs......
  const [handleCopyState, setCopyState] = useState(false);
  const [filedForAccountDelete, setFiledForAccountDelete] = useState({
    'wallateAddress': walletAddress,
    'password': "",
  });
  const inputHandlerForAccountSection = async (e) => {
    const { name, value } = e.target;
    if (name === "wallateAddress") {
      setShowHidePassword2(false);
      // Additional validations can be added here
    }
    if (name === "password") {
      // Additional password validations can be added here
    }
    setFiledForAccountDelete((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCopyAction = () => {
    setCopyState(true);
    setTimeout(() => {
      setCopyState(false);
    }, 1500);
  };

  const copyToClipboard = (order) => {
    copy(order);
    navigator.clipboard.writeText(order);
  };

  const deleteUserAccount = async (e) => {
    try {
      e.preventDefault();
      let data = {
        walletAddress: walletAddress,
        password: filedForAccountDelete.password
      };
      let res = await deleteUserAccountAction(data);
      if (res.success) {
        setErrorMsgChangePass('');
        setSuccessMsgChangePass(res.msg);
        logOutUser();
        setUserPasswordData({
          'oldPassword': "",
          'password': "",
          'confirmPassword': ""
        });
      } else {
        setErrorMsgChangePass(res.msg);
      }
    } catch (error) {
      console.error("Error deleting user account:", error);
    }
  };

  const logOutUser = () => {
    Cookies.remove('purpleUser');
    window.location.href = config.baseUrl + 'earning';
  }

  // section for email 
  const [emailFeildForNotification, setEmailFeildForNotification] = useState();
  const [passwordFeildForNotification, setPasswordFeildForNotification] = useState('');
  const [handleAccordianChange, sethandleAccordianChange] = useState(false)
  const [accordianId, setAccordianId] = useState('0')
  const [isChecked, setIsChecked] = useState(false);
  const [timerInterval, setTimerInterval] = useState('');
  const [btnActiveStatus, setBtnActiveStatus] = useState(false);
  const [btnTimer, setBtnTimer] = useState(60);
  const [userInfo, setUserInfo] = useState([]);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailFeildForNotification(email);
  }

  const getUserDetails = async () => {
    try {
      let res = await getUserDetailsByIdAction();
      setUserInfo(res.data[0]);
      setEmailFeildForNotification(res.data[0].email)
      if (res.data[0].emailNotification == 1) {
        document.getElementById("checkedbox").checked = true;
        setIsChecked(true)
      }
    } catch (error) {
      console.error("Error to fatch data of user :", error);
    }
  };
  const handlePasswordChange = (e) => {
    setPasswordFeildForNotification(e.target.value);
  };
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleAccordianAction = (id) => {
    setAccordianId(id)
    sethandleAccordianChange(true)
    setErrorMsgChangePass('')
    setSuccessMsgChangePass('');
    setGreenMsgChangePass('')
    setPasswordFeildForNotification('')
    setdynamicCssClass('passSecure_0')
    setSecurePercent(0);
    clearInterval(timerInterval);
    setFiledForAccountDelete({
      'password': "",
    })
    setUserPasswordData({
      'oldPassword': "",
      'password': "",
      'confirmPassword': ""
    })
    setValidationNewAccount((prevData) => ({
      ...prevData,
      lowerCaseFound: false,
      upperCaseFound: false,
      minCharFound: false,
      numberFound: false,
      spacialCharFound: false,
      passwordMatch: false
    }))
    setsuccessMsg2Fa('')
    seterrorMsg2Fa('')
    settwoFaFormData((prevData) => ({
      ...prevData,
      'action': userInfo.twofa_status == 1 ? 'activate' : 'deactivate',
      'password':'',
      'code':''
    }));
  }
  const updateUserEmail = async (e) => {
    if (emailFeildForNotification.trim() === '') {
      setErrorMsgChangePass('');
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(emailFeildForNotification)) {
        setGreenMsgChangePass('')
        setErrorMsgChangePass('The provided email address does not meet the required format.');
        return
      } else {
        setErrorMsgChangePass('');
      }
    }
    try {
      let data = {
        email: emailFeildForNotification,
        password: passwordFeildForNotification,
        emailNotification: isChecked ? "1" : "0"
      };
      let res = await updateEmailNotificationAction(data)
      if (res.success == true) {
        getUserDetails()
        if (emailFeildForNotification == '' || setIsChecked == false) {
          setGreenMsgChangePass(res.msg)
          setSuccessMsgChangePass('')
        } else {
          setSuccessMsgChangePass(res.msg);
          setGreenMsgChangePass('')
          setErrorMsgChangePass('')
        }
        setErrorMsgChangePass('')
      } else {
        setErrorMsgChangePass(res.msg)

      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    setTimeout(() => {
      setQrCodeLoder(false)
    }, 2000);

    if (btnTimer == 0) {
      setBtnActiveStatus(false);
      clearInterval(timerInterval);
      setBtnTimer(60);
      setSuccessMsgChangePass('');
    }
  }, [btnTimer])
  const resendEmailConfirmation = async () => {
    if (emailFeildForNotification.trim() === '') {
      setErrorMsgChangePass('');
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(emailFeildForNotification)) {
        setGreenMsgChangePass('')
        setErrorMsgChangePass('The provided email address does not meet the required format.');
        return
      } else {
        setErrorMsgChangePass('');
      }
    }
    try {
      let data = {
        email: emailFeildForNotification
      };
      let res = await sendEmailConfirmationAction(data)
      if (res.success) {
        setErrorMsgChangePass('')
        setSuccessMsgChangePass('');
        setGreenMsgChangePass(res.msg);
        setBtnActiveStatus(true);
        let time = 60;
        let timerIntervalIs = setInterval(() => {
          if (time > 0) {
            time = time - 1
            setBtnTimer(time)
          }
        }, 1000)
        setTimerInterval(timerIntervalIs);

      } else {
        setErrorMsgChangePass('')
        setErrorMsgChangePass(res.msg)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 2FA auth 
  const [activateBtnVariant, setactivateBtnVariant] = useState('');
  const [activateBtnClass, setactivateBtnClass] = useState('');
  const [deActivateBtnVariant, setdeActivateBtnVariant] = useState('');
  const [deActivateBtnClass, setdeActivateBtnClass] = useState('');
  const [twoFaFormData, settwoFaFormData] = useState({ 'code': '', 'password': '', 'action': 'activate' })
  const [successMsg2Fa, setsuccessMsg2Fa] = useState('')
  const [errorMsg2Fa, seterrorMsg2Fa] = useState('')
  const [twoFaUpdateBtn, settwoFaUpdateBtn] = useState(false)

  useEffect(() => {
    setactivateBtnVariant(userInfo.twofa_status == 0 ? 'primary' : 'success');
    setactivateBtnClass(userInfo.twofa_status == 0 ? 'select-btn text-black bg-grey' : '');
    setdeActivateBtnVariant(userInfo.twofa_status == 0 ? 'danger' : 'primary');
    setdeActivateBtnClass(userInfo.twofa_status == 0 ? '' : 'select-btn text-black bg-grey');
    settwoFaFormData((prevData) => ({
      ...prevData,
      'action': userInfo.twofa_status == 1 ? 'activate' : 'deactivate',
    }));
  }, [userInfo])

  useEffect(() => {
    check2faValidation();
  }, [twoFaFormData])
  const twofaInputHandlar = (e) => {
    const { name, value } = e.target;
    settwoFaFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    check2faValidation();
  }
  const activateTwoFA = () => {
    setactivateBtnVariant('success');
    setactivateBtnClass('btn-success');
    setdeActivateBtnVariant('primary');
    setdeActivateBtnClass('select-btn text-black bg-grey');
    settwoFaFormData((prevData) => ({
      ...prevData,
      'action': 'activate',
    }));
    check2faValidation();
  }

  const deActivateTwoFA = () => {
    setactivateBtnVariant('primary');
    setactivateBtnClass('select-btn text-black bg-grey');
    setdeActivateBtnVariant('danger');
    setdeActivateBtnClass('btn-danger');
    settwoFaFormData((prevData) => ({
      ...prevData,
      'action': 'deactivate',
    }));
    check2faValidation()
  }

  const check2faValidation = () => {
    let isEnable = true;
    if (twoFaFormData.action == 'activate' && userInfo.twofa_status == 1) {
      isEnable = false;
    }
    if (twoFaFormData.action == 'deactivate' && userInfo.twofa_status == 0) {
      isEnable = false;
    }
    if (twoFaFormData.password != "" && twoFaFormData.code != "" && twoFaFormData.action && isEnable) {
      settwoFaUpdateBtn(true)
    }
    else {
      settwoFaUpdateBtn(false)
    }
  }

  const updateTwofaSetting = async () => {
    try {
      setsuccessMsg2Fa('');
      seterrorMsg2Fa('');
      let res = await updateTwofaSettingAction(twoFaFormData);
      if (res.success) {
        setsuccessMsg2Fa(res.msg);
        getUserDetails();
        settwoFaFormData({
          'action': 'activate',
          'code': '',
          'password': '',
        });
      }
      else {
        seterrorMsg2Fa(res.msg);
      }
      settwoFaUpdateBtn(false)

    }
    catch (err) {
      seterrorMsg2Fa('Something went wrong. Please try again.');
    }
  }

  return (
    <>
      {console.log({ activateBtnVariant })}
      <Container className="accordionBlue2 position-relative px-0 mb-4 account">
        <div className="faq_box">
          <Accordion className="mb-0" defaultActiveKey={accordianId}>
            <Accordion.Item eventKey="0" className="mb-3">
              <Accordion.Header onClick={() => handleAccordianAction(0)}>
                Account
              </Accordion.Header>
              <Accordion.Body>
                {errorMsgChangePass != "" && (
                  <>
                    <div className="float-rate mb-4 alertBox 1 mb-4 h-auto">
                      <ErrorMessage
                        error={errorMsgChangePass}
                        type={"Error"}
                      />
                    </div>
                  </>
                )}
                {successMsgChangePass != "" && (
                  <>
                    <div className="float-rate mb-4 alertBox accountAlert mb-4 h-auto">
                      <SuccessMessage msg={successMsgChangePass} />
                    </div>
                  </>
                )}
                <div className="filed_box">
                  <Col lg={12}>
                    <Row>
                      {/* Main Address */}
                      <Col lg={10} md={10} xs={12} className="singleField  mb-2">
                        <div className="field_block">
                          <FloatingLabel controlId="mainaddress" label="Main Address">
                            <Form.Control
                              disabled
                              type="text"
                              placeholder="Main Address"
                              name="wallateAddress"
                              autoComplete="off"
                              onChange={inputHandlerForAccountSection}
                              value={walletAddress}
                            />
                            <span className="postion-absolute" style={{ top: "12px", right: "12px" }}>
                              <CiLock fill="#6c757d" style={{ fontSize: "30px" }} />
                            </span>
                          </FloatingLabel>
                        </div>
                      </Col>
                      {/* Copy Button */}
                      <Col lg={2} md={2} xs={7} className="singleBtn ml-auto mb-4">
                        <Button variant="primary"
                          onClick={() => { copyToClipboard(walletAddress); handleCopyAction() }}
                          className="select-btn rounded-2 text-black p-3 justify-content-center"
                        >
                          {handleCopyState ? "Copied" : "Copy"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </div>
                <div className="filed_box">
                  <Col lg={12}>
                    <Row>
                      {/* Current Password */}
                      <Col lg={6} md={6} xs={12} className="singleField mb-2">
                        <div className="field_block">
                          <FloatingLabel controlId="password" label="Account Password">
                            <Form.Control
                              type={showPassword2 ? "text" : "password"}
                              className="pr-3"
                              placeholder="Password"
                              name="password"
                              onChange={inputHandlerForAccountSection}
                              autoComplete="off"
                              value={filedForAccountDelete.password}
                            />
                            <span className="eyeIcon">
                              {showPassword2 ?
                                <FaRegEyeSlash className="eyecut" fill="#6d4aff" onClick={() => setShowHidePassword2(false)} style={{ cursor: "pointer" }} /> :
                                <FaRegEye className="eye" fill="#6d4aff" onClick={() => setShowHidePassword2(true)} style={{ cursor: "pointer" }} />}
                            </span>
                          </FloatingLabel>
                        </div>
                      </Col>
                      {/* Delete Account Button */}
                      <Col lg={6} md={6} xs={7} className="singleBtn ml-auto">
                        {filedForAccountDelete.password == "" ?
                          <Button variant="danger"
                            className="text-white fs-6 w-100"
                            style={{ height: "56px" }}
                            disabled
                          >
                            Delete my Account
                          </Button>
                          :
                          <Button variant="danger"
                            className="text-white fs-6 w-100"
                            style={{ height: "56px" }}
                            onClick={deleteUserAccount}
                          >
                            Delete my Account
                          </Button>
                        }
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="mb-3">
              <Accordion.Header onClick={() => handleAccordianAction(1)}>
                2FA security
              </Accordion.Header>
              <Accordion.Body>

                {errorMsg2Fa != "" && (
                  <>
                    <div className="float-rate mb-4 alertBox 1 mb-4 h-auto">
                      <ErrorMessage
                        error={errorMsg2Fa}
                        type={"Error"}
                      />
                    </div>
                  </>
                )}
                {successMsg2Fa != "" && (
                  <>
                    <div className="float-rate mb-4 alertBox accountAlert mb-4 h-auto">
                      <SuccessMessage msg={successMsg2Fa} />
                    </div>
                  </>
                )}

                <div className="filed_box">
                  <Col lg={12}>
                    <Row>
                      <Col xl={4} lg={5} className="mb-2">
                        <Card className="qrCode border-0 shadow pt-0 customShadow">
                          <small className="text-grey qrcodlable pb-1">QR Code</small>
                          {userInfo.twofa_status == 0 ?
                            <div className="p-1">
                              {!QrCodeLoder ?
                                <img src={userInfo.qrCode} alt="PurpleSwap" width={'100%'} />
                                // <QRCode
                                //   value={userInfo.quCode}
                                //   size={185}
                                // />
                                :
                                <div className="qrImage">
                                  <QrCodeLoader />
                                </div>
                              }

                            </div>
                            :
                            <div className="lockIcon p-5">
                              <SlLock fill="#6c757d" />
                            </div>
                          }
                        </Card>
                      </Col>
                      <Col xl={8} lg={7}>
                        <Row>
                          {console.log({ activateBtnVariant })}
                          {/* Current Password */}
                          <Col lg={6} md={6} xs={7} className="singleBtn mb-4 ml-auto">
                            {userInfo.twofa_status == 1 ?
                              <Button variant={activateBtnVariant}
                                className={`${activateBtnClass} w-100 rounded-2 justify-content-center`}
                                style={{ height: "56px" }}
                                onClick={activateTwoFA}
                              >
                                Activated
                              </Button>
                              :
                              <Button variant={activateBtnVariant}
                                className={`${activateBtnClass} w-100 rounded-2 justify-content-center`}
                                style={{ height: "56px" }}
                                onClick={activateTwoFA}
                              >
                                Activate
                              </Button>
                            }

                          </Col>
                          {/* Delete Account Button */}
                          <Col lg={6} md={6} xs={7} className="singleBtn mb-4 ml-auto ">

                            {userInfo.twofa_status == 0 ?
                              <Button variant={deActivateBtnVariant}
                                className={`${deActivateBtnClass} fs-6 w-100 rounded-2 justify-content-center`}
                                style={{ height: "56px" }}
                                onClick={deActivateTwoFA}
                              >
                                Deactivated
                              </Button>
                              :
                              <Button variant={deActivateBtnVariant}
                                className={`${deActivateBtnClass} fs-6 w-100 rounded-2 justify-content-center`}
                                style={{ height: "56px" }}
                                onClick={deActivateTwoFA}
                              >
                                Deactivate
                              </Button>
                            }

                          </Col>
                          <Col lg={12} md={12} xs={12} className="singleField  mb-4">
                            <div className="field_block">
                              <FloatingLabel controlId="2FA" label="2FA Code">
                                <Form.Control
                                  type="text"
                                  placeholder="2FA Code"
                                  name="code"
                                  autoComplete="off"
                                  value={twoFaFormData.code}
                                  onChange={(e) => twofaInputHandlar(e)}
                                />

                              </FloatingLabel>
                            </div>
                          </Col>
                          <Col lg={12} md={12} xs={12} className="singleField  mb-4">
                            <div className="filed_box px-0">
                              <Row>
                                <Col lg={12} className="singleField">
                                  <div className="field_block">
                                    <FloatingLabel
                                      controlId="password"
                                      label="Current password"
                                    >
                                      <Form.Control
                                        type={showPassword2 ? "text" : "password"}
                                        placeholder="Password"
                                        name="password"
                                        value={twoFaFormData.password}
                                        onChange={(e) => twofaInputHandlar(e)}
                                        autoComplete="off"
                                      />
                                      <span className="eyeIcon">
                                        {!showPassword2 ?
                                          <FaRegEye className="eye" fill="#6d4aff" onClick={(e) => showHidePassword2(true)} style={{ cursor: "pointer" }} /> :
                                          <FaRegEyeSlash className="eyecut" fill="#6d4aff" onClick={(e) => showHidePassword2(false)} style={{ cursor: "pointer" }} />}
                                      </span>
                                    </FloatingLabel>
                                  </div>
                                </Col>

                              </Row>

                            </div>
                          </Col>
                          <Col lg={12} md={12} xs={7} className="singleBtn ml-auto">
                            {twoFaUpdateBtn ?
                              <Button
                                variant="primary"
                                className="text-white fs-6 w-100 custombtn"
                                style={{ height: "56px" }}
                                onClick={updateTwofaSetting}
                              >
                                Confirmation
                              </Button>
                              :
                              <Button
                                variant="primary"
                                className="text-white fs-6 w-100 custombtn"
                                style={{ height: "56px" }}
                                disabled
                              >
                                Confirmation
                              </Button>
                            }
                          </Col>
                        </Row>
                      </Col>



                    </Row>
                  </Col>
                </div>

              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className="mb-3">
              <Accordion.Header onClick={() => handleAccordianAction(2)}>
                Change your Password
              </Accordion.Header>
              <Accordion.Body>
                {errorMsgChangePass != "" && (
                  <>
                    <div className="float-rate mb-4 alertBox 1 h-auto">
                      <ErrorMessage
                        error={errorMsgChangePass}
                        type={"Error"}
                      />
                    </div>
                  </>
                )}
                {successMsgChangePass != "" && (
                  <>
                    <div className="float-rate mb-4 alertBox accountAlert h-auto">
                      <SuccessMessage msg={successMsgChangePass} />
                    </div>
                  </>
                )}
                <Container>
                  <Row className="justify-content-center">

                    <Col lg={12} className="px-0 stepform">

                      <Container className="">

                        <Row>
                          <Col lg={12} className="px-0">
                            <Row className="justify-content-end">
                              <Col xl={9} lg={8} md={9} className="ctm-col-9">
                                <div className="filed_box px-0 mb-4">
                                  <Row>
                                    <Col lg={12} className="singleField">
                                      <div className="field_block">
                                        <FloatingLabel
                                          controlId="password"
                                          label="New password"
                                        >
                                          <Form.Control
                                            type={showPassword2 ? "text" : "password"}
                                            placeholder="Password"
                                            name="password"
                                            value={UserPasswordData.password}
                                            onChange={inputHandlerPassword}
                                            autoComplete="off"
                                          />
                                          <span className="eyeIcon">
                                            {!showPassword2 ?
                                              <FaRegEye className="eye" fill="#6d4aff" onClick={(e) => showHidePassword2(true)} style={{ cursor: "pointer" }} /> :
                                              <FaRegEyeSlash className="eyecut" fill="#6d4aff" onClick={(e) => showHidePassword2(false)} style={{ cursor: "pointer" }} />}
                                          </span>
                                        </FloatingLabel>
                                      </div>
                                    </Col>

                                  </Row>

                                </div>
                                <div className="filed_box mb-4 px-0">
                                  <Row>
                                    <Col lg={12} className="singleField">
                                      <div className="field_block">
                                        <FloatingLabel
                                          controlId="confirmPassword"
                                          label="New password confirmation"
                                        >
                                          <Form.Control
                                            type={showPassword3 ? "text" : "password"}
                                            placeholder="Password"
                                            name="confirmPassword"
                                            value={UserPasswordData.confirmPassword}
                                            onChange={inputHandlerPassword}
                                            autoComplete="off"
                                          />
                                          <span className="eyeIcon">
                                            {!showPassword3 ?
                                              <FaRegEye className="eye" fill="#6d4aff" onClick={(e) => showHidePassword3(true)} style={{ cursor: "pointer" }} /> :
                                              <FaRegEyeSlash className="eyecut" fill="#6d4aff" onClick={(e) => showHidePassword3(false)} style={{ cursor: "pointer" }} />}</span>
                                        </FloatingLabel>
                                      </div>
                                    </Col>

                                  </Row>

                                </div>

                              </Col>
                              <Col xl={3} lg={4} md={3} xs={5} className="ctm-col-4 pl-0">
                                <Card className="border-0 passwordScore" style={{ padding: "0.5rem 0.5rem 0.5rem 0.75rem" }}>
                                  <div className="percentageBox position-relative">
                                    <label className="mb-0" >
                                      Password Score
                                    </label>

                                    <div className="percentageText text-nowrap passSecure_0">
                                      {securePercent == 100
                                        ?
                                        <>
                                          <div className="text-right position-absolute threeStar">
                                            <img src="assets/images/star.png" width={'30px'} />
                                          </div>
                                        </>
                                        :
                                        ""
                                      }
                                      <h1 className={`text-blue mb-0 fw-lighter ${dynamicCssClass}`}>{securePercent}<span className="">%</span></h1>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                            <div className="filed_box px-0">
                              <Row>
                                <Col lg={12} className=" px-0">
                                  <ul class="requirements mb-4 ">
                                    <li className={`${validationNewAccount.lowerCaseFound ? 'text-blue' : 'text-muted'} mb-1`}>
                                      <small>
                                        {validationNewAccount.lowerCaseFound ?
                                          <MdOutlineRadioButtonChecked /> :
                                          <MdOutlineRadioButtonUnchecked />
                                        }
                                        <span style={{ paddingLeft: "15px", fontWeight: "200" }}>One lowercase character</span>
                                      </small>
                                    </li>
                                    <li className={`${validationNewAccount.upperCaseFound ? 'text-blue' : 'text-muted'} mb-1`}>
                                      <small >
                                        {validationNewAccount.upperCaseFound ?
                                          <MdOutlineRadioButtonChecked /> :
                                          <MdOutlineRadioButtonUnchecked />
                                        }
                                        <span style={{ paddingLeft: "15px", fontWeight: "200" }}>One uppercase character</span>
                                      </small>
                                    </li>
                                    <li className={`${validationNewAccount.minCharFound ? 'text-blue' : 'text-muted'} mb-1`}>
                                      <small>
                                        {validationNewAccount.minCharFound ?
                                          <MdOutlineRadioButtonChecked /> :
                                          <MdOutlineRadioButtonUnchecked />
                                        }
                                        <span style={{ paddingLeft: "15px", fontWeight: "200" }}>12 characters minimum</span>
                                      </small>
                                    </li>
                                    <li className={`${validationNewAccount.numberFound ? 'text-blue' : 'text-muted'} mb-1`}>
                                      <small >
                                        {validationNewAccount.numberFound ?
                                          <MdOutlineRadioButtonChecked /> :
                                          <MdOutlineRadioButtonUnchecked />
                                        }
                                        <span style={{ paddingLeft: "15px", fontWeight: "200" }}>One number</span></small>
                                    </li>
                                    <li className={`${validationNewAccount.spacialCharFound ? 'text-blue' : 'text-muted'} mb-1`}>
                                      <small>
                                        {validationNewAccount.spacialCharFound ?
                                          <MdOutlineRadioButtonChecked /> :
                                          <MdOutlineRadioButtonUnchecked />
                                        }

                                        <span style={{ paddingLeft: "15px", fontWeight: "200" }}>One special character</span>
                                      </small>
                                    </li>
                                    <li className={`${validationNewAccount.passwordMatch ? 'text-blue' : 'text-muted'} mb-1`}>
                                      <small>
                                        {validationNewAccount.passwordMatch ?
                                          <MdOutlineRadioButtonChecked /> :
                                          <MdOutlineRadioButtonUnchecked />
                                        }
                                        <span style={{ paddingLeft: "15px", fontWeight: "200" }}>Passwords match</span></small>
                                    </li>

                                  </ul>
                                </Col>
                              </Row>
                            </div>
                            <div className="filed_box px-0">
                              <Col lg={12}>
                                <Row>
                                  <Col lg={6} md={6} xs={12} className="singleField mb-2">
                                    <div className="field_block">
                                      <FloatingLabel
                                        controlId="oldPassword"
                                        label="Current Password"
                                      >
                                        <Form.Control
                                          type={showPassword4 ? "text" : "password"}
                                          className="pr-3"
                                          placeholder="Password"
                                          name="oldPassword"
                                          value={UserPasswordData.oldPassword}
                                          autoComplete="off"
                                          onChange={inputHandlerPassword}

                                        />
                                        <span className="eyeIcon">
                                          {!showPassword4 ?
                                            <FaRegEye className="eye" fill="#6d4aff" onClick={(e) => showHidePassword4(true)} style={{ cursor: "pointer" }} /> :
                                            <FaRegEyeSlash className="eyecut" fill="#6d4aff" onClick={(e) => showHidePassword4(false)} style={{ cursor: "pointer" }} />}
                                        </span>
                                      </FloatingLabel>
                                    </div>
                                  </Col>
                                  <Col lg={6} md={6} xs={7} className="singleBtn  ml-auto">
                                    {securePercent < 100 || UserPasswordData.oldPassword == "" || validationNewAccount.passwordMatch == "" ?
                                      <>
                                        <Button variant="primary"
                                          className="text-white fs-6 w-100 custombtn"
                                          style={{ height: "56px" }}
                                          disabled
                                        >
                                          Save
                                        </Button>
                                      </>
                                      :
                                      <Button variant="primary"
                                        className="text-white fs-6 w-100 custombtn"
                                        style={{ height: "56px" }}
                                        onClick={updateUserPssword}
                                      >
                                        Save
                                      </Button>}

                                  </Col>
                                </Row>
                              </Col>

                            </div>
                          </Col>
                        </Row>

                      </Container>

                    </Col>
                  </Row>
                </Container>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="mb-3">
              <Accordion.Header onClick={() => { handleAccordianAction(3); getUserDetails(); }}>
                Notifications
              </Accordion.Header>
              <Accordion.Body>
                <div className="filed_box mb-4">
                  {errorMsgChangePass != "" && (
                    <>
                      <div className="float-rate mb-4 alertBox 1 h-auto">
                        <ErrorMessage
                          error={errorMsgChangePass}
                          type={"Error"}
                        />
                      </div>
                    </>
                  )}
                  {successMsgChangePass != "" && (
                    <>
                      <Card className="border-0 mb-3" style={{ background: "rgb(255, 249, 230)", color: "#ac8515" }}>
                        <div className="alert_block infoBlock">
                          <div class="warningIconbox me-2">
                            <img src="assets/icons/warningIcon.png" alt="warmingIcon" className="alertIcon" />
                          </div>
                          <div className="alert-text">
                            <p className="mb-0">
                              {successMsgChangePass}
                            </p>
                          </div>
                        </div>
                      </Card>
                      {/* <div className="float-rate mb-4 alertBox accountAlert h-auto">
                        <SuccessMessage msg={successMsgChangePass} />
                      </div> */}
                    </>
                  )}
                  {greenMsgChangePass != "" && (
                    <>
                      <div className="float-rate mb-4 alertBox accountAlert h-auto">
                        <SuccessMessage msg={greenMsgChangePass} />
                      </div>
                    </>
                  )}
                  <Row>
                    <Col lg={10} md={10} xs={12} className="singleField mb-2">

                      <div className="field_block position-relative">

                        <FloatingLabel
                          controlId="Email"
                          label="Email"
                        >
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            autoComplete="off"
                            value={emailFeildForNotification}
                            onChange={handleEmailChange}
                          />

                        </FloatingLabel>

                      </div>
                    </Col>

                    <Col lg={2} md={2} xs={7} className="singleBtn ml-auto">
                      {userInfo.isEmailVerify == 0 ?
                        <>
                          {emailFeildForNotification == '' || emailFeildForNotification != userInfo.email ?
                            <Button variant="primary"
                              className="select-btn rounded-2 text-black p-3 justify-content-center"
                              disabled
                            >
                              Resend
                            </Button>
                            :
                            <>
                              {!btnActiveStatus ?
                                <Button variant="primary"
                                  className="select-btn rounded-2 text-black p-3 justify-content-center"
                                  onClick={resendEmailConfirmation}
                                >
                                  Resend
                                </Button>
                                :
                                <Button variant="primary"
                                  className="select-btn rounded-2 text-black p-3 justify-content-center" disabled
                                >
                                  {btnTimer}
                                </Button>
                              }
                            </>
                          }
                        </>
                        :
                        <>
                          {userInfo.isEmailVerify == 1 || emailFeildForNotification == '' ?
                            <Button variant="primary"
                              className="select-btn rounded-2 text-black p-3 justify-content-center"
                              disabled
                            >
                              Resend
                            </Button>
                            :
                            <>
                              {!btnActiveStatus ?
                                <Button variant="primary"
                                  className="select-btn rounded-2 text-black p-3 justify-content-center"
                                  onClick={resendEmailConfirmation}
                                >
                                  Resend
                                </Button>
                                :
                                <Button variant="primary"
                                  className="select-btn rounded-2 text-black p-3 justify-content-center" disabled
                                >
                                  {btnTimer}
                                </Button>
                              }
                            </>
                          }
                        </>
                      }
                    </Col>
                  </Row>
                </div>
                <div className="filed_box mb-4">
                  <Col lg={12} className="px-1">
                    <div>
                      <ul className="p-3 py-0">

                        <li>

                          <div className="me-1 mb-4">
                            <label class="container-check">
                              <h6 className="mb-0">I confirm :</h6>
                              <p className="mb-0">That I agree to receive emails from Purple Swap related to my account. These emails will solely be notifications of account
                                activities, keeping me informed of the actions occurring within my account.
                              </p>
                              <input type="checkbox" id="checkedbox" name="checkTerms"
                                onChange={handleCheckboxChange} />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </div>
                <div className="filed_box px-0">
                  <Col lg={12}>
                    <Row>
                      <Col lg={6} md={6} xs={12} className="singleField mb-2">
                        <div className="field_block">
                          <FloatingLabel
                            controlId="password"
                            label="Account Password"
                          >
                            <Form.Control
                              type={showPassword4 ? "text" : "password"}
                              className="pr-3"
                              placeholder="Password"
                              name="password"
                              autoComplete="off"
                              value={passwordFeildForNotification}
                              onChange={handlePasswordChange}
                            />
                            <span className="eyeIcon">
                              {!showPassword4 ?
                                <FaRegEye className="eye" fill="#6d4aff" onClick={(e) => showHidePassword4(true)} style={{ cursor: "pointer" }} /> :
                                <FaRegEyeSlash className="eyecut" fill="#6d4aff" onClick={(e) => showHidePassword4(false)} style={{ cursor: "pointer" }} />}
                            </span>
                          </FloatingLabel>
                        </div>
                      </Col>
                      <Col lg={6} md={6} xs={7} className="singleBtn  ml-auto">
                        <Button
                          variant="primary"
                          className="text-white fs-6 w-100 custombtn"
                          style={{ height: "56px" }}
                          onClick={updateUserEmail}
                          disabled={
                            (emailFeildForNotification == '' && isChecked) || (emailFeildForNotification !== '' && !isChecked) || (passwordFeildForNotification == '')
                          }
                        >
                          Save
                        </Button>


                        {/* {(emailFeildForNotification !== '' && isChecked !== true) || passwordFeildForNotification == '' ?
                          <Button variant="primary"
                            className="text-white fs-6 w-100 custombtn"
                            style={{ height: "56px" }}
                            disabled
                          >
                            Save
                          </Button>
                          :
                          <Button variant="primary"
                            className="text-white fs-6 w-100 custombtn"
                            style={{ height: "56px" }}
                            onClick={updateUserEmail}
                          >
                            Save
                          </Button>
                        } */}
                      </Col>
                    </Row>
                  </Col>

                </div>
              </Accordion.Body>
            </Accordion.Item>


          </Accordion >

        </div >
      </Container >
    </>
  );
}
export default Account;