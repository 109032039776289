import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams } from "react-router-dom";
import Header from '../directives/Header';
import Footer from '../directives/Footer';
import { Container, Row, Col, Nav, Navbar, NavDropdown, Button, Form, InputGroup, Modal, Table, Accordion } from 'react-bootstrap';
import config from '../coreFiles/config';
import { useNavigate } from "react-router-dom";
import { getAllCoinsAction, getrateOfpairs, getRangesAction, createExchangeAction } from '../coreFiles/user.action'
const Addexchange = () => {
    const navigate = useNavigate();
    const { from, to, upperamount, loweramount, rate } = useParams()
    const [listCoins, setListCoins] = useState([])
    const [listAllCoins, setListAllCoins] = useState([])
    const [upperDropdown, setupperDropdown] = useState({})
    const [lowerDropdown, setlowerDropdown] = useState({})
    const [upperModel, setupperModel] = useState(false)
    const [lowerModel, setlowerModel] = useState(false)
    const [upperValue, setupperValue] = useState(0.1)
    const [lowerValue, setlowerValue] = useState(0)
    const [upperMinMaxValue, setupperMinMaxValue] = useState({ min: 0, max: 0 })
    const [lowerMinMaxValue, setlowerMinMaxValue] = useState({ min: 0, max: 0 })
    const [walletAddress, setwalletAddress] = useState({ recipientAddress: "", refundAddress: "" })
    const [invalidrecipientAddress, setinvalidrecipientAddress] = useState(false)
    const [invalidrefundAddress, setinvalidrefundAddress] = useState(false)



    useEffect(() => {
        getCoins()
        setupperValue(upperamount)
        setlowerValue(loweramount)
    }, [])


    const getCoins = async () => {
        let res = await getAllCoinsAction({ api_key: config.APIkey })
        if (res && res.length > 0) {
            setListCoins(res)
            setListAllCoins(res)
            setupperDropdown(res.find(item => item.symbol == from))
            setlowerDropdown(res.find(item => item.symbol == to))
            getRanges(from, to)
        }
    }

    const selectHandle = async (e, type, symbol) => {
        if (type == "upper") {
            const checkrange = await getRanges(listAllCoins.find(item => item.symbol == symbol).symbol, lowerDropdown.symbol)
            if (checkrange == true) {
                setupperDropdown(listAllCoins.find(item => item.symbol == symbol))

                closeModel()
            }

        } else {
            const checkrange = await getRanges(upperDropdown.symbol, listAllCoins.find(item => item.symbol == symbol).symbol)
            if (checkrange == true) {
                setlowerDropdown(listAllCoins.find(item => item.symbol == symbol))

                closeModel()
            }
        }


    }


    const getRanges = async (upperDropdownsymbol, lowerDropdownsymbol) => {
        try {
            let resupper = await getRangesAction({ api_key: config.APIkey, fixed: true, currency_from: upperDropdownsymbol ? upperDropdownsymbol : "btc", currency_to: lowerDropdownsymbol ? lowerDropdownsymbol : "eth" })
            let reslower = await getRangesAction({ api_key: config.APIkey, fixed: true, currency_from: lowerDropdownsymbol ? lowerDropdownsymbol : "eth", currency_to: upperDropdownsymbol ? upperDropdownsymbol : "btc" })
            if (resupper && resupper.min && resupper.max) {
                setupperMinMaxValue({ min: resupper.min, max: resupper.max })

            }

            if (reslower && reslower.min && reslower.max) {
                setlowerMinMaxValue({ min: reslower.min, max: reslower.max })

            }
            return true

        } catch (error) {
            return false
        }

    }



    const changeHandler = async (e) => {
        const { name, value } = e.target
        name == "upperValue" ? setupperValue(value) : setlowerValue(value)


        try {
            if (name == "upperValue" && parseFloat(value) > upperMinMaxValue.min && parseFloat(value) < upperMinMaxValue.max) {

                let rate = await getrateOfpairs({ api_key: config.APIkey, fixed: true, currency_from: upperDropdown.symbol ? upperDropdown.symbol : "btc", currency_to: lowerDropdown.symbol ? lowerDropdown.symbol : "eth", amount: value })


                setlowerValue(rate)

            } else if (parseFloat(value) > lowerMinMaxValue.min && parseFloat(value) < lowerMinMaxValue.max) {

                let rate = await getrateOfpairs({ api_key: config.APIkey, fixed: true, currency_from: lowerDropdown.symbol ? lowerDropdown.symbol : "eth", currency_to: upperDropdown.symbol ? upperDropdown.symbol : "btc", amount: value })


                setupperValue(rate)
            } else {
                name == "upperValue" ? setlowerValue(0) : setupperValue(0)


            }


        } catch (error) {
            // console.log('eerr', error)
        }


    }


    const closeModel = (e) => {
        if (upperModel == true) {
            setupperModel(false)
        }
        if (lowerModel == true) {
            setlowerModel(false)
        }

    }

    const openModel = (e, type) => {
        if (type == "upperModel" && upperModel == false) {
            setupperModel(true)
            setlowerModel(false)
            setListCoins(listAllCoins)
        }
        if (type == "lowerModel" && lowerModel == false) {
            setlowerModel(true)
            setupperModel(false)
            setListCoins(listAllCoins)
        }

    }


    const searchCoin = (e) => {
        const { name, value } = e.target
        setListCoins(listAllCoins.filter(object => object.symbol.toUpperCase().includes(value.toUpperCase()) || object.name.toUpperCase().includes(value.toUpperCase())))
    }

    const addressHandler = (e) => {
        const { name, value } = e.target
        var pattern = name == "recipientAddress" ? new RegExp(lowerDropdown.validation_address) : new RegExp(upperDropdown.validation_address)
        if (value && !pattern.test(value)) {
            name == "recipientAddress" ? setinvalidrecipientAddress(true) : setinvalidrefundAddress(true)

            setwalletAddress((old) => {
                return { ...old, [name]: value }
            })
        } else {
            name == "recipientAddress" ? setinvalidrecipientAddress(false) : setinvalidrefundAddress(false)

            setwalletAddress((old) => {
                return { ...old, [name]: value }
            })
        }
    }

    const changeDropdown = (e) => {
        e.preventDefault()
        setupperDropdown(lowerDropdown)
        setlowerDropdown(upperDropdown)
        setlowerValue(upperValue)
        setupperValue(lowerValue)
        setupperMinMaxValue({ min: lowerMinMaxValue.min, max: lowerMinMaxValue.max })
        setlowerMinMaxValue({ min: upperMinMaxValue.min, max: upperMinMaxValue.max })
    }


    const createExchange = async (e) => {
        e.preventDefault()
        let obj = {
            api_key: config.APIkey,
            "fixed": true,
            "currency_from": upperDropdown.symbol,
            "currency_to": lowerDropdown.symbol,
            "amount": upperValue,
            "address_to": walletAddress.recipientAddress,
            "extra_id_to": "",
            "user_refund_address": walletAddress.refundAddress,
            "user_refund_extra_id": "string"
        }
        const res = await createExchangeAction(obj)

        if (res && res.id) {
            navigate(`${config.baseUrl}deposit/${res.id}`)
        }
    }

    return (
        <>

            <Header />



            <section className='addexchange_sec pt-5'>
                <Container>
                    <Row>
                        <Col lg={7} md={7} className='mx-auto pb-5'>
                            <div className='swap_block'>
                                <h4>How to swap:</h4>
                                <div className='swap-head'>
                                    <div className='swapbox'>
                                        <img src="assets/icons/checkicon.svg" alt="checkicon" className='' />
                                        <span>Choose currencies</span>
                                    </div>

                                    <div className='swapbox'>
                                        <img src="assets/icons/nubtwo.svg" alt="nubtwo" className='' />
                                        <span>Enter the address</span>
                                    </div>

                                    <div className='swapbox'>
                                        <img src="assets/icons/nubthree.svg" alt="nubthree" className='' />
                                        <span>Create an exchange</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <div className='add_exchangetext mb-3'>
                            <h2>Add exchange details</h2>
                            <section className='exchange'>
                                <div className='exchange_inner py-0'>
                                    <Row>
                                        <Col lg={12}>
                                            <div  >
                                                <div className='filed_box'>
                                                    <Form>
                                                        {/*------------------------------ Upper Model Content Start ----------------------------------------*/}
                                                        <div className={upperModel == true ? 'filed d-none' : "filed d-block"}>
                                                            <Row>
                                                                <Col lg={9} md={9} className='px-1'>
                                                                    <div className='field_block'>
                                                                        <p>You Send</p>
                                                                        <Form.Control
                                                                            placeholder="0.1"
                                                                            value={upperValue}
                                                                            name="upperValue"
                                                                            onChange={e => changeHandler(e)}
                                                                            aria-label="Username"
                                                                            aria-describedby="basic-addon1"
                                                                        />
                                                                    </div>
                                                                    {parseFloat(upperMinMaxValue.min) > parseFloat(upperValue) ? <div className='red-alert'>Min Amount Required:{upperMinMaxValue.min}</div> : ""}
                                                                    {parseFloat(upperMinMaxValue.max) < parseFloat(upperValue) ? <div className='red-alert'>Max Amount Required:{upperMinMaxValue.max}</div> : ""}
                                                                </Col>
                                                                <Col lg={3} md={3} className='p-0'>

                                                                    <Button onClick={e => openModel(e, "upperModel")} className='select-btn'>
                                                                        <div className='item-select'>
                                                                            <div>
                                                                                <img src={upperDropdown?.image} alt="btc" className='updown-icon pe-2' />
                                                                                <span>{upperDropdown?.symbol?.toUpperCase()}</span>
                                                                            </div>
                                                                            <img src="assets/icons/dwonarrow.svg" alt="dwonarrow" className='dwonarrow' />
                                                                        </div>
                                                                    </Button>

                                                                </Col>

                                                            </Row>
                                                        </div>


                                                        <div className={upperModel == true ? 'select-modal d-block' : "select-modal d-none"}>
                                                            <Row>
                                                                <Col lg={12} >
                                                                    <div >

                                                                        <div className='search_ticket'>
                                                                            <img src="assets/icons/search.svg" alt="search" className='tiketicon' />
                                                                            <input type='search' onChange={e => searchCoin(e)} placeholder='Type a cryptocurrency or ticket' />
                                                                            <img src="assets/icons/close.svg" alt="close" className='tiketicon' onClick={e => closeModel(e)} />
                                                                        </div>

                                                                        <div className='table_popular'>
                                                                            <h6>Popular cryptocurrency</h6>
                                                                            <div className='table-responsive'>
                                                                                <Table >
                                                                                    <tbody style={{ overflow: "scroll", height: "200px" }}>
                                                                                        {listCoins && listCoins.map(item => {
                                                                                            return <tr className='active'>
                                                                                                <td>
                                                                                                    <div className='item-select' onClick={e => selectHandle(e, "upper", item.symbol)}>
                                                                                                        <div onClick={e => selectHandle(e, "upper", item.symbol)}>
                                                                                                            <img src={item.image} alt="btc" className=' pe-2' />
                                                                                                            <span>{item.symbol.toUpperCase()}</span>
                                                                                                            <span className={item.network ? 'token-network' : ""}>{item.network}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div onClick={e => selectHandle(e, "upper", item.symbol)} className='text-end'>
                                                                                                        {item.name}
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        })}


                                                                                        {/* <tr>
                                                                                <td>
                                                                                    <div className='item-select'>
                                                                                        <div>
                                                                                            <img src="assets/icons/eth2.svg" alt="eth2" className=' pe-2' />
                                                                                            <span>ETH</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='text-end'>
                                                                                        Ethereum
                                                                                    </div>
                                                                                </td>
                                                                            </tr> */}
                                                                                    </tbody>
                                                                                </Table>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        {/*------------------------------ Upper Model Content End----------------------------------------*/}


                                                        <Row className={(upperModel) == true ? 'filed d-none' : "filed d-block"}>
                                                            <Col lg={12} md={12} className='px-1'>
                                                                <div className='float-rate'>
                                                                    <div className='floatbox'>
                                                                        <img src="assets/icons/lock.svg" alt="lock" className='lock' />
                                                                        <span>Floating Rate</span>
                                                                    </div>
                                                                    <div>
                                                                        <button onClick={e => changeDropdown(e)}> <img src="assets/icons/updown-icon.svg" alt="updown-icon" className='updown-icon' /></button>
                                                                    </div>
                                                                </div>

                                                                <div className='exchange_rate'>
                                                                    <div className='ratebox'>
                                                                        <div className='ratebox-inner'>
                                                                            <img src="assets/icons/updown-icon.svg" alt="updown-icon" className='updown-icon' />
                                                                            <span>Floating Exchange Rate</span>
                                                                        </div>
                                                                        <p>This amount you get may change due to market volatility. </p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        {/*------------------------------ Lower Model Content Start ----------------------------------------*/}
                                                        <div className={(upperModel || lowerModel) == true ? 'filed d-none' : "filed d-block"}>
                                                            <Row>
                                                                <Col lg={9} md={9} className='px-1'>
                                                                    <div className='field_block'>
                                                                        <p>You  Get</p>
                                                                        <Form.Control
                                                                            placeholder="= 1.82752907"
                                                                            aria-label="Username"
                                                                            name="lowerValue"
                                                                            value={lowerValue}
                                                                            onChange={e => changeHandler(e)}
                                                                            aria-describedby="basic-addon1"
                                                                        />
                                                                    </div>
                                                                    {parseFloat(lowerMinMaxValue.min) > parseFloat(lowerValue) ? <div className='red-alert'>Min Amount Required:{lowerMinMaxValue.min}</div> : ""}
                                                                    {parseFloat(lowerMinMaxValue.max) < parseFloat(lowerValue) ? <div className='red-alert'>Max Amount Required:{lowerMinMaxValue.max}</div> : ""}
                                                                </Col>
                                                                <Col lg={3} md={3} className='p-0'>

                                                                    <Button onClick={e => openModel(e, "lowerModel")} className='select-btn'>
                                                                        <div className='item-select'>
                                                                            <div>
                                                                                <img src={lowerDropdown?.image} alt="btc" className='updown-icon pe-2' />
                                                                                <span>{lowerDropdown?.symbol?.toUpperCase()}</span>
                                                                            </div>
                                                                            <img src="assets/icons/dwonarrow.svg" alt="dwonarrow" className='dwonarrow' />
                                                                        </div>
                                                                    </Button>
                                                                </Col>

                                                            </Row>
                                                        </div>


                                                        <div className={lowerModel == true ? 'select-modal d-block' : "select-modal d-none"}>
                                                            <Row >
                                                                <Col lg={12}>
                                                                    <div >

                                                                        <div className='search_ticket'>
                                                                            <img src="assets/icons/search.svg" alt="search" className='tiketicon' />
                                                                            <input type='search' onChange={e => searchCoin(e)} placeholder='Type a cryptocurrency or ticket' />
                                                                            <img src="assets/icons/close.svg" alt="close" className='tiketicon' onClick={e => closeModel(e)} />
                                                                        </div>

                                                                        <div className='table_popular'>
                                                                            <h6>Popular cryptocurrency</h6>
                                                                            <div className='table-responsive'>
                                                                                <Table >

                                                                                    <tbody>
                                                                                        {listCoins && listCoins.map(item => {
                                                                                            return <tr className='active'>
                                                                                                <td>
                                                                                                    <div onClick={e => selectHandle(e, "lower", item.symbol)} className='item-select'>
                                                                                                        <div onClick={e => selectHandle(e, "lower", item.symbol)}>
                                                                                                            <img src={item.image} alt="btc" className=' pe-2' />
                                                                                                            <span>{item.symbol.toUpperCase()}</span>
                                                                                                            <span className={item.network ? 'token-network' : ""}>{item.network}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div onClick={e => selectHandle(e, "lower", item.symbol)} className='text-end'>
                                                                                                        {item.name}
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        })}


                                                                                        {/* <tr>
                                                                                <td>
                                                                                    <div className='item-select'>
                                                                                        <div>
                                                                                            <img src="assets/icons/eth2.svg" alt="eth2" className=' pe-2' />
                                                                                            <span>ETH</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='text-end'>
                                                                                        Ethereum
                                                                                    </div>
                                                                                </td>
                                                                            </tr> */}

                                                                                    </tbody>
                                                                                </Table>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </Col>

                                                            </Row>
                                                        </div>

                                                        {/*------------------------------ Lower Model Content End ----------------------------------------*/}


                                                        {/* <Row className={upperModel || lowerModel == true ? 'filed d-none' : "filed d-block"}>
                                            <Col lg={12} md={12} className='mx-auto px-1 pt-5'>
                                                <Button variant="primary" className='custombtn w-100'>exchange</Button>{' '}
                                            </Col>
                                        </Row> */}

                                                    </Form>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>

                            </section>
                            {/* <Exchange from={from} to={to} upperamount={upperamount} loweramount={loweramount} rate={rate} handleChange={handleChange} /> */}
                        </div>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <div className='inter_wallet'>
                                <h5>Enter the wallet address</h5>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" name="recipientAddress" value={walletAddress.recipientAddress} onChange={e => addressHandler(e)} placeholder={`The recipient’s ${lowerDropdown?.name} address`} />
                                        {invalidrecipientAddress ? <div className='red-alert'>Invalid Address</div> : ""}
                                    </Form.Group>

                                    <Button disabled={invalidrecipientAddress || walletAddress.recipientAddress.length == 0} onClick={e => createExchange(e)} variant="primary" className='custombtn w-100'>Create an Exchange</Button>

                                </Form>
                            </div>

                            <div className='additional'>
                                <h5>Enter the wallet address</h5>
                                <Form>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Enter the refund address</Form.Label>
                                        <div className='mb-2'>
                                            <Form.Text className="text-muted">
                                                We recommend adding your wallet address for a refund.
                                            </Form.Text>
                                        </div>
                                        <Form.Control type="text" name="refundAddress" onChange={e => addressHandler(e)} value={walletAddress.refundAddress} placeholder={`The  ${upperDropdown?.name} refund address`} />
                                        {invalidrefundAddress ? <div className='red-alert'>Invalid Address</div> : ""}
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>Add Email</Form.Label>
                                        <div className='mb-2'>
                                            <Form.Text className="text-muted">
                                                If you want to get notifications about this exchange.
                                            </Form.Text>
                                        </div>
                                        <Form.Control type="email" placeholder="The email address" />
                                    </Form.Group>
                                </Form>


                            </div>
                        </Col>
                    </Row>


                </Container>
            </section>



            <section className='wallet_sec pt-5'>
                <Container>
                    <Row>
                        <Col lg={12} md={12} className='mx-auto mb-4'>
                            <h2>Have any Questions</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12" xs="12" className='mx-auto'>
                            <div className="amoutpaid_box py-5">

                                <Row>
                                    <Col lg={12} md={12} className='p-4'>
                                        <Accordion className=''>
                                            <Accordion.Item eventKey="0" className='mb-3'>
                                                <Accordion.Header>
                                                    What is the recipient's address and where do I get it?</Accordion.Header>
                                                <Accordion.Body>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis   aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1" className='mb-3'>
                                                <Accordion.Header>
                                                    Why is my recipient address shown as invalid?</Accordion.Header>
                                                <Accordion.Body>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3" className='mb-3'>
                                                <Accordion.Header>
                                                    How do I get cashback for the exchange on SimpleSwap?</Accordion.Header>
                                                <Accordion.Body>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                        </Accordion>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>





            <Footer />


        </>
    )
}

export default Addexchange
