import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import Header from '../directives/Header';
import Footer from '../directives/Footer';
import { Container, Row, Col, Nav, Navbar, NavDropdown, Button, Form, InputGroup, Modal, Table, Accordion, Tab } from 'react-bootstrap';
import config from '../coreFiles/config';
import { useNavigate } from "react-router-dom";
import { SwapForm } from "./swap/Index";
import { Enums } from "./swap/Enum";
import { createExchangeAction, validateAddressAction, submitSwapTransactionAction, getrateOfpairs } from '../coreFiles/user.action'
import axios from 'axios';
import { generateString } from '../coreFiles/helper';
import { ThreeDotLoader } from "./swap/Icons";

const Exchangedetails = () => {
  const [ActiveTab, setActiveTab] = useState('')
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [Error, setError] = useState("")
  const [upperDropdown, setupperDropdown] = useState({})
  const [lowerDropdown, setlowerDropdown] = useState({})
  const [contactEmail, setContactEmail] = useState("");

  const [from, setFrom] = useState({ legacyTicker: searchParams.get('from') || 'btc' })
  const [to, setTo] = useState({ legacyTicker: searchParams.get('to') || 'eth' })
  const [upperValue, setupperValue] = useState(0)
  const [lowerValue, setlowerValue] = useState(0)

  const [walletAddress, setwalletAddress] = useState({ recipientAddress: "", refundAddress: "" })
  const [invalidrecipientAddress, setinvalidrecipientAddress] = useState(false)
  const [invalidrefundAddress, setinvalidrefundAddress] = useState(false)
  const [refreshPrice, setRefreshPrice] = useState(false);

  useEffect(() => {
    refreshPriceTimer()
    // var today = new Date('2023-12-30 04:51:21');
    // var Christmas = new Date();
    // console.log(today)
    // console.log(Christmas)
    // var diffMs = (Christmas - today); // milliseconds between now & Christmas
    // console.log(diffMs)
    // // var diffDays = Math.floor(diffMs / 86400000); // days
    // // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    // console.log(diffMins + " minutes until Christmas =)");
  }, [])
  useEffect(() => {
    switch (searchParams.get('rate')) {
      case Enums.FloatingRate:
        setActiveTab(Enums.FloatingRate);
        setupperValue(searchParams.get('amount') || 0)
        return;
      case Enums.FixedRate:
        setActiveTab(Enums.FixedRate);
        setlowerValue(searchParams.get('amount') || 0)
        return;
      default: setActiveTab(Enums.FloatingRate); return;
    }
  }, [])

  const refreshPriceTimer = () => {
    let second = 23
    setInterval(() => {
      if (second > 0) {
        setRefreshPrice(false)
        second -= 1
      } else {
        setRefreshPrice(true)
        second = 23;
      }
    }, 1000)
  }


  const addressHandler = (e) => {
    const { name, value } = e.target
    var pattern = name == "recipientAddress" ? new RegExp(lowerDropdown.validation_address) : new RegExp(upperDropdown.validation_address)
    if (value && !pattern.test(value)) {
      name == "recipientAddress" ? setinvalidrecipientAddress(true) : setinvalidrefundAddress(true)

      setwalletAddress((old) => {
        return { ...old, [name]: value }
      })
    } else {
      name == "recipientAddress" ? setinvalidrecipientAddress(false) : setinvalidrefundAddress(false)

      setwalletAddress((old) => {
        return { ...old, [name]: value }
      })
    }
  }


  const [creatingSwap, setCreatingSwap] = useState(false);

  const createExchange = async (e) => {
    e.preventDefault()
    try {
      setCreatingSwap(true);
      let usdData = await getrateOfpairs({
        fromCurrency: from.ticker ? from.ticker : "btc",
        toCurrency: "usdt",
        fromAmount: from.amount,
        fromNetwork: from.network,
        toNetwork: "eth",
        flow: ActiveTab
      });

      // const res1 = await axios({
      //   method: 'GET',
      //   params: {
      //     currency: to.network,
      //     address: walletAddress.recipientAddress
      //   },
      //   url: `${config.serverPath}/validate/address`,
      // })
      // if (!res1.data.result) {
      //   console.log('Testing', res1.data)
      //   setError(res1.data.message)
      //   return
      // }
      setError("")
      let obj = {
        "fromCurrency": from.ticker,
        "fromNetwork": from.network,
        "fromAmount": from.amount,
        "toCurrency": to.ticker,
        "toNetwork": to.network,
        "toAmount": to.amount,
        "address": walletAddress.recipientAddress,
        "extraId": "",
        "refundAddress": walletAddress.refundAddress,
        // "refundExtraId": "",
        // "userId": "",
        // "payload": "",
        "contactEmail": contactEmail,
        "flow": ActiveTab,
        "type": "direct",
      }
      if (to.rateId) {
        obj["rateId"] = to.rateId
      }
      const res = await createExchangeAction(obj)
      if (res && res.id) {
        localStorage.setItem("from", from.image)
        localStorage.setItem("to", to.image);
        let trxId = generateString(2) + res.id + generateString(1)
        localStorage.setItem('action', 'create');
        let postData = {
          modifiedSwapId: trxId,
          walletAddress: walletAddress.recipientAddress,
          amoutToSwap: from.amount,
          receivedAmount: to.amount,
          amountUSDT: usdData.toAmount,
          swapFrom: from.ticker.toUpperCase(),
          swapTo: to.ticker.toUpperCase(),
          trxStatus: 'waiting',
          type: ActiveTab == 'standard' ? 1 : 2
        }
        await submitSwapTransactionAction(postData);
        navigate(`${config.baseUrl}exchange/txs/${trxId}`)
      } else {
        setCreatingSwap(false);
        setError(res.data.message)
      }
    } catch (err) {
      setError(err.response.data.message)
      setCreatingSwap(false);
    }

  }

  return (
    <>


      <Header />
      <section className='addexchange_sec pt-lg-5 pt-3'>
        <Container>
          <Row>
            <Col xl={7} lg={12} md={12} className='mx-auto pb-lg-5 pb-2'>
              <div className='swap_block'>
                <h4>How to swap:</h4>
                <div className='swap-head'>
                  <div className='swapbox'>
                    <img src="assets/icons/checkicon.svg" alt="checkicon" className='' />
                    <span>Choose currencies</span>
                  </div>

                  <div className='swapbox'>
                    <img src="assets/icons/nubtwo.svg" alt="nubtwo" className='' />
                    <span>Enter the address</span>
                  </div>

                  <div className='swapbox'>
                    <img src="assets/icons/nubthree.svg" alt="nubthree" className='' />
                    <span>Create an exchange</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>


          <Row>
            <Col lg={8} md={12} className='mx-auto'>
              <div className="tab_style1 exchange_details mb-3">
                <h2>Add exchange details</h2>
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="floating_rate"
                >
                  <Nav variant="pills" className="mb-4">
                    <Nav.Item className="w-50 text-center">
                      <Nav.Link
                        className={`left_tab ${ActiveTab == Enums.FloatingRate ? 'active' : ''}`}
                        onClick={() => setActiveTab(Enums.FloatingRate)}
                      >
                        <div className="position-relative">
                          Floating Rate
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="w-50 text-center">
                      <Nav.Link
                        className={`right_tab ${ActiveTab == Enums.FixedRate ? 'active' : ''}`}
                        onClick={() => setActiveTab(Enums.FixedRate)}
                      >
                        <div className="position-relative">
                          Fixed Rate
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Row>
                    <Col sm={12}>
                      <SwapForm
                        ActiveTab={ActiveTab}
                        hideExchangeBtn={true}
                        fromTicker={from?.legacyTicker}
                        toTicker={to?.legacyTicker}
                        upperValue={upperValue}
                        lowerValue={lowerValue}
                        setFrom={setFrom}
                        setTo={setTo}
                        refreshPrice={refreshPrice}
                      />
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8} md={12} className='mx-auto'>
              <div className='inter_wallet mb-3'>
                <h5>Enter the wallet address</h5>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" className='px-4' name="recipientAddress" value={walletAddress.recipientAddress} onChange={e => addressHandler(e)} placeholder={`The recipient’s ${to.name} address`} />
                    {invalidrecipientAddress ? <div className='red-alert'>Invalid Address</div> : ""}
                  </Form.Group>
                  {Error && <p className='text-danger'>Invalid Address : {Error}</p>}

                  {creatingSwap ?
                    <Button variant="primary" className='custombtn w-100 mt-3'>
                      <ThreeDotLoader />
                    </Button>
                    :
                    <Button disabled={invalidrecipientAddress || walletAddress.recipientAddress.length == 0} onClick={e => createExchange(e)} variant="primary" className='custombtn w-100 mt-3'>Create an Exchange</Button>
                  }
                  <div className='clicking pt-1'>
                    <p className=''>By clicking Create an exchange, I agree to the <a href={config.privacyPolicyUrl} target='__blank'> Privacy Policy</a> and <a href={config.termConditionUrl} target='__blank'> Terms and Conditions.</a></p>
                  </div>
                </Form>


              </div>

              <div className='additional'>
                {/* <div className='addinform'>
                  <span>Additional Information</span><img src="assets/icons/dwonarrow.svg" alt='' className='infoico ms-2' />
                </div> */}
                <h5>Enter the refund address</h5>
                <Form>
                  <Form.Group className="mb-1">
                    <div className='mb-2'>
                      <Form.Text className="text-muted">
                        We recommend adding your wallet address for a refund.
                      </Form.Text>
                    </div>
                    <Form.Control className='px-4' type="text" name="refundAddress" onChange={e => addressHandler(e)} value={walletAddress.refundAddress} placeholder={`The  ${from?.name} refund address`} />
                    {invalidrefundAddress ? <div className='red-alert'>Invalid Address</div> : ""}
                  </Form.Group>

                  {/* <Form.Group className="mb-4">
                    <Form.Label>Add Email</Form.Label>
                    <div className='mb-2'>
                      <Form.Text className="text-muted">
                        If you want to get notifications about this exchange.
                      </Form.Text>
                    </div>
                    <Form.Control className='px-5' onChange={(e) => setContactEmail(e.target.value)} type="email" placeholder="The email address" />
                  </Form.Group> */}
                </Form>


              </div>
            </Col>
          </Row>


        </Container>
      </section >
      <br />



      {/* <section className='wallet_sec pt-2'>
        <Container>
          <Row>
            <Col lg={12} md={12} className='mx-auto '>
              <h2>Have any Questions</h2>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" xs="12" className='mx-auto'>
              <div className="amoutpaid_box pb-lg-5 pb-2">

                <Row>
                  <Col lg={12} md={12} className='py-4'>
                    <Accordion className=''>
                      <Accordion.Item eventKey="0" className='mb-3'>
                        <Accordion.Header>
                          What is the recipient's address and where do I get it?</Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                          aliquip ex ea commodo consequat. Duis   aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                          culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1" className='mb-3'>
                        <Accordion.Header>
                          Why is my recipient address shown as invalid?</Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                          aliquip ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                          culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3" className='mb-3'>
                        <Accordion.Header>
                          How do I get cashback for the exchange on SimpleSwap?</Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                          aliquip ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                          culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                    </Accordion>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}





      <Footer />


    </>
  )
}

export default Exchangedetails
