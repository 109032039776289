import axios from 'axios'
import config from './config'
import Cookies from 'js-cookie';
var loginUserData = Cookies.get('purpleUser') ? JSON.parse(Cookies.get('purpleUser')) : '';

export const request = (path, data, method) => {
    const serverPath = config.serverPath
    //   const serverPath='http://localhost:6011'

    var options = {
        method: method,
        url: `${serverPath}/${path}`,
        headers: {
            'Content-Type': 'application/json',
            'x-changenow-api-key': 'b94cc0f426b1309277516993d782d9f446041d38ea511094911da80b9a0156eb',
            'Authorization': loginUserData?.authToken
        },
        dataType: 'json'
    };
    if (loginUserData) {
        options.headers['Authorization'] = loginUserData?.authToken
    }
    if (method === 'GET') {
        options['params'] = data
    } else {
        options['data'] = data
    }

    let res = axios(options)
    res.then(res1 => { })
        .catch((error) => {
            // console.log('oops', error);
        });
    return res
}

export const postRequest = async (path, data) => await request(path, data, 'POST')
export const getRequest = async (path, data) => await request(path, data, 'GET')
export const putRequest = async (path, data) => await request(path, data, 'PUT')
export const deleteRequest = async (path, data) => await request(path, data, 'DELETE')

export const request2 = (path, data, method) => {
    var options = {
        method: method,
        url: `${config.apiUrl}/${path}`,
        headers: {
            'Content-Type': 'application/json',
        },
        dataType: 'json'
    };
    if (loginUserData) {
        options.headers['Authorization'] = loginUserData?.authToken
    }
    if (method === 'GET') {
        options['params'] = data
    } else {
        options['data'] = data
    }
    let res = axios(options)
    res.then(res1 => { })
    return res
}
export const postRequest2 = async (path, data) => await request2(path, data, 'POST')
export const putRequest2 = async (path, data) => await request2(path, data, 'PUT')
export const getRequest2 = async (path, data) => await request2(path, data, 'GET')


const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generateString = (length) => {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
