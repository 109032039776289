import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Header from '../directives/Header';
import Footer from '../directives/Footer';
import { Container, Row, Col, Nav, Navbar, NavDropdown, Button, Form, InputGroup, Modal, Table, Accordion } from 'react-bootstrap';
import Exchange from './exchange';
import { Link, Navigate, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import config from '../coreFiles/config';
import { getSwapTransactnioStatusAction, getTransactnioStatusAction } from '../coreFiles/user.action';
import copy from 'copy-to-clipboard';


const Successful = ({swapId}) => {
    // let { swapId } = useParams();
    const navigate = useNavigate();
    const [TrxData, setTrxData] = useState({});
    const [dbTrxData, setDbTrxData] = useState({});
    const modifiedSwapId = swapId;
	let trxIdIs = modifiedSwapId.substring(2);
	trxIdIs = trxIdIs.slice(0, -1);
	const [TrxId, setTrxId] = useState(trxIdIs || '')

    if (!TrxId) {
        navigate(`${config.baseUrl}`)
    }
    useEffect(() => {
        getTransactnioStatus()
    }, [])
    const getTransactnioStatus = async () => {
        try {
            const res = await getTransactnioStatusAction({ id: TrxId });
            const getTrx = await getSwapTransactnioStatusAction({ modifiedSwapId: modifiedSwapId });
            if (res.status == "finished") {
                res.fromImage = localStorage.getItem("from")
                res.toImage = localStorage.getItem("to")
                setTrxData(res);
                setDbTrxData(getTrx.data);
            } else {
                navigate(`${config.baseUrl}`)
            }
        } catch (err) {
            navigate(`${config.baseUrl}`)
        }
    }

    return (
        <>
            {/* <Header /> */}
            <section className='successful pt-lg-5 pt-2'>
                <Container>
                    <Row>
                        <Col lg={12} md={12} className='mt-4 mb-5'>
                            <div className='successful_content'>
                                <div>
                                    {/* <img src="assets/images/successful_shape.png" alt='' className='successful_shape' /> */}
                                    <img src="assets/images/succe_back.png" alt='' className='succe_back' />
                                </div>
                                <div className='finish_block'>
                                    <h4 className='mb-lg-5 mb-2'>Finished</h4>
                                            <center>
                                               <p>Your exchange took {dbTrxData.processTime} </p> 
                                            </center>
                                    <Row>
                                        <Col lg={12} md={12} className='mt-4'>
                                            <div className='send_deposit mb-3'>
                                                <div className='depositfield btcoin'>
                                                    <div>
                                                        <span><img src={TrxData?.fromImage} alt='' className='pe-3' /></span>
                                                        {TrxData?.amountFrom} {TrxData?.fromCurrency?.toUpperCase()}
                                                    </div>

                                                    {/* <div className='btcoin ms-lg-4'>
                                                        <p>BTC=20 coin</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12} md={12} className='text-end'>
                                            <img src="assets/icons/longdown.svg" alt='' className='longdown' />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12} md={12} className='mt-3'>
                                            <div className='send_deposit mb-4'>
                                                <div className='depositfield btcoin'>
                                                    <div>
                                                        <span><img src={TrxData?.toImage} alt='' className='pe-3' /></span>
                                                        {TrxData?.amountTo} {TrxData?.toCurrency?.toUpperCase()}
                                                    </div>

                                                    {/* <div className='btcoin ms-lg-4'>
                                                            <p>ETH=20 coin</p>
                                                        </div> */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12} md={12} className='mt-4'>
                                            <div className='promocode_box'>
                                                <p>Your next exchange will be more profitable with this promo code.</p>
                                                <Row className="">
                                                    <Col lg={12} md={12} className='mx-auto'>
                                                        <Button variant="primary" className='custombtn w-100'>Get Promocode Now</Button>
                                                    </Col>
                                                </Row>
                                                <div className='pt-2 text-center'>
                                                    <Link to={`${config.baseUrl}`}>
                                                        <span>Start a  new exchange</span> <img src="assets/icons/new_exchange_icon.svg" alt='' className='new_exchange_icon' />
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>

                            <div className='add_exchangetext '>
                                <div className='deposit_content'>
                                    <div className='operating_head'>
                                        <h4>Operating details</h4>
                                    </div>
                                    <Row>
                                        <Col lg={12} className='mt-4'>
                                            <div className='send_deposit mb-4'>
                                                <p>You get:</p>
                                                <div className='depositfield btcoin'>
                                                    <div>
                                                        <span><img src={TrxData?.toImage} alt='' className='pe-3' /></span>
                                                        {TrxData?.amountTo} {TrxData?.toCurrency?.toUpperCase()}
                                                    </div>

                                                    {/* <div className='btcoin ms-lg-4'>
                                                        <p>ETH=20 coin</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </Col>


                                        <Col lg={12} className='mb-5'>
                                            <div className='depositaddress'>
                                                <p>Recipient address:</p>
                                                <div className='recieptbox'>
                                                    <div className='text-break'>
                                                        <p>{TrxData?.payoutAddress}</p>
                                                    </div>

                                                    <div className='copybox'>
                                                        <a onClick={(e) => copy(TrxData?.payoutAddress, {
                                                            debug: true,
                                                            message: 'Press #{key} to copy',
                                                        })}><span className='icobox'><img src="assets/icons/copyicon.svg" alt='' className='' /></span></a>
                                                        {/* <a href='#'><span className='icobox'><img src="assets/icons/sendicons.svg" alt='' className='' /></span></a> */}
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>
                                        <Col lg={12} className=''>
                                            <div className='depositaddress'>
                                                <p>Payout Hash :</p>
                                                <div className='recieptbox'>
                                                    <div className='text-break'>
                                                        <p>{TrxData?.payoutHash}</p>
                                                    </div>

                                                    <div className='copybox'>
                                                        <a onClick={(e) => copy(TrxData?.payoutHash, {
                                                            debug: true,
                                                            message: 'Press #{key} to copy',
                                                        })}><span className='icobox'><img src="assets/icons/copyicon.svg" alt='' className='' /></span></a>
                                                        {/* <a href='#'><span className='icobox'><img src="assets/icons/sendicons.svg" alt='' className='' /></span></a> */}
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12} className='mt-4'>
                                            <div className='send_deposit mb-4'>
                                                <p>You sent:</p>
                                                <div className='depositfield btcoin'>
                                                    <div>
                                                        <span><img src={TrxData?.fromImage} alt='' className='pe-3' /></span>
                                                        {TrxData?.amountFrom} {TrxData?.fromCurrency?.toUpperCase()}
                                                    </div>

                                                    {/* <div className='btcoin ms-lg-4'>
                                                        <p>BTC=20 coin</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </Col>


                                        <Col lg={12} className='mb-4'>
                                            <div className='depositaddress'>
                                                <p>Deposit address:</p>
                                                <div className='recieptbox'>
                                                    <div className=' text-break'>
                                                        <p>{TrxData?.payinAddress}</p>
                                                    </div>

                                                    <div className='copybox'>
                                                        <a onClick={(e) => copy(TrxData?.payinAddress, {
                                                            debug: true,
                                                            message: 'Press #{key} to copy',
                                                        })}><span className='icobox'><img src="assets/icons/copyicon.svg" alt='' className='' /></span></a>
                                                        {/* <a href='#'><span className='icobox'><img src="assets/icons/sendicons.svg" alt='' className='' /></span></a> */}
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>
                                        <Col lg={12} className=''>
                                            <div className='depositaddress'>
                                                <p>Paying Hash :</p>
                                                <div className='recieptbox'>
                                                    <div className='text-break'>
                                                        <p>{TrxData?.payinHash}</p>
                                                    </div>

                                                    <div className='copybox'>
                                                        <a  onClick={(e) => copy(TrxData?.payinHash, {
                                                            debug: true,
                                                            message: 'Press #{key} to copy',
                                                        })}><span className='icobox'><img src="assets/icons/copyicon.svg" alt='' className='' /></span></a>
                                                        {/* <a href='#'><span className='icobox'><img src="assets/icons/sendicons.svg" alt='' className='' /></span></a> */}
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} md={12} className='my-5'>
                            <div className='start_exchange'>
                                <a href={config.baseUrl}>
                                    <span><img src="assets/icons/addcircle.svg" alt='' className='pe-3' /></span>
                                    Start a new exchange
                                </a>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>





            {/* <Footer /> */}


        </>
    )
}

export default Successful;
