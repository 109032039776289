import { cardData } from "../data/Data";
import { GoChevronRight } from "react-icons/go";
// import "./card.css";
import "../component/componentCss/card.css";
import "../component/componentCss/brand.css";
import { getCmsDataAction } from "../coreFiles/user.action";
import { useEffect, useState } from "react";


const Brand = () => {

  const [contactus,setContactUs] = useState([])
  useEffect(() => {
    getCmsData();
  }, []);

  const getCmsData = async () => {
    try {
      let res = await getCmsDataAction({ });
      if (res.success) {
        setContactUs(res.data);
      }
    } catch (err) {}
  };


  return (
    <>
    <div className="brandingbox">
      <div className="grid-container">
        <div className="inner">
          <div className="grid-item">
            <div className="content-container">
              <div className="item-content">
                <span className="number">{contactus[0]?.triumph_rate}</span>
                <span className="symbol">%</span>
              </div>
              <div className="text" id="rate">
                Triumph Rate
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="content-container">
              <div className="item-content">
                <span className="number">{contactus[0]?.satisfied_clients}</span>
                <span className="symbol">K</span>
              </div>
              <div className="text" id="client">
                Satisfied Clients
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="content-container">
              <div className="item-content" id="eighth">
                <span className="number">{contactus[0]?.cryptocurrencies}</span>
                <span className="symbol" id="plus">
                  +
                </span>
              </div>
              <div className="text" id="currency">
                Cryptocurrencies
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="content-container">
              <div className="item-content">
                <span className="number" id="five">
                {contactus[0]?.average_swap_time}
                </span>
                <span className="symbol" id="min">
                  MIN
                </span>
              </div>
              <div className="text" id="time">
                Average Swap Time
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Brand;
