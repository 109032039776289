import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card
} from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import "../componentCss/earn.css"
import "../componentCss/financial.css"
import "../componentCss/earnDashboard.css"
import 'react-circular-progressbar/dist/styles.css';


import { getAdvantageDataAction, getUserDetailsByIdAction, getWalletBalanceAction } from "../../coreFiles/user.action";
import Fincenter from "./fincenter";

const Overview = ({ setShowSection, loginUserData, setAcordianState }) => {
  // ------------------
  const availableWallet = (props) => (
    <Tooltip id="button-tooltip" {...props} >
      <div className="text-left p-1 mb-0">
        This is the amount available that you can withdraw or transfer to your Invested Wallet.
      </div>
    </Tooltip>
  );
  const investedWallet = (props) => (
    <Tooltip id="button-tooltip" {...props} >
      <div className="text-left p-1 mb-0">
        The total amount in your Invested Wallet that is currently generating profits.
      </div>
    </Tooltip>
  );
  const totalProfits = (props) => (
    <Tooltip id="button-tooltip" {...props} >
      <div className="text-left p-1 mb-0">
        The total amount of profits generated since your account was created.
      </div>
    </Tooltip>
  );
  const accountValue = (props) => (
    <Tooltip id="button-tooltip" {...props} >
      <div className="text-left p-1 mb-0">
        The total amount combining your Available and Invested Wallets, including any ongoing transfers.
      </div>
    </Tooltip>
  );
  const monthlyProfits = (props) => (
    <Tooltip id="button-tooltip" {...props} >
      <div className="text-left p-1 mb-0">
        The amount of profits accumulated this month, which will be made available to you at the end of the month.
      </div>
    </Tooltip>
  );
  const myInvestment = (props) => (
    <Tooltip id="button-tooltip" {...props} >
      <div className="text-left p-1 mb-0">
        The current interest rate percentage that your account is generating.
      </div>
    </Tooltip>
  );


  // -------------
  const switchTo = (newTab) => {
    setShowSection(newTab)
  }

  const [vipLevel, setVipLevel] = useState(1);
  const [userWalletBalance, setuserWalletBalance] = useState([])
  const [showblurSection, setshowblurSection] = useState(false);
  const [userVipLevelInfo, setUserVipLevelInfo] = useState([])
  const [handleEmailConfirmDetail, setHandleEmailConfirmDetail] = useState('')
  const [handleEmailVerify, setHandleEmailVerify] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [twofa_status, settwofa_status] = useState('')

  const totalUserAmount = userWalletBalance.coldWalletBalance + userWalletBalance.hotWalletBalance + userWalletBalance.pendingFund

  useEffect(() => {
    if (loginUserData) {
      getWalletBalance()
      getUserDetails()
      getAdvantageData()
      setshowblurSection
      setInterval(() => {
        getWalletBalance();
      }, 10000);
    }
  }, [])
  // <BlurSection />

  const getWalletBalance = async () => {
    try {
      let data = {
        walletAddress: loginUserData.walletAddress
      }
      let res = await getWalletBalanceAction(data);
      if (res.success) {
        setuserWalletBalance(res.data);
      }
    } catch (error) {
      console.log(error)
    }
  };
  const getAdvantageData = async () => {
    try {
      let res = await getAdvantageDataAction();
      if (res.success) {
        setUserVipLevelInfo(res.data.levelInfo);
      }
    } catch (error) {
      console.log(error)
    }
  };
  const getUserDetails = async () => {
    try {
      let res = await getUserDetailsByIdAction();
      setHandleEmailConfirmDetail(res.data[0].emailNotification)
      setHandleEmailVerify(res.data[0].isEmailVerify)
      setUserEmail(res.data[0].email);
      settwofa_status(res.data[0].twofa_status)
    } catch (error) {
      console.error("Error to fatch data of user :", error);
    }
  };

  const handleScroll = async () => {
    setAcordianState('0')
    window.scroll({
      top: 950,
      left: 950,
      behavior: 'smooth'
    });
  }


  return (

    <>
      <Row>
        <Col lg={12} className="">
          {handleEmailVerify == 1 || userEmail == null || userEmail == "" ?
            <>
              {twofa_status == 0 ?
                <Card className="border-0 mb-3" style={{ background: "rgb(255, 249, 230)", color: "#ac8515" }}>
                  <div className="alert_block infoBlock">
                    <div class="warningIconbox me-2">
                      <img src="assets/icons/warningIcon.png" alt="warmingIcon" className="alertIcon" />
                    </div>
                    <div className="alert-text">
                      <p className="mb-0">
                        We recommend activating 2FA to enhance the security of your account.
                      </p>
                    </div>
                  </div>
                </Card>
                :
                <Card className="border-0 mb-3" style={{ background: "#dbe9ff", color: "#3a5c90" }}>
                  <div className="alert_block infoBlock">
                    <div class="infoIconbox me-2">
                      <img src="assets/icons/infoIcon.png" alt="infoIcon" className="alertIcon" />
                    </div>
                    <div className="alert-text">
                      {totalUserAmount < 0.01 ?
                        <p onClick={handleScroll} style={{ cursor: 'pointer' }} className="mb-0">
                          Welcome to Purple Swap! <span style={{ textDecoration: 'underline' }}>Click here</span> to discover how to start generating profits now.
                        </p>
                        :
                        <p className="mb-0">
                          Welcome back to your Financial Center at Purple Swap! Enjoy managing your funds with ease.
                        </p>
                      }
                    </div>
                  </div>
                </Card>
              }
            </>
            :
            <Card className="border-0 mb-3" style={{ background: "rgb(255, 249, 230)", color: "#ac8515" }}>
              <div className="alert_block infoBlock">
                <div class="warningIconbox me-2">
                  <img src="assets/icons/warningIcon.png" alt="warmingIcon" className="alertIcon" />
                </div>
                <div className="alert-text">
                  <p className="mb-0">
                    Please confirm your email address. Look in your inbox.
                  </p>
                </div>
              </div>
            </Card>

          }


        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} className="mb-3 ">
          <Card className="border-0 p-3 ActiveBox shadow ">
            <div>
              <div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <small className="">Available Wallet &nbsp;
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={availableWallet}
                    >
                      <img src="assets/icons/info.svg" width={`16px`} style={{ marginTop: "-2px" }} />
                    </OverlayTrigger>
                  </small>
                  <small className="text-green d-none">↑ 0.00%</small>
                </div>

                <div className="d-flex align-items-end">
                  <h4 className="mb-0 me-1">{userWalletBalance.hotWalletBalance == null ? '0.00' : parseFloat(userWalletBalance.hotWalletBalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h4>
                  <small className="f fw-lighter">USDT</small>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={4} md={4} className="mb-3">
          <Card className="border-0 p-3 shadow ">
            <div>
              <div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <small className="d-flex align-items-center">Invested Wallet &nbsp;
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={investedWallet}
                    >
                      <img src="assets/icons/info.svg" width={`16px`} style={{ marginTop: "-2px" }} />
                    </OverlayTrigger>
                  </small>
                  <small className="text-green  d-none">↑ 0.00%</small>
                </div>

                <div className="d-flex align-items-end">
                  <h4 className="mb-0 me-1">{userWalletBalance.coldWalletBalance == null ? '0.00' : parseFloat(userWalletBalance.coldWalletBalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h4>
                  <small className="f fw-lighter">USDT</small>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={4} md={4} className="mb-3">
          <Card className="border-0 p-3 shadow ">
            <div>
              <div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <small className="">Total Profits &nbsp;
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={totalProfits}
                    >
                      <img src="assets/icons/info.svg" width={`16px`} style={{ marginTop: "-2px" }} />
                    </OverlayTrigger>
                  </small>
                  <small className="text-green  d-none">↑ 0.00%</small>
                </div>

                <div className="d-flex align-items-end">
                  <h4 className="mb-0 me-1">{userWalletBalance.totalProfit == null ? '0.00' : parseFloat(userWalletBalance.totalProfit).toFixed(2)}</h4>
                  <small className="f fw-lighter">USDT</small>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} className="mb-4">
          <Card className="border-0 p-3 shadow ">
            <div className="PriceBox">
              <div>
                <div className="d-flex mb-1">
                  <small className="">Account Value
                    &nbsp;
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={accountValue}
                    >
                      <img src="assets/icons/info.svg" width={`16px`} style={{ marginTop: "-2px" }} />
                    </OverlayTrigger></small>
                </div>
                <div className="d-flex align-items-end">
                  <h4 className="mb-0 me-1">
                    {parseFloat(totalUserAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </h4>
                  <small className="f fw-lighter">USDT</small>
                </div>
              </div>
              <hr className="my-3" />

              <div className="PriceBoxlist">
                <div className="d-flex align-items-center justify-content-between">
                  <div><p className="mb-2">Available Funds</p></div>
                  <div className="text-right"><p className="mb-2">{userWalletBalance.hotWalletBalance == null ? '0.00' : parseFloat(userWalletBalance.hotWalletBalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</p></div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div><p className="mb-2">Invested Funds</p></div>
                  <div className="text-right"><p className="mb-2">{userWalletBalance.coldWalletBalance == null ? '0.00' : parseFloat(userWalletBalance.coldWalletBalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</p></div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div><p className="mb-0">Pending Funds</p></div>
                  <div className="text-right"><p className="mb-0">{parseFloat(userWalletBalance.pendingFund).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</p></div>
                </div>

                <hr className="my-3" />
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div><p className="mb-0 fw-bold">Total</p></div>
                  <div className="text-right">
                    <p className="mb-0 fw-bold">
                      {parseFloat(userWalletBalance.coldWalletBalance + userWalletBalance.hotWalletBalance + userWalletBalance.pendingFund).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}

                    </p>

                  </div>
                </div>
              </div>
              <Button onClick={() => switchTo('Wallet')} className="select-btn btn btn-primary rounded-2 text-black justify-content-center">Wallet</Button>
            </div>
          </Card>
        </Col>
        <Col lg={4} md={4} className="mb-4">
          <Card className="border-0 p-3 shadow ">
            <div className="PriceBox">
              <div>
                <div className="d-flex mb-1">
                  <small className="">Monthly Profits
                    &nbsp;
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={monthlyProfits}
                    >
                      <img src="assets/icons/info.svg" width={`16px`} style={{ marginTop: "-2px" }} />
                    </OverlayTrigger></small>
                </div>

                <div className="d-flex align-items-end">
                  <h4 className="mb-0 me-1">{userWalletBalance.monthlyProfit == null ? '0.00' : parseFloat(userWalletBalance.monthlyProfit).toFixed(2)}</h4>
                  <small className="f fw-lighter">USDT</small>
                </div>
              </div>
              <hr className="my-3" />

              <div className="PriceBoxlist">
                <div className="d-flex align-items-center justify-content-between">
                  <div><p className="mb-2">Locked Profits</p></div>
                  <div className="text-right"><p className="mb-2">{userWalletBalance.monthlyProfit == null ? '0.00' : parseFloat(userWalletBalance.monthlyProfit).toFixed(2)}</p></div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div><p className="mb-2">Rewards</p></div>
                  <div className="text-right"><p className="mb-2">0.00</p></div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div><p className="mb-0">Referrals</p></div>
                  <div className="text-right"><p className="mb-0">0.00</p></div>
                </div>
                <hr className="my-3" />
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div><p className="mb-0 fw-bold">Total</p></div>
                  <div className="text-right"><p className="mb-0 fw-bold">{userWalletBalance.totalProfit == null ? '0.00' : parseFloat(userWalletBalance.totalProfit).toFixed(2)}</p></div>
                </div>
              </div>
              <Button className="select-btn btn btn-primary rounded-2 text-black justify-content-center" onClick={() => switchTo('Statistics')}>
                <div className="position-relative">
                  Statistics
                  <sup className="soon"></sup>
                </div>
              </Button>


            </div>
          </Card>
        </Col>
        <Col lg={4} md={4} className="mb-4">
          <Card className="border-0 p-3 shadow ">
            <div className="PriceBox">
              <div>
                <div className="d-flex mb-1">
                  <small className="">My Investment
                    &nbsp;
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={myInvestment}
                    >
                      <img src="assets/icons/info.svg" width={`16px`} style={{ marginTop: "-2px" }} />
                    </OverlayTrigger>
                  </small>
                </div>

                <div className="d-flex align-items-end">
                  <h4 className="mb-0 me-1">{userWalletBalance.coldWalletBalance > 100 ? "Active" : "Inactive"}</h4>
                </div>
              </div>
              <hr className="my-3" />

              <div className="PriceBoxlist">
                <div className="d-flex align-items-center justify-content-between">
                  <div><p className="mb-2">Daily Interest</p></div>
                  <div className="text-right"><p className="mb-2">  {parseFloat((userWalletBalance.coldWalletBalance * userVipLevelInfo.dailyIntrest) / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</p></div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div><p className="mb-2">Monthly Interest</p></div>
                  <div className="text-right"><p className="mb-2">
                    {parseFloat((userWalletBalance.coldWalletBalance * userVipLevelInfo.monthlyIntrest) / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </p></div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div><p className="mb-0">Yearly Interest</p></div>
                  <div className="text-right"><p className="mb-0">
                    {parseFloat((userWalletBalance.coldWalletBalance * userVipLevelInfo.yearlyIntrest) / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </p></div>
                </div>
              </div>
              <Button className="select-btn btn btn-primary rounded-2 text-black justify-content-center" onClick={() => switchTo('Transaction')}>Transactions</Button>

            </div>
          </Card>
        </Col>
      </Row>



    </>

  );
}
export default Overview;