import { useEffect, useState } from 'react';
// import './loader.css';
import "../component/componentCss/loader.css";

const Loader = ({ setIsMainLoader }) => {
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [progressMessage, setProgressMessage] = useState('Initiating...');

  const loadingPoints = [
    { time: 0.5, percentage: 22, color: '#987bff', message: 'Validate swap parameters…' },
    { time: 0.9, percentage: 35, color: '#987bff', message: 'Securing connection…' },
    { time: 1.2, percentage: 65, color: '#4e7aee', message: 'Optimizing exchange rates…' },
    { time: 1.8, percentage: 99, color: '#4e7aee', message: 'Creating trade order…' },
    { time: 2.2, percentage: 100, color: '#26b6d8', message: 'Completed!' },
  ];

  // const loadingPoints = [
  //   { time: 0.5, percentage: 22, color: '#987bff', message: 'Validate swap parameters…' },
  //   { time: 10.25, percentage: 35, color: '#987bff', message: 'Securing connection…' },
  //   { time: 20, percentage: 65, color: '#4e7aee', message: 'Optimizing exchange rates…' },
  //   { time: 30.25, percentage: 99, color: '#4e7aee', message: 'Creating trade order…' },
  //   { time: 1, percentage: 100, color: '#26b6d8', message: 'Completed!' },
  // ];

  useEffect(() => {
    let totalTime = 0;

    loadingPoints.forEach((point) => {
      totalTime += point.time * 1000;

      setTimeout(() => {
        setLoadingPercentage(point.percentage);
        setProgressMessage(point.message);
      }, totalTime);
    });
    setTimeout(() => {
      setIsMainLoader(false)
    }, totalTime + 1000)
  }, []);

  return (
    <>
      <div className="line">
        <div className="circle"></div>
      </div>

      <div className="loading-bar-container">
        <div
          className="loading-bar"
          style={{
            width: `${loadingPercentage}%`,
            // backgroundColor: loadingPercentage === 100 ? '#26b6d8' : '#ccc',
            backgroundColor: loadingPercentage === 100 ? '#ccc' : '',
          }}
        ></div>
      </div>

      <div className="progress-message">{progressMessage}</div>
    </>
  );
};

export default Loader;