import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
} from "react-bootstrap";
import "../componentCss/earn.css"
import "../componentCss/financial.css"
import "../componentCss/earnDashboard.css"

import Linechart from '../../component/linechart'
import { getUserStatisticsAction, getWalletBalanceAction } from "../../coreFiles/user.action";

import Cookies from "js-cookie";

const Statistics = () => {
  const [userStatistics, setUserStatistics] = useState([]);
  let loginUserData = Cookies.get('purpleUser') ? JSON.parse(Cookies.get('purpleUser')) : '';
  const [userWalletBalance, setuserWalletBalance] = useState([]);

  useEffect(() => {
    getUserStatistics();
    getWalletBalance();
  }, [])

  const getUserStatistics = async () => {
    try {
      let res = await getUserStatisticsAction();
      if (res.success) {
        setUserStatistics(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  };
  const getWalletBalance = async () => {
    try {
      let res = await getWalletBalanceAction();
      if (res.success) {
        setuserWalletBalance(res.data);
      }
    } catch (error) {
      console.log(error)
    }
  };
  
  return (
    <>

      <Row>
        <Col lg={4} className="mb-3">
          <Card className="border-0 p-3 shadow ">
            <div>
              <div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <small className="">Total Deposits</small>
                </div>

                <div className="d-flex align-items-end">
                  <h4 className="mb-0 me-1">{parseFloat(userStatistics.totalDeposit).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h4>
                  <small className="f fw-lighter">USDT</small>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={4} className="mb-3">
          <Card className="border-0 p-3 shadow ">
            <div>
              <div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <small className="">Total Withdrawals</small>
                </div>

                <div className="d-flex align-items-end">
                  <h4 className="mb-0 me-1">{parseFloat(userStatistics.totalWithdrawal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h4>
                  <small className="f fw-lighter">USDT</small>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={4} className="mb-3">
          <Card className="border-0 p-3 shadow ">
            <div>
              <div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <small className="">Total Profits</small>
                </div>

                <div className="d-flex align-items-end">
                  <h4 className="mb-0 me-1">{parseFloat(userStatistics.totalProfit).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h4>
                  <small className="f fw-lighter">USDT</small>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={4} className="mb-3">
          <Card className="border-0 p-3 shadow ">
            <div>
              <div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <small className="">Total Transfers</small>
                </div>

                <div className="d-flex align-items-end">
                  <h4 className="mb-0 me-1">{userStatistics.totalTransfer}</h4>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={4} className="mb-3">
          <Card className="border-0 p-3 shadow ">
            <div>
              <div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <small className="">Total Transactions</small>
                </div>

                <div className="d-flex align-items-end">
                  <h4 className="mb-0 me-1">{userStatistics.totalTransation}</h4>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={4} className="mb-3">
          <Card className="border-0 p-3 shadow ">
            <div>
              <div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <small className="">Account Age</small>
                </div>

                <div className="d-flex align-items-end">
                  <h4 className="mb-0 me-1">{userStatistics.accountAge}</h4>
                  <small className="f fw-lighter">DAYS</small>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={12} className="mb-4">
          <Card className="border-0 p-3 shadow rounded-8  card ">

            <Linechart userWalletBalance={userWalletBalance} />
          </Card>
        </Col>
        {/* <Col lg={12} className="mb-4">
        <div className="d-flex justify-content-between paginationBtn">
          <div>
            <Button className="select-btn btn btn-primary rounded-2 text-black p-3 justify-content-center px-3">Previous</Button>
          </div>
          <div>
            <Button className="select-btn btn btn-primary rounded-2 text-black p-3 justify-content-center  px-3">Refresh</Button>
          </div>
          <div>
            <Button className="select-btn btn btn-primary rounded-2 text-black p-3 justify-content-center  px-3">Next</Button>
          </div>
        </div>

      </Col> */}

      </Row>

    </>
  );
}
export default Statistics;